import React, { useEffect, useState, version } from "react";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import platform from "platform";
import { Button, Card, Col, Container, Form, Row, Nav } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import {
  generateDetails,
  uploadMultiImage,
} from "../../../api/SupportForm.api";
import { DECRYPTED_DATA, EMP_ID, IMG_TOKEN } from "../../../utils/Variable";
import "./Support_Form.css";
import { getSupportDropdownValues } from "../../../api/SupportForm.api";

import _ from "lodash";
import { getDataInFormat, DateTimeConversion } from "../../../utils/Helper";
import Loading from "../../../components/Loading/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function SupportForm() {
  const [dropdownoptions, setdropdownoptions] = useState([]);
  const [option, setOption] = useState();
  const [impactoption, setimpactOption] = useState("");
  const [datetimevalue, setdatetimevalue] = useState();
  const [urgencyoption, seturgencyOption] = useState("");
  const [description, setDescription] = useState();
  const [summary, setSummary] = useState();
  const [submittedData, setsubmittedData] = useState(false);
  const [getLoading, setLoading] = useState(false);
  const [selectedDate, setselectedDate] = useState(null);
  //   const [customerDetails, setDetails] = useState("");
  const [file, setFile] = useState([]);
  const [isSuccess, setIsSuccess] = useState({
    status_code: "",
    successMsg: "",
  });
  const [getLoc, setLoc] = useState({ lat: "", lng: "" });
  const [showOtherTextfield, setshowOtherTextfield] = useState(false);
  const [otherText, setotherText] = useState("");

  const onChangehandler1 = (event) => {
    if (event.value === "Others") {
      setshowOtherTextfield(true);
    } else {
      setshowOtherTextfield(false);
    }
    setOption(event.value);
  };
  const onImpactChangehandler = (event) => {
    setimpactOption(event.value);
  };
  const onUrgencyChangehandler = (event) => {
    seturgencyOption(event.value);
  };

  const handleDateChange = (date) => {
    setdatetimevalue(DateTimeConversion(date));
    const informat = getDataInFormat(date, true);
    setselectedDate(informat);
  };
  const getDateObject = (strDate) => {
    const [datePart, timePart] = strDate.split(" ");
    const [day, month, year] = datePart.split("/");
    const [hour, minutes] = timePart.split(":");
    let hourValue = parseInt(hour);
    if (hour.includes("PM") && hourValue !== 12) {
      hourValue += 12;
    } else if (hour.includes("AM") && hourValue === 12) {
      hourValue = 0;
    }
    return new Date(year, month - 1, day, hourValue, minutes);
  };

  const selectedDescription = (event) => {
    setDescription(event.target.value);
    let option = event.target.value;
  };

  const selectedSummary = (event) => {
    setSummary(event.target.value);
  };
  const submitData1 = async () => {
    setsubmittedData(true);
    if (
      !onChangehandler1 ||
      !selectedDescription ||
      !uploadSingleFile ||
      !option
    )
      return;

    const combinedOption = showOtherTextfield ? `Others-${otherText}` : option;
    const source_details = {
      Browser: platform.name,
      os: platform.os.family,
      version: platform.version,
    };

    let param = {
      summary: summary,
      details: description,
      userdevice: source_details,
      feature: combinedOption,
      impact: impactoption,
      urgency: urgencyoption,
      imagelist: file,
      affecteddate: datetimevalue === undefined ? "" : datetimevalue,
    };
    const response = await generateDetails(
      param,
      setLoading,
      setIsSuccess,
      setsubmittedData
    );
  };

  async function uploadSingleFile(e) {
    var fileName = document.getElementById("fileName").value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      const uploadedImage = e.target.files[0];

      setFile((prevImages) => [...prevImages, uploadedImage]);
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
    }
  }

  useEffect(() => {
    let element = document.getElementById("myDiv");
    element.classList.add("watermark-logo");
    var _latlng;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        _latlng = _.cloneDeep(getLoc);

        _latlng.lat = position.coords.latitude;
        _latlng.lng = position.coords.longitude;

        setLoc(_latlng);
      });
    }
    return (() => {
      element.classList.remove("watermark-logo");
    })
  }, []);

  useEffect(() => {
    const getApiResponse = async () => {
      const response = await getSupportDropdownValues();
      const getRes = await response;
      setdropdownoptions(
        getRes.map((option) => ({
          value: option,
          label: option,
        }))
      );
    };
    getApiResponse();
  }, []);

  const impactoptions = [
    { value: "Company Wide", label: "Company Wide" },
    { value: "Multiple Users Affected", label: "Multiple Users Affected" },
    { value: "Single User Affected", label: "Single User Affected" },
  ];

  const urgencyoptions = [
    { value: "High", label: "High" },
    { value: "Medium", label: "Medium" },
    { value: "Low", label: "Low" },
  ];

  const handleRemoveImage = (e) => {
    setFile((prevImages) => prevImages.filter((_, i) => i !== e));
  };

  return (
    <Container
      className="margin-container"
      style={{ backgroundColor: "#fffff" }}
    >
      {
        <div className=" d-flex justify-content-xl-center align-items-center ">
          <Card className="card-custom shadow-lg p-3 mb-5 rounded">
            <div className="d-flex justify-content-center "></div>
            <Card.Header className="d-flex justify-content-center card-header-custom1">
              <h3>Support Request Form</h3>
            </Card.Header>
            <small
              id="emailHelp"
              className="d-flex justify-content-center text-muted"
            >
              <a
                href="support@safeworker.ai"
                style={{
                  fontSize: "13px",
                  color: "grey",
                }}
              >
                support@safeworker.ai
              </a>
            </small>

            <Card.Body>
              <Form>
                <Form.Group>
                  <Form.Label className="text-dark ">
                    <span className="text-danger bold-font">*</span>Summary
                  </Form.Label>

                  <Form.Control
                    className="rounded-10"
                    type="text"
                    onChange={selectedSummary}
                  />
                </Form.Group>
                <Form.Label className="text-dark mt-4">
                  <span className="text-danger mt-4 bold-font">*</span>Feature
                </Form.Label>

                <Select
                  options={dropdownoptions}
                  value={option !== undefined ? option.value : option}
                  onChange={onChangehandler1}
                  placeholder="Select Feature"
                />
                <br />

                {showOtherTextfield && (
                  <Form.Control
                    className="mb-3"
                    type="text"
                    value={otherText}
                    onChange={(e) => setotherText(e.target.value)}
                  />
                )}

                <Form.Group className="mb-3">
                  <Form.Label className="text-dark ">Impact</Form.Label>
                  <Select
                    options={impactoptions}
                    placeholder="--Select --"
                    value={
                      impactoption !== undefined
                        ? impactoption.value
                        : impactoption
                    }
                    onChange={onImpactChangehandler}
                    isSearchable
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="text-dark ">Urgency</Form.Label>
                  <Select
                    options={urgencyoptions}
                    placeholder="--Select --"
                    value={
                      urgencyoption !== undefined
                        ? urgencyoption.value
                        : urgencyoption
                    }
                    onChange={onUrgencyChangehandler}
                    isSearchable
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label className="text-dark ">Affected Since</Form.Label>
                  <Row>
                    <Col lg={12} xl={12} md={12} xs={12}>
                      <DatePicker
                        selected={
                          selectedDate ? getDateObject(selectedDate) : null
                        }
                        onChange={handleDateChange}
                        placeholder="--Select Date--"
                        className="custom-datecss"
                        showTimeSelect
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="MM/dd/yyyy HH:mm aa"
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="text-dark ">
                    <span className="text-danger bold-font">*</span>Description
                    <br />
                    <small
                      id="emailHelp"
                      className="form-text text-muted"
                      style={{ fontSize: "12px", marginLeft: "5px" }}
                    >
                      Please provide a detailed description
                    </small>
                  </Form.Label>

                  <Form.Control
                    onChange={selectedDescription}
                    className="rounded-10"
                    as="textarea"
                    rows={5}
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label className="text-dark ">
                    <span className="text-danger bold-font"></span>Attachments
                  </Form.Label>
                  <div className="form-group">
                    <input
                      type="file"
                      id="fileName"
                      accept="image/png, image/gif, image/jpeg"
                      disabled={file.length === 6}
                      className="form-control"
                      onChange={uploadSingleFile}
                    />
                    <small id="emailHelp" className="form-text text-muted">
                      You Can Upload Upto 6 Images
                    </small>
                  </div>

                  <form>
                    <div className="form-group preview">
                      {file.length > 0 &&
                        file.map((item, index) => {
                          return (
                            <div key={index} className="ImageDiv">
                              <Col>
                                <div
                                  className="removeImg1"
                                  onClick={() => handleRemoveImage(index)}
                                >
                                  x
                                </div>
                                <img
                                  src={URL.createObjectURL(item)}
                                  className="image-preview item-w m-1  "
                                  alt=""
                                />
                              </Col>
                            </div>
                          );
                        })}
                    </div>
                  </form>
                </Form.Group>
              </Form>
            </Card.Body>
            {isSuccess.successMsg !== "" ? (
              <Card.Body
                style={{
                  textAlign: "center",
                  color: isSuccess.status_code === 200 ? "#288964" : " #dc2d37",
                  marginTop: "0px",
                }}
              >
                {isSuccess.successMsg}
              </Card.Body>
            ) : null}
            <div className="d-flex justify-content-around mt-10 my-3">
              <Button
                variant="dark"
                size="md"
                type="button"
                style={{ borderRadius: "5px" }}
                disabled={
                  !option ||
                  !summary ||
                  (!description && !showOtherTextfield) ||
                  (showOtherTextfield && option === "Others" && !otherText)
                }
                onClick={submitData1}
              >
                Submit
              </Button>
            </div>
          </Card>
        </div>
      }
      {submittedData ? <Loading width="32" height="32" /> : null}
    </Container>
  );
}
export default SupportForm;
