import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./FormProgressBarforDynamic.scss";
import _ from "lodash";

export default function FormProgressBarforDynamic({
  getClimber,
  inProgressSection,
  getIsCompleted,
  getCrewfill,
  getCrewfillComplete,
  setCrewfillComplete,
  getsummary,
  setProceed,
  getCrewLead,
  getProgressCount,
  _selectedSectionJson,
  form_template,
  getReportRequired,
  getProceed,
  setProceedOld
}) {
  // console.log(getProgressCount, _selectedSectionJson)
  const [getProgress, setProgress] = useState([]);
  const [getCrewMemProgress, setCrewMemProgress] = useState([]);
  const [getCrewMembercount, setCrewMembercount] = useState([]);

  useEffect(() => {
    let _getCrewCount = _.cloneDeep(getCrewMembercount);
    _getCrewCount.push(getCrewLead);
    _getCrewCount = [...new Set(_getCrewCount)];
    setCrewMembercount(_getCrewCount);
  }, [getCrewLead]);

  useEffect(() => {
    if (getReportRequired && !inProgressSection) {
      let _getbackCountArr = form_template.map((i, k) => i.visibility && k);
      let _backCount = _getbackCountArr.filter((z) => z !== false);
      _backCount = [...new Set(_backCount)];
      setProgress(_backCount);
    }
  }, []);

  useEffect(() => {
    if (getCrewMembercount.includes(getCrewLead)) {
      if (!getCrewfill) {
        if (inProgressSection) {
          let _emptyArr = _.cloneDeep(getProgress);
          for (let i = 0; i < getProgressCount; i++) {
            _emptyArr.push(i);
          }
          _emptyArr = [...new Set(_emptyArr)];
          setProgress(_emptyArr);
        } else {
          let _emptyArr = _.cloneDeep(getProgress);
          _emptyArr.push(getProgressCount - 1);
          _emptyArr = [...new Set(_emptyArr)];
          setProgress(_emptyArr);
        }
      } else {
        let _emptyCrewArr = [];
        if (!getCrewfillComplete) {
          setCrewfillComplete(true);
          setCrewMemProgress([]);
        } else {
          _emptyCrewArr = _.cloneDeep(getCrewMemProgress);
        }
        _emptyCrewArr.push(getProgressCount - 1);
        _emptyCrewArr = [...new Set(_emptyCrewArr)];
        setCrewMemProgress(_emptyCrewArr);
      }
    } else {
      let _emptyArr = _.cloneDeep(getCrewMemProgress);
      for (let i = 0; i < getProgressCount; i++) {
        _emptyArr.push(i);
      }
      _emptyArr = [...new Set(_emptyArr)];
      // setProgress(_emptyArr);
      setCrewMemProgress(_emptyArr);
    }
  }, [getProgressCount, getCrewMembercount]);

  const selectGo = (k, e) => {
    if (!e.currentTarget.classList.contains("visibleSummary") && e.currentTarget.classList.contains("selected")) {
      if (getReportRequired && !inProgressSection) {
        if (getClimber) {
          if (k <= 1) {
            setProceedOld(getProceed);
            setProceed(k);
          }
        } else {
          setProceedOld(getProceed);
          setProceed(k);
        }
      } else {
        let _finsalArr = [...new Set(getProgress)];
        if (_finsalArr.includes(k)) {
          if (getClimber) {
            if (k <= 1) {
              setProceedOld(getProceed);
              setProceed(k);
            }
          } else {
            setProceedOld(getProceed);
            setProceed(k);
          }
        }
      }
    }
  };
  const selectGoCrew = (k, e) => {
    if (!e.currentTarget.classList.contains("visibleSummary") && e.currentTarget.classList.contains("selected")) {
      if ((getReportRequired && getIsCompleted) || !getCrewfill) {
        setProceedOld(getProceed);
        setProceed(k);
      } else {
        let _finsalArr = [...new Set(getCrewMemProgress)];
        if (_finsalArr.includes(k)) {
          setProceedOld(getProceed);
          setProceed(k);
        }
      }
    }
  };

  return (
    <>
      {!getCrewfill ? (
        <ul className="step-wizard-list iJHA">
          {_selectedSectionJson.map((i, k) => {
            if (i.visibility) {
              return (
                <li
                  key={k}
                  onClick={(e) => selectGo(k, e)}
                  id={`step${k}`}
                  className={`step-wizard-item ${getProgressCount === k + 1 ? "current-item " : ""}${getProgress.includes(k + 1) ? "selected " : ""}${getClimber && k > 1 ? "notProceed " : ""}${getsummary ? "visibleSummary" : ""}`}>
                  <span className="progress-count"></span>
                  <span className="progress-label">{i.sectionName}</span>
                </li>
              );
            }
          })}
        </ul>
      ) : (
        <ul className="step-wizard-list">
          {_selectedSectionJson.map((i, k) => {
            if ("section" in i) {
              return (
                <li
                  key={k}
                  onClick={(e) => selectGoCrew(k, e)}
                  className={`step-wizard-item ${getProgressCount === k + 1 ? "current-item " : ""}${getCrewMemProgress.includes(k) || getReportRequired ? "selected " : ""}${getClimber && k > 1 ? "notProceed " : ""}${getsummary ? "visibleSummary " : ""}`}>
                  <span className="progress-count">{k + 1}</span>
                  <span className="progress-label">{i.sectionName}</span>
                </li>
              );
            }
          })}
        </ul>
      )}
    </>
  );
}