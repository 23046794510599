import { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import GMapStatic from "../GMap/GMapStatic";
import GMapDirection from "../GMap/GMapDirection";
import { removeTags } from "../../utils/Helper";
import MultiSelect3 from "../MultiSelect3/MultiSelect3";
import {
  getLevelDataApi,
  getTraumaCenterNameApi,
  getHospitalDirectionFromToApi,
} from "../../api/CreateiJHAforIJHADynamic.api";
import _, { cloneWith, identity } from "lodash";
import Loading from "../Loading/Loading";
export default function ModalMapTramaCenterforIJhaDynamic({
  showMdl,
  setShowMdl,
  dataMdl,
  setAddress,
  getAddress,
  form_template,
  setForm_template,
}) {
  // consolelog('dataMdl::', dataMdl, getAddress, dataMdl.fullAdd);

  const [getLevelOptions, setLevelOptions] = useState([]);
  const [getLevelData, setLevelData] = useState([]);
  const [getLoading, setLoading] = useState(false);
  const [getErrMsg, setErrMsg] = useState("");
  const [getTraumaCenterName, setTraumaCenterName] = useState([]);
  const [getMultioptionData, setMultioptionData] = useState({});
  const [getLatLng, setLatLng] = useState({ lat: "", lng: "" });
  const [getFullAdd, setFullAdd] = useState(getAddress);
  const [getDistDura, setDistDura] = useState({
    distance: "Not Available",
    duration: "Not Available",
  });
  const [getCheckUSA, setCheckUSA] = useState(false);
  var defaultOptions = [],
    LevelOptions = [];
  useEffect(() => {
    getLevelDataApi(
      setLevelData,
      setCheckUSA,
      dataMdl.getCurrLatitude,
      dataMdl.getCurrLongitude,
      setTraumaCenterName,
      setLoading,
      setErrMsg
    );
    setLatLng({
      lat: Number(dataMdl.getLatitude),
      lng: Number(dataMdl.getLongitude),
    });
    // getTraumaCenterNameApi(setTraumaCenterName, getLatitude, getLongitude, getLevelData.default_levels, setLoading, setErrMsg);
  }, [dataMdl.getLatitude, dataMdl.getCurrLatitude]);

  useEffect(() => {
    // consolelog(getLevelOptions, 'getLevelOptions')
    !_.isEmpty(getLevelOptions) &&
      getTraumaCenterNameApi(
        setTraumaCenterName,
        setCheckUSA,
        dataMdl.getCurrLatitude,
        dataMdl.getCurrLongitude,
        _.join(
          getLevelOptions.map((obj) => obj.value),
          ","
        ),
        setLoading,
        setErrMsg
      );
  }, [getLevelOptions]);

  useEffect(() => {
    if (getTraumaCenterName.length !== 0) {
      let _getAddress;
      {
        getTraumaCenterName[0].CITY !== "" &&
        getTraumaCenterName[0].STATE !== "" &&
        getTraumaCenterName[0].ZIP !== "" &&
        getTraumaCenterName[0].COUNTRY !== "" &&
        getTraumaCenterName[0].TELEPHONE !== ""
          ? (_getAddress =
              getTraumaCenterName[0].ADDRESS +
              ", " +
              getTraumaCenterName[0].CITY +
              "\n" +
              getTraumaCenterName[0].STATE +
              ", " +
              getTraumaCenterName[0].ZIP +
              "\n" +
              getTraumaCenterName[0].COUNTRY +
              "\n" +
              "TELEPHONE: " +
              getTraumaCenterName[0].TELEPHONE)
          : (_getAddress = getTraumaCenterName[0].ADDRESS);
      }
      const _getLatLng = {
        lat: Number(getTraumaCenterName[0].LATITUDE),
        lng: Number(getTraumaCenterName[0].LONGITUDE),
      };
      setDistDura({
        distance: getTraumaCenterName[0]["Distance"],
        duration: getTraumaCenterName[0]["Duration"],
      });
      setLatLng(_getLatLng);
      setAddress(_getAddress);
      setFullAdd(
        getTraumaCenterName[0].NAME +
          "\n" +
          _getAddress +
          "\n" +
          `(${getTraumaCenterName[0].LATITUDE},${getTraumaCenterName[0].LONGITUDE})`
      );
    }
  }, [getTraumaCenterName]);

  const onAdd = async () => {
    if (!dataMdl.direction) {
      // dataMdl.changeFormTemplateData([4, getAddress, dataMdl._key]);
      // consolelog("getFullAdd::", getFullAdd)
      //  await dataMdl.changeFormTemplateData([23, [getFullAdd, getLatLng.lat, getLatLng.lng], dataMdl._key]);
      let param = {
        lat: dataMdl.getLatitude,
        lng: dataMdl.getLongitude,
        hosp_lat: getLatLng.lat,
        hosp_lng: getLatLng.lng,
      };
      await getHospitalDirectionFromToApi(
        setForm_template,
        form_template,
        param,
        getFullAdd,
        setLoading
      );
    }

    setShowMdl(false);
    // setAddress("");
  };

  useEffect(() => {
    // consolelog("getLevelData", getLevelData, !(_.isEmpty(getLevelData)))
    LevelOptions =
      !_.isEmpty(getLevelData) &&
      getLevelData.tlevels.map((obj, i) => {
        return {
          value: obj,
          label: obj,
          color: "#00B8D9",
          _key: i,
        };
      });

    defaultOptions =
      !_.isEmpty(getLevelData) &&
      getLevelData.default_levels.map((obj, i) => {
        return {
          value: obj,
          label: obj,
          color: "#00B8D9",
          _key: obj,
        };
      });
    // consolelog("getLevelData::l d:", LevelOptions, defaultOptions);
    const _getMultioptionData = _.cloneDeep(getMultioptionData);
    _getMultioptionData["defaultOptions"] = defaultOptions;
    _getMultioptionData["LevelOptions"] = LevelOptions;
    setMultioptionData(_getMultioptionData);
  }, [getLevelData]);

  // useEffect(() => typeof dataMdl.fullAdd !== 'undefined' && setAddress(dataMdl.fullAdd[1] + '\n' + dataMdl.fullAdd[2] + '\n' + dataMdl.fullAdd[3] + '\n' + dataMdl.fullAdd[4]), [dataMdl.fullAdd])
  const onSortByHandler = (_value) => {
    let _getTraumaCenterName = _.cloneDeep(getTraumaCenterName);
    // _getTraumaCenterName = _getTraumaCenterName.map(obj => {
    //     console.log(obj.ADDRESS, " ", obj[_value], " ",)
    //     obj["_Distance"] = Number(_.split(obj["Distance"], ' ')[0])
    //     obj["_Duration"] = Number(_.split(obj["Duration"], ' ')[0])
    //     return obj;
    // })
    // consolelog(_getTraumaCenterName);
    _getTraumaCenterName = _.orderBy(_getTraumaCenterName, [_value], ["asc"]);
    setTraumaCenterName(_getTraumaCenterName);
  };
  const onTraumaCenterChange = (_value) => {
    let _getAddress;
    {
      getTraumaCenterName[_value].CITY !== "" &&
      getTraumaCenterName[_value].STATE !== "" &&
      getTraumaCenterName[_value].ZIP !== "" &&
      getTraumaCenterName[_value].COUNTRY !== "" &&
      getTraumaCenterName[_value].TELEPHONE !== ""
        ? (_getAddress =
            getTraumaCenterName[_value].ADDRESS +
            ", " +
            getTraumaCenterName[_value].CITY +
            "\n" +
            getTraumaCenterName[_value].STATE +
            ", " +
            getTraumaCenterName[_value].ZIP +
            "\n" +
            getTraumaCenterName[_value].COUNTRY +
            "\n" +
            "TELEPHONE: " +
            getTraumaCenterName[_value].TELEPHONE)
        : (_getAddress = getTraumaCenterName[_value].ADDRESS);
    }
    const _getLatLng = {
      lat: Number(getTraumaCenterName[_value].LATITUDE),
      lng: Number(getTraumaCenterName[_value].LONGITUDE),
    };
    setDistDura({
      distance: getTraumaCenterName[_value]["Distance"],
      duration: getTraumaCenterName[_value]["Duration"],
    });
    setLatLng(_getLatLng);
    setAddress(_getAddress);
    setFullAdd(
      getTraumaCenterName[_value].NAME +
        "\n" +
        _getAddress +
        "\n" +
        `(${getTraumaCenterName[_value].LATITUDE},${getTraumaCenterName[_value].LONGITUDE})`
    );
  };
  return (
    <>
      <Modal show={showMdl} onHide={() => setShowMdl(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Trauma Center</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {getLoading ? (
            <Loading width="32" height="32" />
          ) : (
            <div>
              <Form>
                <>
                  {!getCheckUSA && (
                    <Row>
                      <Form.Group controlId="levels">
                        <Form.Label>Levels</Form.Label>
                      </Form.Group>
                      <Col>
                        <MultiSelect3
                          options={getMultioptionData}
                          getOption={getLevelOptions}
                          setOption={setLevelOptions}
                        ></MultiSelect3>
                      </Col>
                    </Row>
                  )}
                  {!getCheckUSA && (
                    <Row>
                      <Col>
                        <Form.Group controlId="Sortby" className="mb-3">
                          <Form.Label>Sort by</Form.Label>

                          <Form.Select
                            aria-label="SortbySelect"
                            className="rounded-0"
                            defaultValue={"_Duration"}
                            onChange={(e) => onSortByHandler(e.target.value)}
                          >
                            <option value="_Duration">Duration</option>
                            <option value="_Distance">Distance</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <Form.Group controlId="TraumaCenterName" className="mb-3">
                        <Form.Label>Trauma Center Name</Form.Label>
                        <Form.Select
                          aria-label="TraumaCenterNameSelect"
                          className="rounded-0"
                          defaultValue={_.findIndex(
                            getTraumaCenterName.map((obj, i) => obj.NAME),
                            (e) => e === dataMdl.fullAdd[0]
                          )}
                          onChange={(e) => onTraumaCenterChange(e.target.value)}
                        >
                          {getTraumaCenterName.map((obj, i) => (
                            <option key={i} value={i}>
                              {obj.NAME}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        controlId="TraumaCenterAddress"
                        className="mb-3"
                      >
                        <Form.Label>Trauma Center Address</Form.Label>

                        <Form.Control
                          className="rounded-0"
                          as="textarea"
                          rows={5}
                          placeholder="Address"
                          value={getAddress}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              </Form>
              <Row>
                <Col>
                  <GMapStatic
                    center={{ lat: getLatLng.lat, lng: getLatLng.lng }}
                    // setAddress={setAddress}
                    getZoom={"12"}
                    getAddress={getAddress}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mt-2">
                  <div>Distance: {getDistDura.distance}</div>
                  <div>Duration: {getDistDura.duration}</div>
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
        {!getLoading && (
          <Modal.Footer>
            {dataMdl.showClose ? (
              <Button variant="secondary" onClick={() => setShowMdl(false)}>
                {" "}
                Close{" "}
              </Button>
            ) : null}
            <Button variant="primary" onClick={onAdd}>
              {dataMdl.saveBtnName}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}
