import { Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import trashcan from "../../assets/Icons/trashcan.svg";
import position from "../../assets/Icons/position.svg";
import _ from "lodash";
import { getDataInFormat, removeTags } from "../../utils/Helper";
import Select from "react-select";
export default function DailyTaskforSIF({
  questions_obj_key,
  form_template,
  setForm_template,
  getProceed,
  questions_obj,
  modalMapCustomHandler,
  sectionId
}) {

  const getDateObject = (strDate) => {
    let dateArr = strDate.split("/");
    return new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);
  };

  const _date = () => {
    const date = new Date();
    return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  }



  const MyContainer = ({ className, children }) => {
    return (
      <div>
        <div className={className}>
          <div style={{ position: "relative" }}>{children}</div>
        </div>
      </div>
    );
  };

  function click(index, e) {
    let _form_template = _.cloneDeep(form_template);
    const questionIndex = _form_template[getProceed].questions.findIndex(
      (question) => question.questionType === questions_obj.questionType
    );

    if (questionIndex !== -1) {
      _form_template[getProceed].questions[
        questions_obj_key
      ].multipleQuestion.splice(index, 1);
      _form_template[getProceed].questions[questionIndex].options.splice(
        index,
        1
      );
      setForm_template(_form_template);
    }
  }
  function onChangeHandler(_key, index, e) {
    let _form_template = _.cloneDeep(form_template);
    _form_template[getProceed].questions[questions_obj_key].multipleQuestion[
      index
    ].questions[_key].description = e;
    setForm_template(_form_template);
  }
  function onClearHandler(_key, index) {
    let _form_template = _.cloneDeep(form_template);
    _form_template[getProceed].questions[questions_obj_key].multipleQuestion[
      index
    ].questions[_key].description = "";
    setForm_template(_form_template);
  }

  const return_control = (question, questionIndex, index) => {
    switch (question.questionType) {
      case "Text":
        return (
          <Form.Control
            className="rounded-0"
            type="text"
            placeholder="Please enter..."
            value={question.description}
            onChange={(e) => {
              onChangeHandler(questionIndex, index, e.target.value);
            }}
          />
        )
      case "Date":
        return (
          <DatePicker
            className="rounded-0 form-control"
            placeholderText=""
            selected={getDateObject(!_.isEmpty(question.description) ? question.description : _date())}
            onChange={(date) =>
              onChangeHandler(questionIndex, index, getDataInFormat(date))
            }
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            todayButton="Today"
            calendarContainer={MyContainer}
          />
        )


      case "Description+Location":
        return (
          <div className="position-relative ">
            <div className="position-absolute top-0 end-0 sig-controls">
              <Button
                variant="light"
                size="sm"
                onClick={() =>
                  modalMapCustomHandler({
                    multipleQuestion: true,
                    heading: question.question,
                    questions_obj_key: questions_obj_key,
                    questionIndex: questionIndex,
                    multipleQuestionIndex: index,
                    sectionId: sectionId,
                    getProceed: getProceed
                  })
                }
              >
                <img
                  src={String(position)}
                  alt="reset sign"
                  style={{ width: "18px" }}
                />
              </Button>
            </div>
            <Form.Control
              className="rounded-0"
              as="textarea"
              rows={5}
              value={removeTags(question.description)}
              onChange={(e) => {
                onChangeHandler(questionIndex, index, e.target.value)
              }}
            />
          </div>
        )

      case "Dropdown":
        return (
          <Select
            options={question.options.map(i => ({value:i, label:i}))}
            value={_.isEmpty(question.description) ? "" : [{value:question.description, label:question.description}] }
            onChange={(selectedOption) => {
              onChangeHandler(questionIndex, index, selectedOption.value)
            }}
            placeholder="Please select"
          />
        )

      default:
        return (
          <Form.Control
            className="rounded-0"
            type="text"
            placeholder="Please enter..."
            value={question.description}
            onChange={(e) => {
              onChangeHandler(questionIndex, index, e.target.value);
            }}
          />
        )
    }
  }

  let questions = form_template[getProceed].questions[questions_obj_key].multipleQuestion;
  let _dailyTask = questions?.map((row, index) => {
    return (
      <Form.Group
        key={index}
        controlId={`form-3-2-container-${index}`}
        className="mb-3 shadow p-3 mb-5 rounded"
      >
        {index !== 0 ? (
          <div className="d-flex justify-content-end">
            <Button variant="light" size="sm" onClick={(e) => click(index, e)}>
              <img
                className="icon-red-color"
                src={String(trashcan)}
                style={{ width: "22px" }}
              />
            </Button>
          </div>
        ) : null}
        {row.questions.map((question, questionIndex) => (
          <Form.Group
            key={questionIndex}
            controlId={`form-3-2-${questionIndex + 1}`}
            className="mb-3"
          >
            <Form.Label>{question.question}</Form.Label>

            <div className="position-relative ">

              {return_control(question, questionIndex, index)}


              {/* <Form.Control
                className="rounded-0"
                as="textarea"
                rows={5}
                value={question.description}
                onChange={(e) => {
                  onChangeHandler(questionIndex, index, e);
                }}
              /> */}
              {/* {questionIndex ? (
                <div className="position-absolute top-0 end-0 sig-controls">
                  <Button
                    variant="light"
                    size="sm"
                    onClick={(e) => onClearHandler(questionIndex, index)}
                  >
                    <img
                      src={String(failed)}
                      alt="clear"
                      style={{ width: "18px" }}
                    />
                  </Button>
                </div>
              ) : null} */}
            </div>
          </Form.Group>
        ))}
      </Form.Group>
    );
  });

  return <>{_dailyTask} </>;
}
