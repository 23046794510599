import { Row, Col, Form } from "react-bootstrap";
import trashcan from "../../assets/Icons/trashcan.svg";
import { Names, emailValidation } from "../../utils/Helper";
import { useForm } from "react-hook-form";
export default function CrewMemberPPE({
  emailId,
  firstName,
  lastName,
  phoneNumber,
  crewMemberChangeHandler,
  _key,
  deleteCrewMember,
  getKey,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm();
  return (
    <>
      <Row
        xs={12}
        className="mb-5 m-1 p-3 d-flex justify-content-left shadow rounded text-decoration-none"
      >
        <Col lg={3} md={3} xs={12}>
          <Form.Group controlId="form-2-1" className="mb-3">
            <Form.Label>
              <span className="text-danger bold-font">*</span>First Name
            </Form.Label>
            <Form.Control
              className="rounded-0 "
              type="text"
              value={firstName || ""}
              onChange={(e) => {
                crewMemberChangeHandler(_key, "firstName", e.target.value);
                if (e.target.value === "") {
                  setError("firstName" + _key, {
                    message: "This field is required",
                  });
                } else if (Names(e)) {
                  setError("firstName" + _key, {
                    message: "Please enter valid First Name",
                  });
                } else {
                  setError("firstName" + _key, { message: "" });
                }
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={12} lg={3} md={3}>
          <Form.Group controlId="form-2-1" className="mb-3">
            <Form.Label>
              <span className="text-danger bold-font">*</span>Last Name
            </Form.Label>
            <Form.Control
              className="rounded-0 "
              type="text"
              value={lastName || ""}
              onChange={(e) => {
                crewMemberChangeHandler(_key, "lastName", e.target.value);
                if (e.target.value === "") {
                  setError("lastName" + _key, {
                    message: "This field is required",
                  });
                } else if (Names(e)) {
                  setError("lastName" + _key, {
                    message: "Please enter valid Last Name",
                  });
                } else {
                  setError("lastName" + _key, { message: "" });
                }
              }}
            />
          </Form.Group>
        </Col>
        <Col xs={12} lg={3} md={3}>
          <Form.Group controlId="form-2-1" className="mb-3">
            <Form.Label>
              <span className="text-danger bold-font">*</span>Email ID
            </Form.Label>
            <Form.Control
              className="rounded-0 "
              type="email"
              value={emailId || ""}
              onChange={(e) => {
                crewMemberChangeHandler(_key, "emailId", e.target.value);
                //let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,3})+$/
                if (e.target.value === "") {
                  setError("emailId" + _key, {
                    message: "This field is required",
                  });
                } else if (emailValidation(e)) {
                  setError("emailId" + _key, {
                    message: "Please enter a valid Email ID",
                  });
                } else {
                  setError("emailId" + _key, { message: "" });
                }
              }}
            />
            <Form.Label className="mt-2 text-danger">
              {errors["emailId" + _key] && errors["emailId" + _key].message}
            </Form.Label>
          </Form.Group>
        </Col>
        <Col xs={12} lg={3} md={3}>
          <Form.Group controlId="form-2-1" className="mb-3">
            <Form.Label>
              <span className="text-danger bold-font"></span>Phone No.
            </Form.Label>
            <Form.Control
              className="rounded-0 "
              type="text"
              value={phoneNumber || ""}
              pattern="[0-9]*"
              onChange={(e) => {
                crewMemberChangeHandler(
                  _key,
                  "phoneNumber",
                  e.target.validity.valid ? e.target.value : phoneNumber
                );
                let regex =
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                if (regex.test(e.target.value)) {
                  setError("phone" + _key, {
                    message: "",
                  });
                } else {
                  setError("phone" + _key, {
                    message: "Please enter a valid phone number",
                  });
                }
              }}
            />
            <Form.Label className="mt-2 text-danger">
              {errors["phone" + _key] && errors["phone" + _key].message}
            </Form.Label>
          </Form.Group>
          {getKey !== 1 && getKey !== 0 ? (
            <div className="d-flex justify-content-end">
              <img
                className="icon-red-color pointer"
                src={String(trashcan)}
                onClick={() => deleteCrewMember(_key)}
                alt="delete icon"
              />
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </>
  );
}
