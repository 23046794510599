import { useState } from "react";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import ReportsTbl from "./ReportsTbl";
import moment from "moment-timezone";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";
import { getReports, isReportsUpdated } from "../../api/Reports.api";
import { getReportsChildElements } from "../../api/ReportsChild.api";
import { getAllForms } from "../../api/Login.api";
import { getDataInFormat } from "../../utils/Helper";
import { useEffect } from "react";
import { DECRYPTED_DATA, UserGroupId } from "../../utils/Variable";
import time from "../../assets/Icons/time.png";
import realtime from "../../assets/Icons/realtime.png";
import info from "../../assets/Icons/info.png";
import _ from "lodash";
export default function Reports() {
  let s = useLocation();

  const [getLastDate, setLastDate] = useState(
    moment().tz("America/Chicago").format("YYYY-MM-DD HH:mm:ss")
  );

  const [startDate, setStartDate] = useState(
    new Date(`${DECRYPTED_DATA().startDate}T00:00:00-06:00`)
  );
  const [endDate, setEndDate] = useState(
    new Date(moment().tz("America/Chicago"))
  );
  const [getFormId, setFormId] = useState([]);
  const [getChildFormId, setChildFormId] = useState([]);
  const [getStatusOption, setStatusOption] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
  ]);
  const [getErrMsg, setErrMsg] = useState("");
  const [getLoading, setLoading] = useState(false);
  const [getReportsData, setReportsData] = useState({});
  const [getChildReportsData, setChildReportsData] = useState({});
  const [getReportCountStatic, setReportCountStatic] = useState(null);
  const [getReportCount, setReportCount] = useState(null);
  const [getInProgressSet, setInProgressSet] = useState(s.state);
  const [getAllFormsData, setAllFormsData] = useState([]);
  const [getStatusData, setStatusData] = useState([]);
  const maxDate = new Date();
  let datepickerData = {
    startDate,
    getStatusData,
    setStartDate,
    endDate,
    setEndDate,
    getStatusOption,
    setStatusOption,
    getFormId,
    setFormId,
    getInProgressSet,
    setInProgressSet,
    maxDate,
  };

  useEffect(() => {
    async function fetchData() {
      const _getAllForms = await getAllForms(
        setAllFormsData,
        setLoading,
        setErrMsg,
        setStatusData
      );
      const _getFormId = _getAllForms.map((i) => i.formId);
      setFormId(_getFormId);
      setChildFormId(_getFormId);
    }
    fetchData();
  }, []);

  let param = {
    start_date: moment(startDate).format("YYYY-MM-DD"),
    end_date: moment(endDate).add(1, "day").format("YYYY-MM-DD"),
    // start_date: moment(startDate).format('YYYY-MM-DD 00:00:00'),
    // end_date: moment(endDate).format('YYYY-MM-DD 23:59:59'),
    status: !_.isNull(getInProgressSet)
      ? getInProgressSet.getStatusOption
      : getStatusOption,
    formId: getFormId,
  };

  let param1 = {
    start_date: moment(startDate).format("YYYY-MM-DD"),
    end_date: moment(endDate).add(1, "day").format("YYYY-MM-DD"),
    // start_date: moment(startDate).format('YYYY-MM-DD 00:00:00'),
    // end_date: moment(endDate).format('YYYY-MM-DD 23:59:59'),
    status: !_.isNull(getInProgressSet)
      ? getInProgressSet.getStatusOption
      : getStatusOption,
    formId: getChildFormId,
  };

  useEffect(() => {
    if (getReportCount !== getReportCountStatic) {
      if (
        !_.isNull(datepickerData.startDate) &&
        !_.isNull(datepickerData.endDate) &&
        !_.isEmpty(getFormId)
      ) {
        getReports(param, setLoading, setErrMsg, setReportsData);
      }
      !_.isEmpty(getFormId) &&
        isReportsUpdated(param, setErrMsg, setReportCountStatic);
    }
  }, [getReportCount]);

  //interval
  useEffect(() => {
    !_.isEmpty(getFormId) &&
      isReportsUpdated(param, setErrMsg, setReportCountStatic);
    var timerID = setInterval(() => {
      !_.isEmpty(getFormId) &&
        isReportsUpdated(param, setErrMsg, setReportCount);
    }, 60000);
    return () => {
      // cleanup call
      clearInterval(timerID);
    };
  }, [getFormId]);

  useEffect(() => {
    if (
      !_.isNull(datepickerData.startDate) &&
      !_.isNull(datepickerData.endDate) &&
      !_.isEmpty(getFormId)
    ) {
      getReports(param, setLoading, setErrMsg, setReportsData);
    }
  }, [startDate, endDate, getFormId, getStatusOption, getStatusData]);

  useEffect(() => {
    setLastDate(moment().tz("America/Chicago").format("YYYY-MM-DD HH:mm:ss"));
  }, [getReportsData]);

  const NewChildComponent = async (ParentReportIdvalue, ReportId) => {
    const response = await getReportsChildElements(
      param1,
      setLoading,
      setErrMsg,
      ParentReportIdvalue,
      ReportId
    );
    return response;
  };

  return (
    <Container className="margin-container Reports">
      <div className="">
        <h4>Reports</h4>
      </div>
      <Row className="mt-3 p-3 d-flex justify-content-center shadow rounded ">
        <Col>
          <CustomDatePickerForm
            getStatusData={getStatusData}
            getAllFormsData={getAllFormsData}
            formName="reports"
            datepickerData={datepickerData}
            submitData={() => {
              setErrMsg("");
              !_.isEmpty(getFormId) &&
                getReports(param, setLoading, setErrMsg, setReportsData);
            }}
          />
        </Col>
      </Row>

      <Row xs={12} className="mt-5 p-2  shadow rounded ">
        {getErrMsg !== "" && (
          <Row className="d-flex justify-content-center">
            <Col xs={12} lg={6} md={6}>
              <Alert variant="danger" onClose={() => setErrMsg("")} dismissible>
                {getErrMsg}
              </Alert>
            </Col>
          </Row>
        )}
        <div className="d-flex justify-content-end mt-1 p-2 align-items-center">
          <Button
            variant="primary"
            size="sm"
            className="me-4"
            onClick={async () => {
              await getReports(param, setLoading, setErrMsg, setReportsData);
              setLastDate(
                moment().tz("America/Chicago").format("YYYY-MM-DD HH:mm:ss")
              );
            }}
          >
            <img
              src={String(realtime)}
              className="icon-white-color"
              width="24"
              height="24"
              alt="last updated time"
            />
            {/* <span className="pt-1"> */}
            &nbsp;REFRESH&nbsp;
            {/* </span> */}
          </Button>
          <img
            src={String(time)}
            className="me-1 "
            width="16"
            height="16"
            alt="last updated time"
          />
          <div className="" style={{ marginTop: "5px !important" }}>
            Last Update: {getLastDate}
          </div>
        </div>
        <div className="d-flex justify-content-end mb-3 align-items-center">
          <div style={{ fontSize: "smaller" }}>
            <img
              className="me-1"
              src={String(info)}
              alt="info"
              width="11"
              height="11"
            />
            <small className="text-muted">
              Auto-refresh happens when there is a new report
            </small>
          </div>
        </div>
        {!getLoading ? (
          <ReportsTbl
            totaltime_js={
              !_.isEmpty(getReportsData) && JSON.parse(getReportsData)
            }
            getStatusOption={getStatusOption}
            getAllFormsData={getAllFormsData}
            NewChildComponent={NewChildComponent}
          />
        ) : (
          <Col
            xs={12}
            className="m-5 p-5 d-flex justify-content-center"
            style={{ cursor: "pointer" }}
          >
            <Loading width="32" height="32" />
          </Col>
        )}
      </Row>
    </Container>
  );
}
