import { TOKEN } from "../utils/Variable";
import { URL } from "../utils/Constant";

export const GetAutoSuggestions = async (dataJSON, setDateForSuggestion) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };

  const resp = await fetch(
    URL.GetSuggestion +
      `?filter=${dataJSON["filter"]}&typed=${dataJSON["typed"]}`,
    { method: "GET", headers: headersList }
  );
  if (resp.ok) {
    const data = await resp.json();

    return data.data;
  }
};
