import { useState } from "react";
import _, { map } from 'lodash';
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { uploadImgPicApi } from "../../api/Profile.api";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Loading from "../Loading/Loading";
export default function ModalImgChange({ showMdl, setShowMdl, dataMdl }) {
    const [getImage, setImage] = useState('');
    const [getLoading, setLoading] = useState(false);
    const [getMsg, setMsg] = useState('');
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const { register, handleSubmit, formState: { errors }, setError, watch } = useForm();
    useEffect(() => {

    }, [getMsg])

    const onUploadImgHandler = async (_value, fileType) => {
        // // handleOnEnd();
        // toBase64(getImage)
        if (_value !== '') {
            let base64 = await toBase64(_value)
            setImage({ base64: base64, fileType: fileType })

            // uploadImgPicApi(base64, setLoading, setMsg, fileType)
        }
        // setShowMdl(false)
    }
    const updateImage = () => {

        uploadImgPicApi(getImage.base64, setLoading, setMsg, getImage.fileType)
    }
    return (
        <Modal show={showMdl} onHide={() => setShowMdl(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{dataMdl.heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form>

                        <Form.Group controlId="formFile" className="mb-3">
                            <Row>
                                <Col>

                                    <Form.Control
                                        className="rounded-0 mt-3" type="file"

                                        onChange={
                                            (e) => {
                                                let fileType = e.target.files[0]["type"];
                                                let validImageTypes = ["image/gif", "image/jpeg", "image/png"];
                                                if (!validImageTypes.includes(fileType)) {
                                                    setError('proImg', {
                                                        message: 'Only jpeg/png/gif image are allowed.'
                                                    })
                                                    onUploadImgHandler('', fileType)
                                                } else {
                                                    setError('proImg', {
                                                        message: ''
                                                    })
                                                    onUploadImgHandler(e.target.files[0], fileType)
                                                }

                                            }
                                        }
                                    />
                                    <Form.Label className="mt-2 text-danger">{errors.proImg && errors.proImg.message}</Form.Label>
                                    <Form.Label className="mt-2"><b>{getMsg !== '' && getMsg}</b></Form.Label>
                                </Col>
                            </Row>
                            {getLoading && <Row className="d-flex justify-content-center">
                                <Col>
                                    <Loading width={"32"} />
                                </Col>
                            </Row>
                            }
                        </Form.Group>

                    </Form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={updateImage} disabled={getLoading} >
                    {dataMdl.saveBtnName}
                </Button>


                {/* {dataMdl.showClose ? <Button variant="danger" onClick={() => setShowMdl(false)}> Close </Button> : null} */}
            </Modal.Footer>
        </Modal >
    )
}