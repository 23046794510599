import "./SidePanel.scss";
import econ from "../../assets/Icons/econ.svg";
import { Col, Row } from "react-bootstrap";
import { appURL } from "../../utils/Constant";
import { Link } from "react-router-dom";
import Logo from "../../assets/Icons/sw_logo.svg"
export default function SidePanel({ showsidepanel, setshowsidepanel }) {
  return (
    showsidepanel && (
      <div className="side-panel">
        <Row className="content">
          <Row className="title-container">
            <Col xs={8}>
              <span className="main-title">Safe Worker</span>
            </Col>
            <Col xs={4} className="text-end">
              <button
                className="close-button"
                onClick={() => setshowsidepanel(!showsidepanel)}
              >
                X
              </button>
            </Col>
          </Row>
          <Col xs={12}>
            <span className="sub-title">Products</span>
          </Col>
          <Link
            to={appURL}
            target="blank"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Col xs={12} className="d-flex align-items-center text-hover">
              <div className="product-symbol">
                <img alt="" src={String(Logo)} className="icon" />
              </div>
              <div className="label-text">
                <span>Dashboard</span>
                <span style={{ opacity: ".6" }}>SW</span>
              </div>
            </Col>
          </Link>
        </Row>
      </div>
    )
  );
}
