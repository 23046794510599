import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
// import logo from "../../assets/Icons/ericsson-logo.svg";
// import ericsson_logo from "../../assets/Icons/ericsson_logo.png";
import "./Login.scss";
import Loading from "../../components/Loading/Loading";
import { loginRequest } from "../../authConfig";
import {useIsAuthenticated, useMsal } from "@azure/msal-react";
// import AlertandRedirect from "../../components/AlertandRedirect/AlertandRedirect";
export default function Login() {
  const isAuthenticated = useIsAuthenticated();
  const [getLoading, setLoading] = useState(false);
  const { instance } = useMsal();
  // const [getRedirect, setRedirect] = useState(false);

  // let activeAccount;
  // if (instance) {
  //   activeAccount = instance.getActiveAccount();
  // }
  // sessionStorage.setItem("isLastLoginDetailsCalled", false);
  // useEffect(() => {
  //   var element = document.getElementById("myDiv");
  //   element.classList.add("watermark-logo");
  //   return () => {
  //     element.classList.remove("watermark-logo");
  //   };
  // }, []);

  useEffect(() => {
    if (!isAuthenticated) {
    setLoading(true);
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    }else {
      sessionStorage.setItem("isLastLoginAPICalled", false); // to show last login date time popup on first time login
    }
  }, [isAuthenticated, instance]);

  // useEffect(() => {
  //   if (window.location.href === "https://sw-webapp.azurewebsites.net/login") {
  //     setRedirect(true);
  //   } else {
  //     setRedirect(false);
  //   }
  // }, []);

  return (
    <>
      {/* {getRedirect && <AlertandRedirect />} */}
      <Container className="margin-container ">
        <div className=" d-flex justify-content-xl-center align-items-center ">
          {getLoading && <Loading width="32" height="32" />}
          {/* <Card bg="light" className="card-custom shadow-lg p-3 mb-3  rounded">
            <div className="d-flex justify-content-center "> */}
          {/* <Card.Img
                variant="top"
                src={String(logo)}
                style={{ width: "60px" }}
              /> */}
        </div>
        {/* <Card.Header className="d-flex justify-content-center card-header-custom">
              <h3>Safe Worker</h3>
            </Card.Header> */}
        {/* <Card.Body>
              {getLoading && <Loading width="32" height="32" />}
              <div>
                <hr />
              </div> */}
        {/* <div className="small-font-cls">
                Please note that this application, provided by Ericsson, is
                experimental. You, as a user, agree to use the application at
                your own risk. Ericsson Safe Worker accepts no responsibility
                for any loss or damages for any reason.
              </div> */}
        {/* </Card.Body>
          </Card> */}
        {/* </div> */}
      </Container>
    </>
  );
}
