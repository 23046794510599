import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  ToggleButton,
  ProgressBar,
  Nav,
  Alert,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";
import {
  getWeather,
  uploadMultiImage,
  detectAI,
  ijhaFormSubmit,
  ijhaInCompleteFormSubmit,
  getAddCrewMemberApi,
  detectAIALL,
  getFormIjhaApi,
  get911AddressInfoApi,
} from "../../api/CreateiJHA.api";
import { DECRYPTED_DATA, EMP_ID, IMG_TOKEN } from "../../utils/Variable";
// import ijhaform from '../../utils/ijhaform1.json';
import Signature from "../../components/Signature/Signature";
import arrow_up from "../../assets/Icons/arrow_up.png";
import position from "../../assets/Icons/position.svg";
import failed from "../../assets/Icons/failed.svg";
import map_directions from "../../assets/Icons/map-directions.svg";
import phone_classic from "../../assets/Icons/phone-classic.svg";
import ic_trauma_center from "../../assets/Icons/ic_trauma_center.png";
import ic_ai from "../../assets/Icons/ic_ai.png";
import ic_temperature from "../../assets/Icons/ic_temperature.png";
import ic_wind from "../../assets/Icons/ic_wind.png";
import ic_sunny from "../../assets/Icons/ic_sunny.png";

import incident1 from "../../assets/Icons/incident1.png";
import ic_partly_sunny from "../../assets/Icons/ic_partly_sunny.png";
import ic_rain from "../../assets/Icons/ic_rain.png";
import ic_sky from "../../assets/Icons/ic_sky.png";
import ic_visibility from "../../assets/Icons/ic_visibility.png";
import ic_humidity from "../../assets/Icons/ic_humidity.png";
import ic_pressure from "../../assets/Icons/ic_pressure.png";
import ic_precipitation from "../../assets/Icons/ic_precipitation.png";
import ic_uv_index from "../../assets/Icons/ic_uv_index.png";
import ic_complete_ppe from "../../assets/Icons/ic_complete_ppe.png";
import triangle_warning from "../../assets/Icons/triangle-warning.svg";

import {
  removeTags,
  getCurrentDataTime,
  getFileTypeImgDoc,
  formatAMPM,
  getTotalTime,
  getUrlWithQueryString,
  getPPEImageUrl,
} from "../../utils/Helper";
import ModalMapCustom from "../../components/Modal/ModalMapCustom";
import ModalMapTramaCenter from "../../components/Modal/ModalMapTramaCenter";
import ModalOther from "../../components/Modal/ModalOther";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import MultiSelect1 from "../../components/MultiSelect1/MultiSelect1";
import MultiSelect2 from "../../components/MultiSelect2/MultiSelect2";
import Loading from "../../components/Loading/Loading";
import DailyTask from "../../components/DailyTask/DailyTask";
// import chevron_right from '../../assets/Icons/chevron-right.svg'
import check from "../../assets/Icons/check.svg";
import "./CreateiJHA.scss";
import FormProgressBarForIjha from "../../components/FormProgressBar/FormProgressBarForIjha";
import _, { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import Summary from "./Summary";
import Resizer from "react-image-file-resizer";
import { checkImageData } from "../../api/ImageMetaData.api";
import AddCrewMember from "../CrewMember/AddCrewMember";
export default function CreateiJHA() {
  let s = useLocation();
  // console.log("vicky::ijhaform-props", s.state)
  // console.log('EMP_ID::', EMP_ID);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        300,
        300
      );
    });
  const [getReportCrewCheck, setReportCrewCheck] = useState(false);
  const [getCrewData, setCrewData] = useState(s.state);
  const [getReportIJHA, setReportIJHA] = useState(s.state.reportIJHA);
  // const [getReportIJHACount, setReportIJHACount] = useState(0);
  const [getAddEditCrew, setAddEditCrew] = useState([]);
  const [getAllFormsData, setAllFormsData] = useState([]);
  const [getLoc, setLoc] = useState({ lat: "", lng: "" });
  const [getCheckComplete, setCheckComplete] = useState(false);
  const [getCrewMemData, setCrewMemData] = useState(s.state.crewmemberdata);
  const [getCrewLeadData, setCrewLeadData] = useState(s.state.getCrewLeadData);
  const [getWeatherHours, setWeatherHours] = useState([]);
  const [getLoading, setLoading] = useState(false);
  const [getHCMOptions, setHCMOptions] = useState([]);
  const [getPFASOptions, setPFASOptions] = useState([]);
  const [getCrewMemberProceed, setCrewMemberProceed] = useState({
    crew: 0,
    steps: 0,
  });
  const [getErrMsg, setErrMsg] = useState("");
  const [getIjhaform, setIjhaform] = useState(s.state.ijhaform);
  const [form_template, setForm_template] = useState(getIjhaform.form_template);
  const [getUserName, setUserName] = useState(
    DECRYPTED_DATA.firstName + " " + DECRYPTED_DATA.lastName
  );
  const [getSignBase64Uri, setSignBase64Uri] = useState("");
  const [getSignBase64Uri1, setSignBase64Uri1] = useState("");
  //const [getCrewMemberData, setCrewMemberData] = useState(s.state.getCrewLeadData)
  const [getProceed, setProceed] = useState(0);
  const [getProceed1, setProceed1] = useState(0);
  const [radioValue, setRadioValue] = useState("imperial");
  const [getLatitude, setLatitude] = useState(0);
  const [getLongitude, setLongitude] = useState(0);
  const [getUnit, setUnit] = useState("");
  const [getImgUrls, setImgUrls] = useState({});
  const [getUploadedImgUrls, setUploadedImgUrls] = useState([]);
  const [getUploadedImgUrls1, setUploadedImgUrls1] = useState([]);
  const [getUploadedImgUrls2, setUploadedImgUrls2] = useState([]);
  const [getAddress, setAddress] = useState("");
  const [showMdl, setShowMdl] = useState(false);
  const [showTraumaMdl, setShowTraumaMdl] = useState(false);
  const [getDataMdl, setDataMdl] = useState({
    heading: "",
    body: "",
    showClose: false,
    saveBtnName: "",
  });
  const [getShowOtherMdl, setShowOtherMdl] = useState(false);
  const [getOtherDataMdl, setOtherDataMdl] = useState({
    heading: "",
    body: "",
    showClose: false,
    saveBtnName: "Add",
  });
  const [getIsCompleted, setIsCompleted] = useState([]);
  const [getSetP, setSetP] = useState(false);
  const [getAddMoreDailyTask, setAddMoreDailyTask] = useState([]);

  const [getSelectedOptionsMulti1, setSelectedOptionsMulti1] = useState();
  const [getCommentMulti1, setCommentMulti1] = useState([]);
  const [directions, setDirections] = useState({});
  const [getImgAiDetectKeys, setImgAiDetectKeys] = useState({});
  const [getImgAiDetectKeys2, setImgAiDetectKeys2] = useState({});
  const [getAIData, setAIData] = useState({});
  const [getIjhaSubmit, setIjhaSubmit] = useState("");
  const [getpbtn, setpbtn] = useState(false);
  const [getpbtn2, setpbtn2] = useState(false);
  const [getCustomername1, setCustomername1] = useState(false);
  const [getSiteLandLord, setSiteLandLord] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm();
  const [showScroll, setShowScroll] = useState(false);
  const [getCrewfill, setCrewfill] = useState(false);
  const [getDelCrew, setDelCrew] = useState(false);
  const [getformTemplateCount, setformTemplateCount] = useState([0]);
  const [getValidation, setValidation] = useState(false);
  const [getReportID, setReportId] = useState();
  const [getSummary, setSummary] = useState(false);
  const [getImgMetaData, setImgMetaData] = useState();
  const [getClimber, setClimber] = useState(false);
  const [getClimberList, setClimberList] = useState([]);
  const [gettypeofWork, settypeofWork] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  // Below code for not replicate In-Progress form when edit
  useEffect(() => {
    if (
      getIjhaform.reportId !== undefined &&
      getIjhaform.reportId !== "" &&
      getIjhaform.complete_status === "InProgress"
    ) {
      setReportId(getIjhaform.reportId);
    } else {
      setReportId(uuidv4());
    }
    if (
      getIjhaform.inProgressSection !== undefined &&
      getIjhaform.inProgressSection !== ""
    ) {
      if (getIjhaform.inProgressSection === form_template.length - 1) {
        setProceed1(15);
        setSummary(true);
      }
      setProceed(getIjhaform.inProgressSection);
      setClimberList(getIjhaform.membertype);

      let _emptyArr = _.cloneDeep(getformTemplateCount);
      for (let i = 0; i <= getIjhaform.inProgressSection; i++) {
        _emptyArr.push(i);
      }
      _emptyArr = [...new Set(_emptyArr)];
      setformTemplateCount(_emptyArr);
    }
  }, []);

  window.addEventListener("scroll", checkScrollTop);
  useEffect(() => {
    // console.log('scroll', showScroll, getProceed)
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    // window.scrollTo(0, 0);
    scrollUp();
  }, [getProceed, getProceed1]);
  // console.log('errors', errors)
  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollDown = () => {
    let _totalHeight = document.body.offsetHeight;
    window.scrollTo({ top: _totalHeight, behavior: "smooth" });
  };

  const modalOtherHandler = () => {
    let dataMdl = {
      heading: "",
      body: "",
      showClose: true,
      saveBtnName: "Add",
    };

    setOtherDataMdl(dataMdl);
    setShowOtherMdl(true);
  };
  const modalMapHandler = (
    heading,
    getAddress,
    changeFormTemplateData,
    _key,
    lat,
    lng
  ) => {
    let dataMdl = {
      heading: heading,
      setAddress: setAddress,
      // getLatitude: getLatitude,
      // getLongitude: getLongitude,
      getLatitude: Number(lat),
      getLongitude: Number(lng),
      direction: false,
      body: "",
      changeFormTemplateData: changeFormTemplateData,
      _key: _key,
      showClose: false,
      saveBtnName: "Add",
    };
    // console.log('modal add::', getAddress)

    setDataMdl(dataMdl);
    setShowMdl(true);
  };

  const modalMapHandler1 = (
    heading,
    getAddress,
    changeFormTemplateData,
    _key,
    lat,
    lng
  ) => {
    // console.log("map::modal::", lat, lng, getLatitude, getLongitude)
    let dataMdl = {
      heading: heading,
      setAddress: setAddress,
      getCurrLatitude: getLatitude,
      getCurrLongitude: getLongitude,
      getLatitude: getLatitude,
      getLongitude: getLongitude,
      direction: false,
      fullAdd: _.split(
        removeTags(form_template[getProceed].questions[_key].answer),
        "\n"
      ),
      body: "",
      changeFormTemplateData: changeFormTemplateData,
      _key: _key,
      showClose: false,
      saveBtnName: "Add",
    };
    // console.log('modal add::', getAddress)

    setDataMdl(dataMdl);
    // setShowMdl(true);
    setShowTraumaMdl(true);
  };

  const modalMapDirectionHandler = (
    heading,
    getAddress,
    changeFormTemplateData,
    _key
  ) => {
    // console.log('1:', heading[1]);
    let dataMdl = {
      heading: heading[0],
      setAddress: setAddress,
      getLatitude: getLatitude,
      getLongitude: getLongitude,
      origin: heading[1],
      destination: heading[2],

      direction: true,
      body: "",
      changeFormTemplateData: changeFormTemplateData,
      _key: _key,
      showClose: false,
      saveBtnName: "Add",
    };
    // console.log('modal add::', getAddress)

    setDataMdl(dataMdl);
    setShowMdl(true);
  };
  const wheather_radio = [
    { name: "°F", value: "imperial" },
    { name: "°C", value: "metric" },
  ];
  const sendData = {
    latitude: getLatitude,
    longitude: getLongitude,
    unit: radioValue,
    loadingTrue: useCallback(() => setLoading(true), [setLoading]),
    loadingFalse: useCallback(() => setLoading(false), [setLoading]),
  };

  const _datetime = () => {
    let _date = new Date();
    let current_date =
      _date.getFullYear() +
      "/" +
      (_date.getMonth() + 1) +
      "/" +
      _date.getDate() +
      " " +
      +_date.getHours() +
      ":" +
      _date.getMinutes() +
      ":" +
      _date.getSeconds();
    return current_date;
  };

  const _totalTime = (_start_time, _end_time) => {
    const _total_Time =
      Math.abs(
        new Date(_end_time).getTime() - new Date(_start_time).getTime()
      ) / 1000;
    return _total_Time;
  };

  useEffect(() => {
    // console.log(errors, '::::error')
    switch (form_template[getProceed].sectionId) {
      case "1":
        if (
          form_template[getProceed].section[getCrewMemberProceed.crew]
            .questions[0].questionType === "Terms&condition"
        ) {
          if (
            form_template[getProceed].section[getCrewMemberProceed.crew]
              .questions[0].checkbox_model[0].value
          ) {
            setpbtn(false);
          } else {
            setpbtn(true);
          }
          if (
            form_template[getProceed].section[getCrewMemberProceed.crew]
              .start_time === ""
          ) {
            form_template[getProceed].section[
              getCrewMemberProceed.crew
            ].start_time = _datetime();
          }
        } else {
          if (
            errors.profileImg &&
            errors.profileImg.message === "" &&
            getSignBase64Uri !== ""
          )
            setpbtn(false);
          else if (
            errors.profileImg &&
            errors.profileImg.message !== "" &&
            getSignBase64Uri === ""
          )
            setpbtn(true);
          else setpbtn(true);
        }
        break;

      case "15":
        if (
          form_template[getProceed - 1].section[getCrewMemberProceed.crew]
            .end_time === "" &&
          form_template[getProceed].start_time === ""
        ) {
          form_template[getProceed - 1].section[
            getCrewMemberProceed.crew
          ].end_time = _datetime();
          form_template[getProceed].start_time = _datetime();
          form_template[getProceed - 1].section[
            getCrewMemberProceed.crew
          ].time_taken = _totalTime(
            form_template[getProceed - 1].section[getCrewMemberProceed.crew]
              .start_time,
            form_template[getProceed - 1].section[getCrewMemberProceed.crew]
              .end_time
          );
        }

        if (
          form_template[getProceed].questions[0].answer
            .split("-")[0]
            .replace(/^\s+|\s+$/gm, "") === "Other"
        ) {
          settypeofWork(true);
        }

        let _Climber_count = getClimberList.filter(
          (x) => x === "Climber"
        ).length;
        if (
          _Climber_count <= 1 &&
          form_template[getProceed].questions[1].answer === "Yes" &&
          form_template[getProceed].questions[1].visibility
        ) {
          setClimber(true);
          setpbtn(true);
        } else {
          setClimber(false);
          if (
            form_template[getProceed].questions[2].visibility &&
            form_template[getProceed].questions[3].visibility &&
            form_template[getProceed].questions[4].visibility &&
            form_template[getProceed].questions[5].visibility &&
            form_template[getProceed].questions[6].visibility &&
            form_template[getProceed].questions[6].visibility
          ) {
            if (
              errors.overallConditionOfTheStructure1 &&
              errors.overallConditionOfTheStructure1.message === "" &&
              errors.conditionOfTheClimbingFacilities1 &&
              errors.conditionOfTheClimbingFacilities1.message === "" &&
              errors.visuallyInspectTheFixed1 &&
              errors.visuallyInspectTheFixed1.message === "" &&
              errors.otherStructuralHazards &&
              errors.otherStructuralHazards.message === "" &&
              errors.AllStructuralAppurtenance &&
              errors.AllStructuralAppurtenance.message === "" &&
              errors.AnchorPointsInspected &&
              errors.AnchorPointsInspected.message === "" &&
              errors.typeofwork1 &&
              errors.typeofwork1.message === "" &&
              errors.typeofwork2 &&
              errors.typeofwork2.message === ""
            )
              setpbtn(false);
            else setpbtn(true);
          } else if (
            form_template[getProceed].questions[2].visibility &&
            form_template[getProceed].questions[7].visibility
          ) {
            if (
              errors.overallConditionOfTheStructure1 &&
              errors.overallConditionOfTheStructure1.message === "" &&
              errors.otherStructuralHazards &&
              errors.otherStructuralHazards.message === "" &&
              errors.typeofwork1 &&
              errors.typeofwork1.message === "" &&
              errors.typeofwork2 &&
              errors.typeofwork2.message === ""
            )
              setpbtn(false);
            else setpbtn(true);
          } else if (errors.typeofwork1 && errors.typeofwork1.message === "")
            setpbtn(false);
          else if (errors.typeofwork1 && errors.typeofwork1.message !== "")
            setpbtn(true);
          else setpbtn(true);
        }

        break;

      case "2":
        if (
          form_template[getProceed - 1].end_time === "" &&
          form_template[getProceed].start_time === ""
        ) {
          form_template[getProceed - 1].end_time = _datetime();
          form_template[getProceed].start_time = _datetime();
          form_template[getProceed - 1].time_taken = _totalTime(
            form_template[getProceed - 1].start_time,
            form_template[getProceed - 1].end_time
          );
        }

        if (
          form_template[getProceed].questions[3].answer
            .split("-")[0]
            .replace(/^\s+|\s+$/gm, "") === "Other"
        ) {
          setCustomername1(true);
        }
        if (
          form_template[getProceed].questions[4].answer
            .split("-")[0]
            .replace(/^\s+|\s+$/gm, "") === "Other"
        ) {
          setSiteLandLord(true);
        }
        if (
          errors.piNumber &&
          errors.piNumber.message === "" &&
          errors.loc911Add &&
          errors.loc911Add.message === "" &&
          errors.ccnameJobName &&
          errors.ccnameJobName.message === "" &&
          errors.customername1 &&
          errors.customername1.message === "" &&
          errors.customername2 &&
          errors.customername2.message === "" &&
          errors.siteLanLord1 &&
          errors.siteLanLord1.message === "" &&
          errors.siteLanLord2 &&
          errors.siteLanLord2.message === ""
        )
          setpbtn(false);
        else if (
          errors.piNumber &&
          errors.piNumber.message !== "" &&
          errors.loc911Add &&
          errors.loc911Add.message !== "" &&
          errors.ccnameJobName &&
          errors.ccnameJobName.message !== "" &&
          errors.customername1 &&
          errors.customername1.message !== "" &&
          errors.customername2 &&
          errors.customername2.message !== "" &&
          errors.siteLanLord1 &&
          errors.siteLanLord1.message !== "" &&
          errors.siteLanLord2 &&
          errors.siteLanLord2.message !== ""
        )
          setpbtn(true);
        else setpbtn(true);
        break;

      case "3":
        // console.log(form_template[3], getpbtn, 'form_template[3].questions[1].multipleQuestion,getpbtn ')
        if (
          form_template[getProceed - 1].end_time === "" &&
          form_template[getProceed].start_time === ""
        ) {
          form_template[getProceed - 1].end_time = _datetime();
          form_template[getProceed].start_time = _datetime();
          form_template[getProceed - 1].time_taken = _totalTime(
            form_template[getProceed - 1].start_time,
            form_template[getProceed - 1].end_time
          );
        }

        let _setpbtn_val = [];
        form_template[getProceed].questions[1].multipleQuestion.map((obj, i) =>
          obj.questions.map((j, k) =>
            j.description === ""
              ? _setpbtn_val.push(false)
              : _setpbtn_val.push(true)
          )
        );

        if (_setpbtn_val.includes(false)) setpbtn(true);
        else setpbtn(false);

        break;

      case "4":
        if (
          form_template[getProceed - 1].end_time === "" &&
          form_template[getProceed].start_time === ""
        ) {
          form_template[getProceed - 1].end_time = _datetime();
          form_template[getProceed].start_time = _datetime();
          form_template[getProceed - 1].time_taken = _totalTime(
            form_template[getProceed - 1].start_time,
            form_template[getProceed - 1].end_time
          );
        }
        if (
          errors.onSiteCompInfo &&
          errors.onSiteCompInfo.message === "" &&
          errors.jobSiteOperMang &&
          errors.jobSiteOperMang.message === "" &&
          errors.jobSiteMangOfSafety &&
          errors.jobSiteMangOfSafety.message === "" &&
          errors.onSitePersonInCharge &&
          errors.onSitePersonInCharge.message === ""
          // errors.jobSitePersonnel && errors.jobSitePersonnel.message === '' &&
          // errors.siteSafetyTrainingDocOnSite && errors.siteSafetyTrainingDocOnSite.message === ''
        )
          setpbtn(false);
        else if (
          errors.onSiteCompInfo &&
          errors.onSiteCompInfo.message !== "" &&
          errors.jobSiteOperMang &&
          errors.jobSiteOperMang.message !== "" &&
          errors.jobSiteMangOfSafety &&
          errors.jobSiteMangOfSafety.message !== "" &&
          errors.onSitePersonInCharge &&
          errors.onSitePersonInCharge.message !== ""
          //                     errors.jobSitePersonnel && errors.jobSitePersonnel.message !== '' &&
          // errors.siteSafetyTrainingDocOnSite && errors.siteSafetyTrainingDocOnSite.message !== ''
        )
          setpbtn(true);
        else setpbtn(true);
        break;

      case "5":
        if (
          form_template[getProceed - 1].end_time === "" &&
          form_template[getProceed].start_time === ""
        ) {
          form_template[getProceed - 1].end_time = _datetime();
          form_template[getProceed].start_time = _datetime();
          form_template[getProceed - 1].time_taken = _totalTime(
            form_template[getProceed - 1].start_time,
            form_template[getProceed - 1].end_time
          );
        }
        if (
          errors.hospitalInfo &&
          errors.hospitalInfo.message === "" &&
          errors.evacuationPoint &&
          errors.evacuationPoint.message === "" &&
          errors.directionsToHospital &&
          errors.directionsToHospital.message === "" &&
          errors.directionsFromHospital &&
          errors.directionsFromHospital.message === "" &&
          errors.nearestFireDept &&
          errors.nearestFireDept.message === "" &&
          errors.nearestPoliceStat &&
          errors.nearestPoliceStat.message === "" &&
          // errors.remainCalmAndAssessEmer1 && errors.remainCalmAndAssessEmer1.message === '' &&
          // errors.remainCalmAndAssessEmer2 && errors.remainCalmAndAssessEmer2.message === '' &&
          // errors.initiateEmergencyResp1 && errors.initiateEmergencyResp1.message === '' &&
          // errors.initiateEmergencyResp2 && errors.initiateEmergencyResp2.message === '' &&
          // errors.initiatePersonMedicalResp1 && errors.initiatePersonMedicalResp1.message === '' &&
          // errors.initiatePersonMedicalResp2 && errors.initiatePersonMedicalResp2.message === '' &&
          // errors.reportSafetyIncidentTo && errors.reportSafetyIncidentTo.message === '' &&
          errors.are911ServicesAvailable &&
          errors.are911ServicesAvailable.message === ""
        )
          setpbtn(false);
        else if (
          errors.hospitalInfo &&
          errors.hospitalInfo.message !== "" &&
          errors.evacuationPoint &&
          errors.evacuationPoint.message !== "" &&
          errors.directionsToHospital &&
          errors.directionsToHospital.message !== "" &&
          errors.directionsFromHospital &&
          errors.directionsFromHospital.message !== "" &&
          errors.nearestFireDept &&
          errors.nearestFireDept.message !== "" &&
          errors.nearestPoliceStat &&
          errors.nearestPoliceStat.message !== "" &&
          // errors.remainCalmAndAssessEmer1 && errors.remainCalmAndAssessEmer1.message !== '' &&
          // errors.remainCalmAndAssessEmer2 && errors.remainCalmAndAssessEmer2.message !== '' &&
          // errors.initiateEmergencyResp1 && errors.initiateEmergencyResp1.message !== '' &&
          // errors.initiateEmergencyResp2 && errors.initiateEmergencyResp2.message !== '' &&
          // errors.initiatePersonMedicalResp1 && errors.initiatePersonMedicalResp1.message !== '' &&
          // errors.initiatePersonMedicalResp2 && errors.initiatePersonMedicalResp2.message !== '' &&
          // errors.reportSafetyIncidentTo && errors.reportSafetyIncidentTo.message !== '' &&
          errors.are911ServicesAvailable &&
          errors.are911ServicesAvailable.message !== ""
        )
          setpbtn(true);
        else setpbtn(true);
        break;

      case "7":
        if (
          form_template[getProceed - 1].end_time === "" &&
          form_template[getProceed].start_time === ""
        ) {
          form_template[getProceed - 1].end_time = _datetime();
          form_template[getProceed].start_time = _datetime();
          form_template[getProceed - 1].time_taken = _totalTime(
            form_template[getProceed - 1].start_time,
            form_template[getProceed - 1].end_time
          );
        }
        if (
          errors.primaryMeansOfRescue &&
          errors.primaryMeansOfRescue.message === ""
          //  &&
          // errors.remainCalmandAssessEmergency1 && errors.remainCalmandAssessEmergency1.message === '' &&
          // errors.remainCalmandAssessEmergency2 && errors.remainCalmandAssessEmergency2.message === '' &&
          // errors.personWillStartRescue1 && errors.personWillStartRescue1.message === '' &&
          // errors.personWillStartRescue2 && errors.personWillStartRescue2.message === '' &&
          // errors.onceVictimAndHero1 && errors.onceVictimAndHero1.message === '' &&
          // errors.onceVictimAndHero2 && errors.onceVictimAndHero2.message === '' &&
          // errors.reportSafetyIncidentToS6 && errors.reportSafetyIncidentToS6.message === ''
        )
          setpbtn(false);
        else if (
          errors.primaryMeansOfRescue &&
          errors.primaryMeansOfRescue.message !== ""
          // &&
          // errors.remainCalmandAssessEmergency1 && errors.remainCalmandAssessEmergency1.message !== '' &&
          // errors.remainCalmandAssessEmergency2 && errors.remainCalmandAssessEmergency2.message !== '' &&
          // errors.personWillStartRescue1 && errors.personWillStartRescue1.message !== '' &&
          // errors.personWillStartRescue2 && errors.personWillStartRescue2.message !== '' &&
          // errors.onceVictimAndHero1 && errors.onceVictimAndHero1.message !== '' &&
          // errors.onceVictimAndHero2 && errors.onceVictimAndHero2.message !== '' &&
          // errors.reportSafetyIncidentToS6 && errors.reportSafetyIncidentToS6.message !== ''
        )
          setpbtn(true);
        else setpbtn(true);
        break;

      case "8":
        if (
          form_template[getProceed - 1].end_time === "" &&
          form_template[getProceed].start_time === ""
        ) {
          form_template[getProceed - 1].end_time = _datetime();
          form_template[getProceed].start_time = _datetime();
          form_template[getProceed - 1].time_taken = _totalTime(
            form_template[getProceed - 1].start_time,
            form_template[getProceed - 1].end_time
          );
        }
        break;

      case "11":
        if (
          form_template[getProceed - 1].end_time === "" &&
          form_template[getProceed].start_time === ""
        ) {
          form_template[getProceed - 1].end_time = _datetime();
          form_template[getProceed].start_time = _datetime();
          form_template[getProceed - 1].time_taken = _totalTime(
            form_template[getProceed - 1].start_time,
            form_template[getProceed - 1].end_time
          );
        }
        break;

      case "12":
        if (
          form_template[getProceed - 1].end_time === "" &&
          form_template[getProceed].start_time === ""
        ) {
          form_template[getProceed - 1].end_time = _datetime();
          form_template[getProceed].start_time = _datetime();
          form_template[getProceed - 1].time_taken = _totalTime(
            form_template[getProceed - 1].start_time,
            form_template[getProceed - 1].end_time
          );
        }
        break;

      case "6":
        if (
          getCrewMemberProceed.crew === 0 &&
          form_template[getProceed - 1].end_time === "" &&
          form_template[getProceed].section[getCrewMemberProceed.crew]
            .start_time === ""
        ) {
          form_template[getProceed - 1].end_time = _datetime();
          form_template[getProceed].section[
            getCrewMemberProceed.crew
          ].start_time = _datetime();
          form_template[getProceed - 1].time_taken = _totalTime(
            form_template[getProceed - 1].start_time,
            form_template[getProceed - 1].end_time
          );
        } else {
          if (
            form_template[0].section[getCrewMemberProceed.crew].end_time ===
              "" &&
            form_template[getProceed].section[getCrewMemberProceed.crew]
              .start_time === ""
          ) {
            form_template[0].section[getCrewMemberProceed.crew].end_time =
              _datetime();
            form_template[getProceed].section[
              getCrewMemberProceed.crew
            ].start_time = _datetime();
            form_template[0].section[getCrewMemberProceed.crew].time_taken =
              _totalTime(
                form_template[0].section[getCrewMemberProceed.crew].start_time,
                form_template[0].section[getCrewMemberProceed.crew].end_time
              );
          }
        }
        if (getClimberList[getCrewMemberProceed.crew] === "Climber") {
          if (
            form_template[getProceed].section[getCrewMemberProceed.crew]
              .questions[2].image_metadata !== "" &&
            form_template[getProceed].section[getCrewMemberProceed.crew]
              .questions[3].image_metadata !== "" &&
            form_template[getProceed].section[getCrewMemberProceed.crew]
              .questions[4].image_metadata !== "" &&
            form_template[getProceed].section[getCrewMemberProceed.crew]
              .questions[5].image_metadata !== "" &&
            form_template[getProceed].section[getCrewMemberProceed.crew]
              .questions[6].image_metadata !== ""
          ) {
            setpbtn(false);
          } else {
            setpbtn(true);
          }
        }

        break;

      case "14":
        if (
          form_template[getProceed].section[getCrewMemberProceed.crew]
            .start_time === "" &&
          form_template[getProceed - 1].section[getCrewMemberProceed.crew]
            .end_time === ""
        ) {
          form_template[getProceed - 1].section[
            getCrewMemberProceed.crew
          ].end_time = _datetime();
          form_template[getProceed].section[
            getCrewMemberProceed.crew
          ].start_time = _datetime();
          form_template[getProceed - 1].section[
            getCrewMemberProceed.crew
          ].time_taken = _totalTime(
            form_template[getProceed - 1].section[getCrewMemberProceed.crew]
              .start_time,
            form_template[getProceed - 1].section[getCrewMemberProceed.crew]
              .end_time
          );
        } else {
          if (
            getProceed1 === 15 &&
            form_template[getProceed].section[getCrewMemberProceed.crew]
              .end_time === ""
          ) {
            form_template[getProceed].section[
              getCrewMemberProceed.crew
            ].end_time = _datetime();
            form_template[getProceed].section[
              getCrewMemberProceed.crew
            ].time_taken = _totalTime(
              form_template[getProceed].section[getCrewMemberProceed.crew]
                .start_time,
              form_template[getProceed].section[getCrewMemberProceed.crew]
                .end_time
            );
          }
        }

        if (
          // errors.hazardsProperlyMitigated2 && errors.hazardsProperlyMitigated2.message === '' &&
          // errors.personalProtectiveEquip2 && errors.personalProtectiveEquip2.message === '' &&
          errors.JobHazardAndSafetyBrief2 &&
          errors.JobHazardAndSafetyBrief2.message === "" &&
          getSignBase64Uri1 !== ""
        )
          setpbtn(false);
        else if (
          // errors.hazardsProperlyMitigated2 && errors.hazardsProperlyMitigated2.message !== '' &&
          // errors.personalProtectiveEquip2 && errors.personalProtectiveEquip2.message !== '' &&
          errors.JobHazardAndSafetyBrief2 &&
          errors.JobHazardAndSafetyBrief2.message !== "" &&
          getSignBase64Uri1 === ""
        )
          setpbtn(true);
        else setpbtn(true);

        if (!_.isEmpty(getIjhaform.reportId)) {
          setpbtn(false);

          if (
            form_template[getProceed].section[getCrewMemberProceed.crew]
              .questions[2].questionType === "Radio+Signature"
          ) {
            if (
              form_template[getProceed].section[getCrewMemberProceed.crew]
                .questions[2].signature === ""
            ) {
              setpbtn(true);
            } else {
              setpbtn(false);
            }
          }
        }
        break;
      // case '15':
      //     if (
      //         errors.typeofwork && errors.typeofwork.message === ''
      //     )
      //         setpbtn(false)
      //     else if (
      //         errors.typeofwork && errors.typeofwork.message !== ''
      //     )
      //         setpbtn(true);
      //     else
      //         setpbtn(true);
      //     break;

      // case '12':
      //     if (
      //         errors.FireExtinguiserAvailable && errors.FireExtinguiserAvailable.message === '' &&
      //         errors.FireExtinguiserInspection && errors.FireExtinguiserInspection.message === '' &&
      //         errors.firstAidKitAvail && errors.firstAidKitAvail.message === '' &&
      //         errors.workZone && errors.workZone.message === ''
      //     )
      //         setpbtn(false)
      //     else if (
      //         errors.FireExtinguiserAvailable && errors.FireExtinguiserAvailable.message !== '' &&
      //         errors.FireExtinguiserInspection && errors.FireExtinguiserInspection.message !== '' &&
      //         errors.firstAidKitAvail && errors.firstAidKitAvail.message !== '' &&
      //         errors.workZone && errors.workZone.message !== ''
      //     )
      //         setpbtn(true);
      //     else
      //         setpbtn(true);
      //     break;
      default:
    }
  }, [watch(errors)]);

  // useEffect(() => {
  //     let _getPFASOptions = [];
  //     form_template[1].questions[0].options.map((obj, i) => {
  //         if (obj.is_selected)
  //             _getPFASOptions.push({
  //                 value: obj.value,
  //                 label: obj.value,
  //                 color: '#00B8D9',
  //                 isFixed: obj.is_selected,
  //                 _is_selected: obj.is_selected,
  //                 file_obj: "",
  //                 file_url: "",
  //                 _key: 0,
  //                 _s_key: i,
  //             });
  //     })
  //     setPFASOptions(_getPFASOptions);
  // }, [])

  useEffect(() => {
    let _getSelectedOptionsMulti1 = [];
    let _getHCMOptions = _.cloneDeep(getHCMOptions);
    let _getCommentMulti1 = _.cloneDeep(getCommentMulti1);
    // form_template[8].questions[0].table_options[i].pre_selected_options
    form_template[8].questions[0].table_options.map((obj, i) => {
      _getSelectedOptionsMulti1.push([]);
      if (obj.pre_selected_options.length) {
        let _optionsArr = obj.options.map((val) => {
          return { value: val, label: val, color: "#00B8D9" };
        });
        _getHCMOptions.push({
          value: obj.question,
          label: obj.question,
          color: "#00B8D9",
          isFixed: false,
          _options: _optionsArr,
          _s_key: i,
        });
        obj.selected_options.map((val, key) => {
          _getSelectedOptionsMulti1[i].push({
            value: val,
            label: val,
            color: "#00B8D9",
          });
        });
        _getCommentMulti1[i] = obj.comment_text;
      }
    });
    setHCMOptions(_getHCMOptions);
    setSelectedOptionsMulti1(_getSelectedOptionsMulti1);
    setCommentMulti1(_getCommentMulti1);

    // let _getPFASOptions = _.cloneDeep(getPFASOptions);
    // form_template[1].questions[0].options.map((obj, i) => {
    //     if (obj.is_selected)
    //         _getPFASOptions.push({
    //             value: obj.value,
    //             label: obj.value,
    //             color: '#00B8D9',
    //             isFixed: obj.is_selected,
    //             _is_selected: obj.is_selected,
    //             file_obj: "",
    //             file_url: "",
    //             _key: 0,
    //             _s_key: i,
    //         });
    // })
    // setPFASOptions(_getPFASOptions);

    // uncommented below to active climber and nonclimber
    let _getCrewLeadData = _.cloneDeep(getCrewLeadData);
    let _checkClimbMember = _getCrewLeadData.crewmemberdata.map(
      (i, k) => i.membertype
    );
    let _checkClimber = [_getCrewLeadData.membertype, ..._checkClimbMember];
    setClimberList(_checkClimber);

    start_datetime_filling();
    getLocation();

    //section id 2
    form_template[2].questions[0].answer !== "" &&
      setError("piNumber", { message: "" });
    form_template[2].questions[1].answer !== "" &&
      setError("loc911Add", { message: "" });
    form_template[2].questions[2].answer !== "" &&
      setError("ccnameJobName", { message: "" });
    getFirstPart(form_template[2].questions[3].answer) !== "" &&
      setError("customername1", { message: "" });
    getSecondPart(form_template[2].questions[3].answer) !== "" &&
      setError("customername2", { message: "" });
    getFirstPart(form_template[2].questions[4].answer) !== "" &&
      setError("siteLanLord1", { message: "" });
    getSecondPart(form_template[2].questions[4].answer) !== "" &&
      setError("siteLanLord2", { message: "" });

    //section id 3
    form_template[3].questions[0].answer !== "" &&
      setError("generalScopOfProj", { message: "" });

    //section id 4
    form_template[4].questions[0].answer !== "" &&
      setError("onSiteCompInfo", { message: "" });
    removeTags(form_template[4].questions[1].answer) !== "" &&
      setError("jobSiteOperMang", { message: "" });
    removeTags(form_template[4].questions[2].answer) !== "" &&
      setError("jobSiteMangOfSafety", { message: "" });
    form_template[4].questions[3].answer !== "" &&
      setError("onSitePersonInCharge", { message: "" });
    // form_template[4].questions[4].answer !== '' && setError('jobSitePersonnel', { message: '' })
    // form_template[4].questions[5].answer !== '' && setError('siteSafetyTrainingDocOnSite', { message: '' })

    //section id 5
    form_template[5].questions[0].answer === ""
      ? setError("are911ServicesAvailable", {
          message: "This field is required",
        })
      : setError("are911ServicesAvailable", { message: "" });
    form_template[5].questions[1].answer !== "" &&
      setError("hospitalInfo", { message: "" });
    form_template[5].questions[2].answer !== "" &&
      setError("evacuationPoint", { message: "" });
    form_template[5].questions[3].answer !== "" &&
      setError("directionsToHospital", { message: "" });
    form_template[5].questions[4].answer !== "" &&
      setError("directionsFromHospital", { message: "" });
    form_template[5].questions[5].answer !== "" &&
      setError("nearestFireDept", { message: "" });
    form_template[5].questions[6].answer !== "" &&
      setError("nearestPoliceStat", { message: "" });
    form_template[5].questions[7].subQuestion[0].answer !== "" &&
      setError("remainCalmAndAssessEmer1", { message: "" });
    form_template[5].questions[7].subQuestion[1].answer !== "" &&
      setError("remainCalmAndAssessEmer2", { message: "" });
    form_template[5].questions[8].subQuestion[0].answer !== "" &&
      setError("initiateEmergencyResp1", { message: "" });
    form_template[5].questions[8].subQuestion[1].answer !== "" &&
      setError("initiateEmergencyResp2", { message: "" });
    form_template[5].questions[9].subQuestion[0].answer !== "" &&
      setError("initiatePersonMedicalResp1", { message: "" });
    form_template[5].questions[9].subQuestion[1].answer !== "" &&
      setError("initiatePersonMedicalResp2", { message: "" });
    form_template[5].questions[10].answer !== "" &&
      setError("reportSafetyIncidentTo", { message: "" });

    //section id 7
    form_template[6].questions[0].answer !== "" &&
      setError("primaryMeansOfRescue", { message: "" });
    form_template[6].questions[1].subQuestion[0].answer !== "" &&
      setError("remainCalmandAssessEmergency1", { message: "" });
    form_template[6].questions[1].subQuestion[1].answer !== "" &&
      setError("remainCalmandAssessEmergency2", { message: "" });
    form_template[6].questions[2].subQuestion[0].answer !== "" &&
      setError("personWillStartRescue1", { message: "" });
    form_template[6].questions[2].subQuestion[1].answer !== "" &&
      setError("personWillStartRescue2", { message: "" });
    form_template[6].questions[3].subQuestion[0].answer !== "" &&
      setError("onceVictimAndHero1", { message: "" });
    form_template[6].questions[3].subQuestion[1].answer !== "" &&
      setError("onceVictimAndHero2", { message: "" });
    form_template[6].questions[4].answer !== "" &&
      setError("reportSafetyIncidentToS6", { message: "" });

    //section id 15
    // getFirstPart(form_template[1].questions[1].answer) !== '' && setError('typeOfStructure1', { message: '' })
    // !== '' && setError('typeOfStructure2', { message: '' })
    getFirstPart(form_template[1].questions[2].answer) !== "" &&
      setError("overallConditionOfTheStructure1", { message: "" });
    form_template[1].questions[2].description !== "" &&
      setError("overallConditionOfTheStructure2", { message: "" });
    getFirstPart(form_template[1].questions[3].answer) !== "" &&
      setError("conditionOfTheClimbingFacilities1", { message: "" });
    // !== '' && setError('conditionOfTheClimbingFacilities2', { message: '' })
    getFirstPart(form_template[1].questions[4].answer) !== "" &&
      setError("visuallyInspectTheFixed1", { message: "" });
    // !== '' && setError('visuallyInspectTheFixed2', { message: '' })
    form_template[1].questions[7].answer !== "" &&
      setError("otherStructuralHazards", { message: "" });
    form_template[1].questions[6].answer === ""
      ? setError("AnchorPointsInspected", { message: "This field is required" })
      : setError("AnchorPointsInspected", { message: "" });
    form_template[1].questions[6].answer === ""
      ? setError("AllStructuralAppurtenance", {
          message: "This field is required",
        })
      : setError("AllStructuralAppurtenance", { message: "" });
    form_template[1].questions[0].answer !== "" &&
      setError("typeofwork1", { message: "" });
    form_template[1].questions[0].answer !== "" &&
      setError("typeofwork2", { message: "" });

    setError("PPEOther7", { message: "" });

    //section id 12
    // setError('FireExtinguiserAvailable', { message: '' })
    // setError('FireExtinguiserInspection', { message: '' })
    // setError('firstAidKitAvail', { message: '' })
    // setError('workZone', { message: '' })

    // section id 14
    form_template[11].section[getCrewMemberProceed.crew].questions[0]
      .description !== "" &&
      setError("hazardsProperlyMitigated2", { message: "" });
    form_template[11].section[getCrewMemberProceed.crew].questions[1]
      .description !== "" &&
      setError("personalProtectiveEquip2", { message: "" });

    // setError('JobHazardAndSafetyBrief2', { message: '' })

    // !_.isEmpty(getIjhaform.reportId) ? setCrewLeadData(s.state.getCrewLeadData) : getAddCrewMemberApi(setCrewLeadData, setLoading, setErrMsg);
  }, []);
  useEffect(() => {
    if (!getDelCrew) {
      if (!_.isEmpty(getCrewLeadData.crewmemberdata)) {
        let _getIsCompleted = [];
        _getIsCompleted[0] = true;
        getCrewLeadData.crewmemberdata.map((obj, i) => {
          _getIsCompleted[i + 1] = false;
        });
        setIsCompleted(_getIsCompleted);
      }
      if (getReportCrewCheck) {
        if (!_.isEmpty(getAddEditCrew)) {
          let _getIsCompleted = [];
          _getIsCompleted[0] = false;
          let _getBlankData = _.cloneDeep(getAddEditCrew);
          _getBlankData = _getBlankData.filter((_blank, k) => {
            if ("employeeId" in _blank) {
              _getIsCompleted[k + 1] = true;
            } else {
              _getIsCompleted[k + 1] = false;
            }
          });
          setIsCompleted(_getIsCompleted);
        } else {
          let _getIsCompleted = [true];
          setIsCompleted(_getIsCompleted);
        }
      }
    }
  }, [getCrewLeadData, getCheckComplete]);
  useEffect(() => {
    // console.log({
    //     'latitude': getLatitude,
    //     'longitude': getLongitude,
    //     'unit': getUnit
    // })
    // console.log('radioValue:', radioValue, sendData);
    // console.log('RUN::', getSignBase64Uri1, getSignBase64Uri)
    getWeather(setForm_template, form_template, sendData, setWeatherHours);
    // console.log("OSOAO", form_template)
    // form_template[getProceed].sectionId === '1' && getUploadImgUrls(getSignBase64Uri, 2, '1');

    form_template[getProceed].sectionId === "1" &&
      setTimeout(
        () => getUploadImgUrls([getSignBase64Uri, 0, 0, "sign"], 4, "0"),
        500
      );
    // form_template[getProceed].sectionId === '14' && getUploadImgUrls(getSignBase64Uri1, 3, '3');
    form_template[getProceed].sectionId === "14" &&
      setTimeout(
        () =>
          getUploadImgUrls(
            [getSignBase64Uri1, getCrewMemberProceed.crew, 2, "sign"],
            4,
            "0"
          ),
        500
      );
  }, [getUnit, radioValue, getSignBase64Uri, getSignBase64Uri1]);

  useEffect(() => {
    // console.log('runnnnn', getUploadedImgUrls);
    if (getUploadedImgUrls.length !== 0) {
      switch (form_template[getProceed].sectionId) {
        case "1":
          //  'signature' ,'picture'
          changeFormTemplateData([9, getUploadedImgUrls, 0, 0]);
          break;
        case "3":
          changeFormTemplateData([17, getUploadedImgUrls, 3, 0]);
          break;
        case "10":
          changeFormTemplateData([10, getUploadedImgUrls]);
          break;
        case "12":
          changeFormTemplateData([12, getUploadedImgUrls]);
          break;
        case "6":
          // section 6
          changeFormTemplateData([13, getUploadedImgUrls, 0]);
          break;
        case "14":
          // section 14
          changeFormTemplateData([14, getUploadedImgUrls, 0]);
          break;
        default:
      }
    }
  }, [getUploadedImgUrls]);

  const aiALLDetectJson = async (pic_url) => {
    const ai_all = await detectAIALL(pic_url, setLoading, setErrMsg);
    // console.log(ai_all, 'ppe-new');
    let _ai_all = [
      "gloves",
      "hard-hat",
      "harness",
      "safety-vest",
      "work-boots",
    ];
    Object.keys(ai_all.data).filter((obj_ai) => obj_ai !== "fire-ext");
    // console.log(_ai_all, "SASIUUUSUUUSUSUS")
    let _form_template = _.cloneDeep(form_template);
    // console.log("OSOOSOSAOPAPPPP", _form_template)
    _form_template[getProceed].section[getCrewMemberProceed.crew].questions =
      _form_template[getProceed].section[
        getCrewMemberProceed.crew
      ].questions.map((obj, i) => {
        _ai_all.map((obj_ai) => {
          // obj_ai !== 'fire-ext'
          // if (i >= 2 && i <= 6) obj.picture = pic_url;

          if (obj.category === obj_ai) {
            if (ai_all.data[obj_ai]) {
              obj.answer = ai_all.data[obj_ai].status;
              obj.confidence = ai_all.data[obj_ai].confidence;
              obj.threshold = ai_all.data[obj_ai].threshold;
              obj.image_metadata = getImgMetaData;
              obj.ai_approved = ai_all.data[obj_ai].ai_approved;
              obj.other_confidence = ai_all.data[obj_ai].other_confidence;
              obj.picture = pic_url;
            } else {
              obj.answer = "";
              obj.confidence = "";
              obj.threshold = "";
              obj.image_metadata = "";
              obj.other_confidence = "";
              obj.picture = "";
              obj.ai_approved = "";
            }
          }
        });
        return obj;
      });
    // console.log("OSOAO", _form_template)
    setForm_template(_form_template);
    // console.log("OSOAO", form_template)
  };

  useEffect(() => {
    //ai data
    // console.log('setUploadedImgUrls1:::', getUploadedImgUrls1, getImgAiDetectKeys, getAIData);
    if (getImgAiDetectKeys.ai === "ai") {
      let category =
        form_template[getProceed].section[getImgAiDetectKeys.section].questions[
          getImgAiDetectKeys.questions
        ].category;
      detectAI(
        setAIData,
        getUploadedImgUrls1[0],
        category,
        getImgAiDetectKeys,
        setImgAiDetectKeys,
        changeFormTemplateData,
        setLoading,
        13
      );
    } else if (getImgAiDetectKeys.ai === "") {
      changeFormTemplateData([
        13,
        getUploadedImgUrls1[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
      ]);
    } else if (getImgAiDetectKeys.ai === "pic") {
      changeFormTemplateData([
        19,
        getUploadedImgUrls1[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "picture",
      ]);
    } else if (getImgAiDetectKeys.ai === "sign") {
      changeFormTemplateData([
        19,
        getUploadedImgUrls1[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "signature",
      ]);
    } else if (getImgAiDetectKeys.ai === "subQuestionPic") {
      changeFormTemplateData([
        20,
        getUploadedImgUrls1[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "picture",
      ]);
    } else if (getImgAiDetectKeys.ai === "picNoSection") {
      changeFormTemplateData([
        21,
        getUploadedImgUrls1[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "picture",
      ]);
    } else if (getImgAiDetectKeys.ai === "PersonalFallArrestSystemMultiPic") {
      changeFormTemplateData([
        27,
        getUploadedImgUrls1[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "multiple_picture",
      ]);
    } else if (getImgAiDetectKeys.ai === "ai-hazard-control") {
      let category =
        form_template[getProceed].questions[getImgAiDetectKeys.questions]
          .category;
      detectAI(
        setAIData,
        getUploadedImgUrls1[0],
        category,
        getImgAiDetectKeys,
        setImgAiDetectKeys,
        changeFormTemplateData,
        setLoading,
        25
      );
    } else if (getImgAiDetectKeys.ai === "ai-all") {
      // console.log("ai-all ppe-new")
      aiALLDetectJson(getUploadedImgUrls1[0]);
    }
    // changeFormTemplateData
    // if (!_.isEmpty(getAIData)) {

    // }
    //pic_url,category
  }, [getUploadedImgUrls1, getImgAiDetectKeys, getImgMetaData]);
  const multiImageUrl = async (_value, _key, _arr_key, fieldType) => {
    let sendDataImg = {
      files: [_value[0]],
      emailId: DECRYPTED_DATA.emailId,
      reportId: uuidv4(),
      upload_type: "JHA",
      section_names: form_template[getProceed].sectionName,
      employeeId: EMP_ID,
      setLoading: setLoading,
      setSetP: setSetP,
    };

    _value[0] !== "" &&
      (await uploadMultiImage(
        setUploadedImgUrls2,
        sendDataImg,
        setImgUrls,
        fieldType,
        setError
      ));
    _value[0] !== "" &&
      setImgAiDetectKeys2({ section: _value[1], questions: _value[2] });
  };
  useEffect(() => {
    changeFormTemplateData([
      27,
      getUploadedImgUrls2[0],
      getImgAiDetectKeys2.section,
      getImgAiDetectKeys2.questions,
      "multiple_picture",
    ]);
  }, [getUploadedImgUrls2, getImgAiDetectKeys2]);
  const getUploadImgUrls = async (
    _value,
    _key,
    _arr_key,
    fieldType,
    isAiImage = false,
    sectionsToUpdate = []
  ) => {
    // console.log(_value, _key, _arr_key, getProceed, form_template, 'ppe-new', fieldType)

    //  getUploadImgUrls([e.target.files[0], 0, 2, 'pic'], 4, '0')
    // event.target.files[0] get data from file input and store in temp state setImgUrls()
    let _getImgUrls = _.cloneDeep(getImgUrls);
    let sendDataImg = {};
    switch (_key) {
      case 1:
        _getImgUrls[_arr_key] = _value;
        break;
      case 2:
        // console.log(_arr_key)
        _getImgUrls[_arr_key] = getSignBase64Uri;
        // _getImgUrls[_arr_key] = _value;
        break;
      case 3:
        _getImgUrls[_arr_key] = getSignBase64Uri1;
        break;
      case 4:
        sendDataImg = {
          files: [_value[0]],
          emailId: DECRYPTED_DATA.emailId,
          reportId: uuidv4(),
          upload_type: "JHA",
          section_names: form_template[getProceed].sectionName,
          employeeId: EMP_ID,
          setLoading: setLoading,
          setSetP: setSetP,
        };
      //[e.target.files[0], 0, 2, 'ai']

      case 5:
        // console.log('5555')
        _getImgUrls[_arr_key] = _value;
        break;
      case 6:
        sendDataImg = {
          files: [_value[0]],
          emailId: DECRYPTED_DATA.emailId,
          reportId: uuidv4(),
          upload_type: "JHA",
          section_names: form_template[getProceed].sectionName,
          employeeId: EMP_ID,
          setLoading: setLoading,
          setSetP: setSetP,
        };
        //[e.target.files[0], 0, 2, 'ai']
        await uploadMultiImage(
          setUploadedImgUrls1,
          sendDataImg,
          setImgUrls,
          fieldType,
          setError
        );
        _value[0] !== "" &&
          setImgAiDetectKeys({
            section: _value[1],
            questions: _value[2],
            ai: _value[3],
          });
        break;
      default:
    }

    if (isAiImage) {
      const response = await checkImageData(
        { doc_file: _value[0] },
        setImgMetaData
      );

      if (fieldType === "PPEHardHat1") {
        // let _form_template = _.cloneDeep(form_template);
        if (sectionsToUpdate.length) {
          sectionsToUpdate.forEach(({ sectionFieldType, sectionId }) => {
            let Timeconversion = parseInt(response.time_diff);
            let message = "";

            if (Timeconversion >= 720) {
              message = "The image is older than 12 hours";
              setError(sectionFieldType, {
                message,
              });
            } else if (response.time_diff === "Time not get extracted") {
              message =
                "This photo does not have date and time information embedded and cannot be verified. Please upload the image captured in last 1 hour only.";
              setError(sectionFieldType, {
                message,
              });
            } else {
              message = "Time Verified";
              setError(sectionFieldType, {
                message1: message,
              });
            }
            // _form_template[getProceed].section[_value[1]].questions[sectionId].image_metadata = message

            // console.log("CHECKING", _form_template)
            // console.log("CHECKING", _form_template[getProceed].section[_value[1]].questions[sectionId])
          });
        }
        // console.log("OSOAO", _form_template)
        // setForm_template(_form_template)
        // console.log("OSOAO", _form_template)
      } else if (fieldType === "FireExtinguiserAvailable") {
        let _form_template = _.cloneDeep(form_template);

        let Timeconversion = parseInt(response.time_diff);
        let message = "";
        if (Timeconversion >= 720) {
          message = "The image is older than 12 hours";
          setError(fieldType, {
            message,
          });
        } else if (response.time_diff === "Time not get extracted") {
          message =
            "This photo does not have date and time information embedded and cannot be verified. Please upload the image captured in last 1 hour only.";
          setError(fieldType, {
            message,
          });
        } else {
          message = "Time Verified";
          setError(fieldType, {
            message1: message,
          });
        }

        _form_template[getProceed].questions[_value[1]].image_metadata =
          message;
        setForm_template(_form_template);
        // console.log("OSOAO", form_template)
        await uploadMultiImage(
          setUploadedImgUrls1,
          sendDataImg,
          setImgUrls,
          fieldType,
          setError
        );

        _value[0] !== "" &&
          setImgAiDetectKeys({
            section: _value[1],
            questions: _value[2],
            ai: _value[3],
          });
      } else {
        // let _form_template = _.cloneDeep(form_template);

        let Timeconversion = parseInt(response.time_diff);
        let message = "";
        if (Timeconversion >= 720) {
          message = "The image is older than 12 hours";
          setError(fieldType, {
            message,
          });
        } else if (response.time_diff === "Time not get extracted") {
          message =
            "This photo does not have date and time information embedded and cannot be verified. Please upload the image captured in last 1 hour only.";
          setError(fieldType, {
            message,
          });
        } else {
          message = "Time Verified";
          setError(fieldType, {
            message1: message,
          });
        }

        //  _form_template[getProceed].section[_value[1]].questions[_value[2]].image_metadata = message;
        // setForm_template(_form_template)
        // console.log("OSOAO", form_template)
        // console.log(_form_template, "***********INSingle_form_template1343*************")
        await uploadMultiImage(
          setUploadedImgUrls1,
          sendDataImg,
          setImgUrls,
          fieldType,
          setError
        );

        _value[0] !== "" &&
          setImgAiDetectKeys({
            section: _value[1],
            questions: _value[2],
            ai: _value[3],
          });
      }
    }

    // console.log(form_template, "***********INSingle_form_template1566*************")
    await uploadMultiImage(
      setUploadedImgUrls1,
      sendDataImg,
      setImgUrls,
      fieldType,
      setError
    );

    _value[0] !== "" &&
      setImgAiDetectKeys({
        section: _value[1],
        questions: _value[2],
        ai: _value[3],
      });

    // console.log("PSPSPSP", _getImgUrls);
    setImgUrls(_getImgUrls);
  };
  useEffect(() => {
    // console.log("ISUAUSAUISUAUSIAFORM")
  }, [form_template]);
  const provideLatLngfrmStr = (text) =>
    text
      .split("(")[1]
      .split(")")
      .filter((obj) => obj !== "")[0]
      .split(",")
      .map((obj) => Number(obj));

  // const isSectionCompleted = (_section_id) => {
  //     switch (_section_id) {
  //         case 1:
  //             f
  //             break;
  //         default:

  //     }
  // }
  const saveSendToUploadImg = () => {
    // // console.log(isSectionCompleted(getProceed))
    // console.log(getImgUrls);
    let sendDataImg = {
      files: Object.values(getImgUrls),
      emailId: DECRYPTED_DATA.emailId,
      reportId: uuidv4(),
      upload_type: "JHA",
      section_names: form_template[getProceed].sectionName,
      employeeId: EMP_ID,
      setLoading: setLoading,
      setSetP: setSetP,
    };
    uploadMultiImage(
      form_template[getProceed].sectionId === "6"
        ? setUploadedImgUrls1
        : setUploadedImgUrls,
      sendDataImg,
      setImgUrls
    );
  };

  useEffect(() => {
    if (
      getIjhaform.reportId === undefined &&
      getIjhaform.complete_status !== "InProgress"
    ) {
      get911AddressInfoApi(setForm_template, form_template, getLoc, setLoading);
    }
  }, [getLoc]);

  const getLocation = () => {
    var _latlng;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        setUnit("imperial");
        _latlng = _.cloneDeep(getLoc);
        _latlng.lat = position.coords.latitude;
        _latlng.lng = position.coords.longitude;
        setLoc(_latlng);
        // console.log(position);
      });
    } else {
      setErrMsg("Geolocation is not supported by this browser.");
    }
  };
  const formTempChange = () => {
    let _form_template = _.cloneDeep(form_template);
    _form_template[0].section[0]["employeeId"] = EMP_ID.replaceAll('"', "");
    // _formTemp[10].section[0]["employeeId"] = EMP_ID.replaceAll('"', '');
    _form_template[10].section[0]["employeeId"] = EMP_ID.replaceAll('"', "");
    _form_template[11].section[0]["employeeId"] = EMP_ID.replaceAll('"', "");

    getCrewLeadData.crewmemberdata.map((obj, i) => {
      _form_template[0].section[i + 1]["employeeId"] = obj.employeeId;
      // _formTemp[10].section[i + 1]["employeeId"] = obj.employeeId;
      _form_template[10].section[i + 1]["employeeId"] = obj.employeeId;
      _form_template[11].section[i + 1]["employeeId"] = obj.employeeId;
    });
    return _form_template;
  };
  const start_datetime_filling = () => {
    let _getIjhaform = _.cloneDeep(getIjhaform);
    _getIjhaform["start_time_datetime"] = getCurrentDataTime();
    setIjhaform(_getIjhaform);
  };

  const onSubmitIJHAForm = () => {
    setSummary(false);
    let crew_members_id = getCrewLeadData.crewmemberdata
      ? getCrewLeadData.crewmemberdata.map((obj) => obj.employeeId)
      : "";
    // uncommented below to active climber and nonclimber
    let membertype = getCrewLeadData.crewmemberdata
      ? getCrewLeadData.crewmemberdata.map((obj) => obj.membertype)
      : "";
    let _getIjhaform = _.cloneDeep(getIjhaform);
    _getIjhaform["form_template"] = formTempChange();

    _getIjhaform["reportId"] = getReportID;

    _getIjhaform["teamLeadId"] = EMP_ID.replaceAll('"', "");
    _getIjhaform["crew_members"] = [
      EMP_ID.replaceAll('"', ""),
      ...crew_members_id,
    ];
    // uncommented below to active climber and nonclimber
    _getIjhaform["membertype"] = [getCrewLeadData.membertype, ...membertype];
    _getIjhaform["latitude"] = getLatitude;
    _getIjhaform["longitude"] = getLongitude;
    _getIjhaform["device_details"] = {
      app_version: null,
      device_token: null,
      build_number: null,
      os_version: null,
    };
    //test
    _getIjhaform["weather"] = weatherDetailsChange();
    // _getIjhaform["weather"]["results"][0].
    _getIjhaform["state"] = "";
    _getIjhaform["state_id"] = "";
    _getIjhaform["complete_status"] = "1";
    _getIjhaform["usergroupId"] = null;

    _getIjhaform["formId"] = "10";
    _getIjhaform["app_type"] = "Ericsson";
    _getIjhaform["submitted_datetime"] = getCurrentDataTime();
    _getIjhaform["total_time_taken"] = getTotalTime(
      _getIjhaform["start_time_datetime"],
      _getIjhaform["submitted_datetime"]
    );
    _getIjhaform["resubmit"] = !_.isEmpty(getIjhaform.reportId) ? "1" : "0";
    _getIjhaform["form_name"] = "Ericsson iJHA";
    // _getIjhaform['update_reason'] = !_.isEmpty(getIjhaform.reportId) ? "n/a" : "";
    // console.log(JSON.stringify(_getIjhaform));
    // _getIjhaform["submitted_datetime"] = ""
    ijhaFormSubmit(_getIjhaform, setLoading, setIjhaSubmit);

    setIjhaform(_getIjhaform);
  };
  const weatherDetailsChange = () => {
    let _weatherDetails = _.cloneDeep(
      form_template[7].questions[0].weather_imperial
    );
    let unitFC = "";
    switch (radioValue) {
      case "imperial":
        unitFC = wheather_radio[0].name;
        break;
      case "metric":
        unitFC = wheather_radio[1].name;
        break;
      default:
    }
    _weatherDetails["results"][0]["cloudCover"] =
      _weatherDetails["results"][0]["cloudCover"] + "%";
    _weatherDetails["results"][0]["dewPoint"]["value"] =
      _weatherDetails["results"][0]["dewPoint"]["value"] + " " + unitFC;
    _weatherDetails["results"][0]["pressure"]["value"] =
      _weatherDetails["results"][0]["pressure"]["value"] +
      " " +
      _weatherDetails["results"][0]["pressure"]["unit"];
    _weatherDetails["results"][0]["realFeelTemperature"]["value"] =
      _weatherDetails["results"][0]["realFeelTemperature"]["value"] +
      " " +
      unitFC;
    _weatherDetails["results"][0]["relativeHumidity"] =
      _weatherDetails["results"][0]["relativeHumidity"] + "%";
    _weatherDetails["results"][0]["temperature"]["value"] =
      _weatherDetails["results"][0]["temperature"]["value"] + " " + unitFC;
    _weatherDetails["results"][0]["visibility"]["value"] =
      _weatherDetails["results"][0]["visibility"]["value"] +
      " " +
      _weatherDetails["results"][0]["visibility"]["unit"];
    _weatherDetails["results"][0]["wind"]["speed"]["value"] =
      _weatherDetails["results"][0]["wind"]["speed"]["value"] +
      " " +
      _weatherDetails["results"][0]["wind"]["speed"]["unit"];
    _weatherDetails["results"][0]["windGust"]["speed"]["value"] =
      _weatherDetails["results"][0]["windGust"]["speed"]["value"] +
      " " +
      _weatherDetails["results"][0]["windGust"]["speed"]["unit"];
    return _weatherDetails;
  };
  const changeFormTemplateData = (args) => {
    const [_type, _value, _key, _s_key, _key_name, _attri] = [...args];
    let first_part, second_part, latitude, longitude;
    let _form_template = _.cloneDeep(form_template);
    // console.log('data::12344', _form_template);
    // console.log(_type, _value, _key, _s_key, _form_template[getProceed].sectionName);
    switch (_type) {
      case 1:
        //common
        _form_template[getProceed].questions[_key].answer = _value;
        break;
      case 2:
        //add only first part of the string for eg 'data1-data2'
        first_part = _value;
        second_part = getSecondPart(
          _form_template[getProceed].questions[_key].answer
        );
        _form_template[getProceed].questions[_key].answer = first_part.concat(
          "-",
          second_part
        );
        break;
      case 3:
        //add only second part of the string for eg 'data1-data2'
        first_part = getFirstPart(
          _form_template[getProceed].questions[_key].answer
        );
        second_part = _value;
        _form_template[getProceed].questions[_key].answer = first_part.concat(
          "-",
          second_part
        );
        break;
      case 4:
        // add latitute and logintute in address field
        let data_loc = "";
        // console.log('667', _form_template[getProceed].questions[_key].answer, _value)
        if (_form_template[getProceed].questions[_key].answer !== "") {
          data_loc =
            _form_template[getProceed].questions[_key].answer.split("(")[0];
        }

        latitude = getLatitude;
        longitude = getLongitude;
        if (typeof _value === "object") {
          _form_template[getProceed].questions[_key].answer = _value[0];
          //set distance and duration
          _form_template[getProceed].questions[_key].distance = _value[1];
          _form_template[getProceed].questions[_key].duration = _value[2];
        } else {
          _form_template[getProceed].questions[_key].answer = _value;
        }
        let latLog = provideLatLngfrmStr(
          _form_template[getProceed].questions[_key].answer
        );
        _form_template[getProceed].questions[_key].lat = latLog[0];
        _form_template[getProceed].questions[_key].long = latLog[1];
        //  + ' ' + data_loc + " (" + latitude + ',' + longitude + ')'
        break;
      case 5:
        //add subquestion
        _form_template[getProceed].questions[_key].subQuestion[_s_key].answer =
          _value;
        break;
      case 6:
        //description
        _form_template[getProceed].questions[_key].description = _value;
        break;
      case 7:
        // section
        //methodType AI

        if (
          _form_template[getProceed].section[_key].questions[_s_key]
            .methodType === "AI" &&
          _form_template[getProceed].section[_key].questions[_s_key]
            .confidence !== "" &&
          _form_template[getProceed].section[_key].questions[_s_key].answer ===
            "No"
        ) {
          // console.log('run', _value)
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].overridden = _value;
        } else {
          _form_template[getProceed].section[_key].questions[_s_key].answer =
            _value;
        }
        break;
      case 8:
        //section description
        _form_template[getProceed].section[_key].questions[_s_key].description =
          _value;
        break;
      case 9:
        //section img add in json 'signature' & 'picture'
        _form_template[getProceed].section[_key].questions[_s_key]["picture"] =
          _value[0];
        _form_template[getProceed].section[_key].questions[_s_key][
          "signature"
        ] = _value[1];
        break;
      case 10:
        _form_template[getProceed].questions[0]["picture"] = _value[0];
        _form_template[getProceed].questions[2]["picture"] = _value[1];
        _form_template[getProceed].questions[3]["picture"] = _value[2];
        break;
      case 12:
        _form_template[getProceed].questions[0]["picture"] = _value[0];
        _form_template[getProceed].questions[1]["picture"] = _value[1];
        _form_template[getProceed].questions[2]["picture"] = _value[2];
        _form_template[getProceed].questions[3]["picture"] = _value[3];
        break;
      case 13:
        //set ai and images related data
        if (typeof _value === "object") {
          // console.log("SASISIISISISI", _value)
          _form_template[getProceed].section[_key].questions[_s_key].picture =
            _value[0];
          // console.log("YSYSYASASAISISII", _value)
          _form_template[getProceed].section[_key].questions[_s_key].fileType =
            getFileTypeImgDoc(_value[0]);
          _form_template[getProceed].section[_key].questions[_s_key].answer =
            _value[1];
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].confidence = _value[2];
          _form_template[getProceed].section[_key].questions[_s_key].threshold =
            _value[3];
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].other_confidence = _value[4];
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].ai_approved = _value[5];
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].image_metadata = getImgMetaData;
        } else {
          _form_template[getProceed].section[_key].questions[_s_key].picture =
            _value;
          _form_template[getProceed].section[_key].questions[_s_key].fileType =
            getFileTypeImgDoc(_value);
        }
        // "overridden": "",
        // _form_template[getProceed].section[_key].questions[1]['picture'] = _value[1];
        // _form_template[getProceed].section[_key].questions[2]['picture'] = _value[2];
        // _form_template[getProceed].section[_key].questions[3]['picture'] = _value[3];
        // _form_template[getProceed].section[_key].questions[4]['picture'] = _value[4];
        // _form_template[getProceed].section[_key].questions[5]['picture'] = _value[5];
        // _form_template[getProceed].section[_key].questions[6]['picture'] = _value[6];
        break;
      case 14:
        _form_template[getProceed].section[_key].questions[2]["picture"] =
          _value[0];
        _form_template[getProceed].section[_key].questions[2]["signature"] =
          _value[1];

        break;

      case 15:
        //for setting address call 15 -> 4
        // modalMapHandler(_value, getAddress, changeFormTemplateData, _key);
        setAddress(
          removeTags(form_template[getProceed].questions[_key].answer)
        );
        // console.log("Vicky ::15::", _value, getAddress, changeFormTemplateData, _key, form_template[getProceed].questions[_key].lat, form_template[getProceed].questions[_key].long)
        modalMapHandler(
          _value,
          getAddress,
          changeFormTemplateData,
          _key,
          form_template[getProceed].questions[_key].lat,
          form_template[getProceed].questions[_key].long
        );

        break;
      case 16:
        //for setting address call 16 -> 4
        //map direction
        setAddress(
          removeTags(form_template[getProceed].questions[_key].answer)
        );
        modalMapDirectionHandler(
          _value,
          getAddress,
          changeFormTemplateData,
          _key
        );
        break;
      case 17:
        //add subquestion picture
        _form_template[getProceed].questions[_key].subQuestion[_s_key][
          "picture"
        ] = _value[0];
        // console.log("getFileTypeImgDoc(_value[0])", getFileTypeImgDoc(_value[0]));
        _form_template[getProceed].questions[_key].subQuestion[_s_key][
          "fileType"
        ] = getFileTypeImgDoc(_value[0]);
        // if (_value[0].match(/\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)\b/)) {
        //     //Image
        //     _form_template[getProceed].questions[_key].subQuestion[_s_key]['fileType'] = 'Image';
        // } else if (_value[0].match(/\.(PDF|pdf)\b/)) {
        //     //Document
        //     _form_template[getProceed].questions[_key].subQuestion[_s_key]['fileType'] = 'Document';
        // } else {
        //     //null
        //     _form_template[getProceed].questions[_key].subQuestion[_s_key]['fileType'] = '';
        // }
        break;
      case 18:
        // [18,stateoptionlist(_value),key,s_key]
        _form_template[getProceed].questions[_key].table_options[
          _s_key
        ].selected_options = _value;
        break;
      case 19:
        // changeFormTemplateData([19, getUploadedImgUrls1[0], getImgAiDetectKeys.section, getImgAiDetectKeys.questions, 'picture']);
        // changeFormTemplateData([19, '', 0, 0, 'picture']);
        // [_type, _value, _key, _s_key, _key_name, _attri
        // _form_template[getProceed].section[_key].questions[2]['picture'] = _value[0];
        _form_template[getProceed].section[_key].questions[_s_key][_key_name] =
          _value;
        if (_key_name === "picture")
          _form_template[getProceed].section[_key].questions[_s_key][
            "fileType"
          ] = getFileTypeImgDoc(_value);
        // console.log(_type, _value, _key, _s_key, _key_name, _attri);
        break;
      case 20:
        _form_template[getProceed].questions[_key].subQuestion[_s_key][
          _key_name
        ] = _value;
        _form_template[getProceed].questions[_key].subQuestion[_s_key][
          "fileType"
        ] = getFileTypeImgDoc(_value);
        break;
      case 21:
        // _form_template[getProceed].questions[0]['picture'] = _value[0];
        _form_template[getProceed].questions[_key][_key_name] = _value;
        _form_template[getProceed].questions[_key]["fileType"] =
          getFileTypeImgDoc(_value);
        break;
      case 22:
        //for setting address call 15 -> 4
        setAddress(
          removeTags(form_template[getProceed].questions[_key].answer)
        );
        modalMapHandler1(
          _value,
          getAddress,
          changeFormTemplateData,
          _key,
          form_template[getProceed].questions[_key].lat,
          form_template[getProceed].questions[_key].long
        );
        break;
      case 23:
        if (typeof _value === "object") {
          _form_template[getProceed].questions[_key].answer = _value[0];
          //set distance and duration
          _form_template[getProceed].questions[_key].lat = _value[1];
          _form_template[getProceed].questions[_key].long = _value[2];
        }
        break;
      case 24:
        // console.log(_value, '24')
        _form_template[getProceed].section[
          _key
        ].questions[0].checkbox_model[0].value = _value;
        break;
      case 25:
        //set ai and images related data
        //Hazard Controls -ai
        if (typeof _value === "object") {
          _form_template[getProceed].questions[_key].picture = _value[0];
          _form_template[getProceed].questions[_key].fileType =
            getFileTypeImgDoc(_value[0]);
          _form_template[getProceed].questions[_key].answer = _value[1];
          _form_template[getProceed].questions[_key].confidence = _value[2];
          _form_template[getProceed].questions[_key].threshold = _value[3];
          _form_template[getProceed].questions[_key].other_confidence =
            _value[4];
          _form_template[getProceed].questions[_key].ai_approved = _value[5];
        } else {
          _form_template[getProceed].questions[_key].picture = _value;
          _form_template[getProceed].questions[_key].fileType =
            getFileTypeImgDoc(_value);
        }
        break;
      case 26:
        // section
        //methodType AI hazard control
        if (
          _form_template[getProceed].questions[_key].methodType === "AI" &&
          _form_template[getProceed].questions[_key].confidence !== "" &&
          _form_template[getProceed].questions[_key].answer === "No"
        ) {
          // console.log('run', _value)
          _form_template[getProceed].questions[_key].overridden = _value;
        } else {
          _form_template[getProceed].questions[_key].answer = _value;
        }
        break;
      case 27:
        // console.log(_value, '27')
        if (_value !== undefined) {
          // const __value = _value;
          // setUploadedImgUrls1([]);
          // setImgAiDetectKeys({});
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].multiple_picture.push(_value);
          // console.log(_form_template[getProceed].section[_key].questions[_s_key].multiple_picture, '27')
        }
        // _form_template[getProceed].questions[_key][_key_name] = _value;
        // _form_template[getProceed].questions[_key]['fileType'] = getFileTypeImgDoc(_value);
        break;
      case 28:
        //add only first part of the string for eg 'data1-data2'
        first_part = _value;
        if (_value === "Other") {
          setCustomername1(true);
          second_part = "";
          setError("customername2", { message: "This field is required" });
          _form_template[getProceed].questions[_key].answer = first_part.concat(
            " - ",
            second_part
          );
        } else {
          setCustomername1(false);
          setError("customername2", { message: "" });
          second_part = getSecondPart(
            _form_template[getProceed].questions[_key].answer
          );
          _form_template[getProceed].questions[_key].answer = first_part;
        }
        break;

      case 29:
        //add only second part of the string for eg 'data1-data2'
        first_part = getFirstPart(
          _form_template[getProceed].questions[_key].answer
        );
        second_part = _value;
        _form_template[getProceed].questions[_key].answer = first_part.concat(
          "-",
          second_part
        );
        break;
      case 30:
        //add only first part of the string for eg 'data1-data2'
        first_part = _value;
        if (_value === "Other") {
          setError("siteLanLord2", { message: "This field is required" });
          setSiteLandLord(true);
          second_part = "";
          _form_template[getProceed].questions[_key].answer = first_part.concat(
            "-",
            second_part
          );
        } else {
          setSiteLandLord(false);
          setError("siteLanLord2", { message: "" });
          second_part = getSecondPart(
            _form_template[getProceed].questions[_key].answer
          );
          _form_template[getProceed].questions[_key].answer = first_part.concat(
            "-",
            second_part
          );
        }
        break;

      case 31:
        //add only second part of the string for eg 'data1-data2'
        first_part = getFirstPart(
          _form_template[getProceed].questions[_key].answer
        );
        second_part = _value;
        _form_template[getProceed].questions[_key].answer = first_part.concat(
          "-",
          second_part
        );
        break;

      case 32:
        // remove Images
        if (
          _form_template[getProceed].section[_key].questions[_s_key].picture !==
          ""
        ) {
          _form_template[getProceed].section[_key].questions[_s_key].picture =
            "";
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].confidence = "";
          _form_template[getProceed].section[_key].questions[_s_key].answer =
            "No";
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].image_metadata = "";
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].ai_approved = "";
          if (
            !_.isEmpty(
              _form_template[getProceed].section[_key].questions[_s_key]
                .other_confidence
            )
          ) {
            _form_template[getProceed].section[_key].questions[
              _s_key
            ].other_confidence[_key_name] = "";
            _form_template[getProceed].section[_key].questions[
              _s_key
            ].other_confidence.status = "";
          }
        }
        break;

      case 33:
        // remove Images
        if (
          _form_template[getProceed].section[_key].questions[_s_key]
            .multiple_picture !== ""
        ) {
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].multiple_picture = _form_template[getProceed].section[
            _key
          ].questions[_s_key].multiple_picture.filter(
            (k, i) => i !== _key_name && k
          );
          setError(_value, { message: "" });
        }
        break;

      case 34:
        // remove Images
        if (_form_template[getProceed].questions[_key].picture !== "") {
          _form_template[getProceed].questions[_key].picture = "";
          _form_template[getProceed].questions[_key].confidence = "";
          _form_template[getProceed].questions[_key].ai_approved = "";
          _form_template[getProceed].questions[_key].image_metadata = "";
          _form_template[getProceed].questions[_key].answer = "No";
          setError(_value, { message: "" });
        }
        break;

      case 35:
        _form_template[getProceed].questions[_key].answer = _value;
        setValidation(false);

        if (_value === "Other") {
          setError("typeofwork2", { message: "This field is required" });
          settypeofWork(true);
        } else {
          setError("typeofwork2", { message: "" });
          settypeofWork(false);
        }
        if (_value === "Ground") {
          _form_template[3].questions[3].visibility = false;
          _form_template[1].questions[1].visibility = false;
          _form_template[1].questions[2].visibility = true;
          _form_template[1].questions[3].visibility = false;
          _form_template[1].questions[4].visibility = false;
          _form_template[1].questions[5].visibility = false;
          _form_template[1].questions[6].visibility = false;
          _form_template[1].questions[7].visibility = true;
          _form_template[10].section.filter((obj, i) => {
            _form_template[10].section[i].questions[5].visibility = false;
          });
          setClimber(false);
        } else if (_value === "In-Building / Data Center") {
          _form_template[3].questions[3].visibility = true;
          _form_template[1].questions[1].visibility = false;
          _form_template[1].questions[2].visibility = false;
          _form_template[1].questions[3].visibility = false;
          _form_template[1].questions[4].visibility = false;
          _form_template[1].questions[5].visibility = false;
          _form_template[1].questions[6].visibility = false;
          _form_template[1].questions[7].visibility = false;
          _form_template[10].section.filter((obj, i) => {
            _form_template[10].section[i].questions[5].visibility = true;
          });
          setClimber(false);
        } else {
          _form_template[3].questions[3].visibility = true;
          _form_template[1].questions[1].visibility = true;
          _form_template[1].questions[2].visibility = true;
          _form_template[1].questions[3].visibility = true;
          _form_template[1].questions[4].visibility = true;
          _form_template[1].questions[5].visibility = true;
          _form_template[1].questions[6].visibility = true;
          _form_template[1].questions[7].visibility = true;
          _form_template[10].section.filter((obj, i) => {
            _form_template[10].section[i].questions[5].visibility = true;
          });
          let _Climber_count = getClimberList.filter(
            (x) => x === "Climber"
          ).length;
          if (
            _Climber_count <= 1 &&
            _form_template[3].questions[2].answer === "Yes"
          ) {
            setClimber(true);
          }
        }
        break;

      default:
    }
    // console.log("hiiiiii-1")
    setForm_template(_form_template);
    // console.log("OSOAO", form_template)
    // !_.isEmpty(getUploadedImgUrls1) && setUploadedImgUrls1([])
  };
  useEffect(() => {
    // console.log(getHCMOptions)
    // console.log('getSelectedOptionsMulti1::', getSelectedOptionsMulti1, getHCMOptions);
    let _form_template = _.cloneDeep(form_template);
    let _getHCMOptions = getHCMOptions.map((obj) => obj.value);
    for (var i = 0; i < getHCMOptions.length; i++) {
      let _value = getSelectedOptionsMulti1[getHCMOptions[i]["_s_key"]].length
        ? getSelectedOptionsMulti1[getHCMOptions[i]["_s_key"]].map(
            (obj) => obj.value
          )
        : [];
      _form_template[8].questions[0].table_options[
        getHCMOptions[i]["_s_key"]
      ].selected_options = _value;
      _form_template[8].questions[0].table_options[
        getHCMOptions[i]["_s_key"]
      ].pre_selected_options = _value;
      _form_template[8].questions[0].table_options[
        getHCMOptions[i]["_s_key"]
      ].comment_text = getCommentMulti1[getHCMOptions[i]["_s_key"]];
    }
    if (getHCMOptions.length !== 0) {
      for (
        let j = 0;
        j < _form_template[8].questions[0].table_options.length;
        j++
      ) {
        if (
          _getHCMOptions.includes(
            _form_template[8].questions[0].table_options[j].question
          )
        ) {
          _form_template[8].questions[0].table_options[j].common = true;
        } else {
          _form_template[8].questions[0].table_options[j].common = false;
        }
      }
    } else {
      for (
        let j = 0;
        j < _form_template[8].questions[0].table_options.length;
        j++
      ) {
        _form_template[8].questions[0].table_options[j].common = false;
      }
    }
    // console.log("hiiiiii-2", form_template[5].questions[7].subQuestion[0].answer)
    setForm_template(_form_template);
    // console.log("OSOAO", form_template)
    // console.log(_form_template[8].questions[0].table_options);
    // _form_template[getProceed].questions[_key].table_options[_s_key]
    // changeFormTemplateData([18, []])
  }, [getHCMOptions, getSelectedOptionsMulti1, getCommentMulti1]);

  const addHazards = (k) => {
    let _form_template = _.cloneDeep(form_template);
    let _getSelectedOptionsMulti1 = _.cloneDeep(getSelectedOptionsMulti1);
    let _getHCMOptions = _.cloneDeep(getHCMOptions);
    //let _getCommentMulti1 = _.cloneDeep(getCommentMulti1);
    let newOther;
    let _optionsArr;
    // console.log('getReportIJHA', getIjhaform);
    for (
      var i = 0;
      i < _form_template[8].questions[0].table_options.length;
      i++
    ) {
      if (getIjhaform.form_template[8].questions[0].table_options.length > i) {
        if (
          getIjhaform.form_template[8].questions[0].table_options[i]
            .question === "Other"
        ) {
          newOther = _.cloneDeep(
            getIjhaform.form_template[8].questions[0].table_options[i]
          );
          newOther.question = "Other -";
          _optionsArr = newOther.options.map((val) => {
            return { value: val, label: val, color: "#00B8D9" };
          });
        }
      }
    }
    _form_template[8].questions[0].table_options.push(newOther);
    //  _form_template[8].questions[0].table_options.map((obj, i) => {
    _getSelectedOptionsMulti1.push([]);
    // })

    setForm_template(_form_template);
    _getHCMOptions.push({
      value: newOther.question,
      label: newOther.question,
      color: "#00B8D9",
      isFixed: false,
      other: true,
      _options: _optionsArr,
      _s_key: _form_template[8].questions[0].table_options.length - 1,
    });
    newOther.selected_options.map((val, key) => {
      _getSelectedOptionsMulti1[key].push({
        value: val,
        label: val,
        color: "#00B8D9",
      });
    });
    setHCMOptions(_getHCMOptions);
    setSelectedOptionsMulti1(_getSelectedOptionsMulti1);
  };

  // Personal Fall Arrest System
  var PFASOptions = form_template[1].questions[0].options.map((obj, i) => {
    return {
      value: obj.value,
      label: obj.value,
      color: "#00B8D9",
      isFixed: obj.is_selected,
      _is_selected: obj.is_selected,
      file_obj: "",
      file_url: "",
      _key: 0,
      _s_key: i,
    };
  });

  // console.log(form_template);
  function getSecondPart(str) {
    // console.log(str)
    return str.split("-")[1];
  }
  function getFirstPart(str) {
    // if (getProceed === 1) {

    // }
    return str.split("-")[0];
  }
  function getAddMoreDailyTaskFunc() {
    let _getAddMoreDailyTask = _.cloneDeep(getAddMoreDailyTask);
    let _form_template = _.cloneDeep(form_template);
    _form_template[3].questions[1].multipleQuestion.push({
      questions: [
        {
          question: "Task",
          questionType: "Description",
          description: "",
        },
        {
          question: "Hazards",
          questionType: "Description",
          description: "",
        },
        {
          question: "Mitigations",
          questionType: "Description",
          description: "",
        },
      ],
    });
    // console.log("hiiiiii-4")
    setForm_template(_form_template);
    // console.log("OSOAO", form_template)
  }

  const HCMOptions = form_template[8].questions[0].table_options.map(
    (obj, i) => {
      return {
        value: obj.question,
        label: obj.question,
        color: "#00B8D9",
        isFixed: obj.selected_options.length ? true : false,
        _options: obj.options.map((s_obj) => {
          return {
            value: s_obj,
            label: s_obj,
            color: "#00B8D9",
          };
        }),
        _key: 0,
        _s_key: i,
      };
    }
  );

  const customeOtherLabelEdit = (k) => {
    let _edit = document.getElementById("edit" + k);
    let _check = document.getElementById("check" + k);
    let _close = document.getElementById("close" + k);
    let ele = document.getElementById("customeOtheredit" + k);
    if (ele.classList.contains("activeLabel")) {
      ele.disabled = true;
      ele.classList.remove("activeLabel");
      _edit.classList.remove("d-none");
      _check.classList.add("d-none");
      _close.classList.add("d-none");

      let _form_template = _.cloneDeep(form_template);
      _form_template[8].questions[0].table_options.map((obj, k) => {
        if (obj.question === ele.name) {
          _form_template[8].questions[0].table_options[k].question =
            "Other - " + ele.value.trimStart();
          // _form_template[8].questions[0].table_options[k].common = true;
        }
      });
      setForm_template(_form_template);
      let _getHCMOptions = _.cloneDeep(getHCMOptions);
      _getHCMOptions.map((i, k) => {
        if (i.value === ele.name) {
          i.value = "Other - " + ele.value.trimStart();
          i.label = "Other - " + ele.value.trimStart();
        }
      });
      setHCMOptions(_getHCMOptions);
      ele.name = "Other -" + ele.value;
    } else {
      ele.disabled = false;
      ele.classList.add("activeLabel");
      _edit.classList.add("d-none");
      _check.classList.remove("d-none");
      _close.classList.remove("d-none");
    }
  };

  const editLabel = (e) => {
    if (e.target.value.length >= 1) {
      let _form_template = _.cloneDeep(form_template);
      _form_template[8].questions[0].table_options.map((obj, k) => {
        if (k === parseInt(e.target.attributes.s_key.value)) {
          _form_template[8].questions[0].table_options[k].question =
            "Other - " + e.target.value.trimStart();
          // _form_template[8].questions[0].table_options[k].common = true;
        }
      });
      setForm_template(_form_template);

      let _getHCMOptions = _.cloneDeep(getHCMOptions);
      _getHCMOptions.map((i, k) => {
        if (i._s_key === parseInt(e.target.attributes.s_key.value)) {
          i.value = "Other - " + e.target.value.trimStart();
          i.label = "Other - " + e.target.value.trimStart();
        }
      });
      setHCMOptions(_getHCMOptions);
    }
  };

  const HMCAllMultiSelect = getHCMOptions.map((obj, k) => {
    return (
      <div class="Hazards">
        <Form.Group
          controlId="form-7-1"
          key={k}
          className={
            obj._s_key === 9 || "other" in obj
              ? "mb-3 shadow-lg p-3 mb-3 rounded otherHazards"
              : "mb-3 shadow-lg p-3 mb-3 rounded"
          }
        >
          <label
            className={
              obj._s_key === 9 || "other" in obj
                ? "customeOther form-label"
                : "form-label"
            }
          >
            <span
              className={obj._s_key === 9 || "other" in obj ? "edit" : "d-none"}
              id={"edit" + k}
              onClick={() => customeOtherLabelEdit(k)}
            >
              &#9998;
            </span>
            {obj._s_key === 9 || "other" in obj ? (
              <>
                {Object.keys(obj.value).length !== 0 && (
                  <>
                    <span>{obj.value.split("-")[0]} &nbsp;-&nbsp;</span>
                    <input
                      type="text"
                      className="HazardLabel"
                      id={"customeOtheredit" + k}
                      s_key={obj._s_key}
                      name={obj.value}
                      onChange={(e) => editLabel(e)}
                      value={obj.value.split("-")[1]}
                      disabled
                    />
                    <span
                      className="check d-none"
                      id={"check" + k}
                      onClick={() => customeOtherLabelEdit(k)}
                    >
                      &#10004;
                    </span>
                    <span
                      className="close d-none"
                      id={"close" + k}
                      onClick={() => customeOtherLabelEdit(k)}
                    >
                      x
                    </span>
                  </>
                )}
              </>
            ) : (
              obj.value
            )}
          </label>
          <MultiSelect1
            options={obj._options}
            getOption={getSelectedOptionsMulti1}
            setOption={setSelectedOptionsMulti1}
            _s_key={obj._s_key}
            getCommentMulti1={getCommentMulti1}
            setCommentMulti1={setCommentMulti1}
          />
        </Form.Group>
      </div>
    );
  });

  const InprocessFormSubmit = () => {
    let crew_members_id = getCrewLeadData.crewmemberdata
      ? getCrewLeadData.crewmemberdata.map((obj) => obj.employeeId)
      : "";

    let _getProceedCount = getformTemplateCount.includes(getProceed)
      ? getformTemplateCount[getformTemplateCount.length - 1]
      : getProceed;
    // uncommented below to active climber and nonclimber
    let membertype = getCrewLeadData.crewmemberdata
      ? getCrewLeadData.crewmemberdata.map((obj) => obj.membertype)
      : "";
    let _getIjhaform = _.cloneDeep(getIjhaform);
    _getIjhaform["form_template"] = formTempChange();
    _getIjhaform["reportId"] = getReportID;
    _getIjhaform["teamLeadId"] = EMP_ID.replaceAll('"', "");
    _getIjhaform["crew_members"] = [
      EMP_ID.replaceAll('"', ""),
      ...crew_members_id,
    ];
    // uncommented below to active climber and nonclimber
    _getIjhaform["membertype"] = [getCrewLeadData.membertype, ...membertype];
    _getIjhaform["latitude"] = getLatitude;
    _getIjhaform["longitude"] = getLongitude;
    _getIjhaform["device_details"] = {
      app_version: null,
      device_token: null,
      build_number: null,
      os_version: null,
    };
    //test
    _getIjhaform["weather"] = weatherDetailsChange();
    _getIjhaform["state"] = "";
    _getIjhaform["state_id"] = "";
    _getIjhaform["complete_status"] = "0";
    _getIjhaform["usergroupId"] = null;
    _getIjhaform["inProgressSection"] = getClimber ? 1 : _getProceedCount;
    _getIjhaform["formId"] = "10";
    _getIjhaform["app_type"] = "Ericsson";
    _getIjhaform["submitted_datetime"] = getCurrentDataTime();
    _getIjhaform["resubmit"] = !_.isEmpty(getIjhaform.reportId) ? "1" : "0";
    _getIjhaform["form_name"] = "Ericsson iJHA";
    // setIjhaform(_getIjhaform);
    ijhaInCompleteFormSubmit(_getIjhaform, setLoading);
  };

  const onProceedHandler = () => {
    InprocessFormSubmit();
    setSetP(false);
    if (getProceed <= 10) {
      if (getCrewMemberProceed.crew === 0) {
        // For crew lead all 12 sections
        let count = true;
        let check = getProceed + 1;
        form_template.map((i, k) => {
          if (count) {
            if (check === k && i.visibility && k !== 0) {
              count = false;
              setProceed(k);
              let _getformTemplateCount = getformTemplateCount;
              if (_getformTemplateCount.includes(k)) {
                _getformTemplateCount.push(k - 1);
              } else {
                _getformTemplateCount.push(k);
              }
              _getformTemplateCount = [...new Set(_getformTemplateCount)];
              setformTemplateCount(_getformTemplateCount);
            } else {
              if (check === k) {
                check++;
              }
            }
          }
        });
      } else {
        // For crew Member 0, 11, 12 sections
        // console.log(getProceed, 'asdadad---setpbtn', getpbtn, form_template[11].section[getCrewMemberProceed.crew].questions[2].signature, getSignBase64Uri1)
        if (
          getProceed === 10 &&
          form_template[11].section[getCrewMemberProceed.crew].questions[2]
            .signature === ""
        ) {
          setSignBase64Uri1("");
        }
        // console.log(getProceed, 'getproceed')
        if (getProceed >= 9) {
          setProceed(getProceed + 1);
        } else if (getProceed === 0) {
          setProceed(10);
        }
      }
    } else {
      // For Last Screen Proceed Button
      let _getformTemplateCount = getformTemplateCount;
      let _filledCheck = form_template.map((i, k) => i.visibility && k);
      let _filledCheckArr = _filledCheck.filter((j) => j !== false);
      setSummary(true);
      if (
        _getformTemplateCount.length >= _filledCheckArr.length ||
        getReportCrewCheck
      ) {
        let _getIsCompleted = _.cloneDeep(getIsCompleted);
        _getIsCompleted[getCrewMemberProceed.crew] = true;
        setIsCompleted(_getIsCompleted);
        setProceed1(15);
        setValidation(true);
      } else {
        let diff = _filledCheckArr.filter(
          (x) => !_getformTemplateCount.includes(x)
        );
        setProceed(diff[0]);
      }
    }
  };

  const onBackHandler = () => {
    if (getCrewMemberProceed.crew === 0) {
      // For crew lead all 12 sections
      let _getbackCountArr = form_template.map((i, k) => i.visibility && k);
      let _backCount = _getbackCountArr.filter((z) => z !== false);
      let _setbackCountArr = _backCount.indexOf(getProceed) - 1;
      setProceed(_backCount[_setbackCountArr]);
    } else {
      // For crew Member 0, 11, 12 sections
      setSetP(true);
      if (getProceed === 10) {
        setProceed(0);
      } else {
        setProceed(getProceed - 1);
        // form_template[getProceed - 1].visibility ? setProceed(getProceed - 1) : setProceed(getProceed - 2);
      }
    }
  };

  // const onSubmitHandle = () => {
  // }

  // const onCrewProceedHandler = () => {
  //     // !_.isEmpty(getIjhaform.reportId) && setpbtn(false);

  // }
  // const proceedOrSave = () => {
  //     switch (getProceed) {
  //         case 0:
  //             return (form_template[0].section[0].questions[0].picture !== '' &&
  //                 form_template[0].section[0].questions[0].signature !== '');
  //         case 2:
  //             return (
  //                 form_template[3].questions[3].subQuestion[0].picture !== ''
  //             )
  //         case 7:
  //             return (form_template[1].questions[0].picture !== '' &&
  //                 form_template[1].questions[2].picture !== '' &&
  //                 form_template[1].questions[3].picture !== '');
  //         case 9:
  //             return (form_template[9].questions[0].picture !== '' &&
  //                 form_template[9].questions[1].picture !== '' &&
  //                 form_template[9].questions[2].picture !== '' &&
  //                 form_template[9].questions[3].picture);
  //         case 10:
  //             return (form_template[10].section[0].questions[0].picture !== '' && typeof form_template[10].section[0].questions[0].picture !== 'undefined' &&
  //                 form_template[10].section[0].questions[1].picture !== '' && typeof form_template[10].section[0].questions[1].picture !== 'undefined' &&
  //                 form_template[10].section[0].questions[6].picture !== '');
  //         case 12:
  //             return (form_template[11].section[0].questions[2].picture &&
  //                 form_template[11].section[0].questions[2].signature !== '')
  //         default:
  //     }
  // }

  const addMorePPEOther = () => {
    let _form_template = _.cloneDeep(form_template);
    _form_template[10].section[getCrewMemberProceed.crew].questions.push({
      answer: "",
      cameraType: "Front",
      category: "",
      confidence: "",
      message: "Please show the PPE",
      methodType: "Manual",
      multiple_picture: [],
      options: [],
      other_question: true,
      overridden: "",
      picName: "ppe_other",
      picture: "",
      pictureRequired: "Yes",
      question: "PPE - Other",
      questionId: "54",
      questionType: "Image+Text",
      state: "Optional",
      threshold: "",
      validation: {
        errorMessage: "Please upload the picture",
      },
      visibility: true,
    });

    // setError('PPEOther' + _form_template[10].section[0].questions.length - 1, { message: '' })
    setForm_template(_form_template);
    // console.log("OSOAO", form_template)
  };
  const weatherIcon = (iconPhrase) => {
    if (iconPhrase !== null && iconPhrase === "clear") return ic_wind;
    else if (iconPhrase !== null && iconPhrase === "sunny") return ic_sunny;
    else if (
      (iconPhrase !== null && iconPhrase === "Mostly cloudy") ||
      iconPhrase === "Partly cloudy" ||
      iconPhrase === "Mostly sunny" ||
      iconPhrase === "Mostly clear"
    )
      return ic_partly_sunny;
    else if (iconPhrase !== null && iconPhrase === "rain") return ic_rain;
    else return ic_partly_sunny;
  };

  if (form_template[5].questions[3].distance === "-1") {
    form_template[5].questions[3].distance = "Not Available";
  }
  if (form_template[5].questions[3].duration === "-1") {
    form_template[5].questions[3].duration = "Not Available";
  }
  if (form_template[5].questions[4].distance === "-1") {
    form_template[5].questions[4].distance = "Not Available";
  }
  if (form_template[5].questions[4].duration === "-1") {
    form_template[5].questions[4].duration = "Not Available";
  }

  useEffect(() => {
    if (getReportCrewCheck) {
      let x = getCrewLeadData.crewmemberdata;
      let y = getCrewMemData;
      let z = [...y, ...x];
      let _getCrewLeadData = _.cloneDeep(getCrewLeadData);
      _getCrewLeadData.membertype = getClimberList[0];
      _getCrewLeadData.crewmemberdata = z;
      setCrewLeadData(_getCrewLeadData);
      const crewFilter = _getCrewLeadData.crewmemberdata.filter(
        (i) => i.emailId !== "" || i.firstName !== ""
      );
      const crewFilterLen = crewFilter.length + 1;
      if (form_template[0].section.length <= crewFilterLen) {
        let _final_CrewForm_array = [];
        const preEmp = y.map((i, keys) => {
          return _final_CrewForm_array.push(i.employeeId);
        });
        let _final_form_template_array = [];
        let _final_form_template = _.cloneDeep(form_template);
        _final_form_template = _final_form_template.filter((obj, k) => {
          if ("section" in obj) {
            let zEmpty = [];
            let preEmp = obj.section.filter((objt, i) => {
              if (i === 0) {
                return zEmpty.push(objt);
              } else {
                _final_CrewForm_array.filter((ele, k) => {
                  if (objt.employeeId === ele) {
                    return zEmpty.push(objt);
                  }
                });
              }
            });
            return _final_form_template_array.push({
              section: zEmpty,
              sectionId: obj.sectionId,
              sectionName: obj.sectionName,
              visibility: obj.visibility,
            });
          }
          return _final_form_template_array.push(obj);
        });

        const count = x.length;
        let _form_template = _.cloneDeep(_final_form_template_array);
        _form_template = _form_template.map((obj, k) => {
          if (!_.isEmpty(obj.section)) {
            for (let i = 0; i < count; i++) {
              const sectionLenght = obj.section.length;
              obj.section[sectionLenght] = _.cloneDeep(
                getAllFormsData.form_template[k].section[0]
              );
            }
          }
          return obj;
        });
        setForm_template(_form_template);
        // console.log("OSOAO", form_template)
        let _getIjhaform = _.cloneDeep(getIjhaform);
        _getIjhaform.form_template = _form_template;
        setIjhaform(_getIjhaform);
      } else {
        let _final_CrewForm_array = [];
        const x = getCrewMemData.map((i, keys) => {
          return _final_CrewForm_array.push(i.employeeId);
        });
        let _final_form_template_array = [];
        let _final_form_template = _.cloneDeep(form_template);
        _final_form_template = _final_form_template.filter((obj, k) => {
          if ("section" in obj) {
            let z = [];
            let x = obj.section.filter((objt, i) => {
              if (i === 0) {
                return z.push(objt);
              } else {
                _final_CrewForm_array.filter((ele, k) => {
                  if (objt.employeeId === ele) {
                    return z.push(objt);
                  }
                });
              }
            });
            return _final_form_template_array.push({
              section: z,
              sectionId: obj.sectionId,
              sectionName: obj.sectionName,
              visibility: obj.visibility,
            });
          }
          return _final_form_template_array.push(obj);
        });
        setForm_template(_final_form_template_array);
        // console.log("OSOAO", form_template)
        let _getIjhaform = _.cloneDeep(getIjhaform);
        _getIjhaform.form_template = _final_form_template_array;
        setIjhaform(_getIjhaform);
      }
      start_datetime_filling();
      setReportIJHA(false);
    }
  }, [getReportCrewCheck]);

  const getIJHForm = async (_getCrewFormData, _checkClimber) => {
    setClimberList(_checkClimber);
    let _final_CrewForm_array = [];
    const x = _getCrewFormData.map((i, keys) => {
      return _final_CrewForm_array.push(i.employeeId);
    });
    if (_final_CrewForm_array[0] === undefined) {
      let _final_form_template_array = [];
      let _final_form_template = _.cloneDeep(form_template);
      _final_form_template = _final_form_template.filter((obj, k) => {
        if ("section" in obj) {
          let z = [];
          let x = obj.section.filter((objt, i) => {
            if (i === 0) {
              return z.push(objt);
            }
          });
          return _final_form_template_array.push({
            section: z,
            sectionId: obj.sectionId,
            sectionName: obj.sectionName,
            visibility: obj.visibility,
          });
        }
        return _final_form_template_array.push(obj);
      });
      setForm_template(_final_form_template_array);
      // console.log("OSOAO", form_template)
    }

    setAddEditCrew(_getCrewFormData);
    let counter = false;
    let _getBlankData = _.cloneDeep(_getCrewFormData);
    _getBlankData = _getBlankData.filter((_blank) => {
      if (!("employeeId" in _blank)) {
        return (counter = true);
      }
    });

    if (counter) {
      await getAddCrewMemberApi(setCrewLeadData, setLoading, setErrMsg);
      const _ijhaform = await getFormIjhaApi(10, getLoc, setLoading, setErrMsg);
      setAllFormsData(_ijhaform.data);
    } else {
      let _getCrewLeadData = _.cloneDeep(getCrewLeadData);
      _getCrewLeadData.crewmemberdata = [];
      // console.log('Roshan', _getCrewLeadData);
      setCrewLeadData(_getCrewLeadData);
    }

    let _getOldCrewData = _.cloneDeep(_getCrewFormData);
    _getOldCrewData = _getOldCrewData.filter((_blank) => {
      if ("employeeId" in _blank) {
        return _blank;
      }
    });

    setReportCrewCheck(true);
    setCrewMemData(_getOldCrewData);
  };

  // consol.log("return::data:::", getProceed, '::::', form_template[getProceed]);
  // consol.log("return::data:::", getReportIJHA);
  // consol.log("return::data::onSubmitIJHAForm:", getIjhaform);
  // consol.log('URL::', form_template[0].section[0].questions[0].picture)
  // consol.log("vicky: getoptions Personal Fall Arrest System", form_template[10].section[0].questions[0].multiselect_options, form_template[10].section[0].questions[0].multiple_picture)

  let ProjectNumber = form_template[2].questions[0].question.split("(")[0];
  let ProjectNumber1 = form_template[2].questions[0].question
    .split("(")[1]
    .replace(/[{()}]/g, "");

  return (
    <Container className="margin-container">
      <>
        {getReportIJHA && getIjhaform.inProgressSection === undefined ? (
          <>
            {!getLoading ? (
              <AddCrewMember
                getIJHForm={getIJHForm}
                getCrewData={getCrewData}
              />
            ) : (
              <div className="p-5">
                <Col
                  xs={12}
                  className="m-5  p-5 d-flex justify-content-center "
                  style={{ cursor: "pointer" }}
                >
                  <Loading width="32" height="32" />
                </Col>
              </div>
            )}
          </>
        ) : (
          <>
            <h4>iJHA Form</h4>
            {showMdl ? (
              <ModalMapCustom
                showMdl={showMdl}
                setShowMdl={setShowMdl}
                dataMdl={getDataMdl}
                getAddress={getAddress}
                setAddress={setAddress}
                setDirections={setDirections}
                directions={directions}
                form_template={form_template}
                setForm_template={setForm_template}
              />
            ) : (
              <ModalMapTramaCenter
                showMdl={showTraumaMdl}
                setShowMdl={setShowTraumaMdl}
                dataMdl={getDataMdl}
                getAddress={getAddress}
                setAddress={setAddress}
                form_template={form_template}
                setForm_template={setForm_template}
              />
            )}
            {/* {getShowOtherMdl &&
                                <ModalOther showMdl={getShowOtherMdl} setShowMdl={setShowOtherMdl} dataMdl={getOtherDataMdl} getProceed={getProceed} setForm_template={setForm_template} form_template={form_template} getPFASOptions={getPFASOptions} setPFASOptions={setPFASOptions} getCrewMemberProceed={getCrewMemberProceed} />} */}
            <Row>
              <Col>
                <FormProgressBarForIjha
                  getClimber={getClimber}
                  inProgressSection={
                    getIjhaform.inProgressSection !== "" &&
                    getIjhaform.inProgressSection !== undefined
                      ? true
                      : false
                  }
                  getCrewMemberProceed={getCrewMemberProceed.crew}
                  getIsCompleted={getIsCompleted[getCrewMemberProceed.crew]}
                  getCrewfill={getCrewfill}
                  form_template={form_template}
                  getReportCrewCheck={getReportCrewCheck}
                  setProceed={setProceed}
                  getProgressCount={getProceed1 === 0 ? getProceed + 1 : 15}
                />
              </Col>
            </Row>
            {getProceed1 === 0 && (
              <div className="mb-2 d-flex justify-content-end">
                <div
                  className="p-2 m-1 ps-3 pe-3 rounded-pill text-capitalize"
                  style={{ background: "rgb(33 37 41)", color: "#f1f1f1" }}
                >
                  {getCrewMemberProceed.crew !== 0
                    ? "Crew Member: " +
                      getCrewLeadData.crewmemberdata[
                        getCrewMemberProceed.crew - 1
                      ].firstName +
                      " " +
                      getCrewLeadData.crewmemberdata[
                        getCrewMemberProceed.crew - 1
                      ].lastName
                    : "Crew Lead: " +
                      getCrewLeadData.firstName +
                      " " +
                      getCrewLeadData.lastName}
                </div>
              </div>
            )}
            <Form className="shadow-lg p-3 mb-5 rounded " encType="multipart">
              <Row xs={12} className="mb-3">
                <Col>
                  {getIjhaSubmit === "" ? (
                    <div className="form-subheading-1">
                      {getProceed1 === 0 && form_template[getProceed].visibility
                        ? form_template[getProceed].sectionName
                        : "Summary"}
                    </div>
                  ) : null}
                </Col>
              </Row>
              {form_template[getProceed].sectionId === "1" &&
              getProceed1 === 0 ? (
                <>
                  {form_template[getProceed].section[0].questions[0]
                    .questionType === "Signature" ? (
                    <>
                      <Row xs={12}>
                        <Col>
                          <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>
                              <span className="text-danger bold-font">*</span>
                              {
                                form_template[0].section[
                                  getCrewMemberProceed.crew
                                ].questions[0].question
                              }
                              {form_template[0].section[
                                getCrewMemberProceed.crew
                              ].questions[0].tooltip_message !== "" &&
                                form_template[0].section[
                                  getCrewMemberProceed.crew
                                ].questions[0].tooltip_message !==
                                  undefined && (
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={
                                      <Tooltip id="tooltip-disabled">
                                        {
                                          form_template[0].section[
                                            getCrewMemberProceed.crew
                                          ].questions[0].tooltip_message
                                        }
                                      </Tooltip>
                                    }
                                  >
                                    <span className="icon-info">i</span>
                                  </OverlayTrigger>
                                )}
                            </Form.Label>
                            <Form.Control
                              className="rounded-0 custom-file-input"
                              type="file"
                              {...register("profileImg", {
                                required: true,
                              })}
                              onChange={(e) => {
                                // let fileSize = e.target.files[0]['size'];
                                let fileType = e.target.files[0]["type"];
                                let validImageTypes = [
                                  "image/gif",
                                  "image/jpeg",
                                  "image/png",
                                ];
                                if (!validImageTypes.includes(fileType)) {
                                  setError("profileImg", {
                                    message:
                                      "Only jpeg/png/gif image are allowed.",
                                  });
                                  getUploadImgUrls("", 1, "0");
                                } else {
                                  setError("profileImg", {
                                    message: "",
                                  });
                                  // getUploadImgUrls(e.target.files[0], 6, '0')
                                  getUploadImgUrls(
                                    [
                                      e.target.files[0],
                                      getCrewMemberProceed.crew,
                                      0,
                                      "pic",
                                    ],
                                    4,
                                    "0"
                                  );
                                }
                              }}
                            />
                            <Form.Label className="mt-2 text-danger">
                              {errors.profileImg && errors.profileImg.message}
                            </Form.Label>
                            <Form.Label className="mt-2">
                              <b>
                                {form_template[0].section[
                                  getCrewMemberProceed.crew
                                ].questions[0].picture !== "" &&
                                  "1 Image Uploaded"}
                              </b>
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row xs={4}>
                        <Col>
                          <Form.Group controlId="signHere" className="mb-3">
                            <Form.Label>
                              <span className="text-danger bold-font">*</span>
                              Sign Here
                            </Form.Label>
                          </Form.Group>
                          {/* <Form.Control className="rounded-0" type="file" onChange={(e) => getUploadImgUrls(e.target.files[0], 1)} /> */}
                          <Signature
                            setSignBase64Uri={setSignBase64Uri}
                            imgName="signature.png"
                            resetSign={() =>
                              changeFormTemplateData([
                                19,
                                "",
                                getCrewMemberProceed.crew,
                                0,
                                "signature",
                              ])
                            }
                          />
                          <Form.Label className="mt-2 text-danger">
                            {errors.signature && errors.signature.message}
                          </Form.Label>
                          <Form.Label className="mt-2 mb-3">
                            <b>
                              {form_template[0].section[
                                getCrewMemberProceed.crew
                              ].questions[0].signature !== "" &&
                                "Sign Uploaded"}
                            </b>
                          </Form.Label>
                          <Form.Label className="mt-2 text-danger">
                            {getSignBase64Uri === "" && "Signature is required"}
                          </Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-flex flex-row bd-highlight align-items-center mb-3">
                          <div className="rounded border p-2 bd-highlight">
                            <img
                              src={DECRYPTED_DATA.profile_img_url}
                              style={{ width: "60px" }}
                              alt="profilePic"
                            />
                          </div>
                          <div className="p-2 bd-highlight">{getUserName}</div>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {
                    //changeFormTemplateData _type, _value, _key, _s_key, _key_name, _attri
                    form_template[getProceed].section[getCrewMemberProceed.crew]
                      .questions[0].questionType === "Terms&condition" ? (
                      <>
                        <Row xs={12}>
                          <Col>
                            <Form.Group controlId="formFile" className="mb-3">
                              <Form.Label>
                                <span className="text-danger bold-font">*</span>
                                {
                                  form_template[0].section[
                                    getCrewMemberProceed.crew
                                  ].questions[0].question
                                }
                                {form_template[0].section[
                                  getCrewMemberProceed.crew
                                ].questions[0].tooltip_message !== "" &&
                                  form_template[0].section[
                                    getCrewMemberProceed.crew
                                  ].questions[0].tooltip_message !==
                                    undefined && (
                                    <OverlayTrigger
                                      placement="right"
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          {
                                            form_template[0].section[
                                              getCrewMemberProceed.crew
                                            ].questions[0].tooltip_message
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      <span className="icon-info">i</span>
                                    </OverlayTrigger>
                                  )}
                              </Form.Label>
                              <Form.Check
                                inline
                                name="group1"
                                checked={
                                  form_template[getProceed].section[
                                    getCrewMemberProceed.crew
                                  ].questions[0].checkbox_model[0].value
                                }
                                label={
                                  form_template[getProceed].section[
                                    getCrewMemberProceed.crew
                                  ].questions[0].checkbox_model[0].text
                                }
                                onChange={(e) =>
                                  changeFormTemplateData([
                                    24,
                                    e.target.checked,
                                    getCrewMemberProceed.crew,
                                  ])
                                }
                                type="checkbox"
                                id={`inline-checkbox-1`}
                              />
                              <div>
                                <Form.Label className="mt-2 text-danger">
                                  {form_template[getProceed].section[
                                    getCrewMemberProceed.crew
                                  ].questions[0].checkbox_model[0].value ===
                                    false && "This field is required"}
                                </Form.Label>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    ) : null
                  }
                </>
              ) : null}

              {form_template[getProceed].sectionId === "15" &&
              getProceed1 === 0 ? (
                <>
                  <Row xs={12}>
                    <Col className="mb-3">
                      <Form.Group controlId="form-15-0">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[1].questions[0].question}
                          {form_template[1].questions[0].tooltip_message !==
                            "" &&
                            form_template[1].questions[0].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[1].questions[0]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>

                        <Form.Select
                          aria-label="type of Work"
                          className="rounded-0"
                          defaultValue={
                            form_template[1].questions[0].answer !== "" &&
                            getFirstPart(
                              form_template[1].questions[0].answer
                            ) !== "Other"
                              ? form_template[1].questions[0].answer
                              : getFirstPart(
                                  form_template[1].questions[0].answer
                                ).replace(/^\s+|\s+$/gm, "")
                          }
                          {...register("typeofwork1", { required: true })}
                          onChange={(e) => {
                            e.target.value === ""
                              ? setError("typeofwork1", {
                                  message: "This field is required",
                                })
                              : setError("typeofwork1", { message: "" });
                            changeFormTemplateData([35, e.target.value, 0]);
                          }}
                        >
                          <option value="" style={{ display: `none` }}>
                            Select
                          </option>
                          {form_template[1].questions[0].options.map(
                            (obj, k) => (
                              <option key={k} value={obj}>
                                {obj}
                              </option>
                            )
                          )}
                        </Form.Select>
                        <Form.Label className="mt-2 text-danger">
                          {errors.typeofwork1 && errors.typeofwork1.message}
                        </Form.Label>
                        {gettypeofWork && (
                          <>
                            <Form.Control
                              className="rounded-0 mt-3"
                              type="text"
                              value={getSecondPart(
                                form_template[1].questions[0].answer
                              )}
                              {...register("typeofwork2", { required: true })}
                              onChange={(e) => {
                                e.target.value === ""
                                  ? setError("typeofwork2", {
                                      message: "This field is required",
                                    })
                                  : setError("typeofwork2", { message: "" });
                                changeFormTemplateData([31, e.target.value, 0]);
                              }}
                            />
                            <Form.Label className="mt-2 text-danger">
                              {errors.typeofwork2 && errors.typeofwork2.message}
                            </Form.Label>
                          </>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  {form_template[1].questions[1].visibility && (
                    <Row xs={12}>
                      <Col>
                        <Form.Group controlId="form-15-1" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {form_template[1].questions[1].question}
                            {form_template[1].questions[1].tooltip_message !==
                              "" &&
                              form_template[1].questions[1].tooltip_message !==
                                undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[1].questions[1]
                                          .tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <div key="inline-radio" className="mb-3">
                            <Form.Check
                              inline
                              label="Yes"
                              name="group1"
                              type="radio"
                              id="inline-radio-1"
                              checked={
                                form_template[1].questions[1].answer === "Yes"
                              }
                              onClick={(e) =>
                                changeFormTemplateData([1, "Yes", 1])
                              }
                            />
                            <Form.Check
                              inline
                              label="No"
                              name="group1"
                              type="radio"
                              id="inline-radio-2"
                              checked={
                                form_template[1].questions[1].answer === "No"
                              }
                              onClick={(e) =>
                                changeFormTemplateData([1, "No", 1])
                              }
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  {getClimber && (
                    <Form.Label className="mt-2 text-danger mb-3">
                      You can not proceed because a minimum of 2 climbers are
                      required for any height related work.
                    </Form.Label>
                  )}

                  {form_template[1].questions[2].visibility && !getClimber && (
                    <Row xs={12}>
                      <Col>
                        <Form.Group controlId="form-15-2" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {form_template[1].questions[2].question}
                            {form_template[1].questions[2].tooltip_message !==
                              "" &&
                              form_template[1].questions[2].tooltip_message !==
                                undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[1].questions[2]
                                          .tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Select
                            aria-label="7-2"
                            className="rounded-0"
                            defaultValue={getFirstPart(
                              form_template[1].questions[2].answer
                            )}
                            {...register("overallConditionOfTheStructure1", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("overallConditionOfTheStructure1", {
                                    message: "This field is required",
                                  })
                                : setError("overallConditionOfTheStructure1", {
                                    message: "",
                                  });
                              changeFormTemplateData([1, e.target.value, 2]);
                            }}
                          >
                            <option value="">Select</option>
                            {form_template[1].questions[2].options.map(
                              (obj) => (
                                <option value={obj}>{obj}</option>
                              )
                            )}
                          </Form.Select>
                          <Form.Label className="mt-2 text-danger">
                            {errors.overallConditionOfTheStructure1 &&
                              errors.overallConditionOfTheStructure1.message}
                          </Form.Label>
                          <Form.Control
                            className="rounded-0  mt-1"
                            as="textarea"
                            rows={3}
                            value={form_template[1].questions[2].description}
                            {...register("overallConditionOfTheStructure2", {
                              required: true,
                            })}
                            onChange={(e) => {
                              // e.target.value === ''
                              //     ? setError('overallConditionOfTheStructure2', { message: "This field is required" })
                              //     : setError('overallConditionOfTheStructure2', { message: "" });
                              changeFormTemplateData([6, e.target.value, 1]);
                            }}
                          />
                          <Form.Label className="mt-2 text-danger">
                            {errors.overallConditionOfTheStructure2 &&
                              errors.overallConditionOfTheStructure2.message}
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  {form_template[1].questions[3].visibility && !getClimber && (
                    <Row xs={12}>
                      <Col>
                        <Form.Group controlId="form-15-3" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {form_template[1].questions[3].question}
                            {form_template[1].questions[3].tooltip_message !==
                              "" &&
                              form_template[1].questions[3].tooltip_message !==
                                undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[1].questions[3]
                                          .tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Select
                            aria-label="7-3"
                            className="rounded-0"
                            defaultValue={getFirstPart(
                              form_template[1].questions[3].answer
                            )}
                            {...register("conditionOfTheClimbingFacilities1", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError(
                                    "conditionOfTheClimbingFacilities1",
                                    { message: "This field is required" }
                                  )
                                : setError(
                                    "conditionOfTheClimbingFacilities1",
                                    { message: "" }
                                  );
                              changeFormTemplateData([1, e.target.value, 3]);
                            }}
                          >
                            <option value="">Select</option>
                            {form_template[1].questions[3].options.map(
                              (obj) => (
                                <option value={obj}>{obj}</option>
                              )
                            )}
                          </Form.Select>
                          <Form.Label className="mt-2 text-danger">
                            {errors.conditionOfTheClimbingFacilities1 &&
                              errors.conditionOfTheClimbingFacilities1.message}
                          </Form.Label>
                          <Form.Control
                            className="rounded-0 mt-1 custom-file-input"
                            type="file"
                            {...register("conditionOfTheClimbingFacilities2", {
                              required: true,
                            })}
                            onChange={(e) => {
                              // let fileSize = e.target.files[0]['size'];
                              let fileType = e.target.files[0]["type"];
                              let validImageTypes = [
                                "image/gif",
                                "image/jpeg",
                                "image/png",
                              ];
                              if (!validImageTypes.includes(fileType)) {
                                setError("conditionOfTheClimbingFacilities2", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls("", 1, "3");
                              } else {
                                setError("conditionOfTheClimbingFacilities2", {
                                  message: "",
                                });
                                // getUploadImgUrls(e.target.files[0], 1, '2')
                                getUploadImgUrls(
                                  [e.target.files[0], 3, 0, "picNoSection"],
                                  4,
                                  "0"
                                );
                              }
                              e.target.value = null;
                            }}
                          />
                          <Form.Label className="mt-2 text-danger">
                            {errors.conditionOfTheClimbingFacilities2 &&
                              errors.conditionOfTheClimbingFacilities2.message}
                          </Form.Label>
                          {form_template[1].questions[3].picture !== "" && (
                            <div className="p-1 m-1 imgDiv">
                              <div
                                className="removeImg"
                                onClick={() =>
                                  changeFormTemplateData([
                                    34,
                                    "conditionOfTheClimbingFacilities2",
                                    3,
                                  ])
                                }
                              >
                                x
                              </div>
                              <img
                                src={
                                  getUrlWithQueryString(
                                    form_template[1].questions[3].picture
                                  ) + IMG_TOKEN
                                }
                                alt="conditionOfTheClimbingFacilities2"
                                className="shadow-sm bg-light rounded img-size_preview"
                              />
                            </div>
                          )}
                          <Form.Label className="mt-2 mb-3">
                            <b>
                              {form_template[1].questions[3].picture !== "" &&
                                "1 Image Uploaded"}
                            </b>
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  {form_template[1].questions[4].visibility && !getClimber && (
                    <Row xs={12}>
                      <Col>
                        <Form.Group controlId="form-15-4" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {form_template[1].questions[4].question}
                            {form_template[1].questions[4].tooltip_message !==
                              "" &&
                              form_template[1].questions[4].tooltip_message !==
                                undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[1].questions[4]
                                          .tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Select
                            aria-label="7-4"
                            className="rounded-0"
                            defaultValue={getFirstPart(
                              form_template[1].questions[4].answer
                            )}
                            {...register("visuallyInspectTheFixed1", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("visuallyInspectTheFixed1", {
                                    message: "This field is required",
                                  })
                                : setError("visuallyInspectTheFixed1", {
                                    message: "",
                                  });
                              changeFormTemplateData([1, e.target.value, 4]);
                            }}
                          >
                            <option value="">Select</option>
                            {form_template[1].questions[4].options.map(
                              (obj) => (
                                <option value={obj}>{obj}</option>
                              )
                            )}
                          </Form.Select>
                          <Form.Label className="mt-2 text-danger">
                            {errors.visuallyInspectTheFixed1 &&
                              errors.visuallyInspectTheFixed1.message}
                          </Form.Label>
                          <Form.Control
                            className="rounded-0 mt-1 custom-file-input"
                            type="file"
                            {...register("visuallyInspectTheFixed2", {
                              required: true,
                            })}
                            onChange={(e) => {
                              // let fileSize = e.target.files[0]['size'];
                              let fileType = e.target.files[0]["type"];
                              let validImageTypes = [
                                "image/gif",
                                "image/jpeg",
                                "image/png",
                              ];
                              if (!validImageTypes.includes(fileType)) {
                                setError("visuallyInspectTheFixed2", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls("", 1, "4");
                              } else {
                                setError("visuallyInspectTheFixed2", {
                                  message: "",
                                });
                                // getUploadImgUrls(e.target.files[0], 1, '3')
                                getUploadImgUrls(
                                  [e.target.files[0], 4, 0, "picNoSection"],
                                  4,
                                  "0"
                                );
                              }
                              e.target.value = null;
                            }}
                          />
                          <Form.Label className="mt-2 text-danger">
                            {errors.visuallyInspectTheFixed2 &&
                              errors.visuallyInspectTheFixed2.message}
                          </Form.Label>
                          {form_template[1].questions[4].picture !== "" && (
                            <div className="p-1 m-1 imgDiv">
                              <div
                                className="removeImg"
                                onClick={() =>
                                  changeFormTemplateData([
                                    34,
                                    "visuallyInspectTheFixed2",
                                    4,
                                  ])
                                }
                              >
                                x
                              </div>
                              <img
                                src={
                                  getUrlWithQueryString(
                                    form_template[1].questions[4].picture
                                  ) + IMG_TOKEN
                                }
                                alt="visuallyInspectTheFixed2"
                                className="shadow-sm bg-light rounded img-size_preview"
                              />
                            </div>
                          )}
                          <Form.Label className="mt-2 mb-3">
                            <b>
                              {form_template[1].questions[4].picture !== "" &&
                                "1 Image Uploaded"}
                            </b>
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  {form_template[1].questions[5].visibility && !getClimber && (
                    <Row xs={12}>
                      <Col>
                        <Form.Group controlId="form-15-5" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {form_template[1].questions[5].question}
                            {form_template[1].questions[5].tooltip_message !==
                              "" &&
                              form_template[1].questions[5].tooltip_message !==
                                undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[1].questions[5]
                                          .tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <div key="inline-radio" className="mb-2">
                            <Form.Check
                              inline
                              label="Yes"
                              name="group7-5"
                              type="radio"
                              id="inline-radio-7-5"
                              checked={
                                form_template[1].questions[5].answer === "Yes"
                              }
                              onClick={() => {
                                setError("AllStructuralAppurtenance", {
                                  message: "",
                                });
                                changeFormTemplateData([1, "Yes", 5]);
                              }}
                            />
                            <Form.Check
                              inline
                              label="No"
                              name="group7-5"
                              type="radio"
                              id="inline-radio-7-5"
                              checked={
                                form_template[1].questions[5].answer === "No"
                              }
                              onClick={() => {
                                setError("AllStructuralAppurtenance", {
                                  message: "",
                                });
                                changeFormTemplateData([1, "No", 5]);
                              }}
                            />
                          </div>
                          <Form.Label className="mt-0 text-danger">
                            {errors.AllStructuralAppurtenance &&
                              errors.AllStructuralAppurtenance.message}
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  {form_template[1].questions[6].visibility && !getClimber && (
                    <Row xs={12}>
                      <Col>
                        <Form.Group controlId="form-15-6" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {form_template[1].questions[6].question}
                            {form_template[1].questions[6].tooltip_message !==
                              "" &&
                              form_template[1].questions[6].tooltip_message !==
                                undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[1].questions[6]
                                          .tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <div key="inline-radio" className="mb-3">
                            <Form.Check
                              inline
                              label="Yes"
                              name="group7-6"
                              type="radio"
                              id="inline-radio-7-6"
                              checked={
                                form_template[1].questions[6].answer === "Yes"
                              }
                              onClick={() => {
                                setError("AnchorPointsInspected", {
                                  message: "",
                                });
                                changeFormTemplateData([1, "Yes", 6]);
                              }}
                            />
                            <Form.Check
                              inline
                              label="No"
                              name="group7-6"
                              type="radio"
                              id="inline-radio-7-6"
                              checked={
                                form_template[1].questions[6].answer === "No"
                              }
                              onClick={() => {
                                setError("AnchorPointsInspected", {
                                  message: "",
                                });
                                changeFormTemplateData([1, "No", 6]);
                              }}
                            />
                          </div>
                          <Form.Label className="mt-0 text-danger">
                            {errors.AnchorPointsInspected &&
                              errors.AnchorPointsInspected.message}
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  {form_template[1].questions[7].visibility && !getClimber && (
                    <Row xs={12}>
                      <Col>
                        <Form.Group controlId="form-15-7" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {form_template[1].questions[7].question}
                            {form_template[1].questions[7].tooltip_message !==
                              "" &&
                              form_template[1].questions[7].tooltip_message !==
                                undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[1].questions[7]
                                          .tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Control
                            className="rounded-0  mt-3"
                            as="textarea"
                            rows={5}
                            placeholder="Details"
                            value={form_template[1].questions[7].answer}
                            {...register("otherStructuralHazards", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("otherStructuralHazards", {
                                    message: "This field is required",
                                  })
                                : setError("otherStructuralHazards", {
                                    message: "",
                                  });
                              changeFormTemplateData([1, e.target.value, 7]);
                            }}
                          />
                          <Form.Label className="mt-2 text-danger">
                            {errors.otherStructuralHazards &&
                              errors.otherStructuralHazards.message}
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </>
              ) : null}

              {form_template[getProceed].sectionId === "2" &&
              getProceed1 === 0 ? (
                <>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-2-1" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {ProjectNumber} (
                          <span className="Textwithcolor">
                            {ProjectNumber1}
                          </span>
                          )
                          {form_template[2].questions[0].tooltip_message !==
                            "" &&
                            form_template[2].questions[0].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[2].questions[0]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>

                        <Form.Control
                          className="rounded-0"
                          type="text"
                          value={form_template[2].questions[0].answer}
                          {...register("piNumber", { required: true })}
                          onChange={(e) => {
                            e.target.value === ""
                              ? setError("piNumber", {
                                  message: "This field is required",
                                })
                              : setError("piNumber", { message: "" });
                            changeFormTemplateData([1, e.target.value, 0]);
                          }}
                        />
                        <Form.Label className="mt-2 text-danger">
                          {errors.piNumber && errors.piNumber.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-2-2" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[2].questions[1].question}
                          {form_template[2].questions[1].tooltip_message !==
                            "" &&
                            form_template[2].questions[1].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[2].questions[1]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div className="position-relative ">
                          <div className="position-absolute top-0 end-0 sig-controls">
                            <Button
                              variant="light"
                              size="sm"
                              onClick={() => {
                                setError("loc911Add", { message: "" });
                                changeFormTemplateData([
                                  15,
                                  form_template[2].questions[1].question,
                                  1,
                                ]);
                              }}
                            >
                              <img
                                src={String(position)}
                                alt="reset sign"
                                style={{ width: "18px" }}
                              />
                            </Button>
                          </div>

                          <Form.Control
                            className="rounded-0"
                            as="textarea"
                            rows={5}
                            value={removeTags(
                              form_template[2].questions[1].answer
                            )}
                            // defaultValue={form_template[2].questions[1].answer}
                            // {...register('loc911Add', { required: true })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("loc911Add", {
                                    message: "This field is required",
                                  })
                                : setError("loc911Add", { message: "" });
                              changeFormTemplateData([1, e.target.value, 1]);
                            }}
                          />
                          <Form.Label className="mt-2 text-danger">
                            {errors.loc911Add && errors.loc911Add.message}
                          </Form.Label>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-2-3" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[2].questions[2].question}
                          {form_template[2].questions[2].tooltip_message !==
                            "" &&
                            form_template[2].questions[2].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[2].questions[2]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Control
                          className="rounded-0"
                          type="text"
                          value={form_template[2].questions[2].answer}
                          {...register("ccnameJobName", { required: true })}
                          onChange={(e) => {
                            e.target.value === ""
                              ? setError("ccnameJobName", {
                                  message: "This field is required",
                                })
                              : setError("ccnameJobName", { message: "" });
                            changeFormTemplateData([1, e.target.value, 2]);
                          }}
                        />
                        <Form.Label className="mt-2 text-danger">
                          {errors.ccnameJobName && errors.ccnameJobName.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-2-4" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[2].questions[3].question}
                          {form_template[2].questions[3].tooltip_message !==
                            "" &&
                            form_template[2].questions[3].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[2].questions[3]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>

                        <Form.Select
                          aria-label="customer name"
                          className="rounded-0"
                          defaultValue={
                            form_template[2].questions[3].answer !== "" &&
                            getFirstPart(
                              form_template[2].questions[3].answer
                            ) !== "Other "
                              ? form_template[2].questions[3].answer
                              : getFirstPart(
                                  form_template[2].questions[3].answer
                                ).replace(/^\s+|\s+$/gm, "")
                          }
                          {...register("customername1", { required: true })}
                          onChange={(e) => {
                            e.target.value === ""
                              ? setError("customername1", {
                                  message: "This field is required",
                                })
                              : setError("customername1", { message: "" });
                            changeFormTemplateData([28, e.target.value, 3]);
                          }}
                        >
                          <option value="">Select</option>
                          {form_template[2].questions[3].options.map(
                            (obj, k) => (
                              <option key={k} value={obj}>
                                {obj}
                              </option>
                            )
                          )}
                        </Form.Select>
                        <Form.Label className="mt-2 text-danger">
                          {errors.customername1 && errors.customername1.message}
                        </Form.Label>
                        {getCustomername1 ? (
                          <>
                            <Form.Control
                              className="rounded-0 mt-3"
                              type="text"
                              value={getSecondPart(
                                form_template[2].questions[3].answer
                              )}
                              {...register("customername2", { required: true })}
                              onChange={(e) => {
                                e.target.value === "" ||
                                getFirstPart(
                                  form_template[2].questions[3].answer
                                ) === ""
                                  ? setError("customername2", {
                                      message: "This field is required",
                                    })
                                  : setError("customername2", { message: "" });
                                changeFormTemplateData([29, e.target.value, 3]);
                              }}
                            />
                            <Form.Label className="mt-2 text-danger">
                              {errors.customername2 &&
                                errors.customername2.message}
                            </Form.Label>
                          </>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-2-5" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[2].questions[4].question}
                          {form_template[2].questions[4].tooltip_message !==
                            "" &&
                            form_template[2].questions[4].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[2].questions[4]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>

                        <Form.Select
                          aria-label="site land lord"
                          className="rounded-0"
                          defaultValue={getFirstPart(
                            form_template[2].questions[4].answer
                          )}
                          {...register("siteLanLord1", { required: true })}
                          onChange={(e) => {
                            e.target.value === ""
                              ? setError("siteLanLord1", {
                                  message: "This field is required",
                                })
                              : setError("siteLanLord1", { message: "" });
                            changeFormTemplateData([30, e.target.value, 4]);
                          }}
                        >
                          <option value="">Select</option>
                          {form_template[2].questions[4].options.map(
                            (obj, k) => (
                              <option key={k} value={obj}>
                                {obj}
                              </option>
                            )
                          )}
                        </Form.Select>
                        <Form.Label className="mt-2 text-danger">
                          {errors.siteLanLord1 && errors.siteLanLord1.message}
                        </Form.Label>
                        {getSiteLandLord ? (
                          <>
                            <Form.Control
                              className="rounded-0 mt-3"
                              type="text"
                              value={getSecondPart(
                                form_template[2].questions[4].answer
                              )}
                              {...register("siteLanLord2", { required: true })}
                              onChange={(e) => {
                                e.target.value === ""
                                  ? setError("siteLanLord2", {
                                      message: "This field is required",
                                    })
                                  : setError("siteLanLord2", { message: "" });
                                changeFormTemplateData([31, e.target.value, 4]);
                              }}
                            />
                            <Form.Label className="mt-2 text-danger">
                              {errors.siteLanLord2 &&
                                errors.siteLanLord2.message}
                            </Form.Label>
                          </>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-2-6" className="mb-3">
                        <Form.Label>
                          {form_template[2].questions[5].question}
                          {form_template[2].questions[5].tooltip_message !==
                            "" &&
                            form_template[2].questions[5].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[2].questions[5]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div className="position-relative ">
                          <div className="position-absolute top-0 end-0 sig-controls">
                            <Button
                              variant="light"
                              size="sm"
                              onClick={(e) =>
                                changeFormTemplateData([1, "", 5])
                              }
                            >
                              <img
                                src={String(failed)}
                                alt="reset"
                                style={{ width: "18px" }}
                              />
                            </Button>
                          </div>
                          <Form.Control
                            className="rounded-0"
                            as="textarea"
                            rows={5}
                            value={form_template[2].questions[5].answer}
                            onChange={(e) =>
                              changeFormTemplateData([1, e.target.value, 5])
                            }
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              ) : null}

              {form_template[getProceed].sectionId === "3" &&
              getProceed1 === 0 ? (
                <>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-3-1" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[3].questions[0].question}
                          {form_template[3].questions[0].tooltip_message !==
                            "" &&
                            form_template[3].questions[0].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[3].questions[0]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div className="position-relative ">
                          <div className="position-absolute top-0 end-0 sig-controls">
                            <Button
                              variant="light"
                              size="sm"
                              onClick={() => {
                                form_template[3].questions[0].answer = "";
                                form_template[3].questions[0].answer === ""
                                  ? setError("generalScopOfProj", {
                                      message: "This field is required",
                                    })
                                  : setError("generalScopOfProj", {
                                      message: "",
                                    });
                                // changeFormTemplateData([1, "", 0])
                              }}
                            >
                              <img
                                src={String(failed)}
                                alt="clear"
                                style={{ width: "18px" }}
                              />
                            </Button>
                          </div>
                          <Form.Control
                            className="rounded-0"
                            as="textarea"
                            rows={5}
                            value={form_template[3].questions[0].answer}
                            {...register("generalScopOfProj", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("generalScopOfProj", {
                                    message: "This field is required",
                                  })
                                : setError("generalScopOfProj", {
                                    message: "",
                                  });
                              changeFormTemplateData([1, e.target.value, 0]);
                            }}
                          />
                        </div>
                        <Form.Label className="mt-2 text-danger">
                          {errors.generalScopOfProj &&
                            errors.generalScopOfProj.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-3-2" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[3].questions[1].question}
                          {form_template[3].questions[1].tooltip_message !==
                            "" &&
                            form_template[3].questions[1].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[3].questions[1]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <DailyTask
                          form_template={form_template}
                          setForm_template={setForm_template}
                          setError={setError}
                          errors={errors}
                        />
                        <div className="d-flex justify-content-center m-3">
                          <Button
                            className=" bd-highlight"
                            size="sm"
                            onClick={getAddMoreDailyTaskFunc}
                          >
                            + Add More
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  {form_template[3].questions[2].visibility && (
                    <Row xs={12}>
                      <Col>
                        <Form.Group controlId="form-3-4" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {form_template[3].questions[2].question}
                            {form_template[3].questions[3].tooltip_message !==
                              "" &&
                              form_template[3].questions[2].tooltip_message !==
                                undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[3].questions[2]
                                          .tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <div key="inline-radio" className="mb-3">
                            <Form.Check
                              inline
                              label="Yes"
                              name="group3"
                              type="radio"
                              id="inline-radio-3-4"
                              checked={
                                form_template[3].questions[2].answer === "Yes"
                              }
                              onClick={(e) =>
                                changeFormTemplateData([1, "Yes", 2])
                              }
                            />
                            <Form.Check
                              inline
                              label="No"
                              name="group3"
                              type="radio"
                              id="inline-radio-3-4"
                              checked={
                                form_template[3].questions[2].answer === "No"
                              }
                              onClick={(e) =>
                                changeFormTemplateData([1, "No", 2])
                              }
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      {form_template[3].questions[2].answer === "Yes" ? (
                        <Row xs={12} className="m-2">
                          <Col>
                            <Form.Group controlId="form-3-3-1" className="mb-3">
                              <Form.Label>
                                <span className="text-danger bold-font">*</span>
                                {
                                  form_template[3].questions[2].subQuestion[0]
                                    .question
                                }
                                {form_template[3].questions[2].subQuestion[0]
                                  .tooltip_message !== "" &&
                                  form_template[3].questions[2].subQuestion[0]
                                    .tooltip_message !== undefined && (
                                    <OverlayTrigger
                                      placement="right"
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          {
                                            form_template[3].questions[2]
                                              .subQuestion[0].tooltip_message
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      <span className="icon-info">i</span>
                                    </OverlayTrigger>
                                  )}
                              </Form.Label>
                              <div key="inline-radio" className="mb-3">
                                <Form.Check
                                  inline
                                  label="Yes"
                                  name="group3-1"
                                  type="radio"
                                  id="inline-radio-3-1"
                                  checked={
                                    form_template[3].questions[2].subQuestion[0]
                                      .answer === "Yes"
                                  }
                                  onClick={(e) =>
                                    changeFormTemplateData([5, "Yes", 2, 0])
                                  }
                                />
                                <Form.Check
                                  inline
                                  label="No"
                                  name="group3-1"
                                  type="radio"
                                  id="inline-radio-3-1"
                                  checked={
                                    form_template[3].questions[2].subQuestion[0]
                                      .answer === "No"
                                  }
                                  onClick={(e) =>
                                    changeFormTemplateData([5, "No", 2, 0])
                                  }
                                />
                              </div>
                              {form_template[3].questions[2].subQuestion[0]
                                .answer === "Yes" ? (
                                <>
                                  <Form.Control
                                    className="rounded-0 custom-file-input"
                                    type="file"
                                    {...register("isRiggingPlan2", {
                                      required: true,
                                    })}
                                    onChange={(e) => {
                                      // let fileSize = e.target.files[0]['size'];
                                      let fileType = e.target.files[0]["type"];
                                      let validImageTypes = [
                                        "application/pdf",
                                        "image/gif",
                                        "image/jpeg",
                                        "image/png",
                                      ];
                                      if (!validImageTypes.includes(fileType)) {
                                        setError("isRiggingPlan2", {
                                          message:
                                            'Only "jpeg/png/gif" image and "pdf" files are allowed.',
                                        });
                                        getUploadImgUrls("", 5, "0");
                                      } else {
                                        setError("isRiggingPlan2", {
                                          message: "",
                                        });
                                        // getUploadImgUrls(e.target.files[0], 5, '0')
                                        getUploadImgUrls(
                                          [
                                            e.target.files[0],
                                            2,
                                            0,
                                            "subQuestionPic",
                                          ],
                                          4,
                                          "0"
                                        );
                                      }
                                    }}
                                  />
                                  <Form.Label className="mt-2 text-danger">
                                    {errors.isRiggingPlan2 &&
                                      errors.isRiggingPlan2.message}
                                  </Form.Label>
                                  <Form.Label className="mt-2 mb-3">
                                    <b>
                                      {form_template[3].questions[2]
                                        .subQuestion[0].picture !== "" &&
                                        "1 File Uploaded"}
                                    </b>
                                  </Form.Label>
                                </>
                              ) : null}
                            </Form.Group>
                          </Col>
                        </Row>
                      ) : null}
                    </Row>
                  )}

                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-3-5" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[3].questions[3].question}
                          {form_template[3].questions[3].tooltip_message !==
                            "" &&
                            form_template[3].questions[3].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[3].questions[3]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div key="inline-radio" className="mb-3">
                          <Form.Check
                            inline
                            label="Yes"
                            name="group4"
                            type="radio"
                            id="inline-radio-3-5"
                            checked={
                              form_template[3].questions[3].answer === "Yes"
                            }
                            onClick={(e) =>
                              changeFormTemplateData([1, "Yes", 3])
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group4"
                            type="radio"
                            id="inline-radio-3-5"
                            checked={
                              form_template[3].questions[3].answer === "No"
                            }
                            onClick={(e) =>
                              changeFormTemplateData([1, "No", 3])
                            }
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              ) : null}
              {form_template[getProceed].sectionId === "4" &&
              getProceed1 === 0 ? (
                <>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-4-1" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[4].questions[0].question}
                          {form_template[4].questions[0].tooltip_message !==
                            "" &&
                            form_template[4].questions[0].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[4].questions[0]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Control
                          className="rounded-0"
                          as="textarea"
                          rows={5}
                          value={form_template[4].questions[0].answer}
                          {...register("onSiteCompInfo", { required: true })}
                          onChange={(e) => {
                            e.target.value === ""
                              ? setError("onSiteCompInfo", {
                                  message: "This field is required",
                                })
                              : setError("onSiteCompInfo", { message: "" });
                            changeFormTemplateData([1, e.target.value, 0]);
                          }}
                        />
                        <Form.Label className="mt-2 text-danger">
                          {errors.onSiteCompInfo &&
                            errors.onSiteCompInfo.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-4-2" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[4].questions[1].question}
                          {form_template[4].questions[1].tooltip_message !==
                            "" &&
                            form_template[4].questions[1].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[4].questions[1]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Control
                          className="rounded-0"
                          as="textarea"
                          rows={5}
                          value={removeTags(
                            form_template[4].questions[1].answer
                          )}
                          {...register("jobSiteOperMang", { required: true })}
                          onChange={(e) => {
                            e.target.value === ""
                              ? setError("jobSiteOperMang", {
                                  message: "This field is required",
                                })
                              : setError("jobSiteOperMang", { message: "" });
                            changeFormTemplateData([1, e.target.value, 1]);
                          }}
                        />
                        <Form.Label className="mt-2 text-danger">
                          {errors.jobSiteOperMang &&
                            errors.jobSiteOperMang.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-4-3" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[4].questions[2].question}
                          {form_template[4].questions[2].tooltip_message !==
                            "" &&
                            form_template[4].questions[2].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[4].questions[2]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Control
                          className="rounded-0"
                          as="textarea"
                          rows={5}
                          value={removeTags(
                            form_template[4].questions[2].answer
                          )}
                          {...register("jobSiteMangOfSafety", {
                            required: true,
                          })}
                          onChange={(e) => {
                            e.target.value === ""
                              ? setError("jobSiteMangOfSafety", {
                                  message: "This field is required",
                                })
                              : setError("jobSiteMangOfSafety", {
                                  message: "",
                                });
                            changeFormTemplateData([1, e.target.value, 2]);
                          }}
                        />
                        <Form.Label className="mt-2 text-danger">
                          {errors.jobSiteMangOfSafety &&
                            errors.jobSiteMangOfSafety.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-4-4" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[4].questions[3].question}
                          {form_template[4].questions[3].tooltip_message !==
                            "" &&
                            form_template[4].questions[3].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[4].questions[3]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Control
                          className="rounded-0"
                          as="textarea"
                          rows={5}
                          value={removeTags(
                            form_template[4].questions[3].answer
                          )}
                          {...register("onSitePersonInCharge", {
                            required: true,
                          })}
                          onChange={(e) => {
                            e.target.value === ""
                              ? setError("onSitePersonInCharge", {
                                  message: "This field is required",
                                })
                              : setError("onSitePersonInCharge", {
                                  message: "",
                                });
                            changeFormTemplateData([1, e.target.value, 3]);
                          }}
                        />
                        <Form.Label className="mt-2 text-danger">
                          {errors.onSitePersonInCharge &&
                            errors.onSitePersonInCharge.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <Row xs={12}>
                                <Col >
                                    <Form.Group controlId="form-4-5" className="mb-3">
                                        <Form.Label><span className="text-danger bold-font">*</span>{form_template[4].questions[4].question}</Form.Label>
                                        <Form.Control className="rounded-0" type="text"
                                            value={form_template[4].questions[4].answer}
                                            {...register('jobSitePersonnel', { required: true })}
                                            onChange={
                                                (e) => {
                                                    e.target.value === ''
                                                        ? setError('jobSitePersonnel', { message: "This field is required" })
                                                        : setError('jobSitePersonnel', { message: "" });
                                                    changeFormTemplateData([1, e.target.value, 4])
                                                }
                                            }
                                        />
                                        <Form.Label className="mt-2 text-danger">{errors.jobSitePersonnel && errors.jobSitePersonnel.message}</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row xs={12}>
                                <Col >
                                    <Form.Group controlId="form-4-6" className="mb-3">
                                        <Form.Label><span className="text-danger bold-font">*</span>{form_template[4].questions[5].question}</Form.Label>
                                        <Form.Control className="rounded-0" type="text"
                                            value={form_template[4].questions[5].answer}
                                            {...register('siteSafetyTrainingDocOnSite', { required: true })}
                                            onChange={
                                                (e) => {
                                                    e.target.value === ''
                                                        ? setError('siteSafetyTrainingDocOnSite', { message: "This field is required" })
                                                        : setError('siteSafetyTrainingDocOnSite', { message: "" });
                                                    changeFormTemplateData([1, e.target.value, 5])
                                                }
                                            }
                                        />
                                        <Form.Label className="mt-2 text-danger">{errors.siteSafetyTrainingDocOnSite && errors.siteSafetyTrainingDocOnSite.message}</Form.Label>

                                    </Form.Group>
                                </Col>
                            </Row> */}
                </>
              ) : null}
              {form_template[getProceed].sectionId === "5" &&
              getProceed1 === 0 ? (
                <>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-4-1" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[5].questions[0].question}
                          {form_template[5].questions[0].tooltip_message !==
                            "" &&
                            form_template[5].questions[0].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[5].questions[0]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div key="inline-radio" className="mb-2">
                          <Form.Check
                            inline
                            label="Yes"
                            name="group4-1"
                            type="radio"
                            id="inline-radio-1"
                            checked={
                              form_template[5].questions[0].answer === "Yes"
                            }
                            onChange={() => {
                              setError("are911ServicesAvailable", {
                                message: "",
                              });
                              changeFormTemplateData([1, "Yes", 0]);
                            }}
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group4-1"
                            type="radio"
                            id="inline-radio-2"
                            checked={
                              form_template[5].questions[0].answer === "No"
                            }
                            onChange={() => {
                              setError("are911ServicesAvailable", {
                                message: "",
                              });
                              changeFormTemplateData([1, "No", 0]);
                            }}
                          />
                        </div>
                        <Form.Label className="mt-0 text-danger">
                          {errors.are911ServicesAvailable &&
                            errors.are911ServicesAvailable.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-4-3" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[5].questions[2].question}
                          {form_template[5].questions[2].tooltip_message !==
                            "" &&
                            form_template[5].questions[2].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[5].questions[2]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div className="position-relative ">
                          <div className="position-absolute top-0 end-0 sig-controls me-3">
                            <Button
                              variant="light"
                              size="sm"
                              // onClick={() => changeFormTemplateData([4, "", 2])}
                              onClick={() => {
                                setError("evacuationPoint", { message: "" });
                                changeFormTemplateData([
                                  15,
                                  form_template[5].questions[2].question,
                                  2,
                                ]);
                              }}
                            >
                              <img
                                src={String(position)}
                                alt="reset"
                                style={{ width: "18px" }}
                              />
                            </Button>
                          </div>
                          <Form.Control
                            className="rounded-0"
                            as="textarea"
                            rows={5}
                            value={removeTags(
                              form_template[5].questions[2].answer
                            )}
                            // {...register('evacuationPoint', { required: true })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("evacuationPoint", {
                                    message: "This field is required",
                                  })
                                : setError("evacuationPoint", { message: "" });
                              changeFormTemplateData([1, e.target.value, 2]);
                            }}
                          />
                          <Form.Label className="mt-2 text-danger">
                            {errors.evacuationPoint &&
                              errors.evacuationPoint.message}
                          </Form.Label>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-4-2" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[5].questions[1].question}
                          {form_template[5].questions[1].tooltip_message !==
                            "" &&
                            form_template[5].questions[1].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[5].questions[1]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div className="position-relative ">
                          <div className="position-absolute top-0 end-0 sig-controls me-3">
                            <Button
                              variant="light"
                              size="sm"
                              // onClick={() => changeFormTemplateData([1, "", 1])}
                              onClick={() => {
                                // form_template[5].questions[1].answer === ''
                                //     ? setError('hospitalInfo', { message: "This field is required" })
                                //     : setError('hospitalInfo', { message: "" });
                                setError("hospitalInfo", { message: "" });
                                setError("directionsToHospital", {
                                  message: "",
                                });
                                setError("directionsFromHospital", {
                                  message: "",
                                });
                                // changeFormTemplateData([15, form_template[5].questions[1].question, 1])
                                changeFormTemplateData([
                                  22,
                                  "Trauma Center",
                                  1,
                                ]);
                              }}
                            >
                              <img
                                src={String(ic_trauma_center)}
                                alt="reset"
                                style={{ width: "18px" }}
                              />
                            </Button>
                          </div>
                          <Form.Control
                            className="rounded-0"
                            as="textarea"
                            rows={5}
                            value={removeTags(
                              form_template[5].questions[1].answer
                            )}
                            // {...register('hospitalInfo', { required: true })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("hospitalInfo", {
                                    message: "This field is required",
                                  })
                                : setError("hospitalInfo", { message: "" });
                              changeFormTemplateData([1, e.target.value, 1]);
                            }}
                          />
                          <Form.Label className="mt-2 text-danger">
                            {errors.hospitalInfo && errors.hospitalInfo.message}
                          </Form.Label>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-4-4" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[5].questions[3].question}
                          {form_template[5].questions[3].tooltip_message !==
                            "" &&
                            form_template[5].questions[3].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[5].questions[3]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div className="position-relative mb-1">
                          <div className="position-absolute top-0 end-0 sig-controls me-3">
                            <Button
                              variant="light"
                              size="sm"
                              onClick={() =>
                                changeFormTemplateData([
                                  16,
                                  [
                                    form_template[5].questions[3].question,
                                    [getLatitude, getLongitude],
                                    [
                                      form_template[5].questions[1].lat,
                                      form_template[5].questions[1].long,
                                    ],
                                  ],
                                  3,
                                ])
                              }
                            >
                              <img
                                src={String(map_directions)}
                                alt="reset"
                                style={{ width: "18px" }}
                              />
                            </Button>
                          </div>
                          {/* <div class="position-absolute bottom-0 end-0 sig-controls ">
                                                <a style={{ cursor: "pointer" }}>more..</a>
                                            </div> */}
                          <Form.Control
                            className="rounded-0"
                            as="textarea"
                            rows={5}
                            value={removeTags(
                              form_template[5].questions[3].answer
                            )}
                            {...register("directionsToHospital", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("directionsToHospital", {
                                    message: "This field is required",
                                  })
                                : setError("directionsToHospital", {
                                    message: "",
                                  });
                              changeFormTemplateData([1, e.target.value, 3]);
                            }}
                          />
                        </div>
                        <Form.Label className="mt-2 text-danger">
                          {errors.directionsToHospital &&
                            errors.directionsToHospital.message}
                        </Form.Label>
                        <div>
                          Distance: {form_template[5].questions[3].distance}
                        </div>
                        <div>
                          Duration: {form_template[5].questions[3].duration}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-4-5" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[5].questions[4].question}
                          {form_template[5].questions[4].tooltip_message !==
                            "" &&
                            form_template[5].questions[4].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[5].questions[4]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div className="position-relative ">
                          <div className="position-absolute top-0 end-0 sig-controls me-3">
                            <Button
                              variant="light"
                              size="sm"
                              onClick={() =>
                                changeFormTemplateData([
                                  16,
                                  [
                                    form_template[5].questions[4].question,
                                    [
                                      form_template[5].questions[1].lat,
                                      form_template[5].questions[1].long,
                                    ],
                                    [getLatitude, getLongitude],
                                  ],
                                  3,
                                ])
                              }
                            >
                              <img
                                src={String(map_directions)}
                                alt="reset"
                                style={{ width: "18px" }}
                              />
                            </Button>
                          </div>
                          {/* <div class="position-absolute bottom-0 end-0 sig-controls ">
                                                <a style={{ cursor: "pointer" }}>more..</a>
                                            </div> */}
                          <Form.Control
                            className="rounded-0"
                            as="textarea"
                            rows={5}
                            value={removeTags(
                              form_template[5].questions[4].answer
                            )}
                            {...register("directionsFromHospital", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("directionsFromHospital", {
                                    message: "This field is required",
                                  })
                                : setError("directionsFromHospital", {
                                    message: "",
                                  });
                              changeFormTemplateData([1, e.target.value, 4]);
                            }}
                          />
                        </div>
                        <Form.Label className="mt-2 text-danger">
                          {errors.directionsFromHospital &&
                            errors.directionsFromHospital.message}
                        </Form.Label>
                        <div>
                          Distance: {form_template[5].questions[4].distance}
                        </div>
                        <div>
                          Duration: {form_template[5].questions[4].duration}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-4-6" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[5].questions[5].question}
                          {form_template[5].questions[5].tooltip_message !==
                            "" &&
                            form_template[5].questions[5].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[5].questions[5]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div className="position-relative ">
                          <div className="position-absolute top-0 end-0 sig-controls me-3 btnClick">
                            {form_template[5].questions[5].answer ===
                              "Not Available" && (
                              <Button
                                variant="light"
                                size="sm"
                                onClick={() =>
                                  changeFormTemplateData([
                                    15,
                                    form_template[5].questions[5].question,
                                    5,
                                  ])
                                }
                              >
                                <img
                                  src={String(position)}
                                  alt="reset"
                                  style={{ width: "18px" }}
                                />
                              </Button>
                            )}
                            {form_template[5].questions[5].answer !==
                              "Not Available" && (
                              <Nav.Link
                                className="link"
                                target="_blank"
                                href={
                                  "https://www.google.com/maps/dir/" +
                                  getLoc.lat +
                                  "," +
                                  getLoc.lng +
                                  "/" +
                                  form_template[5].questions[5].lat +
                                  "," +
                                  form_template[5].questions[5].long +
                                  "/@" +
                                  getLoc.lat +
                                  "," +
                                  getLoc.lng +
                                  ",15z"
                                }
                              >
                                <img
                                  src={String(map_directions)}
                                  alt="reset"
                                  style={{ width: "18px" }}
                                />
                              </Nav.Link>
                            )}
                            {form_template[5].questions[5].phone_number !==
                              "" && (
                              <Nav.Link
                                className="link"
                                href={
                                  "tel:" +
                                  form_template[5].questions[5].phone_number
                                }
                              >
                                <span className="icon-call">&#128381;</span>
                              </Nav.Link>
                            )}
                          </div>

                          {/* <div class="position-absolute bottom-0 end-0 sig-controls me-3">
                                                <Button variant="light" size="sm"><img src={String(phone_classic)} alt="reset" style={{ width: "18px" }} /></Button>
                                            </div> */}
                          <Form.Control
                            className="rounded-0"
                            as="textarea"
                            rows={5}
                            value={removeTags(
                              form_template[5].questions[5].answer
                            )}
                            {...register("nearestFireDept", { required: true })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("nearestFireDept", {
                                    message: "This field is required",
                                  })
                                : setError("nearestFireDept", { message: "" });
                              changeFormTemplateData([1, e.target.value, 5]);
                            }}
                          />
                        </div>
                        <Form.Label className="mt-2 text-danger">
                          {errors.nearestFireDept &&
                            errors.nearestFireDept.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-4-7" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[5].questions[6].question}
                          {form_template[5].questions[6].tooltip_message !==
                            "" &&
                            form_template[5].questions[6].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[5].questions[6]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div className="position-relative ">
                          <div className="position-absolute top-0 end-0 sig-controls me-3 btnClick">
                            {form_template[5].questions[6].answer ===
                              "Not Available" && (
                              <Button
                                variant="light"
                                size="sm"
                                onClick={() =>
                                  changeFormTemplateData([
                                    15,
                                    form_template[5].questions[6].question,
                                    6,
                                  ])
                                }
                              >
                                <img
                                  src={String(position)}
                                  alt="reset"
                                  style={{ width: "18px" }}
                                />
                              </Button>
                            )}
                            {form_template[5].questions[6].answer !==
                              "Not Available" && (
                              <Nav.Link
                                className="link"
                                target="_blank"
                                href={
                                  "https://www.google.com/maps/dir/" +
                                  getLoc.lat +
                                  "," +
                                  getLoc.lng +
                                  "/" +
                                  form_template[5].questions[6].lat +
                                  "," +
                                  form_template[5].questions[6].long +
                                  "/@" +
                                  getLoc.lat +
                                  "," +
                                  getLoc.lng +
                                  ",12z"
                                }
                              >
                                <img
                                  src={String(map_directions)}
                                  alt="reset"
                                  style={{ width: "18px" }}
                                />
                              </Nav.Link>
                            )}
                            {form_template[5].questions[6].phone_number !==
                              "" && (
                              <Nav.Link
                                className="link"
                                href={
                                  "tel:" +
                                  form_template[5].questions[6].phone_number
                                }
                              >
                                <span className="icon-call">&#128381;</span>
                              </Nav.Link>
                            )}
                          </div>

                          {/* <div class="position-absolute bottom-0 end-0 sig-controls me-3">
                                                <Button variant="light" size="sm"><img src={String(phone_classic)} alt="reset" style={{ width: "18px" }} /></Button>
                                            </div> */}
                          <Form.Control
                            className="rounded-0"
                            as="textarea"
                            rows={5}
                            value={removeTags(
                              form_template[5].questions[6].answer
                            )}
                            {...register("nearestPoliceStat", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("nearestPoliceStat", {
                                    message: "This field is required",
                                  })
                                : setError("nearestPoliceStat", {
                                    message: "",
                                  });
                              changeFormTemplateData([1, e.target.value, 6]);
                            }}
                          />
                        </div>
                        <Form.Label className="mt-2 text-danger">
                          {errors.nearestPoliceStat &&
                            errors.nearestPoliceStat.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-4-8" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[5].questions[7].question}
                          {form_template[5].questions[7].tooltip_message !==
                            "" &&
                            form_template[5].questions[7].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[5].questions[7]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Row xs={12} className="ms-3">
                      <Col>
                        <Form.Group controlId="form-4-8-1" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {
                              form_template[5].questions[7].subQuestion[0]
                                .question
                            }
                            {form_template[5].questions[7].subQuestion[0]
                              .tooltip_message !== "" &&
                              form_template[5].questions[7].subQuestion[0]
                                .tooltip_message !== undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[5].questions[7]
                                          .subQuestion[0].tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Select
                            aria-label="4-8-1"
                            className="rounded-0"
                            defaultValue={
                              form_template[5].questions[7].subQuestion[0]
                                .answer
                            }
                            // defaultValue={getUserName}
                            {...register("remainCalmAndAssessEmer1", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("remainCalmAndAssessEmer1", {
                                    message: "This field is required",
                                  })
                                : setError("remainCalmAndAssessEmer1", {
                                    message: "",
                                  });
                              changeFormTemplateData([5, e.target.value, 7, 0]);
                            }}
                          >
                            {/* <option value="">Select</option> */}
                            {form_template[5].questions[7].subQuestion[0].options.map(
                              (obj, k) => (
                                <option key={k} value={obj}>
                                  {obj}
                                </option>
                              )
                            )}
                          </Form.Select>
                          <Form.Label className="mt-2 text-danger">
                            {errors.remainCalmAndAssessEmer1 &&
                              errors.remainCalmAndAssessEmer1.message}
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row xs={12} className="ms-3">
                      <Col>
                        <Form.Group controlId="form-4-8-2" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {
                              form_template[5].questions[7].subQuestion[1]
                                .question
                            }
                            {form_template[5].questions[7].subQuestion[1]
                              .tooltip_message !== "" &&
                              form_template[5].questions[7].subQuestion[1]
                                .tooltip_message !== undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[5].questions[7]
                                          .subQuestion[1].tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Select
                            aria-label="4-8-2"
                            className="rounded-0"
                            defaultValue={
                              form_template[5].questions[7].subQuestion[1]
                                .answer
                            }
                            {...register("remainCalmAndAssessEmer2", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("remainCalmAndAssessEmer2", {
                                    message: "This field is required",
                                  })
                                : setError("remainCalmAndAssessEmer2", {
                                    message: "",
                                  });
                              changeFormTemplateData([5, e.target.value, 7, 1]);
                            }}
                          >
                            {/* <option value="">Select</option> */}
                            {/* <option value={getUserName}>{getUserName}</option> */}
                            {form_template[5].questions[7].subQuestion[1].options.map(
                              (obj, k) => (
                                <option key={k} value={obj}>
                                  {obj}
                                </option>
                              )
                            )}
                          </Form.Select>
                          <Form.Label className="mt-2 text-danger">
                            {errors.remainCalmAndAssessEmer2 &&
                              errors.remainCalmAndAssessEmer2.message}
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-4-9" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[5].questions[8].question}
                          {form_template[5].questions[8].tooltip_message !==
                            "" &&
                            form_template[5].questions[8].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[5].questions[8]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Row xs={12} className="ms-3">
                      <Col>
                        <Form.Group controlId="form-4-9-1" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {
                              form_template[5].questions[8].subQuestion[0]
                                .question
                            }
                            {form_template[5].questions[8].subQuestion[0]
                              .tooltip_message !== "" &&
                              form_template[5].questions[8].subQuestion[0]
                                .tooltip_message !== undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[5].questions[8]
                                          .subQuestion[0].tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Select
                            aria-label="4-9-1"
                            className="rounded-0"
                            defaultValue={
                              form_template[5].questions[8].subQuestion[0]
                                .answer
                            }
                            {...register("initiateEmergencyResp1", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("initiateEmergencyResp1", {
                                    message: "This field is required",
                                  })
                                : setError("initiateEmergencyResp1", {
                                    message: "",
                                  });
                              changeFormTemplateData([5, e.target.value, 8, 0]);
                            }}
                          >
                            {/* <option value="">Select</option> */}
                            {/* <option value={getUserName}>{getUserName}</option> */}
                            {form_template[5].questions[8].subQuestion[0].options.map(
                              (obj, k) => (
                                <option key={k} value={obj}>
                                  {obj}
                                </option>
                              )
                            )}
                          </Form.Select>
                          <Form.Label className="mt-2 text-danger">
                            {errors.initiateEmergencyResp1 &&
                              errors.initiateEmergencyResp1.message}
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row xs={12} className="ms-3">
                      <Col>
                        <Form.Group controlId="form-4-9-2" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {
                              form_template[5].questions[8].subQuestion[1]
                                .question
                            }
                            {form_template[5].questions[8].subQuestion[1]
                              .tooltip_message !== "" &&
                              form_template[5].questions[8].subQuestion[1]
                                .tooltip_message !== undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[5].questions[8]
                                          .subQuestion[1].tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Select
                            aria-label="4-9-2"
                            className="rounded-0"
                            defaultValue={
                              form_template[5].questions[8].subQuestion[1]
                                .answer
                            }
                            {...register("initiateEmergencyResp2", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("initiateEmergencyResp2", {
                                    message: "This field is required",
                                  })
                                : setError("initiateEmergencyResp2", {
                                    message: "",
                                  });
                              changeFormTemplateData([5, e.target.value, 8, 1]);
                            }}
                          >
                            {/* <option value="">Select</option> */}
                            {/* <option value={getUserName}>{getUserName}</option> */}
                            {form_template[5].questions[8].subQuestion[1].options.map(
                              (obj, k) => (
                                <option key={k} value={obj}>
                                  {obj}
                                </option>
                              )
                            )}
                          </Form.Select>
                          <Form.Label className="mt-2 text-danger">
                            {errors.initiateEmergencyResp2 &&
                              errors.initiateEmergencyResp2.message}
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Row>

                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-4-10" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[5].questions[9].question}
                          {form_template[5].questions[9].tooltip_message !==
                            "" &&
                            form_template[5].questions[9].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[5].questions[9]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Row xs={12} className="ms-3">
                      <Col>
                        <Form.Group controlId="form-4-10-1" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {
                              form_template[5].questions[9].subQuestion[0]
                                .question
                            }
                            {form_template[5].questions[9].subQuestion[0]
                              .tooltip_message !== "" &&
                              form_template[5].questions[9].subQuestion[0]
                                .tooltip_message !== undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[5].questions[9]
                                          .subQuestion[0].tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Select
                            aria-label="4-10-1"
                            className="rounded-0"
                            defaultValue={
                              form_template[5].questions[9].subQuestion[0]
                                .answer
                            }
                            {...register("initiatePersonMedicalResp1", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("initiatePersonMedicalResp1", {
                                    message: "This field is required",
                                  })
                                : setError("initiatePersonMedicalResp1", {
                                    message: "",
                                  });
                              changeFormTemplateData([5, e.target.value, 9, 0]);
                            }}
                          >
                            {/* <option value="">Select</option> */}
                            {/* <option value={getUserName}>{getUserName}</option> */}
                            {form_template[5].questions[9].subQuestion[0].options.map(
                              (obj, k) => (
                                <option key={k} value={obj}>
                                  {obj}
                                </option>
                              )
                            )}
                          </Form.Select>
                          <Form.Label className="mt-2 text-danger">
                            {errors.initiatePersonMedicalResp1 &&
                              errors.initiatePersonMedicalResp1.message}
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row xs={12} className="ms-3">
                      <Col>
                        <Form.Group controlId="form-4-10-2" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {
                              form_template[5].questions[9].subQuestion[1]
                                .question
                            }
                            {form_template[5].questions[9].subQuestion[1]
                              .tooltip_message !== "" &&
                              form_template[5].questions[9].subQuestion[1]
                                .tooltip_message !== undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[5].questions[9]
                                          .subQuestion[1].tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Select
                            aria-label="4-10-2"
                            className="rounded-0"
                            defaultValue={
                              form_template[5].questions[9].subQuestion[1]
                                .answer
                            }
                            {...register("initiatePersonMedicalResp2", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("initiatePersonMedicalResp2", {
                                    message: "This field is required",
                                  })
                                : setError("initiatePersonMedicalResp2", {
                                    message: "",
                                  });
                              changeFormTemplateData([5, e.target.value, 9, 1]);
                            }}
                          >
                            {/* <option value="">Select</option> */}
                            {/* <option value={getUserName}>{getUserName}</option> */}
                            {form_template[5].questions[9].subQuestion[1].options.map(
                              (obj, k) => (
                                <option key={k} value={obj}>
                                  {obj}
                                </option>
                              )
                            )}
                          </Form.Select>
                          <Form.Label className="mt-2 text-danger">
                            {errors.initiatePersonMedicalResp2 &&
                              errors.initiatePersonMedicalResp2.message}
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-4-11" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[5].questions[10].question}
                          {form_template[5].questions[10].tooltip_message !==
                            "" &&
                            form_template[5].questions[10].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[5].questions[10]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>

                        <Form.Control
                          className="rounded-0"
                          type="text"
                          value={form_template[5].questions[10].answer}
                          {...register("reportSafetyIncidentTo", {
                            required: true,
                          })}
                          onChange={(e) => {
                            e.target.value === ""
                              ? setError("reportSafetyIncidentTo", {
                                  message: "This field is required",
                                })
                              : setError("reportSafetyIncidentTo", {
                                  message: "",
                                });
                            changeFormTemplateData([1, e.target.value, 10]);
                          }}
                        />
                        <Form.Label className="mt-2 text-danger">
                          {errors.reportSafetyIncidentTo &&
                            errors.reportSafetyIncidentTo.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              ) : null}
              {form_template[getProceed].sectionId === "7" &&
              getProceed1 === 0 ? (
                <>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-5-1" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[6].questions[0].question}
                          {form_template[6].questions[0].tooltip_message !==
                            "" &&
                            form_template[6].questions[0].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[6].questions[0]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Select
                          aria-label="4-11-1"
                          className="rounded-0"
                          // defaultValue={form_template[6].questions[0].answer}
                          value={form_template[6].questions[0].answer}
                          {...register("primaryMeansOfRescue", {
                            required: true,
                          })}
                          onChange={(e) => {
                            e.target.value === ""
                              ? setError("primaryMeansOfRescue", {
                                  message: "This field is required",
                                })
                              : setError("primaryMeansOfRescue", {
                                  message: "",
                                });
                            e.target.value !== "Other"
                              ? changeFormTemplateData([1, e.target.value, 0])
                              : modalOtherHandler();
                          }}
                        >
                          {/* <option value=''>Select</option> */}
                          {form_template[6].questions[0].options.map(
                            (obj, k) => (
                              <option key={k} value={obj}>
                                {obj}
                              </option>
                            )
                          )}
                        </Form.Select>
                        <Form.Label className="mt-2 text-danger">
                          {errors.primaryMeansOfRescue &&
                            errors.primaryMeansOfRescue.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-5-2" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[6].questions[1].question}
                          {form_template[6].questions[1].tooltip_message !==
                            "" &&
                            form_template[6].questions[1].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[6].questions[1]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Row xs={12} className="ms-3">
                      <Col>
                        <Form.Group controlId="form-5-2-1" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {
                              form_template[6].questions[1].subQuestion[0]
                                .question
                            }
                            {form_template[6].questions[1].subQuestion[0]
                              .tooltip_message !== "" &&
                              form_template[6].questions[1].subQuestion[0]
                                .tooltip_message !== undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[6].questions[1]
                                          .subQuestion[0].tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Select
                            aria-label="5-2-1"
                            className="rounded-0"
                            defaultValue={
                              form_template[6].questions[1].subQuestion[0]
                                .answer
                            }
                            {...register("remainCalmandAssessEmergency1", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("remainCalmandAssessEmergency1", {
                                    message: "This field is required",
                                  })
                                : setError("remainCalmandAssessEmergency1", {
                                    message: "",
                                  });
                              changeFormTemplateData([5, e.target.value, 1, 0]);
                            }}
                          >
                            {/* <option value="">Select</option> */}
                            {/* <option value={getUserName}>{getUserName}</option> */}
                            {form_template[6].questions[1].subQuestion[0].options.map(
                              (obj, k) => (
                                <option key={k} value={obj}>
                                  {obj}
                                </option>
                              )
                            )}
                          </Form.Select>
                          <Form.Label className="mt-2 text-danger">
                            {errors.remainCalmandAssessEmergency1 &&
                              errors.remainCalmandAssessEmergency1.message}
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row xs={12} className="ms-3">
                      <Col>
                        <Form.Group controlId="form-5-2-2" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {
                              form_template[6].questions[1].subQuestion[1]
                                .question
                            }
                            {form_template[6].questions[1].subQuestion[1]
                              .tooltip_message !== "" &&
                              form_template[6].questions[1].subQuestion[1]
                                .tooltip_message !== undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[6].questions[1]
                                          .subQuestion[1].tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Select
                            aria-label="5-2-2"
                            className="rounded-0"
                            defaultValue={
                              form_template[6].questions[1].subQuestion[1]
                                .answer
                            }
                            {...register("remainCalmandAssessEmergency2", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("remainCalmandAssessEmergency2", {
                                    message: "This field is required",
                                  })
                                : setError("remainCalmandAssessEmergency2", {
                                    message: "",
                                  });
                              changeFormTemplateData([5, e.target.value, 1, 1]);
                            }}
                          >
                            {/* <option value="">Select</option> */}
                            {/* <option value={getUserName}>{getUserName}</option> */}
                            {form_template[6].questions[1].subQuestion[1].options.map(
                              (obj, k) => (
                                <option key={k} value={obj}>
                                  {obj}
                                </option>
                              )
                            )}
                          </Form.Select>
                          <Form.Label className="mt-2 text-danger">
                            {errors.remainCalmandAssessEmergency2 &&
                              errors.remainCalmandAssessEmergency2.message}
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Row>

                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-5-3" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[6].questions[2].question}
                          {form_template[6].questions[2].tooltip_message !==
                            "" &&
                            form_template[6].questions[2].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[6].questions[2]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Row xs={12} className="ms-3">
                      <Col>
                        <Form.Group controlId="form-5-3-1" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {
                              form_template[6].questions[2].subQuestion[0]
                                .question
                            }
                            {form_template[6].questions[2].subQuestion[0]
                              .tooltip_message !== "" &&
                              form_template[6].questions[2].subQuestion[0]
                                .tooltip_message !== undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[6].questions[2]
                                          .subQuestion[0].tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Select
                            aria-label="5-3-1"
                            className="rounded-0"
                            defaultValue={
                              form_template[6].questions[2].subQuestion[0]
                                .answer
                            }
                            {...register("personWillStartRescue1", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("personWillStartRescue1", {
                                    message: "This field is required",
                                  })
                                : setError("personWillStartRescue1", {
                                    message: "",
                                  });
                              changeFormTemplateData([5, e.target.value, 2, 0]);
                            }}
                          >
                            {/* <option value="">Select</option> */}
                            {/* <option value={getUserName}>{getUserName}</option> */}
                            {form_template[6].questions[2].subQuestion[0].options.map(
                              (obj, k) => (
                                <option key={k} value={obj}>
                                  {obj}
                                </option>
                              )
                            )}
                          </Form.Select>
                          <Form.Label className="mt-2 text-danger">
                            {errors.personWillStartRescue1 &&
                              errors.personWillStartRescue1.message}
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row xs={12} className="ms-3">
                      <Col>
                        <Form.Group controlId="form-5-3-2" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {
                              form_template[6].questions[2].subQuestion[1]
                                .question
                            }
                            {form_template[6].questions[2].subQuestion[1]
                              .tooltip_message !== "" &&
                              form_template[6].questions[2].subQuestion[1]
                                .tooltip_message !== undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[6].questions[2]
                                          .subQuestion[1].tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Select
                            aria-label="5-3-2"
                            className="rounded-0"
                            defaultValue={
                              form_template[6].questions[2].subQuestion[1]
                                .answer
                            }
                            {...register("personWillStartRescue2", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("personWillStartRescue2", {
                                    message: "This field is required",
                                  })
                                : setError("personWillStartRescue2", {
                                    message: "",
                                  });
                              changeFormTemplateData([5, e.target.value, 2, 1]);
                            }}
                          >
                            {/* <option value="">Select</option> */}
                            {/* <option value={getUserName}>{getUserName}</option> */}
                            {form_template[6].questions[2].subQuestion[1].options.map(
                              (obj, k) => (
                                <option key={k} value={obj}>
                                  {obj}
                                </option>
                              )
                            )}
                          </Form.Select>
                          <Form.Label className="mt-2 text-danger">
                            {errors.personWillStartRescue2 &&
                              errors.personWillStartRescue2.message}
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Row>

                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-5-4" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[6].questions[3].question}
                          {form_template[6].questions[3].tooltip_message !==
                            "" &&
                            form_template[6].questions[3].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[6].questions[3]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Row xs={12} className="ms-3">
                      <Col>
                        <Form.Group controlId="form-5-4-1" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {
                              form_template[6].questions[3].subQuestion[0]
                                .question
                            }
                            {form_template[6].questions[3].subQuestion[0]
                              .tooltip_message !== "" &&
                              form_template[6].questions[3].subQuestion[0]
                                .tooltip_message !== undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[6].questions[3]
                                          .subQuestion[0].tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Select
                            aria-label="5-4-1"
                            className="rounded-0"
                            defaultValue={
                              form_template[6].questions[3].subQuestion[0]
                                .answer
                            }
                            {...register("onceVictimAndHero1", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("onceVictimAndHero1", {
                                    message: "This field is required",
                                  })
                                : setError("onceVictimAndHero1", {
                                    message: "",
                                  });
                              changeFormTemplateData([5, e.target.value, 3, 0]);
                            }}
                          >
                            {/* <option value="">Select</option> */}
                            {/* <option value={getUserName}>{getUserName}</option> */}
                            {form_template[6].questions[3].subQuestion[0].options.map(
                              (obj, k) => (
                                <option key={k} value={obj}>
                                  {obj}
                                </option>
                              )
                            )}
                          </Form.Select>
                          <Form.Label className="mt-2 text-danger">
                            {errors.onceVictimAndHero1 &&
                              errors.onceVictimAndHero1.message}
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row xs={12} className="ms-3">
                      <Col>
                        <Form.Group controlId="form-5-4-2" className="mb-3">
                          <Form.Label>
                            <span className="text-danger bold-font">*</span>
                            {
                              form_template[6].questions[3].subQuestion[1]
                                .question
                            }
                            {form_template[6].questions[3].subQuestion[1]
                              .tooltip_message !== "" &&
                              form_template[6].questions[3].subQuestion[1]
                                .tooltip_message !== undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[6].questions[3]
                                          .subQuestion[1].tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Select
                            aria-label="5-4-2"
                            className="rounded-0"
                            defaultValue={
                              form_template[6].questions[3].subQuestion[1]
                                .answer
                            }
                            {...register("onceVictimAndHero2", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("onceVictimAndHero2", {
                                    message: "This field is required",
                                  })
                                : setError("onceVictimAndHero2", {
                                    message: "",
                                  });
                              changeFormTemplateData([5, e.target.value, 3, 1]);
                            }}
                          >
                            {/* <option value="">Select</option> */}
                            {/* <option value={getUserName}>{getUserName}</option> */}
                            {form_template[6].questions[3].subQuestion[1].options.map(
                              (obj, k) => (
                                <option key={k} value={obj}>
                                  {obj}
                                </option>
                              )
                            )}
                          </Form.Select>
                          <Form.Label className="mt-2 text-danger">
                            {errors.onceVictimAndHero2 &&
                              errors.onceVictimAndHero2.message}
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-5-5" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[6].questions[4].question}
                          {form_template[6].questions[4].tooltip_message !==
                            "" &&
                            form_template[6].questions[4].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[6].questions[4]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        {/* <Form.Select aria-label="5-5" className="rounded-0"
                                            defaultValue={form_template[6].questions[4].answer}
                                            {...register('reportSafetyIncidentToS6', { required: true })}
                                            onChange={
                                                (e) => {
                                                    e.target.value === ''
                                                        ? setError('reportSafetyIncidentToS6', { message: "This field is required" })
                                                        : setError('reportSafetyIncidentToS6', { message: "" });
                                                    changeFormTemplateData([1, e.target.value, 4])
                                                }
                                            } >
                                            
                                            {form_template[6].questions[4].options.map(obj => <option value={obj}>{obj}</option>)}
                                        </Form.Select> */}
                        <Form.Control
                          className="rounded-0"
                          type="text"
                          value={form_template[6].questions[4].answer}
                          {...register("reportSafetyIncidentToS6", {
                            required: true,
                          })}
                          onChange={(e) => {
                            e.target.value === ""
                              ? setError("reportSafetyIncidentToS6", {
                                  message: "This field is required",
                                })
                              : setError("reportSafetyIncidentToS6", {
                                  message: "",
                                });
                            changeFormTemplateData([1, e.target.value, 4]);
                          }}
                        />
                        <Form.Label className="mt-2 text-danger">
                          {errors.reportSafetyIncidentToS6 &&
                            errors.reportSafetyIncidentToS6.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              ) : null}
              {form_template[getProceed].sectionId === "8" &&
              getProceed1 === 0 ? (
                <>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-6-1" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[7].questions[0].question}
                          {form_template[7].questions[0].tooltip_message !==
                            "" &&
                            form_template[7].questions[0].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[7].questions[0]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div key="inline-radio" className="mb-3">
                          <Form.Check
                            inline
                            label="Yes"
                            name="group6-1"
                            type="radio"
                            id="inline-radio-6-1"
                            checked={
                              form_template[7].questions[0].answer === "Yes"
                            }
                            onClick={() =>
                              changeFormTemplateData([1, "Yes", 0])
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group6-1"
                            type="radio"
                            id="inline-radio-6-2"
                            checked={
                              form_template[7].questions[0].answer === "No"
                            }
                            onClick={() => changeFormTemplateData([1, "No", 0])}
                          />
                        </div>

                        <div className="d-flex justify-content-end">
                          <ButtonGroup>
                            {wheather_radio.map((radio, idx) => (
                              <ToggleButton
                                key={idx}
                                id={`radio-${idx}`}
                                type="radio"
                                variant={
                                  idx % 2 ? "outline-danger" : "outline-danger"
                                }
                                name="radio"
                                value={radio.value}
                                checked={radioValue === radio.value}
                                onChange={(e) =>
                                  setRadioValue(e.currentTarget.value)
                                }
                                className="ps-5 pe-5"
                              >
                                {radio.name}
                              </ToggleButton>
                            ))}
                          </ButtonGroup>
                        </div>

                        <>
                          <div
                            className="wrapper-w shadow p-2 m-2  rounded mt-3"
                            style={{ background: "lavender" }}
                          >
                            {!_.isEmpty(getWeatherHours) &&
                              getWeatherHours.forecasts.map((obj) => (
                                <Col
                                  lg={1}
                                  md={1}
                                  className=" shadow p-1 m-1 bg-white rounded bd-highlight item-w"
                                >
                                  <div className="p-2 bd-highlight d-flex  justify-content-center">
                                    {formatAMPM(obj.date)}
                                  </div>
                                  <div className="p-2 bd-highlight d-flex  justify-content-center">
                                    <img
                                      style={{ width: "3em" }}
                                      className=""
                                      src={String(weatherIcon(obj.iconPhrase))}
                                      alt={obj.iconPhrase}
                                    />
                                  </div>
                                  <div className="p-1 bd-highlight d-flex  justify-content-center">
                                    {console.log(
                                      "WQOOOOOOOOOOOOOI",
                                      obj.temperature.value,
                                      wheather_radio[0].value,
                                      radioValue,
                                      wheather_radio[0].name,
                                      wheather_radio[1].name
                                    )}
                                    {obj.temperature.value}{" "}
                                    {wheather_radio[0].value === radioValue
                                      ? wheather_radio[0].name
                                      : wheather_radio[1].name}
                                  </div>
                                </Col>
                              ))}
                          </div>
                          <Row className="mt-4">
                            <Col>
                              <div>
                                <div className="d-flex justify-content-center">
                                  <img
                                    src={String(ic_temperature)}
                                    alt="img temperature"
                                    style={{ width: "64px" }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center">
                                  Temperature:{" "}
                                  {
                                    form_template[getProceed].questions[0]
                                      .weather_imperial.results[0].temperature
                                      .value
                                  }{" "}
                                  {wheather_radio[0].value === radioValue
                                    ? wheather_radio[0].name
                                    : wheather_radio[1].name}
                                </div>
                                <div className="d-flex justify-content-center">
                                  Feel:{" "}
                                  {
                                    form_template[getProceed].questions[0]
                                      .weather_imperial.results[0]
                                      .realFeelTemperature.value
                                  }{" "}
                                  {wheather_radio[0].value === radioValue
                                    ? wheather_radio[0].name
                                    : wheather_radio[1].name}
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div>
                                <div className="d-flex justify-content-center">
                                  <img
                                    src={String(ic_wind)}
                                    alt="img wind"
                                    style={{ width: "64px" }}
                                  />
                                </div>

                                <div className="d-flex justify-content-center">
                                  Wind:{" "}
                                  {
                                    form_template[getProceed].questions[0]
                                      .weather_imperial.results[0].wind.speed
                                      .value
                                  }{" "}
                                  {
                                    form_template[getProceed].questions[0]
                                      .weather_imperial.results[0].wind.speed
                                      .unit
                                  }{" "}
                                </div>
                                <div className="d-flex justify-content-center">
                                  Gust:{" "}
                                  {
                                    form_template[getProceed].questions[0]
                                      .weather_imperial.results[0].windGust
                                      .speed.value
                                  }{" "}
                                  {
                                    form_template[getProceed].questions[0]
                                      .weather_imperial.results[0].windGust
                                      .speed.unit
                                  }
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row className="mt-2">
                            <Col>
                              <div>
                                <div className="d-flex justify-content-center">
                                  <img
                                    src={String(ic_sky)}
                                    alt="img sky"
                                    style={{ width: "64px" }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center">
                                  Cloud Cover:{" "}
                                  {
                                    form_template[getProceed].questions[0]
                                      .weather_imperial.results[0].cloudCover
                                  }
                                  %
                                </div>
                                <div className="d-flex justify-content-center"></div>
                              </div>
                            </Col>
                            <Col>
                              <div>
                                <div className="d-flex justify-content-center">
                                  <img
                                    src={String(ic_visibility)}
                                    alt="img visibility"
                                    style={{ width: "64px" }}
                                  />
                                </div>

                                <div className="d-flex justify-content-center">
                                  Visibility:{" "}
                                  {
                                    form_template[getProceed].questions[0]
                                      .weather_imperial.results[0].visibility
                                      .value
                                  }{" "}
                                  {
                                    form_template[getProceed].questions[0]
                                      .weather_imperial.results[0].visibility
                                      .unit
                                  }
                                </div>
                                <div className="d-flex justify-content-center"></div>
                              </div>
                            </Col>
                          </Row>

                          <Row className="mt-2">
                            <Col>
                              <div>
                                <div className="d-flex justify-content-center">
                                  <img
                                    src={String(ic_humidity)}
                                    alt="img humidity"
                                    style={{ width: "64px" }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center">
                                  Humidity:{" "}
                                  {
                                    form_template[getProceed].questions[0]
                                      .weather_imperial.results[0]
                                      .relativeHumidity
                                  }
                                  %
                                </div>
                                <div className="d-flex justify-content-center">
                                  Dew Point:{" "}
                                  {
                                    form_template[getProceed].questions[0]
                                      .weather_imperial.results[0].dewPoint
                                      .value
                                  }{" "}
                                  {wheather_radio[0].value === radioValue
                                    ? wheather_radio[0].name
                                    : wheather_radio[1].name}
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div>
                                <div className="d-flex justify-content-center">
                                  <img
                                    src={String(ic_pressure)}
                                    alt="img pressure"
                                    style={{ width: "64px" }}
                                  />
                                </div>

                                <div className="d-flex justify-content-center">
                                  Pressure:{" "}
                                  {
                                    form_template[getProceed].questions[0]
                                      .weather_imperial.results[0].pressure
                                      .value
                                  }{" "}
                                  {
                                    form_template[getProceed].questions[0]
                                      .weather_imperial.results[0].pressure.unit
                                  }{" "}
                                </div>
                                <div className="d-flex justify-content-center"></div>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-2 mb-3">
                            <Col>
                              <div>
                                <div className="d-flex justify-content-center">
                                  <img
                                    src={String(ic_precipitation)}
                                    alt="img precipitation"
                                    style={{ width: "64px" }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center">
                                  Precipitation: 0%
                                </div>
                                <div className="d-flex justify-content-center"></div>
                              </div>
                            </Col>
                            <Col>
                              <div>
                                <div className="d-flex justify-content-center">
                                  <img
                                    src={String(ic_uv_index)}
                                    alt="img uv index"
                                    style={{ width: "64px" }}
                                  />
                                </div>

                                <div className="d-flex justify-content-center">
                                  UV Index:{" "}
                                  {
                                    form_template[getProceed].questions[0]
                                      .weather_imperial.results[0].uvIndex
                                  }{" "}
                                </div>
                                <div className="d-flex justify-content-center"></div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              ) : null}

              {form_template[getProceed].sectionId === "11" &&
              getProceed1 === 0 ? (
                <div className="HazardsDiv">
                  <Form.Group controlId="form-7-1" className="mb-3">
                    <Form.Label>
                      {form_template[8].questions[0].question}
                      {form_template[8].questions[0].tooltip_message !== "" &&
                        form_template[8].questions[0].tooltip_message !==
                          undefined && (
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                {form_template[8].questions[0].tooltip_message}
                              </Tooltip>
                            }
                          >
                            <span className="icon-info">i</span>
                          </OverlayTrigger>
                        )}
                    </Form.Label>
                    <MultiSelect
                      options={HCMOptions}
                      getOption={getHCMOptions}
                      setOption={setHCMOptions}
                    />
                  </Form.Group>
                  {HMCAllMultiSelect}
                  {getHCMOptions.map((obj, k) => {
                    if (obj._s_key === 9) {
                      return (
                        <div
                          className="addOtherHazards p-3"
                          id={"addOther" + k}
                        >
                          <Button size="sm" onClick={() => addHazards(k)}>
                            + Add Other
                          </Button>
                        </div>
                      );
                    }
                  })}
                </div>
              ) : null}
              {form_template[getProceed].sectionId === "12" &&
              getProceed1 === 0 ? (
                <>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-9-1" className="mb-3">
                        <Form.Label>
                          {form_template[9].questions[0].question}
                          {form_template[9].questions[0].tooltip_message !==
                            "" &&
                            form_template[9].questions[0].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[9].questions[0]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div key="inline-radio" className="mb-3">
                          <img
                            src={String(ic_ai)}
                            alt="ai-img"
                            className="me-2"
                            style={{ width: "32px" }}
                          />

                          <Form.Check
                            inline
                            label="Yes"
                            name="group9-0"
                            type="radio"
                            id="inline-radio-9-1"
                            checked={
                              form_template[9].questions[0].overridden === "Yes"
                                ? form_template[9].questions[0].overridden ===
                                  "Yes"
                                : form_template[9].questions[0].answer === "Yes"
                            }
                            onClick={() =>
                              changeFormTemplateData([26, "Yes", 0, 0])
                            }
                            disabled={
                              form_template[9].questions[0].confidence === ""
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group9-0"
                            type="radio"
                            id="inline-radio-9-2"
                            checked={
                              form_template[9].questions[0].overridden === "Yes"
                                ? form_template[9].questions[0].overridden ===
                                  "No"
                                : form_template[9].questions[0].answer === "No"
                            }
                            onClick={() =>
                              changeFormTemplateData([26, "No", 0, 0])
                            }
                          />
                        </div>
                        <Form.Control
                          className="rounded-0 mt-3 custom-file-input"
                          type="file"
                          {...register("FireExtinguiserAvailable", {
                            required: true,
                          })}
                          onChange={async (e) => {
                            // let fileSize = e.target.files[0]['size'];
                            let fileType = e.target.files[0]["type"];
                            let validImageTypes = [
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                            ];
                            const file = e.target.files[0];
                            if (file.size >= 4e6) {
                              const time = file.lastModified;

                              const image = await resizeFile(file);

                              if (!validImageTypes.includes(fileType)) {
                                setError("FireExtinguiserAvailable", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", 0, 0, "ai-hazard-control"],
                                  4,
                                  "0"
                                );
                              } else {
                                if (file.size >= 4e6) {
                                  getUploadImgUrls(
                                    [file, 0, 0],
                                    4,
                                    "0",
                                    "FireExtinguiserAvailable",
                                    true
                                  );
                                  getUploadImgUrls(
                                    [image, 0, 0, "ai-hazard-control"],
                                    4
                                  );
                                } else {
                                  getUploadImgUrls(
                                    [image, 0, 0, "ai-hazard-control"],
                                    4,
                                    "0",
                                    "FireExtinguiserAvailable",
                                    true
                                  );
                                }
                              }
                            } else {
                              const image = file;

                              if (!validImageTypes.includes(fileType)) {
                                setError("FireExtinguiserAvailable", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", 0, 0, "ai-hazard-control"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [image, 0, 0, "ai-hazard-control"],
                                  4,
                                  "0",
                                  "FireExtinguiserAvailable",
                                  true
                                );
                              }
                            }
                            e.target.value = null;
                          }}
                          // onChange={(e) => getUploadImgUrls(e.target.files[0], 1, '0')}
                        />
                        <Form.Label className="mt-2 text-warning">
                          {errors.FireExtinguiserAvailable &&
                            errors.FireExtinguiserAvailable.message}
                        </Form.Label>
                        <Form.Label className="mt-2 text-success">
                          {errors.FireExtinguiserAvailable &&
                            errors.FireExtinguiserAvailable.message1}
                        </Form.Label>
                        {form_template[9].questions[0].picture !== "" && (
                          <div className="p-1 m-1 imgDiv">
                            <div
                              className="removeImg"
                              onClick={() =>
                                changeFormTemplateData([
                                  34,
                                  "FireExtinguiserAvailable",
                                  0,
                                ])
                              }
                            >
                              x
                            </div>
                            <img
                              src={
                                getUrlWithQueryString(
                                  form_template[9].questions[0].picture
                                ) + IMG_TOKEN
                              }
                              alt="FireExtinguiserAvailable"
                              className="shadow-sm bg-light rounded img-size_preview"
                            />
                          </div>
                        )}
                        <Form.Label className="mt-2 mb-3">
                          <b>
                            {form_template[9].questions[0].picture !== "" &&
                              "1 Image Uploaded"}
                          </b>
                        </Form.Label>
                        {/* {
                                                            form_template[9].questions[0].confidence !== '' &&
                                                            <div>
                                                                <div>Confidence: {form_template[9].questions[0].confidence * 100}%</div>
                                                                <div ><ProgressBar variant="success"
                                                                    now={form_template[9].questions[0].confidence * 100}
                                                                    label={`${form_template[9].questions[0].confidence * 100}%`} /></div>
                                                            </div>
                                                        } */}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-9-2" className="mb-3">
                        <Form.Label>
                          {form_template[9].questions[1].question}
                          {form_template[9].questions[1].tooltip_message !==
                            "" &&
                            form_template[9].questions[1].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[9].questions[1]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>

                        <Form.Control
                          className="rounded-0 mt-3 custom-file-input"
                          type="file"
                          {...register("FireExtinguiserInspection", {
                            required: true,
                          })}
                          onChange={(e) => {
                            // let fileSize = e.target.files[0]['size'];
                            let fileType = e.target.files[0]["type"];
                            let validImageTypes = [
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                            ];
                            if (!validImageTypes.includes(fileType)) {
                              setError("FireExtinguiserInspection", {
                                message: "Only jpeg/png/gif image are allowed.",
                              });
                              getUploadImgUrls("", 1, "1");
                            } else {
                              setError("FireExtinguiserInspection", {
                                message: "",
                              });
                              // getUploadImgUrls(e.target.files[0], 1, '1')
                              getUploadImgUrls(
                                [e.target.files[0], 1, 0, "picNoSection"],
                                4,
                                "0"
                              );
                            }
                            e.target.value = null;
                          }}
                          // onChange={(e) => getUploadImgUrls(e.target.files[0], 1, '1')}
                        />
                        <Form.Label className="mt-2 text-danger">
                          {errors.FireExtinguiserInspection &&
                            errors.FireExtinguiserInspection.message}
                        </Form.Label>

                        {form_template[9].questions[1].picture !== "" && (
                          <div className="p-1 m-1 imgDiv">
                            <div
                              className="removeImg"
                              onClick={() =>
                                changeFormTemplateData([
                                  34,
                                  "FireExtinguiserInspection",
                                  1,
                                ])
                              }
                            >
                              x
                            </div>
                            <img
                              src={
                                getUrlWithQueryString(
                                  form_template[9].questions[1].picture
                                ) + IMG_TOKEN
                              }
                              alt="FireExtinguiserInspection"
                              className="shadow-sm bg-light rounded img-size_preview"
                            />
                          </div>
                        )}
                        <Form.Label className="mt-2 mb-3">
                          <b>
                            {form_template[9].questions[1].picture !== "" &&
                              "1 Image Uploaded"}
                          </b>
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-9-3" className="mb-3">
                        <Form.Label>
                          {form_template[9].questions[2].question}
                          {form_template[9].questions[2].tooltip_message !==
                            "" &&
                            form_template[9].questions[2].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[9].questions[2]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div key="inline-radio" className="mb-3">
                          <Form.Check
                            inline
                            label="Yes"
                            name="group9-3"
                            type="radio"
                            id="inline-radio-9-3-1"
                            checked={
                              form_template[9].questions[2].answer === "Yes"
                            }
                            onClick={() =>
                              changeFormTemplateData([1, "Yes", 2])
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group9-3"
                            type="radio"
                            id="inline-radio-9-3-2"
                            checked={
                              form_template[9].questions[2].answer === "No"
                            }
                            onClick={() => changeFormTemplateData([1, "No", 2])}
                          />
                        </div>
                        <Form.Control
                          className="rounded-0 mt-3 custom-file-input"
                          type="file"
                          {...register("firstAidKitAvail", { required: true })}
                          onChange={(e) => {
                            // let fileSize = e.target.files[0]['size'];
                            let fileType = e.target.files[0]["type"];
                            let validImageTypes = [
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                            ];
                            if (!validImageTypes.includes(fileType)) {
                              setError("firstAidKitAvail", {
                                message: "Only jpeg/png/gif image are allowed.",
                              });
                              getUploadImgUrls("", 1, "2");
                            } else {
                              setError("firstAidKitAvail", {
                                message: "",
                              });
                              // getUploadImgUrls(e.target.files[0], 1, '2')
                              getUploadImgUrls(
                                [e.target.files[0], 2, 0, "picNoSection"],
                                4,
                                "0"
                              );
                            }
                            e.target.value = null;
                          }}
                          // onChange={(e) => getUploadImgUrls(e.target.files[0], 1, '2')}
                        />
                        <Form.Label className="mt-2 text-danger">
                          {errors.firstAidKitAvail &&
                            errors.firstAidKitAvail.message}
                        </Form.Label>
                        {form_template[9].questions[2].picture !== "" && (
                          <div className="p-1 m-1 imgDiv">
                            <div
                              className="removeImg"
                              onClick={() =>
                                changeFormTemplateData([
                                  34,
                                  "firstAidKitAvail",
                                  2,
                                ])
                              }
                            >
                              x
                            </div>
                            <img
                              src={
                                getUrlWithQueryString(
                                  form_template[9].questions[2].picture
                                ) + IMG_TOKEN
                              }
                              alt="firstAidKitAvail"
                              className="shadow-sm bg-light rounded img-size_preview"
                            />
                          </div>
                        )}
                        <Form.Label className="mt-2 mb-3">
                          <b>
                            {form_template[9].questions[2].picture !== "" &&
                              "1 Image Uploaded"}
                          </b>
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-9-4" className="mb-3">
                        <Form.Label>
                          {form_template[9].questions[3].question}
                          {form_template[9].questions[3].tooltip_message !==
                            "" &&
                            form_template[9].questions[3].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[9].questions[3]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div key="inline-radio" className="mb-3">
                          <Form.Check
                            inline
                            label="Yes"
                            name="group9-4"
                            type="radio"
                            id="inline-radio-9-4-1"
                            checked={
                              form_template[9].questions[3].answer === "Yes"
                            }
                            onClick={() =>
                              changeFormTemplateData([1, "Yes", 3])
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group9-4"
                            type="radio"
                            id="inline-radio-9-4-2"
                            checked={
                              form_template[9].questions[3].answer === "No"
                            }
                            onClick={() => changeFormTemplateData([1, "No", 3])}
                          />
                        </div>
                        <Form.Control
                          className="rounded-0 mt-3 custom-file-input"
                          type="file"
                          {...register("workZone", { required: true })}
                          onChange={(e) => {
                            // let fileSize = e.target.files[0]['size'];
                            let fileType = e.target.files[0]["type"];
                            let validImageTypes = [
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                            ];
                            if (!validImageTypes.includes(fileType)) {
                              setError("workZone", {
                                message: "Only jpeg/png/gif image are allowed.",
                              });
                              getUploadImgUrls("", 1, "3");
                            } else {
                              setError("workZone", {
                                message: "",
                              });
                              // getUploadImgUrls(e.target.files[0], 1, '3')
                              getUploadImgUrls(
                                [e.target.files[0], 3, 0, "picNoSection"],
                                4,
                                "0"
                              );
                            }
                            e.target.value = null;
                          }}
                          //  onChange={(e) => getUploadImgUrls(e.target.files[0], 1, '3')}
                        />
                        <Form.Label className="mt-2 text-danger">
                          {errors.workZone && errors.workZone.message}
                        </Form.Label>
                        {form_template[9].questions[3].picture !== "" && (
                          <div className="p-1 m-1 imgDiv">
                            <div
                              className="removeImg"
                              onClick={() =>
                                changeFormTemplateData([
                                  34,
                                  "firstAidKitAvail",
                                  3,
                                ])
                              }
                            >
                              x
                            </div>
                            <img
                              src={
                                getUrlWithQueryString(
                                  form_template[9].questions[3].picture
                                ) + IMG_TOKEN
                              }
                              alt="workZone"
                              className="shadow-sm bg-light rounded img-size_preview"
                            />
                          </div>
                        )}
                        <Form.Label className="mt-2 mb-3">
                          <b>
                            {form_template[9].questions[3].picture !== "" &&
                              "1 Image Uploaded"}
                          </b>
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              ) : null}
              {/* test */}
              {form_template[getProceed].sectionId === "6" &&
              getProceed1 === 0 ? (
                <>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-10-0" className="mb-3">
                        <Form.Label>
                          {
                            form_template[10].section[getCrewMemberProceed.crew]
                              .questions[0].question
                          }
                          {form_template[10].section[getCrewMemberProceed.crew]
                            .questions[0].tooltip_message !== "" &&
                            form_template[10].section[getCrewMemberProceed.crew]
                              .questions[0].tooltip_message !== undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[10].section[
                                        getCrewMemberProceed.crew
                                      ].questions[0].tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Control
                          className="rounded-0 mt-3"
                          type="number"
                          value={
                            form_template[10].section[getCrewMemberProceed.crew]
                              .questions[0].answer
                          }
                          onChange={(e) =>
                            changeFormTemplateData([
                              7,
                              e.target.value,
                              getCrewMemberProceed.crew,
                              0,
                            ])
                          }
                        />
                        <Form.Control
                          className="rounded-0 mt-3 custom-file-input"
                          type="file"
                          {...register("RFMonitorCount", { required: true })}
                          onChange={(e) => {
                            // let fileSize = e.target.files[0]['size'];
                            let fileType = e.target.files[0]["type"];
                            let validImageTypes = [
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                            ];
                            if (!validImageTypes.includes(fileType)) {
                              setError("RFMonitorCount", {
                                message: "Only jpeg/png/gif image are allowed.",
                              });
                              getUploadImgUrls(
                                ["", getCrewMemberProceed.crew, 0, ""],
                                4,
                                "0"
                              );
                            } else {
                              setError("RFMonitorCount", {
                                message: "",
                              });
                              getUploadImgUrls(
                                [
                                  e.target.files[0],
                                  getCrewMemberProceed.crew,
                                  0,
                                  "",
                                ],
                                4,
                                "0"
                              );
                            }
                            e.target.value = null;
                          }}
                        />
                        <Form.Label className="mt-2 text-danger">
                          {errors.RFMonitorCount &&
                            errors.RFMonitorCount.message}
                        </Form.Label>
                        {form_template[10].section[getCrewMemberProceed.crew]
                          .questions[0].picture !== "" &&
                        typeof form_template[10].section[
                          getCrewMemberProceed.crew
                        ].questions[0].picture !== "undefined" ? (
                          <div className="p-1 m-1 imgDiv">
                            <div
                              className="removeImg"
                              onClick={() =>
                                changeFormTemplateData([
                                  32,
                                  "RFMonitorCount",
                                  getCrewMemberProceed.crew,
                                  0,
                                ])
                              }
                            >
                              x
                            </div>
                            <img
                              src={
                                getUrlWithQueryString(
                                  form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[0].picture
                                ) + IMG_TOKEN
                              }
                              alt="RFMonitorCount"
                              className="shadow-sm bg-light rounded img-size_preview"
                            />
                          </div>
                        ) : null}
                        <Form.Label className="mt-2 mb-3">
                          <b>
                            {form_template[10].section[
                              getCrewMemberProceed.crew
                            ].questions[0].picture !== "" &&
                            typeof form_template[10].section[
                              getCrewMemberProceed.crew
                            ].questions[0].picture !== "undefined"
                              ? "1 Image Uploaded"
                              : null}
                          </b>
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-10-1" className="mb-3">
                        <Form.Label>
                          {
                            form_template[10].section[getCrewMemberProceed.crew]
                              .questions[1].question
                          }
                          {form_template[10].section[getCrewMemberProceed.crew]
                            .questions[1].tooltip_message !== "" &&
                            form_template[10].section[getCrewMemberProceed.crew]
                              .questions[1].tooltip_message !== undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[10].section[
                                        getCrewMemberProceed.crew
                                      ].questions[1].tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Control
                          className="rounded-0 custom-file-input"
                          type="file"
                          {...register("RFMonitorCalibration", {
                            required: true,
                          })}
                          onChange={(e) => {
                            // let fileSize = e.target.files[0]['size'];
                            let fileType = e.target.files[0]["type"];
                            let validImageTypes = [
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                            ];
                            if (!validImageTypes.includes(fileType)) {
                              setError("RFMonitorCalibration", {
                                message: "Only jpeg/png/gif image are allowed.",
                              });
                              getUploadImgUrls(
                                ["", getCrewMemberProceed.crew, 1, ""],
                                4,
                                "0"
                              );
                            } else {
                              setError("RFMonitorCalibration", {
                                message: "",
                              });
                              getUploadImgUrls(
                                [
                                  e.target.files[0],
                                  getCrewMemberProceed.crew,
                                  1,
                                  "",
                                ],
                                4,
                                "0"
                              );
                            }
                            e.target.value = null;
                          }}
                          // onChange={(e) => getUploadImgUrls([e.target.files[0], 0, 1, ''], 4, '0')}
                        />
                        <Form.Label className="mt-2 text-danger">
                          {errors.RFMonitorCalibration &&
                            errors.RFMonitorCalibration.message}
                        </Form.Label>
                        {form_template[10].section[getCrewMemberProceed.crew]
                          .questions[1].picture !== "" &&
                        typeof form_template[10].section[
                          getCrewMemberProceed.crew
                        ].questions[1].picture !== "undefined" ? (
                          <div className="p-1 m-1 imgDiv">
                            <div
                              className="removeImg"
                              onClick={() =>
                                changeFormTemplateData([
                                  32,
                                  "RFMonitorCalibration",
                                  getCrewMemberProceed.crew,
                                  1,
                                ])
                              }
                            >
                              x
                            </div>
                            <img
                              src={
                                getUrlWithQueryString(
                                  form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[1].picture
                                ) + IMG_TOKEN
                              }
                              alt="RFMonitorCalibration"
                              className="shadow-sm bg-light rounded img-size_preview"
                            />
                          </div>
                        ) : null}
                        <Form.Label className="mt-2 mb-3">
                          <b>
                            {form_template[10].section[
                              getCrewMemberProceed.crew
                            ].questions[1].picture !== "" &&
                            typeof form_template[10].section[
                              getCrewMemberProceed.crew
                            ].questions[1].picture !== "undefined"
                              ? "1 Image Uploaded"
                              : null}
                          </b>
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-10-2" className="mb-3">
                        <Form.Label>
                          Please upload an image of Individual Wearing the
                          following PPE - Hard Hat, Work Gloves, Work Boots,
                          Climbing harness
                        </Form.Label>
                        <Form.Control
                          className="rounded-0 custom-file-input "
                          type="file"
                          {...register("PPEHardHat1", { required: true })}
                          onChange={async (e) => {
                            // let fileSize = e.target.files[0]['size'];
                            let fileType = e.target.files[0]["type"];
                            let validImageTypes = [
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                            ];

                            const file = e.target.files[0];
                            if (file.size >= 4e6) {
                              const time = file.lastModified;

                              const image = await resizeFile(file);

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEHardHat1", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 2, "ai-all"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [file, getCrewMemberProceed.crew],
                                  4,
                                  "0",
                                  "PPEHardHat1",
                                  true,
                                  [
                                    {
                                      sectionId: 2,
                                      sectionFieldType: "PPEHardHat",
                                    },
                                    {
                                      sectionId: 3,
                                      sectionFieldType: "PPEWorkGloves",
                                    },
                                    {
                                      sectionId: 4,
                                      sectionFieldType: "PPEWorkBoots",
                                    },
                                    {
                                      sectionId: 5,
                                      sectionFieldType:
                                        "PPEClimberWearingClimbingHarness",
                                    },
                                    {
                                      sectionId: 6,
                                      sectionFieldType: "PPESafetyVest",
                                    },
                                  ]
                                );
                                getUploadImgUrls(
                                  [
                                    image,
                                    getCrewMemberProceed.crew,
                                    2,
                                    "ai-all",
                                  ],
                                  4
                                );
                              }
                            } else {
                              const image = file;

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEHardHat1", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 2, "ai-all"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [
                                    image,
                                    getCrewMemberProceed.crew,
                                    2,
                                    "ai-all",
                                  ],
                                  4,
                                  "0",
                                  "PPEHardHat1",
                                  true,
                                  [
                                    {
                                      sectionId: 2,
                                      sectionFieldType: "PPEHardHat",
                                    },
                                    {
                                      sectionId: 3,
                                      sectionFieldType: "PPEWorkGloves",
                                    },
                                    {
                                      sectionId: 4,
                                      sectionFieldType: "PPEWorkBoots",
                                    },
                                    {
                                      sectionId: 5,
                                      sectionFieldType:
                                        "PPEClimberWearingClimbingHarness",
                                    },
                                    {
                                      sectionId: 6,
                                      sectionFieldType: "PPESafetyVest",
                                    },
                                  ]
                                );
                                getUploadImgUrls(
                                  [
                                    image,
                                    getCrewMemberProceed.crew,
                                    2,
                                    "ai-all",
                                  ],
                                  4
                                );
                              }
                            }
                            e.target.value = null;
                          }}
                        />

                        <Form.Label className="mt-2 text-danger">
                          {errors.PPEHardHat1 && errors.PPEHardHat1.message}
                        </Form.Label>
                        <br />
                        <Form.Label className="mt-3">
                          {getClimberList[getCrewMemberProceed.crew] ===
                            "Climber" && (
                            <span className="text-danger bold-font">*</span>
                          )}
                          {
                            form_template[10].section[getCrewMemberProceed.crew]
                              .questions[2].question
                          }
                          {form_template[10].section[getCrewMemberProceed.crew]
                            .questions[2].tooltip_message !== "" &&
                            form_template[10].section[getCrewMemberProceed.crew]
                              .questions[2].tooltip_message !== undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[10].section[
                                        getCrewMemberProceed.crew
                                      ].questions[2].tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>

                        <Form.Control
                          className="rounded-0 custom-file-input"
                          type="file"
                          {...register("PPEHardHat", { required: true })}
                          onChange={async (e) => {
                            // let fileSize = e.target.files[0]['size'];
                            let fileType = e.target.files[0]["type"];
                            let validImageTypes = [
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                            ];

                            const file = e.target.files[0];
                            if (file.size >= 4e6) {
                              const time = file.lastModified;

                              const image = await resizeFile(file);

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEHardHat", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 2, "ai"],
                                  4,
                                  "0"
                                );
                              } else {
                                if (file.size >= 4e6) {
                                  getUploadImgUrls(
                                    [file, getCrewMemberProceed.crew, 2],
                                    4,
                                    "0",
                                    "PPEHardHat",
                                    true
                                  );
                                  getUploadImgUrls(
                                    [image, getCrewMemberProceed.crew, 2, "ai"],
                                    4
                                  );
                                } else {
                                  getUploadImgUrls(
                                    [image, getCrewMemberProceed.crew, 2, "ai"],
                                    4,
                                    "0",
                                    "PPEHardHat",
                                    true
                                  );
                                }
                              }
                            } else {
                              const image = file;

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEHardHat", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 2, "ai"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [image, getCrewMemberProceed.crew, 2, "ai"],
                                  4,
                                  "0",
                                  "PPEHardHat",
                                  true
                                );
                              }
                            }
                            e.target.value = null;
                          }}
                          // onChange={(e) => getUploadImgUrls([e.target.files[0], 0, 2, 'ai'], 4, '0')}
                        />
                        {form_template[10].section[getCrewMemberProceed.crew]
                          .questions[2].confidence !== "" && (
                          <div className="mt-3 mb-1 text-warning">
                            {errors.PPEHardHat && errors.PPEHardHat.message}
                          </div>
                        )}
                        {form_template[10].section[getCrewMemberProceed.crew]
                          .questions[2].confidence !== "" && (
                          <div className="mt-3 mb-1 text-success">
                            {errors.PPEHardHat && errors.PPEHardHat.message1}
                          </div>
                        )}
                        {form_template[10].section[getCrewMemberProceed.crew]
                          .questions[2].picture !== "" &&
                        typeof form_template[10].section[
                          getCrewMemberProceed.crew
                        ].questions[2].picture !== "undefined" ? (
                          <div className="p-1 m-1 imgDiv">
                            <div
                              className="removeImg"
                              onClick={() =>
                                changeFormTemplateData([
                                  32,
                                  "PPEHardHat",
                                  getCrewMemberProceed.crew,
                                  2,
                                ])
                              }
                            >
                              x
                            </div>
                            <img
                              src={
                                getUrlWithQueryString(
                                  form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[2].picture
                                ) + IMG_TOKEN
                              }
                              alt="PPEHardHat"
                              className="shadow-sm bg-light rounded img-size_preview"
                            />
                          </div>
                        ) : null}
                        <div key="inline-radio" className="mb-3 mt-3">
                          <img
                            src={String(ic_ai)}
                            alt="ai-img"
                            className="me-2"
                            style={{ width: "32px" }}
                          />

                          <Form.Check
                            inline
                            label="Yes"
                            name="group10-2"
                            type="radio"
                            id="inline-radio-10-2-1"
                            checked={
                              form_template[10].section[
                                getCrewMemberProceed.crew
                              ].questions[2].overridden === "Yes"
                                ? form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[2].overridden === "Yes"
                                : form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[2].answer === "Yes"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "Yes",
                                getCrewMemberProceed.crew,
                                2,
                              ])
                            }
                            disabled={
                              form_template[10].section[
                                getCrewMemberProceed.crew
                              ].questions[2].confidence === ""
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group10-2"
                            type="radio"
                            id="inline-radio-10-2-2"
                            checked={
                              form_template[10].section[
                                getCrewMemberProceed.crew
                              ].questions[2].overridden === "Yes"
                                ? form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[2].overridden === "No"
                                : form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[2].answer === "No"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "No",
                                getCrewMemberProceed.crew,
                                2,
                              ])
                            }
                          />
                        </div>
                        {/* {
                                                            form_template[10].section[getCrewMemberProceed.crew].questions[2].confidence !== '' &&
                                                            <div>
                                                                <div>Confidence: {form_template[10].section[getCrewMemberProceed.crew].questions[2].confidence * 100}%</div>
                                                                <div ><ProgressBar variant="success"
                                                                    now={form_template[10].section[getCrewMemberProceed.crew].questions[2].confidence * 100}
                                                                    label={`${form_template[10].section[getCrewMemberProceed.crew].questions[2].confidence * 100}%`} /></div>
                                                            </div>
                                                        } */}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-10-3" className="mb-3">
                        <Form.Label>
                          {getClimberList[getCrewMemberProceed.crew] ===
                            "Climber" && (
                            <span className="text-danger bold-font">*</span>
                          )}
                          {
                            form_template[10].section[getCrewMemberProceed.crew]
                              .questions[3].question
                          }
                          {form_template[10].section[getCrewMemberProceed.crew]
                            .questions[3].tooltip_message !== "" &&
                            form_template[10].section[getCrewMemberProceed.crew]
                              .questions[3].tooltip_message !== undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[10].section[
                                        getCrewMemberProceed.crew
                                      ].questions[3].tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Control
                          className="rounded-0 custom-file-input"
                          type="file"
                          {...register("PPEWorkGloves", { required: true })}
                          onChange={async (e) => {
                            // let fileSize = e.target.files[0]['size'];
                            let fileType = e.target.files[0]["type"];
                            let validImageTypes = [
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                            ];
                            const file = e.target.files[0];
                            if (file.size >= 4e6) {
                              const time = file.lastModified;

                              const image = await resizeFile(file);

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEWorkGloves", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 3, "ai"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [file, getCrewMemberProceed.crew, 3, "ai"],
                                  4,
                                  "0",
                                  "PPEWorkGloves",
                                  true
                                );
                                getUploadImgUrls(
                                  [image, getCrewMemberProceed.crew, 3, "ai"],
                                  4
                                );
                              }
                            } else {
                              const image = file;

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEWorkGloves", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 3, "ai"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [image, getCrewMemberProceed.crew, 3, "ai"],
                                  4,
                                  "0",
                                  "PPEWorkGloves",
                                  true
                                );
                              }
                            }
                            e.target.value = null;
                          }}

                          // onChange={(e) => getUploadImgUrls([e.target.files[0], 0, 3, 'ai'], 4, '0')}
                        />

                        {form_template[10].section[getCrewMemberProceed.crew]
                          .questions[3].confidence !== "" && (
                          <div className="mt-3 mb-1 text-warning">
                            {errors.PPEWorkGloves &&
                              errors.PPEWorkGloves.message}
                          </div>
                        )}
                        {form_template[10].section[getCrewMemberProceed.crew]
                          .questions[3].confidence !== "" && (
                          <div className="mt-3 mb-1 text-success">
                            {errors.PPEWorkGloves &&
                              errors.PPEWorkGloves.message1}
                          </div>
                        )}

                        {form_template[10].section[getCrewMemberProceed.crew]
                          .questions[3].picture !== "" &&
                        typeof form_template[10].section[
                          getCrewMemberProceed.crew
                        ].questions[3].picture !== "undefined" ? (
                          <div className="p-1 m-1 imgDiv">
                            <div
                              className="removeImg"
                              onClick={() =>
                                changeFormTemplateData([
                                  32,
                                  "PPEWorkGloves",
                                  getCrewMemberProceed.crew,
                                  3,
                                  "hand",
                                ])
                              }
                            >
                              x
                            </div>
                            <img
                              src={
                                getUrlWithQueryString(
                                  form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[3].picture
                                ) + IMG_TOKEN
                              }
                              alt="PPEWorkGloves"
                              className="shadow-sm bg-light rounded img-size_preview"
                            />
                          </div>
                        ) : null}
                        <div key="inline-radio" className="mb-3 mt-3">
                          <img
                            src={String(ic_ai)}
                            alt="ai-img"
                            className="me-2"
                            style={{ width: "36px" }}
                          />
                          <Form.Check
                            inline
                            label="Yes"
                            name="group10-3"
                            type="radio"
                            id="inline-radio-10-3-1"
                            // checked={form_template[10].section[0].questions[3].answer === 'Yes'}
                            checked={
                              form_template[10].section[
                                getCrewMemberProceed.crew
                              ].questions[3].overridden === "Yes"
                                ? form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[3].overridden === "Yes"
                                : form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[3].answer === "Yes"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "Yes",
                                getCrewMemberProceed.crew,
                                3,
                              ])
                            }
                            disabled={
                              form_template[10].section[
                                getCrewMemberProceed.crew
                              ].questions[3].confidence === ""
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group10-3"
                            type="radio"
                            id="inline-radio-10-3-2"
                            // checked={form_template[10].section[0].questions[3].answer === 'No'}
                            checked={
                              form_template[10].section[
                                getCrewMemberProceed.crew
                              ].questions[3].overridden === "Yes"
                                ? form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[3].overridden === "No"
                                : form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[3].answer === "No"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "No",
                                getCrewMemberProceed.crew,
                                3,
                              ])
                            }
                          />
                        </div>
                        {/* {
                                                            form_template[10].section[getCrewMemberProceed.crew].questions[3].confidence !== '' &&
                                                            <div>
                                                                <div>Confidence: {form_template[10].section[getCrewMemberProceed.crew].questions[3].confidence * 100}%</div>
                                                                <div ><ProgressBar variant="success"
                                                                    now={form_template[10].section[getCrewMemberProceed.crew].questions[3].confidence * 100}
                                                                    label={`${form_template[10].section[getCrewMemberProceed.crew].questions[3].confidence * 100}%`} /></div>
                                                            </div>
                                                        } */}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-10-4" className="mb-3">
                        <Form.Label>
                          {getClimberList[getCrewMemberProceed.crew] ===
                            "Climber" && (
                            <span className="text-danger bold-font">*</span>
                          )}
                          {
                            form_template[10].section[getCrewMemberProceed.crew]
                              .questions[4].question
                          }
                          {form_template[10].section[getCrewMemberProceed.crew]
                            .questions[4].tooltip_message !== "" &&
                            form_template[10].section[getCrewMemberProceed.crew]
                              .questions[4].tooltip_message !== undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[10].section[
                                        getCrewMemberProceed.crew
                                      ].questions[4].tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Control
                          className="rounded-0 custom-file-input"
                          type="file"
                          {...register("PPEWorkBoots", { required: true })}
                          onChange={async (e) => {
                            // let fileSize = e.target.files[0]['size'];
                            let fileType = e.target.files[0]["type"];
                            let validImageTypes = [
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                            ];
                            const file = e.target.files[0];
                            if (file.size >= 4e6) {
                              const time = file.lastModified;

                              const image = await resizeFile(file);

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEWorkBoots", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 4, "ai"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [file, getCrewMemberProceed.crew, 4],
                                  4,
                                  "0",
                                  "PPEWorkBoots",
                                  true
                                );
                                getUploadImgUrls(
                                  [image, getCrewMemberProceed.crew, 4, "ai"],
                                  4
                                );
                              }
                            } else {
                              const image = file;

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEWorkBoots", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 4, "ai"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [image, getCrewMemberProceed.crew, 4, "ai"],
                                  4,
                                  "0",
                                  "PPEWorkBoots",
                                  true
                                );
                              }
                            }
                            e.target.value = null;
                          }}
                          // onChange={(e) => getUploadImgUrls([e.target.files[0], 0, 4, 'ai'], 4, '0')}
                        />
                        {form_template[10].section[getCrewMemberProceed.crew]
                          .questions[4].confidence !== "" && (
                          <div className="mt-3 mb-1 text-warning">
                            {errors.PPEWorkBoots && errors.PPEWorkBoots.message}
                          </div>
                        )}
                        {form_template[10].section[getCrewMemberProceed.crew]
                          .questions[4].confidence !== "" && (
                          <div className="mt-3 mb-1 text-success">
                            {errors.PPEWorkBoots &&
                              errors.PPEWorkBoots.message1}
                          </div>
                        )}
                        {form_template[10].section[getCrewMemberProceed.crew]
                          .questions[4].picture !== "" &&
                        typeof form_template[10].section[
                          getCrewMemberProceed.crew
                        ].questions[4].picture !== "undefined" ? (
                          <div className="p-1 m-1 imgDiv">
                            <div
                              className="removeImg"
                              onClick={() =>
                                changeFormTemplateData([
                                  32,
                                  "PPEWorkBoots",
                                  getCrewMemberProceed.crew,
                                  4,
                                ])
                              }
                            >
                              x
                            </div>
                            <img
                              src={
                                getUrlWithQueryString(
                                  form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[4].picture
                                ) + IMG_TOKEN
                              }
                              alt="PPEWorkBoots"
                              className="shadow-sm bg-light rounded img-size_preview"
                            />
                          </div>
                        ) : null}
                        <div key="inline-radio" className="mb-3 mt-1">
                          <img
                            src={String(ic_ai)}
                            alt="ai-img"
                            className="me-2"
                            style={{ width: "36px" }}
                          />
                          <Form.Check
                            inline
                            label="Yes"
                            name="group10-4"
                            type="radio"
                            id="inline-radio-10-4-1"
                            // checked={form_template[10].section[0].questions[4].answer === 'Yes'}
                            checked={
                              form_template[10].section[
                                getCrewMemberProceed.crew
                              ].questions[4].overridden === "Yes"
                                ? form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[4].overridden === "Yes"
                                : form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[4].answer === "Yes"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "Yes",
                                getCrewMemberProceed.crew,
                                4,
                              ])
                            }
                            disabled={
                              form_template[10].section[
                                getCrewMemberProceed.crew
                              ].questions[4].confidence === ""
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group10-4"
                            type="radio"
                            id="inline-radio-10-4-2"
                            // checked={form_template[10].section[0].questions[4].answer === 'No'}
                            checked={
                              form_template[10].section[
                                getCrewMemberProceed.crew
                              ].questions[4].overridden === "Yes"
                                ? form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[4].overridden === "No"
                                : form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[4].answer === "No"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "No",
                                getCrewMemberProceed.crew,
                                4,
                              ])
                            }
                          />
                        </div>
                        {/* {
                                                            form_template[10].section[getCrewMemberProceed.crew].questions[4].confidence !== '' &&
                                                            <div>
                                                                <div>Confidence: {form_template[10].section[getCrewMemberProceed.crew].questions[4].confidence * 100}%</div>
                                                                <div ><ProgressBar variant="success"
                                                                    now={form_template[10].section[getCrewMemberProceed.crew].questions[4].confidence * 100}
                                                                    label={`${form_template[10].section[getCrewMemberProceed.crew].questions[4].confidence * 100}%`} /></div>
                                                            </div>
                                                        } */}
                      </Form.Group>
                    </Col>
                  </Row>

                  {form_template[10].section[getCrewMemberProceed.crew]
                    .questions[5].visibility && (
                    <Row xs={12}>
                      <Col>
                        <Form.Group controlId="form-10-5" className="mb-3">
                          <Form.Label>
                            {getClimberList[getCrewMemberProceed.crew] ===
                              "Climber" && (
                              <span className="text-danger bold-font">*</span>
                            )}
                            {
                              form_template[10].section[
                                getCrewMemberProceed.crew
                              ].questions[5].question
                            }
                            {form_template[10].section[
                              getCrewMemberProceed.crew
                            ].questions[5].tooltip_message !== "" &&
                              form_template[10].section[
                                getCrewMemberProceed.crew
                              ].questions[5].tooltip_message !== undefined && (
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      {
                                        form_template[10].section[
                                          getCrewMemberProceed.crew
                                        ].questions[5].tooltip_message
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon-info">i</span>
                                </OverlayTrigger>
                              )}
                          </Form.Label>
                          <Form.Control
                            className="rounded-0 custom-file-input"
                            type="file"
                            {...register("PPESafetyVest", { required: true })}
                            onChange={async (e) => {
                              // let fileSize = e.target.files[0]['size'];
                              let fileType = e.target.files[0]["type"];
                              let validImageTypes = [
                                "image/gif",
                                "image/jpeg",
                                "image/png",
                              ];
                              const file = e.target.files[0];
                              if (file.size >= 4e6) {
                                const time = file.lastModified;

                                const image = await resizeFile(file);

                                if (!validImageTypes.includes(fileType)) {
                                  setError("PPESafetyVest", {
                                    message:
                                      "Only jpeg/png/gif image are allowed.",
                                  });
                                  getUploadImgUrls(
                                    ["", getCrewMemberProceed.crew, 5, "ai"],
                                    4,
                                    "0"
                                  );
                                } else {
                                  getUploadImgUrls(
                                    [file, getCrewMemberProceed.crew, 5],
                                    4,
                                    "0",
                                    "PPESafetyVest",
                                    true
                                  );
                                  getUploadImgUrls(
                                    [image, getCrewMemberProceed.crew, 5, "ai"],
                                    4
                                  );
                                }
                              } else {
                                const image = file;

                                if (!validImageTypes.includes(fileType)) {
                                  setError("PPESafetyVest", {
                                    message:
                                      "Only jpeg/png/gif image are allowed.",
                                  });
                                  getUploadImgUrls(
                                    ["", getCrewMemberProceed.crew, 5, "ai"],
                                    4,
                                    "0"
                                  );
                                } else {
                                  getUploadImgUrls(
                                    [image, getCrewMemberProceed.crew, 5, "ai"],
                                    4,
                                    "0",
                                    "PPESafetyVest",
                                    true
                                  );
                                }
                              }
                              e.target.value = null;
                            }}
                            // onChange={(e) => getUploadImgUrls([e.target.files[0], 0, 5, 'ai'], 4, '0')}
                          />
                          {form_template[10].section[getCrewMemberProceed.crew]
                            .questions[5].confidence !== "" && (
                            <div className="mt-3 mb-1 text-warning">
                              {errors.PPESafetyVest &&
                                errors.PPESafetyVest.message}
                            </div>
                          )}
                          {form_template[10].section[getCrewMemberProceed.crew]
                            .questions[5].confidence !== "" && (
                            <div className="mt-3 mb-1 text-success">
                              {errors.PPESafetyVest &&
                                errors.PPESafetyVest.message1}
                            </div>
                          )}
                          {form_template[10].section[getCrewMemberProceed.crew]
                            .questions[5].picture !== "" &&
                          typeof form_template[10].section[
                            getCrewMemberProceed.crew
                          ].questions[5].picture !== "undefined" ? (
                            <div className="p-1 m-1 imgDiv">
                              <div
                                className="removeImg"
                                onClick={() =>
                                  changeFormTemplateData([
                                    32,
                                    "PPESafetyVest",
                                    getCrewMemberProceed.crew,
                                    5,
                                    "t-shirt",
                                  ])
                                }
                              >
                                x
                              </div>
                              <img
                                src={
                                  getUrlWithQueryString(
                                    form_template[10].section[
                                      getCrewMemberProceed.crew
                                    ].questions[5].picture
                                  ) + IMG_TOKEN
                                }
                                alt="PPESafetyVest"
                                className="shadow-sm bg-light rounded img-size_preview"
                              />
                            </div>
                          ) : null}
                          <div key="inline-radio" className="mb-3 mt-1">
                            <img
                              src={String(ic_ai)}
                              alt="ai-img"
                              className="me-2"
                              style={{ width: "36px" }}
                            />
                            <Form.Check
                              inline
                              label="Yes"
                              name="group10-5"
                              type="radio"
                              id="inline-radio-10-5-1"
                              // checked={form_template[10].section[0].questions[5].answer === 'Yes'}
                              checked={
                                form_template[10].section[
                                  getCrewMemberProceed.crew
                                ].questions[5].overridden === "Yes"
                                  ? form_template[10].section[
                                      getCrewMemberProceed.crew
                                    ].questions[5].overridden === "Yes"
                                  : form_template[10].section[
                                      getCrewMemberProceed.crew
                                    ].questions[5].answer === "Yes"
                              }
                              onClick={() =>
                                changeFormTemplateData([
                                  7,
                                  "Yes",
                                  getCrewMemberProceed.crew,
                                  5,
                                ])
                              }
                              disabled={
                                form_template[10].section[
                                  getCrewMemberProceed.crew
                                ].questions[5].confidence === ""
                              }
                            />
                            <Form.Check
                              inline
                              label="No"
                              name="group10-5"
                              type="radio"
                              id="inline-radio-10-5-2"
                              // checked={form_template[10].section[0].questions[5].answer === 'No'}
                              checked={
                                form_template[10].section[
                                  getCrewMemberProceed.crew
                                ].questions[5].overridden === "Yes"
                                  ? form_template[10].section[
                                      getCrewMemberProceed.crew
                                    ].questions[5].overridden === "No"
                                  : form_template[10].section[
                                      getCrewMemberProceed.crew
                                    ].questions[5].answer === "No"
                              }
                              onClick={() =>
                                changeFormTemplateData([
                                  7,
                                  "No",
                                  getCrewMemberProceed.crew,
                                  5,
                                ])
                              }
                            />
                          </div>
                          {/* {
                                                            form_template[10].section[getCrewMemberProceed.crew].questions[5].confidence !== '' &&
                                                            <div>
                                                                <div>Confidence: {form_template[10].section[getCrewMemberProceed.crew].questions[5].confidence * 100}%</div>
                                                                <div ><ProgressBar variant="success"
                                                                    now={form_template[10].section[getCrewMemberProceed.crew].questions[5].confidence * 100}
                                                                    label={`${form_template[10].section[getCrewMemberProceed.crew].questions[5].confidence * 100}%`} /></div>
                                                            </div>
                                                        } */}
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-10-6" className="mb-3">
                        <Form.Label>
                          {getClimberList[getCrewMemberProceed.crew] ===
                            "Climber" && (
                            <span className="text-danger bold-font">*</span>
                          )}
                          {
                            form_template[10].section[getCrewMemberProceed.crew]
                              .questions[6].question
                          }
                          {form_template[10].section[getCrewMemberProceed.crew]
                            .questions[6].tooltip_message !== "" &&
                            form_template[10].section[getCrewMemberProceed.crew]
                              .questions[6].tooltip_message !== undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[10].section[
                                        getCrewMemberProceed.crew
                                      ].questions[6].tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Control
                          className="rounded-0 custom-file-input"
                          type="file"
                          {...register("PPEClimberWearingClimbingHarness", {
                            required: true,
                          })}
                          onChange={async (e) => {
                            // let fileSize = e.target.files[0]['size'];
                            let fileType = e.target.files[0]["type"];
                            let validImageTypes = [
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                            ];
                            const file = e.target.files[0];
                            if (file.size >= 4e6) {
                              const time = file.lastModified;

                              const image = await resizeFile(file);

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEClimberWearingClimbingHarness", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 6, "ai"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [file, getCrewMemberProceed.crew, 6],
                                  4,
                                  "0",
                                  "PPEClimberWearingClimbingHarness",
                                  true
                                );
                                getUploadImgUrls(
                                  [image, getCrewMemberProceed.crew, 6, "ai"],
                                  4
                                );
                              }
                            } else {
                              const image = file;

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEClimberWearingClimbingHarness", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 6, "ai"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [image, getCrewMemberProceed.crew, 6, "ai"],
                                  4,
                                  "0",
                                  "PPEClimberWearingClimbingHarness",
                                  true
                                );
                              }
                            }
                            e.target.value = null;
                          }}
                        />
                        {form_template[10].section[getCrewMemberProceed.crew]
                          .questions[6].confidence !== "" && (
                          <div className="mt-3 mb-1 text-warning">
                            {errors.PPEClimberWearingClimbingHarness &&
                              errors.PPEClimberWearingClimbingHarness.message}
                          </div>
                        )}
                        {form_template[10].section[getCrewMemberProceed.crew]
                          .questions[6].confidence !== "" && (
                          <div className="mt-3 mb-1 text-success">
                            {errors.PPEClimberWearingClimbingHarness &&
                              errors.PPEClimberWearingClimbingHarness.message1}
                          </div>
                        )}
                        {form_template[10].section[getCrewMemberProceed.crew]
                          .questions[6].picture !== "" &&
                        typeof form_template[10].section[
                          getCrewMemberProceed.crew
                        ].questions[6].picture !== "undefined" ? (
                          <div className="p-1 m-1 imgDiv">
                            <div
                              className="removeImg"
                              onClick={() =>
                                changeFormTemplateData([
                                  32,
                                  "PPEClimberWearingClimbingHarness",
                                  getCrewMemberProceed.crew,
                                  6,
                                  "t-shirt",
                                ])
                              }
                            >
                              x
                            </div>
                            <img
                              src={
                                getUrlWithQueryString(
                                  form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[6].picture
                                ) + IMG_TOKEN
                              }
                              alt="PPEClimberWearingClimbingHarness"
                              className="shadow-sm bg-light rounded img-size_preview"
                            />
                          </div>
                        ) : null}
                        <div key="inline-radio" className="mb-3 mt-1">
                          <img
                            src={String(ic_ai)}
                            alt="ai-img"
                            className="me-2"
                            style={{ width: "36px" }}
                          />
                          <Form.Check
                            inline
                            label="Yes"
                            name="group10-6"
                            type="radio"
                            id="inline-radio-10-6-1"
                            checked={
                              form_template[10].section[
                                getCrewMemberProceed.crew
                              ].questions[6].overridden === "Yes"
                                ? form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[6].overridden === "Yes"
                                : form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[6].answer === "Yes"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "Yes",
                                getCrewMemberProceed.crew,
                                6,
                              ])
                            }
                            disabled={
                              form_template[10].section[
                                getCrewMemberProceed.crew
                              ].questions[6].confidence === ""
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group10-6"
                            type="radio"
                            id="inline-radio-10-6-2"
                            checked={
                              form_template[10].section[
                                getCrewMemberProceed.crew
                              ].questions[6].overridden === "Yes"
                                ? form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[6].overridden === "No"
                                : form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[6].answer === "No"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "No",
                                getCrewMemberProceed.crew,
                                6,
                              ])
                            }
                          />
                        </div>
                        {/* {
                                                            form_template[10].section[getCrewMemberProceed.crew].questions[6].confidence !== '' &&
                                                            <div>
                                                                <div>Confidence: {form_template[10].section[getCrewMemberProceed.crew].questions[6].confidence * 100}%</div>
                                                                <div ><ProgressBar variant="success"
                                                                    now={form_template[10].section[getCrewMemberProceed.crew].questions[6].confidence * 100}
                                                                    label={`${form_template[10].section[getCrewMemberProceed.crew].questions[6].confidence * 100}%`} /></div>
                                                            </div>
                                                        } */}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      {form_template[10].section[
                        getCrewMemberProceed.crew
                      ].questions.map((obj, i) => {
                        if (i > 6) {
                          return (
                            <Form.Group
                              controlId={"form-10-" + i}
                              className="mb-1"
                            >
                              <Form.Label>
                                {
                                  form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[i].question
                                }
                                {form_template[10].section[
                                  getCrewMemberProceed.crew
                                ].questions[i].tooltip_message !== "" &&
                                  form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[i].tooltip_message !==
                                    undefined && (
                                    <OverlayTrigger
                                      placement="right"
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          {
                                            form_template[10].section[
                                              getCrewMemberProceed.crew
                                            ].questions[i].tooltip_message
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      <span className="icon-info">i</span>
                                    </OverlayTrigger>
                                  )}
                              </Form.Label>
                              <Form.Control
                                className="rounded-0"
                                type="text"
                                value={
                                  form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[i].answer
                                }
                                onChange={(e) =>
                                  changeFormTemplateData([
                                    7,
                                    e.target.value,
                                    getCrewMemberProceed.crew,
                                    i,
                                  ])
                                }
                              />
                              <Form.Control
                                className="rounded-0 mt-3 custom-file-input"
                                type="file"
                                // onChange={(e) => getUploadImgUrls(e.target.files[0], 1, '6')}
                                {...register("PPEOther" + i, {
                                  required: true,
                                })}
                                onChange={(e) => {
                                  // let fileSize = e.target.files[0]['size'];
                                  let fileType = e.target.files[0]["type"];
                                  let validImageTypes = [
                                    "image/gif",
                                    "image/jpeg",
                                    "image/png",
                                  ];
                                  if (!validImageTypes.includes(fileType)) {
                                    setError("PPEOther" + i, {
                                      message:
                                        "Only jpeg/png/gif image are allowed.",
                                    });
                                    getUploadImgUrls(
                                      ["", getCrewMemberProceed.crew, i, ""],
                                      4,
                                      "0"
                                    );
                                  } else {
                                    setError("PPEOther" + i, {
                                      message: "",
                                    });
                                    getUploadImgUrls(
                                      [
                                        e.target.files[0],
                                        getCrewMemberProceed.crew,
                                        i,
                                        "",
                                      ],
                                      4,
                                      "0"
                                    );
                                  }
                                  e.target.value = null;
                                }}
                                // onChange={(e) => getUploadImgUrls([e.target.files[0], 0, 6, ''], 4, '0')}
                              />
                              {/* {form_template[10].section[getCrewMemberProceed.crew].questions[i].picture === '' ? */}
                              <Form.Label className="mt-2 text-danger">
                                {errors["PPEOther" + i] &&
                                  errors["PPEOther" + i].message}
                              </Form.Label>
                              {form_template[10].section[
                                getCrewMemberProceed.crew
                              ].questions[i].picture !== "" &&
                              typeof form_template[10].section[
                                getCrewMemberProceed.crew
                              ].questions[i].picture !== "undefined" ? (
                                <div className="p-1 m-1 imgDiv">
                                  <div
                                    className="removeImg"
                                    onClick={() =>
                                      changeFormTemplateData([
                                        32,
                                        "PPEOther" + i,
                                        getCrewMemberProceed.crew,
                                        i,
                                      ])
                                    }
                                  >
                                    x
                                  </div>
                                  <img
                                    src={
                                      getUrlWithQueryString(
                                        form_template[10].section[
                                          getCrewMemberProceed.crew
                                        ].questions[i].picture
                                      ) + IMG_TOKEN
                                    }
                                    alt={"PPEOther" + i}
                                    className="shadow-sm bg-light rounded img-size_preview"
                                  />
                                </div>
                              ) : null}
                              {/* : null} */}
                              <Form.Label className="mt-2">
                                <b>
                                  {form_template[10].section[
                                    getCrewMemberProceed.crew
                                  ].questions[i].picture !== "" &&
                                    "1 Image Uploaded"}
                                </b>
                              </Form.Label>
                            </Form.Group>
                          );
                        }
                      })}
                      <div className="mb-4">
                        <Button
                          size="sm"
                          className="mt-3"
                          onClick={addMorePPEOther}
                        >
                          + Add More
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : null}

              {form_template[getProceed].sectionId === "14" &&
              getProceed1 === 0 ? (
                <>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-12-0" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {
                            form_template[11].section[getCrewMemberProceed.crew]
                              .questions[0].question
                          }
                          {form_template[11].section[getCrewMemberProceed.crew]
                            .questions[0].tooltip_message !== "" &&
                            form_template[11].section[getCrewMemberProceed.crew]
                              .questions[0].tooltip_message !== undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[11].section[
                                        getCrewMemberProceed.crew
                                      ].questions[0].tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div key="inline-radio" className="mb-3">
                          <Form.Check
                            inline
                            label="Yes"
                            name="group12-0"
                            type="radio"
                            id="inline-radio-12-0-1"
                            checked={
                              form_template[11].section[
                                getCrewMemberProceed.crew
                              ].questions[0].answer === "Yes"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "Yes",
                                getCrewMemberProceed.crew,
                                0,
                              ])
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group12-0"
                            type="radio"
                            id="inline-radio-12-0-2"
                            checked={
                              form_template[11].section[
                                getCrewMemberProceed.crew
                              ].questions[0].answer === "No"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "No",
                                getCrewMemberProceed.crew,
                                0,
                              ])
                            }
                          />
                          <Form.Control
                            className="rounded-0 mt-3"
                            as="textarea"
                            rows={5}
                            placeholder="Details"
                            defaultValue={
                              form_template[11].section[
                                getCrewMemberProceed.crew
                              ].questions[0].description
                            }
                            {...register("hazardsProperlyMitigated2", {
                              required: true,
                            })}
                            onChange={(e) => {
                              // e.target.value === ''
                              //     ? setError('hazardsProperlyMitigated2', { message: "This field is required" })
                              //     : setError('hazardsProperlyMitigated2', { message: "" });
                              changeFormTemplateData([
                                8,
                                e.target.value,
                                getCrewMemberProceed.crew,
                                0,
                              ]);
                            }}
                          />
                        </div>
                        <Form.Label className="mt-2 text-danger">
                          {errors.hazardsProperlyMitigated2 &&
                            errors.hazardsProperlyMitigated2.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-12-1" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {
                            form_template[11].section[getCrewMemberProceed.crew]
                              .questions[1].question
                          }
                          {form_template[11].section[getCrewMemberProceed.crew]
                            .questions[1].tooltip_message !== "" &&
                            form_template[11].section[getCrewMemberProceed.crew]
                              .questions[1].tooltip_message !== undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[11].section[
                                        getCrewMemberProceed.crew
                                      ].questions[1].tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div key="inline-radio" className="mb-3">
                          <Form.Check
                            inline
                            label="Yes"
                            name="group12-1"
                            type="radio"
                            id="inline-radio-12-1-1"
                            checked={
                              form_template[11].section[
                                getCrewMemberProceed.crew
                              ].questions[1].answer === "Yes"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "Yes",
                                getCrewMemberProceed.crew,
                                1,
                              ])
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group12-1"
                            type="radio"
                            id="inline-radio-12-1-2"
                            checked={
                              form_template[11].section[
                                getCrewMemberProceed.crew
                              ].questions[1].answer === "No"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "No",
                                getCrewMemberProceed.crew,
                                1,
                              ])
                            }
                          />
                          <Form.Control
                            className="rounded-0  mt-3"
                            as="textarea"
                            rows={5}
                            placeholder="Details"
                            defaultValue={
                              form_template[11].section[
                                getCrewMemberProceed.crew
                              ].questions[1].description
                            }
                            {...register("personalProtectiveEquip2", {
                              required: true,
                            })}
                            onChange={(e) => {
                              // e.target.value === ''
                              //     ? setError('personalProtectiveEquip2', { message: "This field is required" })
                              //     : setError('personalProtectiveEquip2', { message: "" });
                              changeFormTemplateData([
                                8,
                                e.target.value,
                                getCrewMemberProceed.crew,
                                1,
                              ]);
                            }}
                          />
                          <Form.Label className="mt-2 text-danger">
                            {errors.personalProtectiveEquip2 &&
                              errors.personalProtectiveEquip2.message}
                          </Form.Label>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-12-2" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {
                            form_template[11].section[getCrewMemberProceed.crew]
                              .questions[2].question
                          }
                          {form_template[11].section[getCrewMemberProceed.crew]
                            .questions[2].tooltip_message !== "" &&
                            form_template[11].section[getCrewMemberProceed.crew]
                              .questions[2].tooltip_message !== undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[11].section[
                                        getCrewMemberProceed.crew
                                      ].questions[2].tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div key="inline-radio" className="mb-3">
                          <Form.Check
                            inline
                            label="Yes"
                            name="group12-2"
                            type="radio"
                            id="inline-radio-12-2-1"
                            checked={
                              form_template[11].section[
                                getCrewMemberProceed.crew
                              ].questions[2].answer === "Yes"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "Yes",
                                getCrewMemberProceed.crew,
                                2,
                              ])
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group12-2"
                            type="radio"
                            id="inline-radio-12-2-2"
                            checked={
                              form_template[11].section[
                                getCrewMemberProceed.crew
                              ].questions[2].answer === "No"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "No",
                                getCrewMemberProceed.crew,
                                2,
                              ])
                            }
                          />

                          <div className="mt-3 mb-1 text-warning">
                            Please upload a self portrait and sign in the grey
                            area
                          </div>

                          <Form.Control
                            className="rounded-0 custom-file-input mt-3"
                            type="file"
                            {...register("JobHazardAndSafetyBrief2", {
                              required: true,
                            })}
                            onChange={(e) => {
                              // let fileSize = e.target.files[0]['size'];
                              let fileType = e.target.files[0]["type"];
                              let validImageTypes = [
                                "image/gif",
                                "image/jpeg",
                                "image/png",
                              ];
                              if (!validImageTypes.includes(fileType)) {
                                setError("JobHazardAndSafetyBrief2", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls("", 1, "2");
                              } else {
                                setError("JobHazardAndSafetyBrief2", {
                                  message: "",
                                });
                                // getUploadImgUrls(e.target.files[0], 1, '2')
                                getUploadImgUrls(
                                  [
                                    e.target.files[0],
                                    getCrewMemberProceed.crew,
                                    2,
                                    "pic",
                                  ],
                                  4,
                                  "0"
                                );
                              }
                              e.target.value = null;
                            }}
                            // onChange={(e) => getUploadImgUrls(e.target.files[0], 1, '2')}
                          />
                          <Form.Label className="mt-2 text-danger">
                            {errors.JobHazardAndSafetyBrief2 &&
                              errors.JobHazardAndSafetyBrief2.message}
                          </Form.Label>
                          {form_template[11].section[getCrewMemberProceed.crew]
                            .questions[2].picture !== "" && (
                            <div className="p-1 m-1 imgDiv">
                              <div
                                className="removeImg"
                                onClick={() => {
                                  setpbtn(true);
                                  setError("JobHazardAndSafetyBrief2", {
                                    message: "This field is required",
                                  });
                                  changeFormTemplateData([
                                    32,
                                    "",
                                    getCrewMemberProceed.crew,
                                    2,
                                  ]);
                                }}
                              >
                                x
                              </div>
                              <img
                                src={
                                  getUrlWithQueryString(
                                    form_template[11].section[
                                      getCrewMemberProceed.crew
                                    ].questions[2].picture
                                  ) + IMG_TOKEN
                                }
                                alt="JobHazardAndSafetyBrief2"
                                className="shadow-sm bg-light rounded img-size_preview"
                              />
                            </div>
                          )}
                          <Form.Label className="mt-2 mb-1">
                            <b>
                              {form_template[11].section[
                                getCrewMemberProceed.crew
                              ].questions[2].picture !== "" &&
                                "1 Image Uploaded"}
                            </b>
                          </Form.Label>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  {form_template[11].section[getCrewMemberProceed.crew]
                    .questions[2].picture ? (
                    <Row>
                      <Col xs={3}>
                        <div className=" mt-3">
                          <Signature
                            setSignBase64Uri={setSignBase64Uri1}
                            imgName="signature1.png"
                            resetSign={() =>
                              changeFormTemplateData([
                                19,
                                "",
                                getCrewMemberProceed.crew,
                                2,
                                "signature",
                              ])
                            }
                          />
                          <Form.Label className="mt-2 text-danger">
                            {getSignBase64Uri1 === "" &&
                              "Signature is required"}
                          </Form.Label>
                          {form_template[11].section[getCrewMemberProceed.crew]
                            .questions[2].signature !== "" && (
                            <div className="p-1 m-1">
                              <img
                                src={
                                  getUrlWithQueryString(
                                    form_template[11].section[
                                      getCrewMemberProceed.crew
                                    ].questions[2].signature
                                  ) + IMG_TOKEN
                                }
                                alt="signature1"
                                className="shadow-sm bg-light rounded img-size_preview"
                              />
                            </div>
                          )}
                          <Form.Label className="mt-2 mb-3">
                            <b>
                              {form_template[11].section[
                                getCrewMemberProceed.crew
                              ].questions[2].signature !== "" &&
                                "Sign Uploaded"}
                            </b>
                          </Form.Label>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </>
              ) : null}
              {getProceed1 === 15 ? (
                getIjhaSubmit === "" ? (
                  <>
                    {getSummary === true ? (
                      <>
                        <Summary
                          form_template={form_template}
                          setForm_template={setForm_template}
                          setCrewLeadData={setCrewLeadData}
                          setDelCrew={setDelCrew}
                          setIsCompleted={setIsCompleted}
                          getIsCompleted={getIsCompleted}
                          profile_img_url={DECRYPTED_DATA.profile_img_url}
                          getUserName={getUserName}
                          check={check}
                          setProceed1={setProceed1}
                          setProceed={setProceed}
                          setCrewMemberProceed={setCrewMemberProceed}
                          getCrewMemberProceed={getCrewMemberProceed}
                          getCrewLeadData={getCrewLeadData}
                          setPFASOptions={setPFASOptions}
                          setpbtn={setpbtn}
                          setCrewfill={setCrewfill}
                        />
                        {/* <Row xs={12}>
                                                <Col md={6} className="d-flex flex-row bd-highlight align-items-center mb-3 position-relative" >
                                                    <div className="rounded border p-2 bd-highlight" >
                                                        <img src={DECRYPTED_DATA().profile_img_url} style={{ width: "60px" }} alt="profilePic" />
                                                    </div>
                                                    <div className="p-2 bd-highlight">{getUserName}</div>

                                                </Col>
                                            </Row>
                                            */}
                      </>
                    ) : (
                      <Row xs={12}>
                        <div>
                          <h5 className="mt-3 d-flex justify-content-center text-center">
                            Please wait while we process your Ericsson iJHA form
                            submission.
                          </h5>
                          <h5 className="mt-1 p-2 d-flex justify-content-center text-center">
                            Do not refresh the page or navigate away while this
                            is happening.
                          </h5>
                        </div>
                      </Row>
                    )}
                  </>
                ) : (
                  <>
                    <Row xs={12}>
                      <Col className="d-flex flex-row bd-highlight align-items-center mb-3 position-relative">
                        <img
                          src={String(ic_complete_ppe)}
                          className="p-2 m-2"
                          alt="check-success"
                          style={{ width: "74px" }}
                        />
                        <h5 className="p-2 bd-highlight">
                          {getIjhaSubmit !== "" &&
                            getIjhaSubmit + " Your iJHA will be available in "}
                          {getIjhaSubmit !== "" && (
                            <a href="/reports" style={{ fontSize: "1.25rem" }}>
                              Reports tab
                            </a>
                          )}
                        </h5>
                      </Col>
                    </Row>
                    <Row xs={12}>
                      <Col className="d-flex justify-content-center mt-3 mb-3">
                        Click to go&nbsp;<a href="/">Home Page</a>
                      </Col>
                    </Row>
                  </>
                )
              ) : null}
              <Row>
                {getIjhaSubmit === "" ? (
                  <Col>
                    {/* crew lead all 12 sections */}
                    {getProceed1 === 0 && (
                      <>
                        <Button
                          className="me-3"
                          onClick={() => {
                            setSetP(true);
                            //form_template[getProceed - 1].visibility ? setProceed(getProceed - 1) : setProceed(getProceed - 2);
                            // setProceed(getProceed - 1)
                            setpbtn(false);
                            onBackHandler();
                          }}
                          disabled={getLoading ? getLoading : getProceed === 0}
                        >
                          Back
                        </Button>

                        <Button
                          onClick={onProceedHandler}
                          variant={"success"}
                          className="me-5"
                          disabled={getpbtn || getLoading}
                        >
                          Proceed
                        </Button>

                        {!(getLoading || getpbtn === true) &&
                        getValidation &&
                        getIsCompleted[getCrewMemberProceed.crew] ? (
                          <Button
                            className="ms-5 btn btn-dark"
                            onClick={() => {
                              setProceed(11);
                              setProceed1(15);
                              setSummary(true);
                            }}
                            // disabled={getUploadedImgUrls.length === 0}
                            variant={"success"}
                            disabled={getLoading}
                          >
                            Go to Summary
                          </Button>
                        ) : null}
                      </>
                    )}

                    {getProceed > 10 && getProceed1 !== 0 && (
                      <div>
                        <Button
                          className="me-3"
                          onClick={() => {
                            setProceed1(0);
                            setSummary(false);
                          }}
                          disabled={getLoading ? getLoading : getProceed === 0}
                        >
                          Back
                        </Button>
                        {_.isEmpty(getIjhaform.reportId) && (
                          <Button
                            variant={"success"}
                            onClick={onSubmitIJHAForm}
                            disabled={
                              getLoading ||
                              !getIsCompleted.every((obj) => obj === true)
                            }
                          >
                            {" "}
                            Submit
                          </Button>
                        )}
                        {!_.isEmpty(getIjhaform.reportId) &&
                        !getIsCompleted.every((obj) => obj === true) ? (
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                Please make sure all sections are reviewed for
                                all members in the iJHA
                              </Tooltip>
                            }
                          >
                            <span className="d-inline-block">
                              <Button
                                variant={"success"}
                                onClick={onSubmitIJHAForm}
                                disabled={
                                  getLoading ||
                                  !getIsCompleted.every((obj) => obj === true)
                                }
                              >
                                {" "}
                                Submit
                              </Button>
                            </span>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                        {!_.isEmpty(getIjhaform.reportId) &&
                        getIsCompleted.every((obj) => obj === true) ? (
                          <Button
                            variant={"success"}
                            onClick={onSubmitIJHAForm}
                            disabled={
                              getLoading ||
                              !getIsCompleted.every((obj) => obj === true)
                            }
                          >
                            {" "}
                            Submit
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </Col>
                ) : null}
                {getLoading && <Loading width="32" height="32" />}
              </Row>
              <Button
                onClick={scrollDown}
                className="btn btn-sm btn-dark mb-5 rounded-0 position-fixed topToBottom end-0 translate-middle "
                value="bottom"
                id="back-to-down"
              >
                <img
                  src={String(arrow_up)}
                  alt="scroll bottom"
                  style={{ width: "18px" }}
                  className="icon-white-color"
                />
              </Button>
              <Button
                onClick={scrollUp}
                className="btn btn-sm btn-dark mb-5 rounded-0 position-fixed bottom-0 end-0 translate-middle "
                value="top"
                id="back-to-up"
              >
                <img
                  src={String(arrow_up)}
                  alt="scroll top"
                  style={{ width: "18px" }}
                  className="icon-white-color"
                />
              </Button>
            </Form>
          </>
        )}
      </>
    </Container>
  );
}
