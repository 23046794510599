import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  ToggleButton,
  ProgressBar,
  Alert,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";
import Figure from "react-bootstrap/Figure";
import {
  uploadMultiImage,
  detectAI,
  ijhaFormSubmit,
  ijhaInCompleteFormSubmit,
  getAddCrewMemberApi,
  detectAIALL,
  getFormIjhaApi,
} from "../../api/CreateiJHA.api";
import { DECRYPTED_DATA, EMP_ID, IMG_TOKEN } from "../../utils/Variable";
// import ijhaform from '../../utils/ijhaform1.json';
import { getWeatherApi } from "../../api/PPESubmission.api";
import arrow_up from "../../assets/Icons/arrow_up.png";
import position from "../../assets/Icons/position.svg";
import failed from "../../assets/Icons/failed.svg";
import ExampleImage from "../../assets/Icons/ExampleImage.png";
import ic_ai from "../../assets/Icons/ic_ai.png";
import ic_wind from "../../assets/Icons/ic_wind.png";
import ic_sunny from "../../assets/Icons/ic_sunny.png";
import ic_partly_sunny from "../../assets/Icons/ic_partly_sunny.png";
import ic_rain from "../../assets/Icons/ic_rain.png";
import ic_complete_ppe from "../../assets/Icons/ic_complete_ppe.png";
import {
  removeTags,
  getCurrentDataTime,
  getFileTypeImgDoc,
  formatAMPM,
  getTotalTime,
  getUrlWithQueryString,
  getPPEImageUrl,
} from "../../utils/Helper";
import ModalMapCustom from "../../components/Modal/ModalMapCustom";
import ModalMapTramaCenter from "../../components/Modal/ModalMapTramaCenter";
import ModalOther from "../../components/Modal/ModalOther";
import MultiSelect1 from "../../components/MultiSelect1/MultiSelect1";
import Loading from "../../components/Loading/Loading";
import check from "../../assets/Icons/check.svg";
import "./CreateiJHA.scss";
import FormProgressBar from "../../components/FormProgressBar/FormProgressBar";
import _, { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import SummaryforPpeSubmission from "./SummaryforPpeSumbmission";
import Resizer from "react-image-file-resizer";
import { checkImageData } from "../../api/ImageMetaData.api";
import AddCrewMemberPPE from "../CrewMember/AddCrewMemberPPE";
export default function PPE_Submission_Form() {
  let s = useLocation();
  // console.log("vicky::ijhaform-props", s.state)
  // console.log('EMP_ID::', EMP_ID);
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        300,
        300
      );
    });

  const [getFormID, setFormID] = useState(14);
  const [getReportCrewCheck, setReportCrewCheck] = useState(false);
  const [getCrewData, setCrewData] = useState(s.state);
  const [getReportIJHA, setReportIJHA] = useState(s.state.reportIJHA);
  // const [getReportIJHACount, setReportIJHACount] = useState(0);
  const [getAddEditCrew, setAddEditCrew] = useState([]);
  const [getAllFormsData, setAllFormsData] = useState([]);
  const [getLoc, setLoc] = useState({ lat: "", lng: "" });
  const [getCheckComplete, setCheckComplete] = useState(false);
  const [getCrewMemData, setCrewMemData] = useState(s.state.crewmemberdata);
  const [getCrewLeadData, setCrewLeadData] = useState(s.state.getCrewLeadData);
  const [getWeatherHours, setWeatherHours] = useState([]);
  const [getLoading, setLoading] = useState(false);
  const [getHCMOptions, setHCMOptions] = useState([]);
  const [getPFASOptions, setPFASOptions] = useState([]);
  const [getCrewMemberProceed, setCrewMemberProceed] = useState({
    crew: 0,
    steps: 0,
  });
  const [getErrMsg, setErrMsg] = useState("");
  const [getIjhaform, setIjhaform] = useState(s.state.Ppeform);
  const [form_template, setForm_template] = useState(getIjhaform.form_template);
  const [getUserName, setUserName] = useState(
    DECRYPTED_DATA().firstName + " " + DECRYPTED_DATA().lastName
  );
  const [getSignBase64Uri, setSignBase64Uri] = useState("");
  const [getSignBase64Uri1, setSignBase64Uri1] = useState("");
  const [getProceed, setProceed] = useState(0);
  const [getProceed1, setProceed1] = useState(0);
  const [radioValue, setRadioValue] = useState("imperial");
  const [getLatitude, setLatitude] = useState(0);
  const [getLongitude, setLongitude] = useState(0);
  const [getUnit, setUnit] = useState("");
  const [getImgUrls, setImgUrls] = useState({});
  const [getUploadedImgUrls, setUploadedImgUrls] = useState([]);
  const [getUploadedImgUrls1, setUploadedImgUrls1] = useState([]);
  const [getUploadedImgUrls2, setUploadedImgUrls2] = useState([]);
  const [getAddress, setAddress] = useState("");
  const [showMdl, setShowMdl] = useState(false);
  const [showTraumaMdl, setShowTraumaMdl] = useState(false);
  const [getDataMdl, setDataMdl] = useState({
    heading: "",
    body: "",
    showClose: false,
    saveBtnName: "",
  });
  const [getShowOtherMdl, setShowOtherMdl] = useState(false);
  const [getOtherDataMdl, setOtherDataMdl] = useState({
    heading: "",
    body: "",
    showClose: false,
    saveBtnName: "Add",
  });
  const [getIsCompleted, setIsCompleted] = useState([]);
  const [getSetP, setSetP] = useState(false);
  const [getAddMoreDailyTask, setAddMoreDailyTask] = useState([]);

  const [getSelectedOptionsMulti1, setSelectedOptionsMulti1] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const [getCommentMulti1, setCommentMulti1] = useState([]);
  const [directions, setDirections] = useState({});
  const [getImgAiDetectKeys, setImgAiDetectKeys] = useState({});
  const [getImgAiDetectKeys2, setImgAiDetectKeys2] = useState({});
  const [getAIData, setAIData] = useState({});
  const [getIjhaSubmit, setIjhaSubmit] = useState("");
  const [getpbtn, setpbtn] = useState(false);
  const [getpbtn2, setpbtn2] = useState(false);
  const [getCustomername1, setCustomername1] = useState(false);
  const [getSiteLandLord, setSiteLandLord] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm();
  const [showScroll, setShowScroll] = useState(false);
  const [getCrewfill, setCrewfill] = useState(false);
  const [getDelCrew, setDelCrew] = useState(false);
  const [getReportID, setReportId] = useState();
  const [getSummary, setSummary] = useState(false);
  const [getImgMetaData, setImgMetaData] = useState();

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  // Below code for not replicate In-Progress form when edit
  useEffect(() => {
    if (
      getIjhaform.reportId !== undefined &&
      getIjhaform.reportId !== "" &&
      getIjhaform.complete_status === "InProgress"
    ) {
      setReportId(getIjhaform.reportId);
    } else {
      setReportId(uuidv4());
    }

    if (
      getIjhaform.inProgressSection !== undefined &&
      getIjhaform.inProgressSection !== ""
    ) {
      if (getIjhaform.inProgressSection === form_template.length - 1) {
        setProceed1(1);
        setSummary(true);
      }
      setProceed(getIjhaform.inProgressSection);
    }
  }, []);

  window.addEventListener("scroll", checkScrollTop);
  useEffect(() => {
    // console.log('scroll', showScroll, getProceed)
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    // window.scrollTo(0, 0);
    scrollUp();
  }, [getProceed, getProceed1]);

  const scrollDown = () => {
    let _totalHeight = document.body.offsetHeight;
    window.scrollTo({ top: _totalHeight, behavior: "smooth" });
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const modalOtherHandler = () => {
    let dataMdl = {
      heading: "",
      body: "",
      showClose: true,
      saveBtnName: "Add",
    };

    setOtherDataMdl(dataMdl);
    setShowOtherMdl(true);
  };
  const modalMapHandler = (
    heading,
    getAddress,
    changeFormTemplateData,
    _key,
    lat,
    lng
  ) => {
    let dataMdl = {
      heading: heading,
      setAddress: setAddress,
      // getLatitude: getLatitude,
      // getLongitude: getLongitude,
      getLatitude: Number(lat),
      getLongitude: Number(lng),
      direction: false,
      body: "",
      changeFormTemplateData: changeFormTemplateData,
      _key: _key,
      showClose: false,
      saveBtnName: "Add",
    };
    // console.log('modal add::', getAddress)

    setDataMdl(dataMdl);
    setShowMdl(true);
  };

  const modalMapHandler1 = (
    heading,
    getAddress,
    changeFormTemplateData,
    _key,
    lat,
    lng
  ) => {
    // console.log("map::modal::", lat, lng, getLatitude, getLongitude)
    let dataMdl = {
      heading: heading,
      setAddress: setAddress,
      getCurrLatitude: getLatitude,
      getCurrLongitude: getLongitude,
      getLatitude: Number(lat),
      getLongitude: Number(lng),
      direction: false,
      fullAdd: _.split(
        removeTags(form_template[getProceed].questions[_key].answer),
        "\n"
      ),
      body: "",
      changeFormTemplateData: changeFormTemplateData,
      _key: _key,
      showClose: false,
      saveBtnName: "Add",
    };
    // console.log('modal add::', getAddress)

    setDataMdl(dataMdl);
    // setShowMdl(true);
    setShowTraumaMdl(true);
  };

  const modalMapDirectionHandler = (
    heading,
    getAddress,
    changeFormTemplateData,
    _key
  ) => {
    // console.log('1:', heading[1]);
    let dataMdl = {
      heading: heading[0],
      setAddress: setAddress,
      getLatitude: getLatitude,
      getLongitude: getLongitude,
      origin: heading[1],
      destination: heading[2],

      direction: true,
      body: "",
      changeFormTemplateData: changeFormTemplateData,
      _key: _key,
      showClose: false,
      saveBtnName: "Add",
    };
    // console.log('modal add::', getAddress)

    setDataMdl(dataMdl);
    setShowMdl(true);
  };
  const wheather_radio = [
    { name: "°F", value: "imperial" },
    { name: "°C", value: "metric" },
  ];
  const sendData = {
    latitude: getLatitude,
    longitude: getLongitude,
    unit: radioValue,
    loadingTrue: useCallback(() => setLoading(true), [setLoading]),
    loadingFalse: useCallback(() => setLoading(false), [setLoading]),
  };

  const _datetime = () => {
    let _date = new Date();
    let current_date =
      _date.getFullYear() +
      "/" +
      (_date.getMonth() + 1) +
      "/" +
      _date.getDate() +
      " " +
      +_date.getHours() +
      ":" +
      _date.getMinutes() +
      ":" +
      _date.getSeconds();
    return current_date;
  };

  const _totalTime = (_start_time, _end_time) => {
    const _total_Time =
      Math.abs(
        new Date(_end_time).getTime() - new Date(_start_time).getTime()
      ) / 1000;
    return _total_Time;
  };

  useEffect(() => {
    switch (form_template[getProceed].sectionId) {
      case "2":
        if (form_template[getProceed].start_time === "") {
          form_template[getProceed].start_time = _datetime();
        }
        if (
          form_template[0].questions[3].answer
            .split("-")[0]
            .replace(/^\s+|\s+$/gm, "") === "Other"
        ) {
          setCustomername1(true);
        }
        if (
          form_template[0].questions[4].answer
            .split("-")[0]
            .replace(/^\s+|\s+$/gm, "") === "Other"
        ) {
          setSiteLandLord(true);
        }
        if (
          form_template[0].questions[0].answer === "" ||
          form_template[0].questions[1].answer === "" ||
          form_template[0].questions[2].answer === "" ||
          form_template[0].questions[3].answer.split("-")[0] === "" ||
          form_template[0].questions[4].answer.split("-")[0] === ""
        ) {
          setpbtn(true);
        } else if (
          (form_template[0].questions[3].answer
            .split("-")[0]
            .replace(/^\s+|\s+$/gm, "") === "Other" &&
            form_template[0].questions[3].answer
              .split("-")[1]
              .replace(/^\s+|\s+$/gm, "") === "") ||
          (form_template[0].questions[4].answer
            .split("-")[0]
            .replace(/^\s+|\s+$/gm, "") === "Other" &&
            form_template[0].questions[4].answer
              .split("-")[1]
              .replace(/^\s+|\s+$/gm, "") === "")
        ) {
          setpbtn(true);
        } else {
          setpbtn(false);
        }

        break;

      case "6":
        if (
          form_template[getProceed].section[getCrewMemberProceed.crew]
            .start_time === ""
        ) {
          form_template[getProceed - 1].end_time = _datetime();
          form_template[getProceed].section[
            getCrewMemberProceed.crew
          ].start_time = _datetime();
          form_template[getProceed - 1].time_taken = _totalTime(
            form_template[getProceed - 1].start_time,
            form_template[getProceed - 1].end_time
          );
        } else {
          if (
            getProceed1 === 1 &&
            form_template[getProceed].section[getCrewMemberProceed.crew]
              .end_time === ""
          ) {
            form_template[getProceed].section[
              getCrewMemberProceed.crew
            ].end_time = _datetime();
            form_template[getProceed].section[
              getCrewMemberProceed.crew
            ].time_taken = _totalTime(
              form_template[getProceed].section[getCrewMemberProceed.crew]
                .start_time,
              form_template[getProceed].section[getCrewMemberProceed.crew]
                .end_time
            );
          }
        }

        break;

      default:
    }
  }, [watch(errors)]);
  useEffect(() => {
    let _getPFASOptions = [];
    if (form_template[11] !== undefined) {
      form_template[11].section[
        getCrewMemberProceed.crew
      ].questions[0].multiselect_options.map((obj, i) => {
        if (obj.is_selected)
          _getPFASOptions.push({
            value: obj.value,
            label: obj.value,
            color: "#00B8D9",
            isFixed: obj.is_selected,
            _is_selected: obj.is_selected,
            file_obj: "",
            file_url: "",
            _key: 0,
            _s_key: i,
          });
      });
    }
    setPFASOptions(_getPFASOptions);
  }, [getCrewMemberProceed]);
  useEffect(() => {
    let _getSelectedOptionsMulti1 = _.cloneDeep(getSelectedOptionsMulti1);
    let _getHCMOptions = _.cloneDeep(getHCMOptions);
    let _getCommentMulti1 = _.cloneDeep(getCommentMulti1);
    // form_template[8].questions[0].table_options[i].pre_selected_options
    if (form_template[8] !== undefined) {
      form_template[8].questions[0].table_options.map((obj, i) => {
        if (obj.pre_selected_options.length) {
          let _optionsArr = obj.options.map((val) => {
            return { value: val, label: val, color: "#00B8D9" };
          });
          _getHCMOptions.push({
            value: obj.question,
            label: obj.question,
            color: "#00B8D9",
            isFixed: false,
            _options: _optionsArr,
            _s_key: i,
          });
          obj.selected_options.map((val, key) => {
            _getSelectedOptionsMulti1[i].push({
              value: val,
              label: val,
              color: "#00B8D9",
            });
          });
          _getCommentMulti1[i] = obj.comment_text;
        }
      });
    }
    setHCMOptions(_getHCMOptions);
    setSelectedOptionsMulti1(_getSelectedOptionsMulti1);
    setCommentMulti1(_getCommentMulti1);

    let _getPFASOptions = _.cloneDeep(getPFASOptions);
    if (form_template[11] !== undefined) {
      form_template[11].section[
        getCrewMemberProceed.crew
      ].questions[0].multiselect_options.map((obj, i) => {
        if (obj.is_selected)
          _getPFASOptions.push({
            value: obj.value,
            label: obj.value,
            color: "#00B8D9",
            isFixed: obj.is_selected,
            _is_selected: obj.is_selected,
            file_obj: "",
            file_url: "",
            _key: 0,
            _s_key: i,
          });
      });
    }
    setPFASOptions(_getPFASOptions);
    start_datetime_filling();
    getLocation();
    //section id 2
    if (getFormID === 10) {
      form_template[1].questions[0].answer !== "" &&
        setError("piNumber", { message: "" });
      form_template[1].questions[1].answer !== "" &&
        setError("loc911Add", { message: "" });
      form_template[1].questions[2].answer !== "" &&
        setError("ccnameJobName", { message: "" });
      getFirstPart(form_template[1].questions[3].answer) !== "" &&
        setError("customername1", { message: "" });
      getSecondPart(form_template[1].questions[3].answer) !== "" &&
        setError("customername2", { message: "" });
      if (form_template[1].questions[4] !== undefined) {
        getFirstPart(form_template[1].questions[4].answer) !== "" &&
          setError("siteLanLord1", { message: "" });
        getSecondPart(form_template[1].questions[4].answer) !== "" &&
          setError("siteLanLord2", { message: "" });
      }
    } else {
      form_template[0].questions[0].answer !== "" &&
        setError("piNumber", { message: "" });
      form_template[0].questions[1].answer !== "" &&
        setError("loc911Add", { message: "" });
      form_template[0].questions[2].answer !== "" &&
        setError("ccnameJobName", { message: "" });
      getFirstPart(form_template[0].questions[3].answer) !== "" &&
        setError("customername1", { message: "" });
      getSecondPart(form_template[0].questions[3].answer) !== "" &&
        setError("customername2", { message: "" });
      if (form_template[0].questions[4] !== undefined) {
        getFirstPart(form_template[0].questions[4].answer) !== "" &&
          setError("siteLanLord1", { message: "" });
        getSecondPart(form_template[0].questions[4].answer) !== "" &&
          setError("siteLanLord2", { message: "" });
      }
      if (form_template[0].questions[5] !== undefined) {
        form_template[0].questions[5].answer !== "" &&
          setError("generalScopOfProj", { message: "" });
      }
    }

    //section id 7
    if (form_template[5] !== undefined) {
      form_template[5].questions[0].answer !== "" &&
        setError("primaryMeansOfRescue", { message: "" });
      form_template[5].questions[1].subQuestion[0].answer !== "" &&
        setError("remainCalmandAssessEmergency1", { message: "" });
      form_template[5].questions[1].subQuestion[1].answer !== "" &&
        setError("remainCalmandAssessEmergency2", { message: "" });
      form_template[5].questions[2].subQuestion[0].answer !== "" &&
        setError("personWillStartRescue1", { message: "" });
      form_template[5].questions[2].subQuestion[1].answer !== "" &&
        setError("personWillStartRescue2", { message: "" });
      form_template[5].questions[3].subQuestion[0].answer !== "" &&
        setError("onceVictimAndHero1", { message: "" });
      form_template[5].questions[3].subQuestion[1].answer !== "" &&
        setError("onceVictimAndHero2", { message: "" });
      form_template[5].questions[4].answer !== "" &&
        setError("reportSafetyIncidentToS6", { message: "" });
    }
    //section id 10
    if (form_template[7] !== undefined) {
      getFirstPart(form_template[7].questions[0].answer) !== "" &&
        setError("typeOfStructure1", { message: "" });
      // !== '' && setError('typeOfStructure2', { message: '' })
      getFirstPart(form_template[7].questions[1].answer) !== "" &&
        setError("overallConditionOfTheStructure1", { message: "" });
      form_template[7].questions[1].description !== "" &&
        setError("overallConditionOfTheStructure2", { message: "" });
      getFirstPart(form_template[7].questions[2].answer) !== "" &&
        setError("conditionOfTheClimbingFacilities1", { message: "" });
      // !== '' && setError('conditionOfTheClimbingFacilities2', { message: '' })
      getFirstPart(form_template[7].questions[3].answer) !== "" &&
        setError("visuallyInspectTheFixed1", { message: "" });
      // !== '' && setError('visuallyInspectTheFixed2', { message: '' })
      form_template[7].questions[6].answer !== "" &&
        setError("otherStructuralHazards", { message: "" });
      form_template[7].questions[5].answer === ""
        ? setError("AnchorPointsInspected", {
            message: "This field is required",
          })
        : setError("AnchorPointsInspected", { message: "" });
      form_template[7].questions[5].answer === ""
        ? setError("AllStructuralAppurtenance", {
            message: "This field is required",
          })
        : setError("AllStructuralAppurtenance", { message: "" });

      setError("PPEOther7", { message: "" });
    }
    //section id 12
    // setError('FireExtinguiserAvailable', { message: '' })
    // setError('FireExtinguiserInspection', { message: '' })
    // setError('firstAidKitAvail', { message: '' })
    // setError('workZone', { message: '' })

    // section id 14
    if (form_template[12] !== undefined) {
      form_template[12].section[getCrewMemberProceed.crew].questions[0]
        .description !== "" &&
        setError("hazardsProperlyMitigated2", { message: "" });
      form_template[12].section[getCrewMemberProceed.crew].questions[1]
        .description !== "" &&
        setError("personalProtectiveEquip2", { message: "" });
    }
    // setError('JobHazardAndSafetyBrief2', { message: '' })
    // !_.isEmpty(getIjhaform.reportId) ? setCrewLeadData(s.state.getCrewLeadData) : getAddCrewMemberApi(setCrewLeadData, setLoading, setErrMsg);
  }, []);
  useEffect(() => {
    if (!getDelCrew) {
      if (!_.isEmpty(getCrewLeadData.crewmemberdata)) {
        let _getIsCompleted = [];
        _getIsCompleted[0] = true;
        getCrewLeadData.crewmemberdata.map((obj, i) => {
          _getIsCompleted[i + 1] = false;
        });
        setIsCompleted(_getIsCompleted);
      }
      if (getReportCrewCheck) {
        if (!_.isEmpty(getAddEditCrew)) {
          let _getIsCompleted = [];
          _getIsCompleted[0] = false;
          let _getBlankData = _.cloneDeep(getAddEditCrew);
          _getBlankData = _getBlankData.filter((_blank, k) => {
            if ("employeeId" in _blank) {
              _getIsCompleted[k + 1] = true;
            } else {
              _getIsCompleted[k + 1] = false;
            }
          });
          setIsCompleted(_getIsCompleted);
        } else {
          let _getIsCompleted = [true];
          setIsCompleted(_getIsCompleted);
        }
      }
    }
  }, [getCrewLeadData, getCheckComplete]);
  useEffect(() => {
    // // console.log({
    //     'latitude': getLatitude,
    //     'longitude': getLongitude,
    //     'unit': getUnit
    // })
    // console.log('radioValue:', radioValue, sendData);
    // console.log('RUN::', getSignBase64Uri1, getSignBase64Uri)
    // getWeather(setForm_template, form_template, sendData, setWeatherHours);
    // form_template[getProceed].sectionId === '1' && getUploadImgUrls(getSignBase64Uri, 2, '1');

    form_template[getProceed].sectionId === "1" &&
      setTimeout(
        () => getUploadImgUrls([getSignBase64Uri, 0, 0, "sign"], 4, "0"),
        500
      );
    // form_template[getProceed].sectionId === '14' && getUploadImgUrls(getSignBase64Uri1, 3, '3');
    if (getFormID === 10) {
      form_template[getProceed].sectionId === "14" &&
        setTimeout(
          () =>
            getUploadImgUrls(
              [getSignBase64Uri1, getCrewMemberProceed.crew, 2, "sign"],
              4,
              "0"
            ),
          500
        );
    } else {
      form_template[getProceed].sectionId === "14" &&
        setTimeout(
          () =>
            getUploadImgUrls(
              [getSignBase64Uri1, getCrewMemberProceed.crew, 1, "sign"],
              4,
              "0"
            ),
          500
        );
    }
  }, [getUnit, radioValue, getSignBase64Uri, getSignBase64Uri1]);

  useEffect(() => {
    // console.log('runnnnn', getUploadedImgUrls);
    if (getUploadedImgUrls.length !== 0) {
      switch (form_template[getProceed].sectionId) {
        case "1":
          //  'signature' ,'picture'
          changeFormTemplateData([9, getUploadedImgUrls, 0, 0]);
          break;
        case "3":
          changeFormTemplateData([17, getUploadedImgUrls, 3, 0]);
          break;
        case "10":
          changeFormTemplateData([10, getUploadedImgUrls]);
          break;
        case "12":
          changeFormTemplateData([12, getUploadedImgUrls]);
          break;
        case "6":
          // section 6
          changeFormTemplateData([13, getUploadedImgUrls, 0]);
          break;
        case "14":
          // section 14
          changeFormTemplateData([14, getUploadedImgUrls, 0]);
          break;
        default:
      }
    }
  }, [getUploadedImgUrls]);

  const aiALLDetectJson = async (pic_url) => {
    const ai_all = await detectAIALL(pic_url, setLoading, setErrMsg);

    let _ai_all = [
      "gloves",
      "hard-hat",
      "harness",
      "safety-vest",
      "work-boots",
    ];
    Object.keys(ai_all.data).filter((obj_ai) => obj_ai !== "fire-ext");
    // console.log(_ai_all, "SASIUUUSUUUSUSUS")
    let _form_template = _.cloneDeep(form_template);
    _form_template[getProceed].section[getCrewMemberProceed.crew].questions =
      _form_template[getProceed].section[
        getCrewMemberProceed.crew
      ].questions.map((obj, i) => {
        _ai_all.map((obj_ai) => {
          // obj_ai !== 'fire-ext'
          // if (i >= 2 && i <= 6) obj.picture = pic_url;

          if (obj.category === obj_ai) {
            if (ai_all.data[obj_ai]) {
              obj.answer = ai_all.data[obj_ai].status;
              obj.confidence = ai_all.data[obj_ai].confidence;
              obj.threshold = ai_all.data[obj_ai].threshold;
              obj.image_metadata = getImgMetaData;
              obj.ai_approved = ai_all.data[obj_ai].ai_approved;
              obj.other_confidence = ai_all.data[obj_ai].other_confidence;
              obj.picture = pic_url;
            } else {
              obj.answer = "";
              obj.confidence = "";
              obj.threshold = "";
              obj.image_metadata = "";
              obj.other_confidence = "";
              obj.picture = "";
              obj.ai_approved = "";
            }
          }
        });
        return obj;
      });
    setForm_template(_form_template);
    // console.log(_form_template, '<<<<<ppe-new');
  };
  useEffect(() => {
    //ai data
    // console.log('setUploadedImgUrls1:::', getUploadedImgUrls1, getImgAiDetectKeys, getAIData);
    if (getImgAiDetectKeys.ai === "ai") {
      let category =
        form_template[getProceed].section[getImgAiDetectKeys.section].questions[
          getImgAiDetectKeys.questions
        ].category;
      detectAI(
        setAIData,
        getUploadedImgUrls1[0],
        category,
        getImgAiDetectKeys,
        setImgAiDetectKeys,
        changeFormTemplateData,
        setLoading,
        13
      );
    } else if (getImgAiDetectKeys.ai === "") {
      changeFormTemplateData([
        13,
        getUploadedImgUrls1[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
      ]);
    } else if (getImgAiDetectKeys.ai === "pic") {
      changeFormTemplateData([
        19,
        getUploadedImgUrls1[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "picture",
      ]);
    } else if (getImgAiDetectKeys.ai === "sign") {
      changeFormTemplateData([
        19,
        getUploadedImgUrls1[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "signature",
      ]);
    } else if (getImgAiDetectKeys.ai === "subQuestionPic") {
      changeFormTemplateData([
        20,
        getUploadedImgUrls1[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "picture",
      ]);
    } else if (getImgAiDetectKeys.ai === "picNoSection") {
      changeFormTemplateData([
        21,
        getUploadedImgUrls1[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "picture",
      ]);
    } else if (getImgAiDetectKeys.ai === "PersonalFallArrestSystemMultiPic") {
      changeFormTemplateData([
        27,
        getUploadedImgUrls1[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "multiple_picture",
      ]);
    } else if (getImgAiDetectKeys.ai === "ai-hazard-control") {
      let category =
        form_template[getProceed].questions[getImgAiDetectKeys.questions]
          .category;
      detectAI(
        setAIData,
        getUploadedImgUrls1[0],
        category,
        getImgAiDetectKeys,
        setImgAiDetectKeys,
        changeFormTemplateData,
        setLoading,
        25
      );
    } else if (getImgAiDetectKeys.ai === "ai-all") {
      // console.log("ai-all ppe-new")
      aiALLDetectJson(getUploadedImgUrls1[0]);
    }
    // changeFormTemplateData
    // if (!_.isEmpty(getAIData)) {

    // }
    //pic_url,category
  }, [getUploadedImgUrls1, getImgAiDetectKeys, getImgMetaData]);
  const multiImageUrl = async (_value, _key, _arr_key, fieldType) => {
    let sendDataImg = {
      files: [_value[0]],
      emailId: DECRYPTED_DATA().emailId,
      reportId: uuidv4(),
      upload_type: "JHA",
      section_names: form_template[getProceed].sectionName,
      employeeId: EMP_ID(),
      setLoading: setLoading,
      setSetP: setSetP,
    };

    _value[0] !== "" &&
      (await uploadMultiImage(
        setUploadedImgUrls2,
        sendDataImg,
        setImgUrls,
        fieldType,
        setError
      ));
    _value[0] !== "" &&
      setImgAiDetectKeys2({ section: _value[1], questions: _value[2] });
  };
  useEffect(() => {
    changeFormTemplateData([
      27,
      getUploadedImgUrls2[0],
      getImgAiDetectKeys2.section,
      getImgAiDetectKeys2.questions,
      "multiple_picture",
    ]);
  }, [getUploadedImgUrls2, getImgAiDetectKeys2]);
  const getUploadImgUrls = async (
    _value,
    _key,
    _arr_key,
    fieldType,
    isAiImage = false,
    sectionsToUpdate = []
  ) => {
    // console.log(_value, _key, _arr_key, getProceed, form_template, 'ppe-new', fieldType)
    // console.log(sectionsToUpdate, "YSYSYSYYSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS111")

    //  getUploadImgUrls([e.target.files[0], 0, 2, 'pic'], 4, '0')
    // event.target.files[0] get data from file input and store in temp state setImgUrls()
    let _getImgUrls = _.cloneDeep(getImgUrls);
    let sendDataImg = {};
    switch (_key) {
      case 1:
        _getImgUrls[_arr_key] = _value;
        break;
      case 2:
        // console.log(_arr_key)
        _getImgUrls[_arr_key] = getSignBase64Uri;
        // _getImgUrls[_arr_key] = _value;
        break;
      case 3:
        _getImgUrls[_arr_key] = getSignBase64Uri1;
        break;
      case 4:
        sendDataImg = {
          files: [_value[0]],
          emailId: DECRYPTED_DATA().emailId,
          reportId: uuidv4(),
          upload_type: "JHA",
          section_names: form_template[getProceed].sectionName,
          employeeId: EMP_ID(),
          setLoading: setLoading,
          setSetP: setSetP,
        };
        break;
      //[e.target.files[0], 0, 2, 'ai']

      case 5:
        // // console.log('5555')
        _getImgUrls[_arr_key] = _value;
        break;
      case 6:
        sendDataImg = {
          files: [_value[0]],
          emailId: DECRYPTED_DATA().emailId,
          reportId: uuidv4(),
          upload_type: "JHA",
          section_names: form_template[getProceed].sectionName,
          employeeId: EMP_ID(),
          setLoading: setLoading,
          setSetP: setSetP,
        };
        //[e.target.files[0], 0, 2, 'ai']
        await uploadMultiImage(
          setUploadedImgUrls1,
          sendDataImg,
          setImgUrls,
          fieldType,
          setError
        );

        _value[0] !== "" &&
          setImgAiDetectKeys({
            section: _value[1],
            questions: _value[2],
            ai: _value[3],
          });
        break;
      default:
    }
    if (isAiImage) {
      const response = await checkImageData(
        { doc_file: _value[0] },
        setImgMetaData
      );

      if (fieldType === "PPEHardHat1") {
        // let _form_template = _.cloneDeep(form_template);
        if (sectionsToUpdate.length) {
          sectionsToUpdate.forEach(({ sectionFieldType, sectionId }) => {
            let Timeconversion = parseInt(response.time_diff);
            let message = "";

            if (Timeconversion >= 720) {
              message = "The image is older than 12 hours";
              setError(sectionFieldType, {
                message,
              });
            } else if (response.time_diff === "Time not get extracted") {
              message =
                "This photo does not have date and time information embedded and cannot be verified. Please upload the image captured in last 1 hour only.";
              setError(sectionFieldType, {
                message,
              });
            } else {
              message = "Time Verified";
              setError(sectionFieldType, {
                message1: message,
              });
            }
            //  _form_template[getProceed].section[_value[1]].questions[sectionId].image_metadata = message

            // console.log("CHECKING", _form_template)
            // console.log("CHECKING", _form_template[getProceed].section[_value[1]].questions[sectionId])
          });
        }
        //  console.log("OSOAO", _form_template)
        //  setForm_template(_form_template)
        // console.log("OSOAO", _form_template)
      } else {
        //  let _form_template = _.cloneDeep(form_template);

        let Timeconversion = parseInt(response.time_diff);
        let message = "";
        if (Timeconversion >= 720) {
          message = "The image is older than 12 hours";
          setError(fieldType, {
            message,
          });
        } else if (response.time_diff === "Time not get extracted") {
          message =
            "This photo does not have date and time information embedded and cannot be verified. Please upload the image captured in last 1 hour only.";
          setError(fieldType, {
            message,
          });
        } else {
          message = "Time Verified";
          setError(fieldType, {
            message1: message,
          });
        }

        // _form_template[getProceed].section[_value[1]].questions[_value[2]].image_metadata = message;
        // setForm_template(_form_template)
        // console.log("OSOAO", form_template)
        // console.log(_form_template, "***********INSingle_form_template1343*************")
        await uploadMultiImage(
          setUploadedImgUrls1,
          sendDataImg,
          setImgUrls,
          fieldType,
          setError
        );

        _value[0] !== "" &&
          setImgAiDetectKeys({
            section: _value[1],
            questions: _value[2],
            ai: _value[3],
          });
      }
    }

    // console.log(form_template, "***********INSingle_form_template1566*************")
    await uploadMultiImage(
      setUploadedImgUrls1,
      sendDataImg,
      setImgUrls,
      fieldType,
      setError
    );

    _value[0] !== "" &&
      setImgAiDetectKeys({
        section: _value[1],
        questions: _value[2],
        ai: _value[3],
      });

    // console.log("PSPSPSP", _getImgUrls);
    setImgUrls(_getImgUrls);
  };
  // console.log("PSPSPSP", _getImgUrls);

  const provideLatLngfrmStr = (text) =>
    text
      .split("(")[1]
      .split(")")
      .filter((obj) => obj !== "")[0]
      .split(",")
      .map((obj) => Number(obj));

  // const isSectionCompleted = (_section_id) => {
  //     switch (_section_id) {
  //         case 1:
  //             f
  //             break;
  //         default:

  //     }
  // }
  const saveSendToUploadImg = () => {
    // // console.log(isSectionCompleted(getProceed))
    // console.log(getImgUrls);
    let sendDataImg = {
      files: Object.values(getImgUrls),
      emailId: DECRYPTED_DATA().emailId,
      reportId: uuidv4(),
      upload_type: "JHA",
      section_names: form_template[getProceed].sectionName,
      employeeId: EMP_ID(),
      setLoading: setLoading,
      setSetP: setSetP,
    };
    uploadMultiImage(
      form_template[getProceed].sectionId === "6"
        ? setUploadedImgUrls1
        : setUploadedImgUrls,
      sendDataImg,
      setImgUrls
    );
  };
  const getLocation = () => {
    var _latlng;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        setUnit("imperial");
        _latlng = _.cloneDeep(getLoc);
        _latlng.lat = position.coords.latitude;
        _latlng.lng = position.coords.longitude;
        setLoc(_latlng);
        // // console.log(position);
      });
    } else {
      setErrMsg("Geolocation is not supported by this browser.");
    }
  };
  const formTempChange = () => {
    let _formTemp = _.cloneDeep(form_template);
    if (getFormID === 10) {
      _formTemp[0].section[0]["employeeId"] = EMP_ID().replaceAll('"', "");
      _formTemp[10].section[0]["employeeId"] = EMP_ID().replaceAll('"', "");
      _formTemp[11].section[0]["employeeId"] = EMP_ID().replaceAll('"', "");
      _formTemp[12].section[0]["employeeId"] = EMP_ID().replaceAll('"', "");

      getCrewLeadData.crewmemberdata.map((obj, i) => {
        _formTemp[0].section[i + 1]["employeeId"] = obj.employeeId;
        _formTemp[10].section[i + 1]["employeeId"] = obj.employeeId;
        _formTemp[11].section[i + 1]["employeeId"] = obj.employeeId;
        _formTemp[12].section[i + 1]["employeeId"] = obj.employeeId;
      });
    } else {
      _formTemp[1].section[0]["employeeId"] = EMP_ID().replaceAll('"', "");

      getCrewLeadData.crewmemberdata.map((obj, i) => {
        _formTemp[1].section[i + 1]["employeeId"] = obj.employeeId;
      });
    }

    return _formTemp;
  };
  const start_datetime_filling = () => {
    let _getIjhaform = _.cloneDeep(getIjhaform);
    _getIjhaform["start_time_datetime"] = getCurrentDataTime();
    setIjhaform(_getIjhaform);
  };
  // console.log("CREW_DETAILS:", CREW_DETAILS, "getCrewLeadData:", getCrewLeadData, "CREW_DETAILS-1-out---->getCrewLeadData--->", getCrewLeadData)

  const InprocessFormSubmit = () => {
    let crew_members_id = getCrewLeadData.crewmemberdata
      ? getCrewLeadData.crewmemberdata.map((obj) => obj.employeeId)
      : "";
    let _getIjhaform = _.cloneDeep(getIjhaform);
    _getIjhaform["form_template"] = formTempChange();
    _getIjhaform["reportId"] = getReportID;
    _getIjhaform["teamLeadId"] = EMP_ID().replaceAll('"', "");
    _getIjhaform["crew_members"] = [
      EMP_ID().replaceAll('"', ""),
      ...crew_members_id,
    ];
    _getIjhaform["latitude"] = getLatitude;
    _getIjhaform["longitude"] = getLongitude;
    _getIjhaform["device_details"] = {
      app_version: null,
      device_token: null,
      build_number: null,
      os_version: null,
    };
    _getIjhaform["weather"] = getWeatherData();
    _getIjhaform["state"] = "";
    _getIjhaform["state_id"] = "";
    _getIjhaform["complete_status"] = "0";
    _getIjhaform["usergroupId"] = null;
    _getIjhaform["inProgressSection"] = getProceed;
    _getIjhaform["formId"] = "14";
    _getIjhaform["app_type"] = "PPE Submission";
    _getIjhaform["submitted_datetime"] = getCurrentDataTime();
    _getIjhaform["resubmit"] = !_.isEmpty(getIjhaform.reportId) ? "1" : "0";
    _getIjhaform["form_name"] = "PPE Submission";
    setIjhaform(_getIjhaform);
    ijhaInCompleteFormSubmit(_getIjhaform, setLoading);
  };

  const onSubmitPPEForm = async () => {
    // console.log(getCrewLeadData, "CREW_DETAILS-1")
    setSummary(false);
    let crew_members_id = getCrewLeadData.crewmemberdata
      ? getCrewLeadData.crewmemberdata.map((obj) => obj.employeeId)
      : "";
    let _getIjhaform = _.cloneDeep(getIjhaform);
    _getIjhaform["form_template"] = formTempChange();
    _getIjhaform["reportId"] = getReportID;
    _getIjhaform["teamLeadId"] = EMP_ID().replaceAll('"', "");
    _getIjhaform["crew_members"] = [
      EMP_ID().replaceAll('"', ""),
      ...crew_members_id,
    ];
    _getIjhaform["latitude"] = getLatitude;
    _getIjhaform["longitude"] = getLongitude;
    _getIjhaform["device_details"] = {
      app_version: null,
      device_token: null,
      build_number: null,
      os_version: null,
    };
    _getIjhaform["weather"] = await getWeatherData();
    _getIjhaform["state"] = "";
    _getIjhaform["state_id"] = "";
    _getIjhaform["complete_status"] = "1";
    _getIjhaform["usergroupId"] = null;
    _getIjhaform["formId"] = "14";
    _getIjhaform["app_type"] = "PPE Submission";
    _getIjhaform["submitted_datetime"] = getCurrentDataTime();
    _getIjhaform["total_time_taken"] = getTotalTime(
      _getIjhaform["start_time_datetime"],
      _getIjhaform["submitted_datetime"]
    );
    _getIjhaform["resubmit"] = !_.isEmpty(getIjhaform.reportId) ? "1" : "0";
    _getIjhaform["form_name"] = "PPE Submission";

    ijhaFormSubmit(_getIjhaform, setLoading, setIjhaSubmit);

    //  setIjhaform(_getIjhaform);
  };

  const getWeatherData = async () => {
    let { lat, lng } = getLoc;
    // console.log(lat, lng)

    const weatherData = await getWeatherApi(lat, lng);
    // console.log(weatherData, '--->_weatherDetails');
    let _weatherDetails = [];
    if (weatherData.status_code === 200) {
      _weatherDetails = weatherData.data;

      const unitFC = "°F";

      _weatherDetails["results"][0]["cloudCover"] =
        _weatherDetails["results"][0]["cloudCover"] + "%";
      _weatherDetails["results"][0]["dewPoint"]["value"] =
        _weatherDetails["results"][0]["dewPoint"]["value"] + " " + unitFC;
      _weatherDetails["results"][0]["pressure"]["value"] =
        _weatherDetails["results"][0]["pressure"]["value"] +
        " " +
        _weatherDetails["results"][0]["pressure"]["unit"];
      _weatherDetails["results"][0]["realFeelTemperature"]["value"] =
        _weatherDetails["results"][0]["realFeelTemperature"]["value"] +
        " " +
        unitFC;
      _weatherDetails["results"][0]["relativeHumidity"] =
        _weatherDetails["results"][0]["relativeHumidity"] + "%";
      _weatherDetails["results"][0]["temperature"]["value"] =
        _weatherDetails["results"][0]["temperature"]["value"] + " " + unitFC;
      _weatherDetails["results"][0]["visibility"]["value"] =
        _weatherDetails["results"][0]["visibility"]["value"] +
        " " +
        _weatherDetails["results"][0]["visibility"]["unit"];
      _weatherDetails["results"][0]["wind"]["speed"]["value"] =
        _weatherDetails["results"][0]["wind"]["speed"]["value"] +
        " " +
        _weatherDetails["results"][0]["wind"]["speed"]["unit"];
      _weatherDetails["results"][0]["windGust"]["speed"]["value"] =
        _weatherDetails["results"][0]["windGust"]["speed"]["value"] +
        " " +
        _weatherDetails["results"][0]["windGust"]["speed"]["unit"];
      return _weatherDetails;
    }
    // console.log(_weatherDetails, '--->weatherData change');
    return [];
  };

  const weatherDetailsChange = () => {
    let _weatherDetails = _.cloneDeep(
      form_template[6].questions[0].weather_imperial
    );
    let unitFC = "";
    switch (radioValue) {
      case "imperial":
        unitFC = wheather_radio[0].name;
        break;
      case "metric":
        unitFC = wheather_radio[1].name;
        break;
      default:
    }
    _weatherDetails["results"][0]["cloudCover"] =
      _weatherDetails["results"][0]["cloudCover"] + "%";
    _weatherDetails["results"][0]["dewPoint"]["value"] =
      _weatherDetails["results"][0]["dewPoint"]["value"] + " " + unitFC;
    _weatherDetails["results"][0]["pressure"]["value"] =
      _weatherDetails["results"][0]["pressure"]["value"] +
      " " +
      _weatherDetails["results"][0]["pressure"]["unit"];
    _weatherDetails["results"][0]["realFeelTemperature"]["value"] =
      _weatherDetails["results"][0]["realFeelTemperature"]["value"] +
      " " +
      unitFC;
    _weatherDetails["results"][0]["relativeHumidity"] =
      _weatherDetails["results"][0]["relativeHumidity"] + "%";
    _weatherDetails["results"][0]["temperature"]["value"] =
      _weatherDetails["results"][0]["temperature"]["value"] + " " + unitFC;
    _weatherDetails["results"][0]["visibility"]["value"] =
      _weatherDetails["results"][0]["visibility"]["value"] +
      " " +
      _weatherDetails["results"][0]["visibility"]["unit"];
    _weatherDetails["results"][0]["wind"]["speed"]["value"] =
      _weatherDetails["results"][0]["wind"]["speed"]["value"] +
      " " +
      _weatherDetails["results"][0]["wind"]["speed"]["unit"];
    _weatherDetails["results"][0]["windGust"]["speed"]["value"] =
      _weatherDetails["results"][0]["windGust"]["speed"]["value"] +
      " " +
      _weatherDetails["results"][0]["windGust"]["speed"]["unit"];
    return _weatherDetails;
  };
  const changeFormTemplateData = (args) => {
    const [_type, _value, _key, _s_key, _key_name, _attri] = [...args];
    let first_part, second_part, latitude, longitude;
    let _form_template = _.cloneDeep(form_template);
    // console.log('data::12344', _form_template);
    // console.log(_type, _value, _key, _s_key, _form_template[getProceed].sectionName);
    switch (_type) {
      case 1:
        //common
        _form_template[getProceed].questions[_key].answer = _value;
        break;
      case 2:
        //add only first part of the string for eg 'data1-data2'
        first_part = _value;
        second_part = getSecondPart(
          _form_template[getProceed].questions[_key].answer
        );
        _form_template[getProceed].questions[_key].answer = first_part.concat(
          "-",
          second_part
        );
        break;
      case 3:
        //add only second part of the string for eg 'data1-data2'
        first_part = getFirstPart(
          _form_template[getProceed].questions[_key].answer
        );
        second_part = _value;
        _form_template[getProceed].questions[_key].answer = first_part.concat(
          "-",
          second_part
        );
        break;
      case 4:
        // add latitute and logintute in address field
        let data_loc = "";
        // console.log('667', _form_template[getProceed].questions[_key].answer, _value)
        if (_form_template[getProceed].questions[_key].answer !== "") {
          data_loc =
            _form_template[getProceed].questions[_key].answer.split("(")[0];
        }

        latitude = getLatitude;
        longitude = getLongitude;
        if (typeof _value === "object") {
          _form_template[getProceed].questions[_key].answer = _value[0];
          //set distance and duration
          _form_template[getProceed].questions[_key].distance = _value[1];
          _form_template[getProceed].questions[_key].duration = _value[2];
        } else {
          _form_template[getProceed].questions[_key].answer = _value;
        }
        let latLog = provideLatLngfrmStr(
          _form_template[getProceed].questions[_key].answer
        );
        _form_template[getProceed].questions[_key].lat = latLog[0];
        _form_template[getProceed].questions[_key].long = latLog[1];
        //  + ' ' + data_loc + " (" + latitude + ',' + longitude + ')'
        break;
      case 5:
        //add subquestion
        _form_template[getProceed].questions[_key].subQuestion[_s_key].answer =
          _value;
        break;
      case 6:
        //description
        _form_template[getProceed].questions[_key].description = _value;
        break;
      case 7:
        // section
        //methodType AI

        if (
          _form_template[getProceed].section[_key].questions[_s_key]
            .methodType === "AI" &&
          _form_template[getProceed].section[_key].questions[_s_key]
            .confidence !== "" &&
          _form_template[getProceed].section[_key].questions[_s_key].answer ===
            "No"
        ) {
          // console.log('run', _value)
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].overridden = _value;
        } else {
          _form_template[getProceed].section[_key].questions[_s_key].answer =
            _value;
        }
        break;
      case 8:
        //section description
        _form_template[getProceed].section[_key].questions[_s_key].description =
          _value;
        break;
      case 9:
        //section img add in json 'signature' & 'picture'
        _form_template[getProceed].section[_key].questions[_s_key]["picture"] =
          _value[0];
        _form_template[getProceed].section[_key].questions[_s_key][
          "signature"
        ] = _value[1];
        break;
      case 10:
        _form_template[getProceed].questions[0]["picture"] = _value[0];
        _form_template[getProceed].questions[2]["picture"] = _value[1];
        _form_template[getProceed].questions[3]["picture"] = _value[2];
        break;
      case 12:
        _form_template[getProceed].questions[0]["picture"] = _value[0];
        _form_template[getProceed].questions[1]["picture"] = _value[1];
        _form_template[getProceed].questions[2]["picture"] = _value[2];
        _form_template[getProceed].questions[3]["picture"] = _value[3];
        break;
      case 13:
        //set ai and images related data
        if (typeof _value === "object") {
          // console.log("SASISIISISISI", _value)
          _form_template[getProceed].section[_key].questions[_s_key].picture =
            _value[0];
          // console.log("YSYSYASASAISISII", _value)
          _form_template[getProceed].section[_key].questions[_s_key].fileType =
            getFileTypeImgDoc(_value[0]);
          _form_template[getProceed].section[_key].questions[_s_key].answer =
            _value[1];
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].confidence = _value[2];
          _form_template[getProceed].section[_key].questions[_s_key].threshold =
            _value[3];
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].other_confidence = _value[4];
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].ai_approved = _value[5];
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].image_metadata = getImgMetaData;
        } else {
          _form_template[getProceed].section[_key].questions[_s_key].picture =
            _value;
          _form_template[getProceed].section[_key].questions[_s_key].fileType =
            getFileTypeImgDoc(_value);
        }
        // "overridden": "",
        // _form_template[getProceed].section[_key].questions[1]['picture'] = _value[1];
        // _form_template[getProceed].section[_key].questions[2]['picture'] = _value[2];
        // _form_template[getProceed].section[_key].questions[3]['picture'] = _value[3];
        // _form_template[getProceed].section[_key].questions[4]['picture'] = _value[4];
        // _form_template[getProceed].section[_key].questions[5]['picture'] = _value[5];
        // _form_template[getProceed].section[_key].questions[6]['picture'] = _value[6];
        break;
      case 14:
        _form_template[getProceed].section[_key].questions[2]["picture"] =
          _value[0];
        _form_template[getProceed].section[_key].questions[2]["signature"] =
          _value[1];

        break;

      case 15:
        //for setting address call 15 -> 4
        // modalMapHandler(_value, getAddress, changeFormTemplateData, _key);

        setAddress(
          removeTags(form_template[getProceed].questions[_key].answer)
        );
        // console.log("Vicky ::15::", _value, getAddress, changeFormTemplateData, _key, form_template[getProceed].questions[_key].lat, form_template[getProceed].questions[_key].long)
        modalMapHandler(
          _value,
          getAddress,
          changeFormTemplateData,
          _key,
          form_template[getProceed].questions[_key].lat,
          form_template[getProceed].questions[_key].long
        );

        break;
      case 16:
        //for setting address call 16 -> 4
        //map direction
        setAddress(
          removeTags(form_template[getProceed].questions[_key].answer)
        );
        modalMapDirectionHandler(
          _value,
          getAddress,
          changeFormTemplateData,
          _key
        );
        break;
      case 17:
        //add subquestion picture
        _form_template[getProceed].questions[_key].subQuestion[_s_key][
          "picture"
        ] = _value[0];
        // console.log("getFileTypeImgDoc(_value[0])", getFileTypeImgDoc(_value[0]));
        _form_template[getProceed].questions[_key].subQuestion[_s_key][
          "fileType"
        ] = getFileTypeImgDoc(_value[0]);
        // if (_value[0].match(/\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)\b/)) {
        //     //Image
        //     _form_template[getProceed].questions[_key].subQuestion[_s_key]['fileType'] = 'Image';
        // } else if (_value[0].match(/\.(PDF|pdf)\b/)) {
        //     //Document
        //     _form_template[getProceed].questions[_key].subQuestion[_s_key]['fileType'] = 'Document';
        // } else {
        //     //null
        //     _form_template[getProceed].questions[_key].subQuestion[_s_key]['fileType'] = '';
        // }
        break;
      case 18:
        // [18,stateoptionlist(_value),key,s_key]
        _form_template[getProceed].questions[_key].table_options[
          _s_key
        ].selected_options = _value;
        break;
      case 19:
        // changeFormTemplateData([19, getUploadedImgUrls1[0], getImgAiDetectKeys.section, getImgAiDetectKeys.questions, 'picture']);
        // changeFormTemplateData([19, '', 0, 0, 'picture']);
        // [_type, _value, _key, _s_key, _key_name, _attri
        // _form_template[getProceed].section[_key].questions[2]['picture'] = _value[0];

        _form_template[getProceed].section[_key].questions[_s_key][_key_name] =
          _value;
        if (_key_name === "picture")
          _form_template[getProceed].section[_key].questions[_s_key][
            "fileType"
          ] = getFileTypeImgDoc(_value);
        // console.log(_type, _value, _key, _s_key, _key_name, _attri);
        break;
      case 20:
        _form_template[getProceed].questions[_key].subQuestion[_s_key][
          _key_name
        ] = _value;
        _form_template[getProceed].questions[_key].subQuestion[_s_key][
          "fileType"
        ] = getFileTypeImgDoc(_value);
        break;
      case 21:
        // _form_template[getProceed].questions[0]['picture'] = _value[0];
        _form_template[getProceed].questions[_key][_key_name] = _value;
        _form_template[getProceed].questions[_key]["fileType"] =
          getFileTypeImgDoc(_value);
        break;
      case 22:
        //for setting address call 15 -> 4
        setAddress(
          removeTags(form_template[getProceed].questions[_key].answer)
        );
        modalMapHandler1(
          _value,
          getAddress,
          changeFormTemplateData,
          _key,
          form_template[getProceed].questions[_key].lat,
          form_template[getProceed].questions[_key].long
        );
        break;
      case 23:
        if (typeof _value === "object") {
          _form_template[getProceed].questions[_key].answer = _value[0];
          //set distance and duration
          _form_template[getProceed].questions[_key].lat = _value[1];
          _form_template[getProceed].questions[_key].long = _value[2];
        }
        break;
      case 24:
        // console.log(_value, '24')
        _form_template[getProceed].section[
          _key
        ].questions[0].checkbox_model[0].value = _value;
        break;
      case 25:
        //set ai and images related data
        //Hazard Controls -ai
        if (typeof _value === "object") {
          _form_template[getProceed].questions[_key].picture = _value[0];
          _form_template[getProceed].questions[_key].fileType =
            getFileTypeImgDoc(_value[0]);
          _form_template[getProceed].questions[_key].answer = _value[1];
          _form_template[getProceed].questions[_key].confidence = _value[2];
          _form_template[getProceed].questions[_key].threshold = _value[3];
          _form_template[getProceed].questions[_key].other_confidence =
            _value[4];
          _form_template[getProceed].questions[_key].ai_approved = _value[5];
        } else {
          _form_template[getProceed].questions[_key].picture = _value;
          _form_template[getProceed].questions[_key].fileType =
            getFileTypeImgDoc(_value);
        }
        break;
      case 26:
        // section
        //methodType AI hazard control
        if (
          _form_template[getProceed].questions[_key].methodType === "AI" &&
          _form_template[getProceed].questions[_key].confidence !== "" &&
          _form_template[getProceed].questions[_key].answer === "No"
        ) {
          // console.log('run', _value)
          _form_template[getProceed].questions[_key].overridden = _value;
        } else {
          _form_template[getProceed].questions[_key].answer = _value;
        }
        break;
      case 27:
        // console.log(_value, '27')
        if (_value !== undefined) {
          // const __value = _value;
          // setUploadedImgUrls1([]);
          // setImgAiDetectKeys({});
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].multiple_picture.push(_value);
          // console.log(_form_template[getProceed].section[_key].questions[_s_key].multiple_picture, '27')
        }
        // _form_template[getProceed].questions[_key][_key_name] = _value;
        // _form_template[getProceed].questions[_key]['fileType'] = getFileTypeImgDoc(_value);
        break;
      case 28:
        //add only first part of the string for eg 'data1-data2'
        first_part = _value;
        if (_value === "Other") {
          setCustomername1(true);
          second_part = "";
          setError("customername2", { message: "This field is required" });
          _form_template[getProceed].questions[_key].answer = first_part.concat(
            " - ",
            second_part
          );
        } else {
          setCustomername1(false);
          setError("customername2", { message: "" });
          second_part = getSecondPart(
            _form_template[getProceed].questions[_key].answer
          );
          _form_template[getProceed].questions[_key].answer = first_part;
        }
        break;

      case 29:
        //add only second part of the string for eg 'data1-data2'
        first_part = getFirstPart(
          _form_template[getProceed].questions[_key].answer
        );
        second_part = _value;
        _form_template[getProceed].questions[_key].answer = first_part.concat(
          "-",
          second_part
        );
        break;
      case 30:
        //add only first part of the string for eg 'data1-data2'
        first_part = _value;
        if (_value === "Other") {
          setError("siteLanLord2", { message: "This field is required" });
          setSiteLandLord(true);
          second_part = "";
          _form_template[getProceed].questions[_key].answer = first_part.concat(
            "-",
            second_part
          );
        } else {
          setSiteLandLord(false);
          setError("siteLanLord2", { message: "" });
          second_part = getSecondPart(
            _form_template[getProceed].questions[_key].answer
          );
          _form_template[getProceed].questions[_key].answer = first_part.concat(
            "-",
            second_part
          );
        }
        break;

      case 31:
        //add only second part of the string for eg 'data1-data2'
        first_part = getFirstPart(
          _form_template[getProceed].questions[_key].answer
        );
        second_part = _value;
        _form_template[getProceed].questions[_key].answer = first_part.concat(
          "-",
          second_part
        );
        break;

      case 32:
        // remove Images
        if (
          _form_template[getProceed].section[_key].questions[_s_key].picture !==
          ""
        ) {
          _form_template[getProceed].section[_key].questions[_s_key].picture =
            "";
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].confidence = "";
          _form_template[getProceed].section[_key].questions[_s_key].answer =
            "No";
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].image_metadata = "";
          _form_template[getProceed].section[_key].questions[
            _s_key
          ].ai_approved = "";
          if (
            !_.isEmpty(
              _form_template[getProceed].section[_key].questions[_s_key]
                .other_confidence
            )
          ) {
            _form_template[getProceed].section[_key].questions[
              _s_key
            ].other_confidence[_key_name] = "";
            _form_template[getProceed].section[_key].questions[
              _s_key
            ].other_confidence.status = "";
          }
          setError(_value, { message: "" });
        }
        break;

      default:
    }
    // console.log("hiiiiii-1")
    setForm_template(_form_template);
    // !_.isEmpty(getUploadedImgUrls1) && setUploadedImgUrls1([])
  };
  useEffect(() => {
    // // console.log(getHCMOptions)

    // console.log('getSelectedOptionsMulti1::', getSelectedOptionsMulti1, getHCMOptions);
    let _form_template = _.cloneDeep(form_template);
    let _getHCMOptions = getHCMOptions.map((obj) => obj.value);
    if (_form_template[8] !== undefined) {
      for (var i = 0; i < getHCMOptions.length; i++) {
        let _value = getSelectedOptionsMulti1[getHCMOptions[i]["_s_key"]].length
          ? getSelectedOptionsMulti1[getHCMOptions[i]["_s_key"]].map(
              (obj) => obj.value
            )
          : [];
        _form_template[8].questions[0].table_options[
          getHCMOptions[i]["_s_key"]
        ].selected_options = _value;
        _form_template[8].questions[0].table_options[
          getHCMOptions[i]["_s_key"]
        ].pre_selected_options = _value;
        _form_template[8].questions[0].table_options[
          getHCMOptions[i]["_s_key"]
        ].comment_text = getCommentMulti1[getHCMOptions[i]["_s_key"]];
      }

      if (getHCMOptions.length !== 0) {
        for (
          let j = 0;
          j < _form_template[8].questions[0].table_options.length;
          j++
        ) {
          if (
            _getHCMOptions.includes(
              _form_template[8].questions[0].table_options[j].question
            )
          ) {
            _form_template[8].questions[0].table_options[j].common = true;
          } else {
            _form_template[8].questions[0].table_options[j].common = false;
          }
        }
      } else {
        for (
          let j = 0;
          j < _form_template[8].questions[0].table_options.length;
          j++
        ) {
          _form_template[8].questions[0].table_options[j].common = false;
        }
      }
    }
    // // console.log("hiiiiii-2", form_template[4].questions[7].subQuestion[0].answer)
    setForm_template(_form_template);
    // // console.log(_form_template[8].questions[0].table_options);
    // _form_template[getProceed].questions[_key].table_options[_s_key]
    // changeFormTemplateData([18, []])
  }, [getHCMOptions, getSelectedOptionsMulti1, getCommentMulti1]);

  // Personal Fall Arrest System
  if (form_template[11] !== undefined) {
    var PFASOptions = form_template[11].section[
      getCrewMemberProceed.crew
    ].questions[0].multiselect_options.map((obj, i) => {
      return {
        value: obj.value,
        label: obj.value,
        color: "#00B8D9",
        isFixed: obj.is_selected,
        _is_selected: obj.is_selected,
        file_obj: "",
        file_url: "",
        _key: 0,
        _s_key: i,
      };
    });
  }

  useEffect(() => {
    let _form_template = _.cloneDeep(form_template);
    let _getPFASOptions = getPFASOptions.map((obj) => obj.value);
    // console.log("asdasd: 2", _getPFASOptions);
    if (_form_template[11] !== undefined) {
      if (_getPFASOptions.includes("Other")) {
        modalOtherHandler();
      } else {
        if (getPFASOptions.length !== 0) {
          for (
            let j = 0;
            j <
            _form_template[11].section[getCrewMemberProceed.crew].questions[0]
              .multiselect_options.length;
            j++
          ) {
            if (
              _getPFASOptions.includes(
                _form_template[11].section[getCrewMemberProceed.crew]
                  .questions[0].multiselect_options[j].value
              )
            ) {
              _form_template[11].section[
                getCrewMemberProceed.crew
              ].questions[0].multiselect_options[j].is_selected = true;
            } else {
              _form_template[11].section[
                getCrewMemberProceed.crew
              ].questions[0].multiselect_options[j].is_selected = false;
            }
          }
        } else {
          for (
            let j = 0;
            j <
            _form_template[11].section[getCrewMemberProceed.crew].questions[0]
              .multiselect_options.length;
            j++
          ) {
            if (
              _form_template[11].section[getCrewMemberProceed.crew].questions[0]
                .multiselect_options[j].is_selected !== true
            )
              _form_template[11].section[
                getCrewMemberProceed.crew
              ].questions[0].multiselect_options[j].is_selected = false;
          }
        }
        // console.log("hiiiiii-3", form_template[4].questions[7].subQuestion[0].answer)
      }
    }
    setForm_template(_form_template);
  }, [getPFASOptions]);
  // // console.log(form_template);
  function getSecondPart(str) {
    // // console.log(str)
    return str.split("-")[1];
  }
  function getFirstPart(str) {
    // if (getProceed === 1) {

    // }
    return str.split("-")[0];
  }
  function getAddMoreDailyTaskFunc() {
    let _getAddMoreDailyTask = _.cloneDeep(getAddMoreDailyTask);
    let _form_template = _.cloneDeep(form_template);
    _form_template[2].questions[1].multipleQuestion.push({
      questions: [
        {
          question: "Task",
          questionType: "Description",
          description: "",
        },
        {
          question: "Hazards",
          questionType: "Description",
          description: "",
        },
        {
          question: "Mitigations",
          questionType: "Description",
          description: "",
        },
      ],
    });
    // console.log("hiiiiii-4")
    setForm_template(_form_template);
    // _getAddMoreDailyTask.push({ dailyTask: <DailyTask form_template={form_template} setForm_template={setForm_template} /> });

    // setAddMoreDailyTask(_getAddMoreDailyTask);
  }

  function PPEgetAddMoreDailyTaskFunc() {
    let _getAddMoreDailyTask = _.cloneDeep(getAddMoreDailyTask);
    let _form_template = _.cloneDeep(form_template);
    _form_template[1].questions[1].multipleQuestion.push({
      questions: [
        {
          question: "Task",
          questionType: "Description",
          description: "",
        },
        {
          question: "Hazards",
          questionType: "Description",
          description: "",
        },
        {
          question: "Mitigations",
          questionType: "Description",
          description: "",
        },
      ],
    });
    // console.log("hiiiiii-4")
    setForm_template(_form_template);
  }

  if (form_template[8] !== undefined) {
    var HCMOptions = form_template[8].questions[0].table_options.map(
      (obj, i) => {
        return {
          value: obj.question,
          label: obj.question,
          color: "#00B8D9",
          isFixed: obj.selected_options.length ? true : false,
          _options: obj.options.map((s_obj) => {
            return {
              value: s_obj,
              label: s_obj,
              color: "#00B8D9",
            };
          }),
          _key: 0,
          _s_key: i,
        };
      }
    );
  }

  const HMCAllMultiSelect = getHCMOptions.map((obj, k) => {
    // console.log('getSelectedOptionsMulti::', getSelectedOptionsMulti obj._s_key);
    return (
      <Form.Group
        controlId="form-7-1"
        key={k}
        className="mb-3 ms-3 shadow-lg p-3 mb-5 rounded"
      >
        <Form.Label>{obj.value}</Form.Label>
        <MultiSelect1
          options={obj._options}
          getOption={getSelectedOptionsMulti1}
          setOption={setSelectedOptionsMulti1}
          _s_key={obj._s_key}
          getCommentMulti1={getCommentMulti1}
          setCommentMulti1={setCommentMulti1}
        />
      </Form.Group>
    );
  });

  const onProceedHandler = () => {
    InprocessFormSubmit();
    setSetP(false);
    // !_.isEmpty(getIjhaform.reportId) && setpbtn(false);
    // console.log(!_.isEmpty(getIjhaform.reportId), 'onprocee')
    // setReportIJHACount(0);
    setProceed(getProceed + 1);
  };

  const onCrewProceedHandler = () => {
    InprocessFormSubmit();
    // !_.isEmpty(getIjhaform.reportId) && setpbtn(false);
    setSetP(false);
    // console.log(getProceed, 'asdadad---setpbtn', getpbtn, form_template[12].section[getCrewMemberProceed.crew].questions[2].signature, getSignBase64Uri1)
    if (getFormID === 10) {
      if (
        getProceed === 11 &&
        form_template[12].section[getCrewMemberProceed.crew].questions[2]
          .signature === ""
      ) {
        setpbtn(true);
        setSignBase64Uri1("");
        // console.log(getProceed, 'true asdadad---setpbtn', getpbtn)
      }
    } else {
      if (
        getProceed === 2 &&
        form_template[3].section[getCrewMemberProceed.crew].questions[1]
          .signature === ""
      ) {
        setpbtn(true);
        setSignBase64Uri1("");
        // console.log(getProceed, 'true asdadad---setpbtn', getpbtn)
      }
    }
    // console.log(getProceed, 'getproceed')
    if (getFormID === 10) {
      if (getProceed >= 10) {
        setProceed(getProceed + 1);
      } else if (getProceed === 0) {
        setProceed(10);
      }
    } else {
      // console.log("IIIOOOAOAOAOAO", getProceed)
      if (getProceed >= 2) {
        setProceed(getProceed + 1);
      } else if (getProceed === 0) {
        setProceed(1);
      }
    }
  };
  const proceedOrSave = () => {
    switch (getProceed) {
      case 0:
        return (
          form_template[0].section[0].questions[0].picture !== "" &&
          form_template[0].section[0].questions[0].signature !== ""
        );
      case 2:
        return form_template[2].questions[3].subQuestion[0].picture !== "";
      case 7:
        return (
          form_template[7].questions[0].picture !== "" &&
          form_template[7].questions[2].picture !== "" &&
          form_template[7].questions[3].picture !== ""
        );
      case 9:
        return (
          form_template[9].questions[0].picture !== "" &&
          form_template[9].questions[1].picture !== "" &&
          form_template[9].questions[2].picture !== "" &&
          form_template[9].questions[3].picture
        );
      case 10:
        return (
          form_template[10].section[0].questions[0].picture !== "" &&
          typeof form_template[10].section[0].questions[0].picture !==
            "undefined" &&
          form_template[10].section[0].questions[1].picture !== "" &&
          typeof form_template[10].section[0].questions[1].picture !==
            "undefined" &&
          form_template[10].section[0].questions[6].picture !== ""
        );
      case 12:
        return (
          form_template[12].section[0].questions[2].picture &&
          form_template[12].section[0].questions[2].signature !== ""
        );
      default:
    }
  };
  const addMorePPEOther = () => {
    let _form_template = _.cloneDeep(form_template);
    _form_template[10].section[getCrewMemberProceed.crew].questions.push({
      answer: "",
      cameraType: "Front",
      category: "",
      confidence: "",
      message: "Please show the PPE",
      methodType: "Manual",
      multiple_picture: [],
      options: [],
      other_question: true,
      overridden: "",
      picName: "ppe_other",
      picture: "",
      pictureRequired: "Yes",
      question: "PPE - Other",
      questionId: "54",
      questionType: "Image+Text",
      state: "Optional",
      threshold: "",
      validation: {
        errorMessage: "Please upload the picture",
      },
      visibility: true,
    });

    // setError('PPEOther' + _form_template[10].section[0].questions.length - 1, { message: '' })
    setForm_template(_form_template);
  };

  const PPEaddMorePPEOther = () => {
    let _form_template = _.cloneDeep(form_template);
    _form_template[2].section[getCrewMemberProceed.crew].questions.push({
      answer: "",
      cameraType: "Front",
      category: "",
      confidence: "",
      message: "Please show the PPE",
      methodType: "Manual",
      multiple_picture: [],
      options: [],
      other_question: true,
      overridden: "",
      picName: "ppe_other",
      picture: "",
      pictureRequired: "Yes",
      question: "PPE - Other",
      questionId: "54",
      questionType: "Image+Text",
      state: "Optional",
      threshold: "",
      validation: {
        errorMessage: "Please upload the picture",
      },
      visibility: true,
    });

    // setError('PPEOther' + _form_template[10].section[0].questions.length - 1, { message: '' })
    setForm_template(_form_template);
  };

  const weatherIcon = (iconPhrase) => {
    if (iconPhrase !== null && iconPhrase === "clear") return ic_wind;
    else if (iconPhrase !== null && iconPhrase === "sunny") return ic_sunny;
    else if (
      (iconPhrase !== null && iconPhrase === "Mostly cloudy") ||
      iconPhrase === "Partly cloudy" ||
      iconPhrase === "Mostly sunny" ||
      iconPhrase === "Mostly clear"
    )
      return ic_partly_sunny;
    else if (iconPhrase !== null && iconPhrase === "rain") return ic_rain;
    else return ic_partly_sunny;
  };
  // console.log("return::data:::", getProceed, '::::', form_template[getProceed]);
  // console.log("return::data:::", form_template);
  // console.log("return::data::onSubmitIJHAForm:", getIjhaform);
  // console.log('URL::', form_template[0].section[0].questions[0].picture)
  // console.log("vicky: getoptions Personal Fall Arrest System", form_template[11].section[0].questions[0].multiselect_options, form_template[11].section[0].questions[0].multiple_picture)
  if (form_template[4] !== undefined) {
    if (form_template[4].questions[3].distance === "-1") {
      form_template[4].questions[3].distance = "Not Available";
    }
    if (form_template[4].questions[3].duration === "-1") {
      form_template[4].questions[3].duration = "Not Available";
    }
    if (form_template[4].questions[4].distance === "-1") {
      form_template[4].questions[4].distance = "Not Available";
    }
    if (form_template[4].questions[4].duration === "-1") {
      form_template[4].questions[4].duration = "Not Available";
    }
  }

  useEffect(() => {
    if (getReportCrewCheck) {
      let x = getCrewLeadData.crewmemberdata;
      let y = getCrewMemData;
      let z = [...y, ...x];
      let _getCrewLeadData = _.cloneDeep(getCrewLeadData);
      _getCrewLeadData.crewmemberdata = z;
      setCrewLeadData(_getCrewLeadData);
      //  setCrewLeadData(_getCrewLeadData);
      const crewFilter = _getCrewLeadData.crewmemberdata.filter(
        (i) => i.emailId !== "" || i.firstName !== ""
      );
      const crewFilterLen = crewFilter.length + 1;
      let formNo;

      if (getFormID === 10) {
        formNo = 0;
      } else {
        formNo = 1;
      }
      if (form_template[formNo].section.length <= crewFilterLen) {
        let _final_CrewForm_array = [];
        const preEmp = y.map((i, keys) => {
          return _final_CrewForm_array.push(i.employeeId);
        });
        let _final_form_template_array = [];
        let _final_form_template = _.cloneDeep(form_template);
        _final_form_template = _final_form_template.filter((obj, k) => {
          if ("section" in obj) {
            let zEmpty = [];
            let preEmp = obj.section.filter((objt, i) => {
              if (i === 0) {
                return zEmpty.push(objt);
              } else {
                _final_CrewForm_array.filter((ele, k) => {
                  if (objt.employeeId === ele) {
                    return zEmpty.push(objt);
                  }
                });
              }
            });
            return _final_form_template_array.push({
              section: zEmpty,
              sectionId: obj.sectionId,
              sectionName: obj.sectionName,
              visibility: obj.visibility,
            });
          }
          return _final_form_template_array.push(obj);
        });

        const count = x.length;
        let _form_template = _.cloneDeep(_final_form_template_array);
        _form_template = _form_template.map((obj, k) => {
          if (!_.isEmpty(obj.section)) {
            for (let i = 0; i < count; i++) {
              const sectionLenght = obj.section.length;
              obj.section[sectionLenght] = _.cloneDeep(
                getAllFormsData.form_template[k].section[0]
              );
            }
          }
          return obj;
        });
        setForm_template(_form_template);
        let _getIjhaform = _.cloneDeep(getIjhaform);
        _getIjhaform.form_template = _form_template;
        setIjhaform(_getIjhaform);
      } else {
        let _final_CrewForm_array = [];
        const x = getCrewMemData.map((i, keys) => {
          return _final_CrewForm_array.push(i.employeeId);
        });
        let _final_form_template_array = [];
        let _final_form_template = _.cloneDeep(form_template);
        _final_form_template = _final_form_template.filter((obj, k) => {
          if ("section" in obj) {
            let z = [];
            let x = obj.section.filter((objt, i) => {
              if (i === 0) {
                return z.push(objt);
              } else {
                _final_CrewForm_array.filter((ele, k) => {
                  if (objt.employeeId === ele) {
                    return z.push(objt);
                  }
                });
              }
            });
            return _final_form_template_array.push({
              section: z,
              sectionId: obj.sectionId,
              sectionName: obj.sectionName,
              visibility: obj.visibility,
            });
          }
          return _final_form_template_array.push(obj);
        });
        setForm_template(_final_form_template_array);
        let _getIjhaform = _.cloneDeep(getIjhaform);
        _getIjhaform.form_template = _final_form_template_array;
        setIjhaform(_getIjhaform);
      }

      start_datetime_filling();
      setReportIJHA(false);
      setLoading(false);
    }
  }, [getReportCrewCheck]);

  const getIJHForm = async (_getCrewLeadData) => {
    let _final_CrewForm_array = [];
    const x = _getCrewLeadData.map((i, keys) => {
      return _final_CrewForm_array.push(i.employeeId);
    });
    if (_final_CrewForm_array[0] === undefined) {
      let _final_form_template_array = [];
      let _final_form_template = _.cloneDeep(form_template);
      _final_form_template = _final_form_template.filter((obj, k) => {
        if ("section" in obj) {
          let z = [];
          let x = obj.section.filter((objt, i) => {
            if (i === 0) {
              return z.push(objt);
            }
          });
          return _final_form_template_array.push({
            section: z,
            sectionId: obj.sectionId,
            sectionName: obj.sectionName,
            visibility: obj.visibility,
          });
        }
        return _final_form_template_array.push(obj);
      });
      setForm_template(_final_form_template_array);
    }

    setAddEditCrew(_getCrewLeadData);
    let counter = false;
    let _getBlankData = _.cloneDeep(_getCrewLeadData);
    _getBlankData = _getBlankData.filter((_blank) => {
      if (!("employeeId" in _blank)) {
        return (counter = true);
      }
    });

    if (counter) {
      await getAddCrewMemberApi(setCrewLeadData, setLoading, setErrMsg);
      if (getFormID === 10) {
        const _ijhaform = await getFormIjhaApi(
          10,
          getLoc,
          setLoading,
          setErrMsg
        );
        setAllFormsData(_ijhaform.data);
      } else {
        const _ijhaform = await getFormIjhaApi(
          14,
          getLoc,
          setLoading,
          setErrMsg
        );
        setAllFormsData(_ijhaform.data);
      }
    } else {
      let _getCrewLeadData = _.cloneDeep(getCrewLeadData);
      _getCrewLeadData.crewmemberdata = [];

      setCrewLeadData(_getCrewLeadData);
    }

    let _getOldCrewData = _.cloneDeep(_getCrewLeadData);
    _getOldCrewData = _getOldCrewData.filter((_blank) => {
      if ("employeeId" in _blank) {
        return _blank;
      }
    });

    setReportCrewCheck(true);
    setCrewMemData(_getOldCrewData);
  };
  let ProjectNumber = form_template[0].questions[0].question.split("(")[0];
  let ProjectNumber1 = form_template[0].questions[0].question
    .split("(")[1]
    .replace(/[{()}]/g, "");

  return (
    <Container className="margin-container">
      <>
        {getReportIJHA && getIjhaform.inProgressSection === undefined ? (
          <>
            {!getLoading ? (
              <AddCrewMemberPPE
                getIJHForm={getIJHForm}
                getCrewData={getCrewData}
              />
            ) : (
              <div className="p-5">
                <Col
                  xs={12}
                  className="m-5  p-5 d-flex justify-content-center "
                  style={{ cursor: "pointer" }}
                >
                  <Loading width="32" height="32" />
                </Col>
              </div>
            )}
          </>
        ) : (
          <>
            <h4>PPE Submission Form</h4>
            {showMdl && (
              <ModalMapCustom
                showMdl={showMdl}
                setShowMdl={setShowMdl}
                dataMdl={getDataMdl}
                getAddress={getAddress}
                setAddress={setAddress}
                setDirections={setDirections}
                directions={directions}
                form_template={form_template}
                setForm_template={setForm_template}
              />
            )}

            {getShowOtherMdl && (
              <ModalOther
                showMdl={getShowOtherMdl}
                setShowMdl={setShowOtherMdl}
                dataMdl={getOtherDataMdl}
                getProceed={getProceed}
                setForm_template={setForm_template}
                form_template={form_template}
                getPFASOptions={getPFASOptions}
                setPFASOptions={setPFASOptions}
                getCrewMemberProceed={getCrewMemberProceed}
              />
            )}
            <Row>
              <Col>
                <FormProgressBar
                  inProgressSection={
                    getIjhaform.inProgressSection !== "" &&
                    getIjhaform.inProgressSection !== undefined
                      ? true
                      : false
                  }
                  getCrewMemberProceed={getCrewMemberProceed.crew}
                  getIsCompleted={getIsCompleted[getCrewMemberProceed.crew]}
                  getCrewfill={getCrewfill}
                  form_template={form_template}
                  getReportCrewCheck={getReportCrewCheck}
                  setProceed={setProceed}
                  getProgressCount={getProceed1 === 0 ? getProceed + 1 : 15}
                />
              </Col>
            </Row>

            <div className="mb-2 d-flex justify-content-end">
              <div
                className="p-2 m-1 ps-3 pe-3 rounded-pill text-capitalize"
                style={{ background: "rgb(33 37 41)", color: "#f1f1f1" }}
              >
                {getCrewMemberProceed.crew !== 0
                  ? "Crew Member: " +
                    getCrewLeadData.crewmemberdata[
                      getCrewMemberProceed.crew - 1
                    ].firstName +
                    " " +
                    getCrewLeadData.crewmemberdata[
                      getCrewMemberProceed.crew - 1
                    ].lastName
                  : "Crew Lead: " +
                    getCrewLeadData.firstName +
                    " " +
                    getCrewLeadData.lastName}
              </div>
            </div>

            <Form className="shadow-lg p-3 mb-5 rounded " encType="multipart">
              {form_template[getProceed].sectionId === "2" &&
              getProceed1 === 0 ? (
                <>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-2-1" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {ProjectNumber} (
                          <span className="Textwithcolor">
                            {ProjectNumber1}
                          </span>
                          )
                          {form_template[0].questions[0].tooltip_message !==
                            "" &&
                            form_template[0].questions[0].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[0].questions[0]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Control
                          className="rounded-0 checking1"
                          type="text"
                          value={form_template[0].questions[0].answer}
                          {...register("piNumber", { required: true })}
                          onChange={(e) => {
                            e.target.value === ""
                              ? setError("piNumber", {
                                  message: "This field is required",
                                })
                              : setError("piNumber", { message: "" });
                            changeFormTemplateData([1, e.target.value, 0]);
                          }}
                        />

                        <Form.Label className="mt-2 text-danger">
                          {errors.piNumber && errors.piNumber.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-2-2" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[0].questions[1].question}
                          {form_template[0].questions[1].tooltip_message !==
                            "" &&
                            form_template[0].questions[1].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[0].questions[1]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div className="position-relative ">
                          <div className="position-absolute top-0 end-0 sig-controls">
                            <Button
                              variant="light"
                              size="sm"
                              onClick={() => {
                                setError("loc911Add", { message: "" });
                                changeFormTemplateData([
                                  15,
                                  form_template[0].questions[1].question,
                                  1,
                                ]);
                              }}
                            >
                              <img
                                src={String(position)}
                                alt="reset sign"
                                style={{ width: "18px" }}
                              />
                            </Button>
                          </div>

                          <Form.Control
                            className="rounded-0 checking1"
                            as="textarea"
                            rows={5}
                            value={removeTags(
                              form_template[0].questions[1].answer
                            )}
                            // defaultValue={form_template[1].questions[1].answer}
                            // {...register('loc911Add', { required: true })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("loc911Add", {
                                    message: "This field is required",
                                  })
                                : setError("loc911Add", { message: "" });
                              changeFormTemplateData([1, e.target.value, 1]);
                            }}
                          />
                          <Form.Label className="mt-2 text-danger">
                            {errors.loc911Add && errors.loc911Add.message}
                          </Form.Label>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-2-3" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[0].questions[2].question}
                          {form_template[0].questions[2].tooltip_message !==
                            "" &&
                            form_template[0].questions[2].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[0].questions[2]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Control
                          className="rounded-0 checking1"
                          type="text"
                          value={form_template[0].questions[2].answer}
                          {...register("ccnameJobName", { required: true })}
                          onChange={(e) => {
                            e.target.value === ""
                              ? setError("ccnameJobName", {
                                  message: "This field is required",
                                })
                              : setError("ccnameJobName", { message: "" });
                            changeFormTemplateData([1, e.target.value, 2]);
                          }}
                        />
                        <Form.Label className="mt-2 text-danger">
                          {errors.ccnameJobName && errors.ccnameJobName.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-2-4" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[0].questions[3].question}
                          {form_template[0].questions[3].tooltip_message !==
                            "" &&
                            form_template[0].questions[3].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[0].questions[3]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>

                        <Form.Select
                          aria-label="customer name"
                          className="rounded-0 checking1"
                          defaultValue={
                            form_template[0].questions[3].answer !== "" &&
                            getFirstPart(
                              form_template[0].questions[3].answer
                            ) !== "Other "
                              ? form_template[0].questions[3].answer
                              : getFirstPart(
                                  form_template[0].questions[3].answer
                                ).replace(/^\s+|\s+$/gm, "")
                          }
                          {...register("customername1", { required: true })}
                          onChange={(e) => {
                            e.target.value === ""
                              ? setError("customername1", {
                                  message: "This field is required",
                                })
                              : setError("customername1", { message: "" });
                            changeFormTemplateData([28, e.target.value, 3]);
                          }}
                        >
                          <option value="">Select</option>
                          {form_template[0].questions[3].options.map((obj) => (
                            <option value={obj}>{obj}</option>
                          ))}
                        </Form.Select>
                        <Form.Label className="mt-2 text-danger">
                          {errors.customername1 && errors.customername1.message}
                        </Form.Label>
                        {getCustomername1 ? (
                          <>
                            <Form.Control
                              className="rounded-0 checking1 mt-3"
                              type="text"
                              value={getSecondPart(
                                form_template[0].questions[3].answer
                              )}
                              {...register("customername2", { required: true })}
                              onChange={(e) => {
                                e.target.value === "" ||
                                getFirstPart(
                                  form_template[0].questions[3].answer
                                ) === ""
                                  ? setError("customername2", {
                                      message: "This field is required",
                                    })
                                  : setError("customername2", { message: "" });
                                changeFormTemplateData([29, e.target.value, 3]);
                              }}
                            />
                            <Form.Label className="mt-2 text-danger">
                              {errors.customername2 &&
                                errors.customername2.message}
                            </Form.Label>
                          </>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-2-5" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[0].questions[4].question}
                          {form_template[0].questions[4].tooltip_message !==
                            "" &&
                            form_template[0].questions[4].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[0].questions[4]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Select
                          aria-label="site land lord"
                          className="rounded-0 checking1"
                          defaultValue={getFirstPart(
                            form_template[0].questions[4].answer
                          )}
                          {...register("siteLanLord1", { required: true })}
                          onChange={(e) => {
                            e.target.value === ""
                              ? setError("siteLanLord1", {
                                  message: "This field is required",
                                })
                              : setError("siteLanLord1", { message: "" });
                            changeFormTemplateData([30, e.target.value, 4]);
                          }}
                        >
                          <option value="">Select</option>
                          {form_template[0].questions[4].options.map((obj) => (
                            <option value={obj}>{obj}</option>
                          ))}
                        </Form.Select>
                        <Form.Label className="mt-2 text-danger">
                          {errors.siteLanLord1 && errors.siteLanLord1.message}
                        </Form.Label>
                        {getSiteLandLord ? (
                          <>
                            <Form.Control
                              className="rounded-0 checking1 mt-3"
                              type="text"
                              value={getSecondPart(
                                form_template[0].questions[4].answer
                              )}
                              {...register("siteLanLord2", { required: true })}
                              onChange={(e) => {
                                e.target.value === ""
                                  ? setError("siteLanLord2", {
                                      message: "This field is required",
                                    })
                                  : setError("siteLanLord2", { message: "" });
                                changeFormTemplateData([31, e.target.value, 4]);
                              }}
                            />
                            <Form.Label className="mt-2 text-danger">
                              {errors.siteLanLord2 &&
                                errors.siteLanLord2.message}
                            </Form.Label>
                          </>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-2-6" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {form_template[0].questions[5].question}
                          {form_template[0].questions[5].tooltip_message !==
                            "" &&
                            form_template[0].questions[5].tooltip_message !==
                              undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[0].questions[5]
                                        .tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div className="position-relative ">
                          <div className="position-absolute top-0 end-0 sig-controls">
                            <Button
                              variant="light"
                              size="sm"
                              onClick={() => {
                                form_template[0].questions[5].answer === ""
                                  ? setError("generalScopOfProj", {
                                      message: "This field is required",
                                    })
                                  : setError("generalScopOfProj", {
                                      message: "",
                                    });
                                changeFormTemplateData([1, "", 0]);
                              }}
                            >
                              <img
                                src={String(failed)}
                                alt="clear"
                                style={{ width: "18px" }}
                              />
                            </Button>
                          </div>
                          <Form.Control
                            className="rounded-0 checking1"
                            as="textarea"
                            rows={5}
                            value={form_template[0].questions[5].answer}
                            {...register("generalScopOfProj", {
                              required: true,
                            })}
                            onChange={(e) => {
                              e.target.value === ""
                                ? setError("generalScopOfProj", {
                                    message: "This field is required",
                                  })
                                : setError("generalScopOfProj", {
                                    message: "",
                                  });
                              changeFormTemplateData([1, e.target.value, 5]);
                            }}
                          />
                        </div>
                        <Form.Label className="mt-2 text-danger">
                          {errors.generalScopOfProj &&
                            errors.generalScopOfProj.message}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              ) : null}

              {form_template[getProceed].sectionId === "6" &&
              getProceed1 === 0 ? (
                <>
                  <Row
                    xs={12}
                    className="d-flex flex-row justify-content-start"
                  >
                    <Col>
                      <Form.Group controlId="form-10-2" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {
                            form_template[1].section[getCrewMemberProceed.crew]
                              .questions[0].question
                          }
                          {form_template[1].section[getCrewMemberProceed.crew]
                            .questions[0].tooltip_message !== "" &&
                            form_template[1].section[getCrewMemberProceed.crew]
                              .questions[0].tooltip_message !== undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[1].section[
                                        getCrewMemberProceed.crew
                                      ].questions[0].tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>

                        <Form.Control
                          className="rounded-0 custom-file-input "
                          type="file"
                          {...register("PPEHardHat1", { required: true })}
                          onChange={async (e) => {
                            // let fileSize = e.target.files[0]['size'];
                            let fileType = e.target.files[0]["type"];
                            let validImageTypes = [
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                            ];
                            const file = e.target.files[0];
                            if (
                              e.target.files[0] === ""
                                ? setError("PPEHardHat1", {
                                    message: "This field is required",
                                  })
                                : setError("PPEHardHat1", { message: "" })
                            );

                            if (file.size >= 4e6) {
                              // console.log("IMAGE IS Greater THAN $MNNNN", file.size)
                              const time = file.lastModified;
                              // console.log("YSYSYSYSYYS1111YSYS", time)
                              // console.log("YSYSYSYSYYS1111YSYS", file)
                              const image = await resizeFile(file);
                              // console.log(">>>>>>>>>>1111", image.size)

                              // console.log("YSYSYSYSYYS1111YSYS", image)

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEHardHat1", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 1, "ai-all"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [file, getCrewMemberProceed.crew],
                                  4,
                                  "0",
                                  "PPEHardHat1",
                                  true,
                                  [
                                    {
                                      sectionId: 1,
                                      sectionFieldType: "PPEHardHat",
                                    },
                                    {
                                      sectionId: 2,
                                      sectionFieldType: "PPEWorkGloves",
                                    },
                                    {
                                      sectionId: 3,
                                      sectionFieldType: "PPEWorkBoots",
                                    },
                                    {
                                      sectionId: 4,
                                      sectionFieldType:
                                        "PPEClimberWearingClimbingHarness",
                                    },
                                  ]
                                );
                                getUploadImgUrls(
                                  [
                                    image,
                                    getCrewMemberProceed.crew,
                                    1,
                                    "ai-all",
                                  ],
                                  4
                                );
                              }
                            } else {
                              const image = file;
                              // console.log(">>>>>>>>>>", image.size)
                              // console.log("IMAGE IS LESS THAN $MNNNN", file.size)

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEHardHat1", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 1, "ai-all"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [
                                    image,
                                    getCrewMemberProceed.crew,
                                    1,
                                    "ai-all",
                                  ],
                                  4,
                                  "0",
                                  "PPEHardHat1",
                                  true,
                                  [
                                    {
                                      sectionId: 1,
                                      sectionFieldType: "PPEHardHat",
                                    },
                                    {
                                      sectionId: 2,
                                      sectionFieldType: "PPEWorkGloves",
                                    },
                                    {
                                      sectionId: 3,
                                      sectionFieldType: "PPEWorkBoots",
                                    },
                                    {
                                      sectionId: 4,
                                      sectionFieldType:
                                        "PPEClimberWearingClimbingHarness",
                                    },
                                  ]
                                );
                                getUploadImgUrls(
                                  [
                                    image,
                                    getCrewMemberProceed.crew,
                                    1,
                                    "ai-all",
                                  ],
                                  4
                                );
                              }
                            }
                            e.target.value = null;
                          }}
                        />

                        <Form.Label className="mt-2 text-danger">
                          {errors.PPEHardHat1 && errors.PPEHardHat1.message}
                        </Form.Label>

                        <br />
                        <Form.Label className="mt-3">
                          {
                            form_template[1].section[getCrewMemberProceed.crew]
                              .questions[1].question
                          }
                          {form_template[1].section[getCrewMemberProceed.crew]
                            .questions[1].tooltip_message !== "" &&
                            form_template[1].section[getCrewMemberProceed.crew]
                              .questions[1].tooltip_message !== undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[1].section[
                                        getCrewMemberProceed.crew
                                      ].questions[1].tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>

                        <Form.Control
                          className="rounded-0 custom-file-input"
                          type="file"
                          {...register("PPEHardHat", { required: true })}
                          onChange={async (e) => {
                            // let fileSize = e.target.files[0]['size'];
                            let fileType = e.target.files[0]["type"];
                            let validImageTypes = [
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                            ];

                            const file = e.target.files[0];

                            if (file.size >= 4e6) {
                              // console.log("IMAGE IS Greater THAN $MNNNN", file.size)
                              const time = file.lastModified;
                              // console.log("YSYSYSYSYYS1111YSYS", time)
                              // console.log("YSYSYSYSYYS1111YSYS", file)
                              const image = await resizeFile(file);
                              // console.log(">>>>>>>>>>1111", image.size)

                              // console.log("YSYSYSYSYYS1111YSYS", image)

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEHardHat", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 1, "ai"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [file, getCrewMemberProceed.crew, 1, "ai"],
                                  4,
                                  "0",
                                  "PPEHardHat",
                                  true
                                );
                                getUploadImgUrls(
                                  [image, getCrewMemberProceed.crew, 1, "ai"],
                                  4
                                );
                              }
                            } else {
                              const image = file;
                              // console.log(">>>>>>>>>>", image.size)
                              // console.log("IMAGE IS LESS THAN $MNNNN", file.size)

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEHardHat", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 1, "ai"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [image, getCrewMemberProceed.crew, 1, "ai"],
                                  4,
                                  "0",
                                  "PPEHardHat",
                                  true
                                );
                              }
                            }
                            e.target.value = null;
                          }}
                          // onChange={(e) => getUploadImgUrls([e.target.files[0], 0, 2, 'ai'], 4, '0')}
                        />
                        {form_template[1].section[getCrewMemberProceed.crew]
                          .questions[1].confidence !== "" && (
                          <div className="mt-3 mb-1 text-warning">
                            {errors.PPEHardHat && errors.PPEHardHat.message}
                          </div>
                        )}
                        {form_template[1].section[getCrewMemberProceed.crew]
                          .questions[1].confidence !== "" && (
                          <div className="mt-3 mb-1 text-success">
                            {errors.PPEHardHat && errors.PPEHardHat.message1}
                          </div>
                        )}

                        {form_template[1].section[getCrewMemberProceed.crew]
                          .questions[1].picture !== "" &&
                        typeof form_template[1].section[
                          getCrewMemberProceed.crew
                        ].questions[1].picture !== "undefined" ? (
                          <div className="p-1 m-1 imgDiv">
                            <div
                              className="removeImg"
                              onClick={() =>
                                changeFormTemplateData([
                                  32,
                                  "PPEHardHat",
                                  getCrewMemberProceed.crew,
                                  1,
                                ])
                              }
                            >
                              x
                            </div>
                            <img
                              src={
                                getUrlWithQueryString(
                                  form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[1].picture
                                ) + IMG_TOKEN()
                              }
                              alt="PPEHardHat"
                              className="shadow-sm bg-light rounded img-size_preview"
                            />
                          </div>
                        ) : null}
                        <div key="inline-radio" className="mb-3 mt-3">
                          <img
                            src={String(ic_ai)}
                            alt="ai-img"
                            className="me-2"
                            style={{ width: "32px" }}
                          />

                          <Form.Check
                            inline
                            label="Yes"
                            name="group10-2"
                            type="radio"
                            id="inline-radio-10-2-1"
                            checked={
                              form_template[1].section[
                                getCrewMemberProceed.crew
                              ].questions[1].overridden === "Yes"
                                ? form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[1].overridden === "Yes"
                                : form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[1].answer === "Yes"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "Yes",
                                getCrewMemberProceed.crew,
                                1,
                              ])
                            }
                            disabled={
                              form_template[1].section[
                                getCrewMemberProceed.crew
                              ].questions[1].confidence === ""
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group10-2"
                            type="radio"
                            id="inline-radio-10-2-2"
                            checked={
                              form_template[1].section[
                                getCrewMemberProceed.crew
                              ].questions[1].overridden === "Yes"
                                ? form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[1].overridden === "No"
                                : form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[1].answer === "No"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "No",
                                getCrewMemberProceed.crew,
                                1,
                              ])
                            }
                          />
                        </div>
                        {/* {
                                                            form_template[1].section[getCrewMemberProceed.crew].questions[1].confidence !== '' &&
                                                            <div>
                                                                <div>Confidence: {form_template[1].section[getCrewMemberProceed.crew].questions[1].confidence * 100}%</div>
                                                                <div ><ProgressBar variant="success"
                                                                    now={form_template[1].section[getCrewMemberProceed.crew].questions[1].confidence * 100}
                                                                    label={`${form_template[1].section[getCrewMemberProceed.crew].questions[1].confidence * 100}%`} /></div>
                                                            </div>
                                                        } */}
                      </Form.Group>
                    </Col>
                    <Col>
                      <div className="ExampleImageinPPE d-flex flex-row justify-content-end">
                        <Figure className="ImageProps">
                          <a href={String(ExampleImage)} target="_blank">
                            <Figure.Image
                              alt="PPE Image"
                              src={String(ExampleImage)}
                              className="ImageProperties"
                            />
                          </a>
                          <Figure.Caption className="caption ">
                            Example Showing an Individual wearing all PPE's
                            Correctly
                          </Figure.Caption>
                        </Figure>
                      </div>
                    </Col>
                  </Row>

                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-10-3" className="mb-3">
                        <Form.Label>
                          {
                            form_template[1].section[getCrewMemberProceed.crew]
                              .questions[2].question
                          }
                          {form_template[1].section[getCrewMemberProceed.crew]
                            .questions[2].tooltip_message !== "" &&
                            form_template[1].section[getCrewMemberProceed.crew]
                              .questions[2].tooltip_message !== undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[1].section[
                                        getCrewMemberProceed.crew
                                      ].questions[2].tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Control
                          className="rounded-0 custom-file-input"
                          type="file"
                          {...register("PPEWorkGloves", { required: true })}
                          onChange={async (e) => {
                            // let fileSize = e.target.files[0]['size'];
                            let fileType = e.target.files[0]["type"];
                            let validImageTypes = [
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                            ];
                            const file = e.target.files[0];
                            if (file.size >= 4e6) {
                              // console.log("IMAGE IS Greater THAN $MNNNN", file.size)
                              const time = file.lastModified;
                              // console.log("YSYSYSYSYYS1111YSYS", time)
                              // console.log("YSYSYSYSYYS1111YSYS", file)
                              const image = await resizeFile(file);
                              // console.log(">>>>>>>>>>1111", image.size)

                              // console.log("YSYSYSYSYYS1111YSYS", image)

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEWorkGloves", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 2, "ai"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [file, getCrewMemberProceed.crew, 2, "ai"],
                                  4,
                                  "0",
                                  "PPEWorkGloves",
                                  true
                                );
                                getUploadImgUrls(
                                  [image, getCrewMemberProceed.crew, 2, "ai"],
                                  4
                                );
                              }
                            } else {
                              const image = file;
                              // console.log(">>>>>>>>>>", image.size)
                              // console.log("IMAGE IS LESS THAN $MNNNN", file.size)

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEWorkGloves", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 2, "ai"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [image, getCrewMemberProceed.crew, 2, "ai"],
                                  4,
                                  "0",
                                  "PPEWorkGloves",
                                  true
                                );
                              }
                            }
                            e.target.value = null;
                          }}

                          // onChange={(e) => getUploadImgUrls([e.target.files[0], 0, 3, 'ai'], 4, '0')}
                        />

                        {form_template[1].section[getCrewMemberProceed.crew]
                          .questions[2].confidence !== "" && (
                          <div className="mt-3 mb-1 text-warning">
                            {errors.PPEWorkGloves &&
                              errors.PPEWorkGloves.message}
                          </div>
                        )}
                        {form_template[1].section[getCrewMemberProceed.crew]
                          .questions[2].confidence !== "" && (
                          <div className="mt-3 mb-1 text-success">
                            {errors.PPEWorkGloves &&
                              errors.PPEWorkGloves.message1}
                          </div>
                        )}
                        {form_template[1].section[getCrewMemberProceed.crew]
                          .questions[2].picture !== "" &&
                        typeof form_template[1].section[
                          getCrewMemberProceed.crew
                        ].questions[2].picture !== "undefined" ? (
                          <div className="p-1 m-1 imgDiv">
                            <div
                              className="removeImg"
                              onClick={() =>
                                changeFormTemplateData([
                                  32,
                                  "PPEWorkGloves",
                                  getCrewMemberProceed.crew,
                                  2,
                                  "hand",
                                ])
                              }
                            >
                              x
                            </div>
                            <img
                              src={
                                getUrlWithQueryString(
                                  form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[2].picture
                                ) + IMG_TOKEN()
                              }
                              alt="PPEWorkGloves"
                              className="shadow-sm bg-light rounded img-size_preview"
                            />
                          </div>
                        ) : null}
                        <div key="inline-radio" className="mb-3 mt-3">
                          <img
                            src={String(ic_ai)}
                            alt="ai-img"
                            className="me-2"
                            style={{ width: "36px" }}
                          />
                          <Form.Check
                            inline
                            label="Yes"
                            name="group10-3"
                            type="radio"
                            id="inline-radio-10-3-1"
                            // checked={form_template[10].section[0].questions[3].answer === 'Yes'}
                            checked={
                              form_template[1].section[
                                getCrewMemberProceed.crew
                              ].questions[2].overridden === "Yes"
                                ? form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[2].overridden === "Yes"
                                : form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[2].answer === "Yes"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "Yes",
                                getCrewMemberProceed.crew,
                                2,
                              ])
                            }
                            disabled={
                              form_template[1].section[
                                getCrewMemberProceed.crew
                              ].questions[2].confidence === ""
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group10-3"
                            type="radio"
                            id="inline-radio-10-3-2"
                            // checked={form_template[10].section[0].questions[3].answer === 'No'}
                            checked={
                              form_template[1].section[
                                getCrewMemberProceed.crew
                              ].questions[2].overridden === "Yes"
                                ? form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[2].overridden === "No"
                                : form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[2].answer === "No"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "No",
                                getCrewMemberProceed.crew,
                                2,
                              ])
                            }
                          />
                        </div>
                        {/* {
                                                            form_template[1].section[getCrewMemberProceed.crew].questions[2].confidence !== '' &&
                                                            <div>
                                                                <div>Confidence: {form_template[1].section[getCrewMemberProceed.crew].questions[2].confidence * 100}%</div>
                                                                <div ><ProgressBar variant="success"
                                                                    now={form_template[1].section[getCrewMemberProceed.crew].questions[2].confidence * 100}
                                                                    label={`${form_template[1].section[getCrewMemberProceed.crew].questions[2].confidence * 100}%`} /></div>
                                                            </div>
                                                        } */}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-10-4" className="mb-3">
                        <Form.Label>
                          {
                            form_template[1].section[getCrewMemberProceed.crew]
                              .questions[3].question
                          }
                          {form_template[1].section[getCrewMemberProceed.crew]
                            .questions[3].tooltip_message !== "" &&
                            form_template[1].section[getCrewMemberProceed.crew]
                              .questions[3].tooltip_message !== undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[1].section[
                                        getCrewMemberProceed.crew
                                      ].questions[3].tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Control
                          className="rounded-0 custom-file-input"
                          type="file"
                          {...register("PPEWorkBoots", { required: true })}
                          onChange={async (e) => {
                            // let fileSize = e.target.files[0]['size'];
                            let fileType = e.target.files[0]["type"];
                            let validImageTypes = [
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                            ];
                            const file = e.target.files[0];
                            if (file.size >= 4e6) {
                              // console.log("IMAGE IS Greater THAN $MNNNN", file.size)
                              const time = file.lastModified;
                              // console.log("YSYSYSYSYYS1111YSYS", time)
                              // console.log("YSYSYSYSYYS1111YSYS", file)
                              const image = await resizeFile(file);
                              // console.log(">>>>>>>>>>1111", image.size)

                              // console.log("YSYSYSYSYYS1111YSYS", image)

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEWorkBoots", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 3, "ai"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [file, getCrewMemberProceed.crew, 3],
                                  4,
                                  "0",
                                  "PPEWorkBoots",
                                  true
                                );
                                getUploadImgUrls(
                                  [image, getCrewMemberProceed.crew, 3, "ai"],
                                  4
                                );
                              }
                            } else {
                              const image = file;
                              // console.log(">>>>>>>>>>", image.size)
                              // console.log("IMAGE IS LESS THAN $MNNNN", file.size)

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEWorkBoots", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 3, "ai"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [image, getCrewMemberProceed.crew, 3, "ai"],
                                  4,
                                  "0",
                                  "PPEWorkBoots",
                                  true
                                );
                              }
                            }
                            e.target.value = null;
                          }}
                          // onChange={(e) => getUploadImgUrls([e.target.files[0], 0, 4, 'ai'], 4, '0')}
                        />
                        {form_template[1].section[getCrewMemberProceed.crew]
                          .questions[3].confidence !== "" && (
                          <div className="mt-3 mb-1 text-warning">
                            {errors.PPEWorkBoots && errors.PPEWorkBoots.message}
                          </div>
                        )}
                        {form_template[1].section[getCrewMemberProceed.crew]
                          .questions[3].confidence !== "" && (
                          <div className="mt-3 mb-1 text-success">
                            {errors.PPEWorkBoots &&
                              errors.PPEWorkBoots.message1}
                          </div>
                        )}
                        {form_template[1].section[getCrewMemberProceed.crew]
                          .questions[3].picture !== "" &&
                        typeof form_template[1].section[
                          getCrewMemberProceed.crew
                        ].questions[3].picture !== "undefined" ? (
                          <div className="p-1 m-1 imgDiv">
                            <div
                              className="removeImg"
                              onClick={() =>
                                changeFormTemplateData([
                                  32,
                                  "PPEWorkBoots",
                                  getCrewMemberProceed.crew,
                                  3,
                                ])
                              }
                            >
                              x
                            </div>
                            <img
                              src={
                                getUrlWithQueryString(
                                  form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[3].picture
                                ) + IMG_TOKEN()
                              }
                              alt="PPEWorkBoots"
                              className="shadow-sm bg-light rounded img-size_preview"
                            />
                          </div>
                        ) : null}
                        <div key="inline-radio" className="mb-3 mt-1">
                          <img
                            src={String(ic_ai)}
                            alt="ai-img"
                            className="me-2"
                            style={{ width: "36px" }}
                          />
                          <Form.Check
                            inline
                            label="Yes"
                            name="group10-4"
                            type="radio"
                            id="inline-radio-10-4-1"
                            // checked={form_template[10].section[0].questions[4].answer === 'Yes'}
                            checked={
                              form_template[1].section[
                                getCrewMemberProceed.crew
                              ].questions[3].overridden === "Yes"
                                ? form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[3].overridden === "Yes"
                                : form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[3].answer === "Yes"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "Yes",
                                getCrewMemberProceed.crew,
                                3,
                              ])
                            }
                            disabled={
                              form_template[1].section[
                                getCrewMemberProceed.crew
                              ].questions[3].confidence === ""
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group10-4"
                            type="radio"
                            id="inline-radio-10-4-2"
                            // checked={form_template[10].section[0].questions[4].answer === 'No'}
                            checked={
                              form_template[1].section[
                                getCrewMemberProceed.crew
                              ].questions[3].overridden === "Yes"
                                ? form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[3].overridden === "No"
                                : form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[3].answer === "No"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "No",
                                getCrewMemberProceed.crew,
                                3,
                              ])
                            }
                          />
                        </div>
                        {/* {
                                                            form_template[1].section[getCrewMemberProceed.crew].questions[3].confidence !== '' &&
                                                            <div>
                                                                <div>Confidence: {form_template[1].section[getCrewMemberProceed.crew].questions[3].confidence * 100}%</div>
                                                                <div ><ProgressBar variant="success"
                                                                    now={form_template[1].section[getCrewMemberProceed.crew].questions[3].confidence * 100}
                                                                    label={`${form_template[1].section[getCrewMemberProceed.crew].questions[3].confidence * 100}%`} /></div>
                                                            </div>
                                                        } */}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-10-6" className="mb-3">
                        <Form.Label>
                          {
                            form_template[1].section[getCrewMemberProceed.crew]
                              .questions[4].question
                          }
                          {form_template[1].section[getCrewMemberProceed.crew]
                            .questions[4].tooltip_message !== "" &&
                            form_template[1].section[getCrewMemberProceed.crew]
                              .questions[4].tooltip_message !== undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[1].section[
                                        getCrewMemberProceed.crew
                                      ].questions[4].tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <Form.Control
                          className="rounded-0 custom-file-input"
                          type="file"
                          {...register("PPEClimberWearingClimbingHarness", {
                            required: true,
                          })}
                          onChange={async (e) => {
                            // let fileSize = e.target.files[0]['size'];
                            let fileType = e.target.files[0]["type"];
                            let validImageTypes = [
                              "image/gif",
                              "image/jpeg",
                              "image/png",
                            ];
                            const file = e.target.files[0];
                            if (file.size >= 4e6) {
                              // console.log("IMAGE IS Greater THAN $MNNNN", file.size)
                              const time = file.lastModified;
                              // console.log("YSYSYSYSYYS1111YSYS", time)
                              // console.log("YSYSYSYSYYS1111YSYS", file)
                              const image = await resizeFile(file);
                              // console.log(">>>>>>>>>>1111", image.size)

                              // console.log("YSYSYSYSYYS1111YSYS", image)

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEClimberWearingClimbingHarness", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 4, "ai"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [file, getCrewMemberProceed.crew, 4],
                                  4,
                                  "0",
                                  "PPEClimberWearingClimbingHarness",
                                  true
                                );
                                getUploadImgUrls(
                                  [image, getCrewMemberProceed.crew, 4, "ai"],
                                  4
                                );
                              }
                            } else {
                              const image = file;
                              // console.log(">>>>>>>>>>", image.size)
                              // console.log("IMAGE IS LESS THAN $MNNNN", file.size)

                              if (!validImageTypes.includes(fileType)) {
                                setError("PPEClimberWearingClimbingHarness", {
                                  message:
                                    "Only jpeg/png/gif image are allowed.",
                                });
                                getUploadImgUrls(
                                  ["", getCrewMemberProceed.crew, 4, "ai"],
                                  4,
                                  "0"
                                );
                              } else {
                                getUploadImgUrls(
                                  [image, getCrewMemberProceed.crew, 4, "ai"],
                                  4,
                                  "0",
                                  "PPEClimberWearingClimbingHarness",
                                  true
                                );
                              }
                            }
                            e.target.value = null;
                          }}
                        />
                        {form_template[1].section[getCrewMemberProceed.crew]
                          .questions[4].confidence !== "" && (
                          <div className="mt-3 mb-1 text-warning">
                            {errors.PPEClimberWearingClimbingHarness &&
                              errors.PPEClimberWearingClimbingHarness.message}
                          </div>
                        )}
                        {form_template[1].section[getCrewMemberProceed.crew]
                          .questions[4].confidence !== "" && (
                          <div className="mt-3 mb-1 text-success">
                            {errors.PPEClimberWearingClimbingHarness &&
                              errors.PPEClimberWearingClimbingHarness.message1}
                          </div>
                        )}
                        {form_template[1].section[getCrewMemberProceed.crew]
                          .questions[4].picture !== "" &&
                        typeof form_template[1].section[
                          getCrewMemberProceed.crew
                        ].questions[4].picture !== "undefined" ? (
                          <div className="p-1 m-1 imgDiv">
                            <div
                              className="removeImg"
                              onClick={() =>
                                changeFormTemplateData([
                                  32,
                                  "PPEClimberWearingClimbingHarness",
                                  getCrewMemberProceed.crew,
                                  4,
                                  "t-shirt",
                                ])
                              }
                            >
                              x
                            </div>
                            <img
                              src={
                                getUrlWithQueryString(
                                  form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[4].picture
                                ) + IMG_TOKEN()
                              }
                              alt="PPEClimberWearingClimbingHarness"
                              className="shadow-sm bg-light rounded img-size_preview"
                            />
                          </div>
                        ) : null}
                        <div key="inline-radio" className="mb-3 mt-1">
                          <img
                            src={String(ic_ai)}
                            alt="ai-img"
                            className="me-2"
                            style={{ width: "36px" }}
                          />
                          <Form.Check
                            inline
                            label="Yes"
                            name="group10-6"
                            type="radio"
                            id="inline-radio-10-6-1"
                            checked={
                              form_template[1].section[
                                getCrewMemberProceed.crew
                              ].questions[4].overridden === "Yes"
                                ? form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[4].overridden === "Yes"
                                : form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[4].answer === "Yes"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "Yes",
                                getCrewMemberProceed.crew,
                                4,
                              ])
                            }
                            disabled={
                              form_template[1].section[
                                getCrewMemberProceed.crew
                              ].questions[4].confidence === ""
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="group10-6"
                            type="radio"
                            id="inline-radio-10-6-2"
                            checked={
                              form_template[1].section[
                                getCrewMemberProceed.crew
                              ].questions[4].overridden === "Yes"
                                ? form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[4].overridden === "No"
                                : form_template[1].section[
                                    getCrewMemberProceed.crew
                                  ].questions[4].answer === "No"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "No",
                                getCrewMemberProceed.crew,
                                4,
                              ])
                            }
                          />
                        </div>
                        {/* {
                                                            form_template[1].section[getCrewMemberProceed.crew].questions[4].confidence !== '' &&
                                                            <div>
                                                                <div>Confidence: {form_template[1].section[getCrewMemberProceed.crew].questions[4].confidence * 100}%</div>
                                                                <div ><ProgressBar variant="success"
                                                                    now={form_template[1].section[getCrewMemberProceed.crew].questions[4].confidence * 100}
                                                                    label={`${form_template[1].section[getCrewMemberProceed.crew].questions[4].confidence * 100}%`} /></div>
                                                            </div>
                                                        } */}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row xs={12}>
                    <Col>
                      <Form.Group controlId="form-4-1" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {
                            form_template[1].section[getCrewMemberProceed.crew]
                              .questions[5].question
                          }
                          {form_template[1].section[getCrewMemberProceed.crew]
                            .questions[5].tooltip_message !== "" &&
                            form_template[1].section[getCrewMemberProceed.crew]
                              .questions[5].tooltip_message !== undefined && (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {
                                      form_template[1].section[
                                        getCrewMemberProceed.crew
                                      ].questions[5].tooltip_message
                                    }
                                  </Tooltip>
                                }
                              >
                                <span className="icon-info">i</span>
                              </OverlayTrigger>
                            )}
                        </Form.Label>
                        <div key="inline-radio" className="mb-3">
                          <Form.Check
                            inline
                            label="Yes"
                            name="form-4-1"
                            type="radio"
                            id="inline-radio-1"
                            checked={
                              form_template[1].section[
                                getCrewMemberProceed.crew
                              ].questions[5].answer === "Yes"
                            }
                            onClick={() => {
                              changeFormTemplateData([
                                7,
                                "Yes",
                                getCrewMemberProceed.crew,
                                5,
                              ]);
                            }}
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="form-4-1"
                            type="radio"
                            id="inline-radio-2"
                            checked={
                              form_template[1].section[
                                getCrewMemberProceed.crew
                              ].questions[5].answer === "No"
                            }
                            onClick={() =>
                              changeFormTemplateData([
                                7,
                                "No",
                                getCrewMemberProceed.crew,
                                5,
                              ])
                            }
                          />

                          <Form.Label className="mt-2 text-danger">
                            {errors.personalProtectiveEquip2 &&
                              errors.personalProtectiveEquip2.message}
                          </Form.Label>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              ) : null}

              <>
                {getProceed1 === 1 ? (
                  getIjhaSubmit === "" ? (
                    <>
                      {getSummary === true ? (
                        <>
                          <SummaryforPpeSubmission
                            form_template={form_template}
                            setForm_template={setForm_template}
                            setCrewLeadData={setCrewLeadData}
                            setDelCrew={setDelCrew}
                            setIsCompleted={setIsCompleted}
                            getIsCompleted={getIsCompleted}
                            profile_img_url={DECRYPTED_DATA().profile_img_url}
                            getUserName={getUserName}
                            check={check}
                            setProceed1={setProceed1}
                            setProceed={setProceed}
                            setCrewMemberProceed={setCrewMemberProceed}
                            getCrewMemberProceed={getCrewMemberProceed}
                            getCrewLeadData={getCrewLeadData}
                            setPFASOptions={setPFASOptions}
                            setpbtn={setpbtn}
                            getFormID={getFormID}
                            setCrewfill={setCrewfill}
                          />
                        </>
                      ) : (
                        <Row xs={12}>
                          <div>
                            <h5 className="mt-3 d-flex justify-content-center text-center">
                              Please wait while we process your PPE Submission
                              form submission.
                            </h5>
                            <h5 className="mt-1 p-2 d-flex justify-content-center text-center">
                              Do not refresh the page or navigate away while
                              this is happening.
                            </h5>
                          </div>
                        </Row>
                      )}
                    </>
                  ) : (
                    <>
                      <Row xs={12}>
                        <Col className="d-flex flex-row bd-highlight align-items-center mb-3 position-relative">
                          <img
                            src={String(ic_complete_ppe)}
                            className="p-2 m-2"
                            alt="check-success"
                            style={{ width: "74px" }}
                          />
                          <h5 className="p-2 bd-highlight">
                            {getIjhaSubmit !== "" &&
                            getIjhaSubmit === "Internal Server Error" ? (
                              getIjhaSubmit
                            ) : (
                              <>
                                {getIjhaSubmit !== "" &&
                                  getIjhaSubmit +
                                    " Your PPE Submission will be available in "}
                                {getIjhaSubmit !== "" && (
                                  <a
                                    href="/reports"
                                    style={{ fontSize: "1.25rem" }}
                                  >
                                    Reports tab
                                  </a>
                                )}
                              </>
                            )}
                          </h5>
                        </Col>
                      </Row>
                      <Row xs={12}>
                        <Col className="d-flex justify-content-center mt-3 mb-3">
                          Click to go&nbsp;<a href="/">Home Page</a>
                        </Col>
                      </Row>
                    </>
                  )
                ) : null}
              </>

              <Row>
                {getIjhaSubmit === "" ? (
                  <Col>
                    <>
                      {getProceed <= 0 ? (
                        <div>
                          {/* crew lead all 12 sections */}
                          {getCrewMemberProceed.crew === 0 && (
                            <>
                              <Button
                                className="me-3"
                                onClick={() => {
                                  setSetP(true);
                                  setProceed(getProceed - 1);
                                  setpbtn(false);
                                }}
                                disabled={
                                  getLoading ? getLoading : getProceed === 0
                                }
                              >
                                Back
                              </Button>

                              {getpbtn === true ? (
                                <Button
                                  variant={"success"}
                                  className="me-5 disabled"
                                >
                                  Proceed
                                </Button>
                              ) : (
                                <Button
                                  onClick={onProceedHandler}
                                  variant={"success"}
                                  className="me-5"
                                  disabled={getLoading}
                                >
                                  Proceed
                                </Button>
                              )}
                            </>
                          )}
                          {/* crew members 1,6,11,12*/}
                          {getCrewMemberProceed.crew !== 0 && (
                            <>
                              <Button
                                className="me-3"
                                onClick={() => {
                                  setSetP(true);

                                  if (getProceed === 1) {
                                    setProceed(0);
                                  } else if (getProceed > 1) {
                                    setProceed(getProceed - 1);
                                  }
                                  setpbtn(false);
                                }}
                                disabled={
                                  getLoading ? getLoading : getProceed === 0
                                }
                              >
                                Back
                              </Button>

                              <Button
                                onClick={onCrewProceedHandler}
                                variant={"success"}
                                className="me-5"
                                disabled={getLoading || getpbtn === true}
                              >
                                Proceed
                              </Button>
                            </>
                          )}

                          {!(getLoading || getpbtn === true) &&
                            getIsCompleted[getCrewMemberProceed.crew]}
                          {/* {getLoading && <Loading width="32" height="32" />} */}
                        </div>
                      ) : getProceed1 === 0 ? (
                        <div>
                          <Button
                            className="me-3"
                            onClick={() => {
                              setSetP(true);
                              setProceed(getProceed - 1);
                              setpbtn(false);
                            }}
                            disabled={
                              getLoading
                                ? getLoading
                                : getCrewMemberProceed.crew === 1
                            }
                          >
                            Back
                          </Button>

                          <Button
                            onClick={() => {
                              let _getIsCompleted = _.cloneDeep(getIsCompleted);
                              _getIsCompleted[getCrewMemberProceed.crew] = true;
                              setIsCompleted(_getIsCompleted);
                              setProceed1(1);
                              InprocessFormSubmit();
                              setSummary(true);
                            }}
                            variant={"success"}
                            disabled={getLoading || getpbtn === true}
                          >
                            Proceed
                          </Button>

                          {/* {getLoading && <Loading width="32" height="32" />} */}
                        </div>
                      ) : (
                        <div>
                          <Button
                            className="me-3"
                            onClick={() => {
                              setProceed1(0);
                              setSummary(false);
                            }}
                            disabled={
                              getLoading ? getLoading : getProceed === 0
                            }
                          >
                            Back
                          </Button>
                          {_.isEmpty(getIjhaform.reportId) && (
                            <Button
                              variant={"success"}
                              onClick={onSubmitPPEForm}
                              disabled={
                                getLoading ||
                                !getIsCompleted.every((obj) => obj === true)
                              }
                            >
                              {" "}
                              Submit
                            </Button>
                          )}

                          {!_.isEmpty(getIjhaform.reportId) &&
                          !getIsCompleted.every((obj) => obj === true) ? (
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  Please make sure all sections are reviewed for
                                  all members in the iJHA
                                </Tooltip>
                              }
                            >
                              <span className="d-inline-block">
                                <Button
                                  variant={"success"}
                                  onClick={onSubmitPPEForm}
                                  disabled={
                                    getLoading ||
                                    !getIsCompleted.every((obj) => obj === true)
                                  }
                                >
                                  {" "}
                                  Submit
                                </Button>
                              </span>
                            </OverlayTrigger>
                          ) : (
                            ""
                          )}

                          {!_.isEmpty(getIjhaform.reportId) &&
                          getIsCompleted.every((obj) => obj === true) ? (
                            <Button
                              variant={"success"}
                              onClick={onSubmitPPEForm}
                              disabled={
                                getLoading ||
                                !getIsCompleted.every((obj) => obj === true)
                              }
                            >
                              {" "}
                              Submit
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </>

                    {getLoading && <Loading width="32" height="32" />}
                  </Col>
                ) : null}
              </Row>
              <Button
                onClick={scrollDown}
                className="btn btn-sm btn-dark mb-5 rounded-0 position-fixed topToBottom end-0 translate-middle "
                value="bottom"
                id="back-to-down"
              >
                <img
                  src={String(arrow_up)}
                  alt="scroll bottom"
                  style={{ width: "18px" }}
                  className="icon-white-color"
                />
              </Button>
              <Button
                onClick={scrollUp}
                className="btn btn-sm btn-dark mb-5 rounded-0 position-fixed bottom-0 end-0 translate-middle "
                value="top"
                id="back-to-up"
              >
                <img
                  src={String(arrow_up)}
                  alt="scroll top"
                  style={{ width: "18px" }}
                  className="icon-white-color"
                />
              </Button>
            </Form>
          </>
        )}
      </>
    </Container>
  );
}
