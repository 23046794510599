import { useEffect, useState } from "react";
import _ from "lodash";
import "./FormProgressBar.scss";
export default function FormProgressBarForIjha({
  getClimber,
  inProgressSection,
  getCrewMemberProceed,
  getIsCompleted,
  getCrewfill,
  form_template,
  getReportCrewCheck,
  setProceed,
  getProgressCount,
}) {
  const [getProgress, setProgress] = useState([]);
  const [getCrewMemProgress, setCrewMemProgress] = useState([]);
  const [getCrewMembercount, setCrewMembercount] = useState([]);
  useEffect(() => {
    let _getCrewCount = _.cloneDeep(getCrewMembercount);
    _getCrewCount.push(getCrewMemberProceed);
    _getCrewCount = [...new Set(_getCrewCount)];
    setCrewMembercount(_getCrewCount);
  }, [getCrewMemberProceed]);

  useEffect(() => {
    if (getReportCrewCheck && !inProgressSection) {
      let _getbackCountArr = form_template.map((i, k) => i.visibility && k);
      let _backCount = _getbackCountArr.filter((z) => z !== false);
      _backCount = [...new Set(_backCount)];
      setProgress(_backCount);
    }
  }, []);

  useEffect(() => {
    if (getCrewMembercount.includes(getCrewMemberProceed)) {
      if (!getCrewfill) {
        if (inProgressSection) {
          let _emptyArr = _.cloneDeep(getProgress);
          for (let i = 0; i < getProgressCount; i++) {
            _emptyArr.push(i);
          }
          _emptyArr = [...new Set(_emptyArr)];
          setProgress(_emptyArr);
        } else {
          let _emptyArr = _.cloneDeep(getProgress);
          _emptyArr.push(getProgressCount - 1);
          _emptyArr = [...new Set(_emptyArr)];
          setProgress(_emptyArr);
        }
      } else {
        let _emptyCrewArr = _.cloneDeep(getCrewMemProgress);
        _emptyCrewArr.push(getProgressCount - 1);
        _emptyCrewArr = [...new Set(_emptyCrewArr)];
        setCrewMemProgress(_emptyCrewArr);
      }
    } else {
      setCrewMemProgress([]);
    }
  }, [getProgressCount, getCrewMembercount]);

  const selectGo = (k, e) => {
    if (!e.currentTarget.classList.contains("visibleSummary")) {
      if (getReportCrewCheck && !inProgressSection) {
        if (getClimber) {
          if (k <= 1) {
            setProceed(k);
          }
        } else {
          setProceed(k);
        }
      } else {
        let _finsalArr = [...new Set(getProgress)];
        if (_finsalArr.includes(k)) {
          if (getClimber) {
            if (k <= 1) {
              setProceed(k);
            }
          } else {
            setProceed(k);
          }
        }
      }
    }
  };

  const selectGoCrew = (k, e) => {
    if (!e.currentTarget.classList.contains("visibleSummary")) {
      if ((getReportCrewCheck && getIsCompleted) || !getCrewfill) {
        setProceed(k);
      } else {
        let _finsalArr = [...new Set(getCrewMemProgress)];
        if (_finsalArr.includes(k)) {
          setProceed(k);
        }
      }
    }
  };

  return (
    <>
      {!getCrewfill || getProgressCount === 15 ? (
        <ul className="step-wizard-list iJHA">
          {form_template.map((i, k) => {
            if (i.visibility) {
              return (
                <li
                  key={k}
                  onClick={(e) => selectGo(k, e)}
                  id={`step${k}`}
                  className={`step-wizard-item ${
                    getProgressCount === k + 1 ? "current-item " : ""
                  }${getProgress.includes(k) ? "selected " : ""}${
                    getClimber && k > 1 ? "notProceed" : ""
                  }${getProgressCount === 15 ? "visibleSummary" : ""} `}
                >
                  <span className="progress-count"></span>
                  <span className="progress-label">{i.sectionName}</span>
                </li>
              );
            }
          })}
        </ul>
      ) : (
        <ul className="step-wizard-list">
          {form_template.map((i, k) => {
            if ("section" in i) {
              return (
                <li
                  key={k}
                  onClick={(e) => selectGoCrew(k, e)}
                  className={`step-wizard-item ${
                    getProgressCount === k + 1 ? "current-item " : ""
                  }${
                    getCrewMemProgress.includes(k) || getReportCrewCheck
                      ? "selected "
                      : ""
                  }${getProgressCount === 15 ? "visibleSummary" : ""} `}
                >
                  <span className="progress-count">{k + 1}</span>
                  <span className="progress-label">{i.sectionName}</span>
                </li>
              );
            }
          })}
        </ul>
      )}

      {/* <ul className="step-wizard-list">
                <li className={`step-wizard-item ${getProgressCount === 1 ? "current-item" : ""}`} >
                    <span className="progress-count ">1</span>
                    <span className="progress-label" onClick={() => selectGo(0)}>Terms Of Use - Ericsson</span>
                </li>
                <li className={`step-wizard-item ${getProgressCount === 2 ? "current-item" : ""}`} >
                    <span className="progress-count ">2</span>
                    <span className="progress-label" onClick={() => selectGo(1)}>Type of Work</span>
                </li>
                <li className={`step-wizard-item ${getProgressCount === 3 ? "current-item" : ""}`} >
                    <span className="progress-count">3</span>
                    <span className="progress-label" onClick={() => selectGo(2)}>General Site Information</span>
                </li>
                <li className={`step-wizard-item ${getProgressCount === 4 ? "current-item" : ""}`} >
                    <span className="progress-count">4</span>
                    <span className="progress-label" onClick={() => selectGo(3)}>Job Information</span>
                </li>
                <li className={`step-wizard-item ${getProgressCount === 5 ? "current-item" : ""}`} >
                    <span className="progress-count">5</span>
                    <span className="progress-label" onClick={() => selectGo(4)}>Project Personnel Details</span>
                </li>
                <li className={`step-wizard-item ${getProgressCount === 6 ? "current-item" : ""}`} >
                    <span className="progress-count ">6</span>
                    <span className="progress-label" onClick={() => selectGo(5)}>Emergency Action Plan - EAP</span>
                </li>
                <li className={`step-wizard-item ${getProgressCount === 7 ? "current-item" : ""}`} >
                    <span className="progress-count">7</span>
                    <span className="progress-label" onClick={() => selectGo(6)}>Site Specific Rescue Plan</span>
                </li>
                <li className={`step-wizard-item ${getProgressCount === 8 ? "current-item" : ""}`} >
                    <span className="progress-count">8</span>
                    <span className="progress-label" onClick={() => selectGo(7)}>Environmental & Health Hazards - Weather</span>
                </li>
                <li className={`step-wizard-item ${getProgressCount === 9 ? "current-item" : ""}`} >
                    <span className="progress-count">9</span>
                    <span className="progress-label" onClick={() => selectGo(8)}>Structural Hazards</span>
                </li>
                <li className={`step-wizard-item ${getProgressCount === 10 ? "current-item" : ""}`} >
                    <span className="progress-count ">10</span>
                    <span className="progress-label" onClick={() => selectGo(9)}>Means and Methods Hazards</span>
                </li>
                <li className={`step-wizard-item ${getProgressCount === 11 ? "current-item" : ""}`} >
                    <span className="progress-count">11</span>
                    <span className="progress-label" onClick={() => selectGo(10)}>Hazard Controls</span>
                </li>
                <li className={`step-wizard-item ${getProgressCount === 12 ? "current-item" : ""}`} >
                    <span className="progress-count">12</span>
                    <span className="progress-label" onClick={() => selectGo(11)}>Personal Protective Equipment</span>
                </li>

                <li className={`step-wizard-item ${getProgressCount === 13 ? "current-item" : ""}`} >
                    <span className="progress-count">13</span>
                    <span className="progress-label" onClick={() => selectGo(12)}>Terms of Use (Exit)</span>
                </li>
            </ul> */}
    </>
  );
}
