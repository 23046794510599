import Select from "react-select";
import _ from "lodash";
import { Form } from "react-bootstrap";
import { useState } from "react";
export default function MultiSelect1forCrockets1({
  options,
  getOption,
  setOption,
  _s_key,
}) {
  const [getNewOption, setNewOption] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  function setmData(e, index) {
    let updatedOptions = [...getOption];
    updatedOptions[_s_key] = e;

    setOption(updatedOptions);
    setNewOption(updatedOptions);
  }

  return (
    <>
      <Select
        // defaultValue={[]}
        isMulti
        id={`mitigations_${_s_key}`}
        name={`mitigations_${_s_key}`}
        value={
          getOption[_s_key] !== "" ? getOption[_s_key] : getNewOption[_s_key]
        }
        options={options}
        onChange={(e, index) => setmData(e, index)}
        className="basic-multi-select mb-3"
        classNamePrefix="select"
      />
    </>
  );
}
