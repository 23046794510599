import React, { useState, useCallback, memo, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import _ from "lodash";
import { googleMapsKey } from "../../utils/Constant";
const containerStyle = {
  width: "100%",
  height: "300px",
};

// const center = {
//     lat: -3.745,
//     lng: -38.523
// };
// const center = { lat: 19.0759837, lng: 72.8776559 };

function GMap({ center, setAddress, getAddress, setLatLng911 }) {
  const [_address, _setAddress] = useState(getAddress);
  const [zget, zset] = useState(0);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsKey,
    libraries: ["places"],
  });
  const [markerposition, setMarkerposition] = useState(center);
  const [map, setMap] = useState(null);
  const [searchBox, setSearchBox] = useState();
  const [bounds, setBounds] = useState();
  const [getcenter, setCenter] = useState(center);

  const onBoundsChanged = () => {
    setBounds(map.getBounds());
    // setCenter(maps.getCenter());
  };

  const onPlacesChanged = (map) => {
    if (searchBox != null) {
      const places = searchBox.getPlaces();
      const bounds = new window.google.maps.LatLngBounds();
      places.forEach((place) => {
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });

      const nextMarkers = places.map((place) => ({
        position: place.geometry.location,
      }));

      const nextCenter = _.get(nextMarkers, "0.position", getcenter);
      let lat = nextCenter.lat();
      let lng = nextCenter.lng();

      setCenter({ lat: lat, lng: lng });
      setMarkerposition({ lat: lat, lng: lng });
      getReverseGeocodingData(lat, lng);
    }
  };

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    map.setZoom(3);
    setMap(map);
    // const zoom = 4;
    // const _goo = new window.google.maps.Map(map, {
    //     zoom,
    //     center
    // });
    // map.fitBounds(_goo);
    // setMap(_goo)
    // console.log('onload map::', map.getZoom(), map)
    getReverseGeocodingData(center.lat, center.lng);
  }, []);

  const onClick = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // setMap(map)
    // console.log(map.latLng.lat(), map.latLng.lng());
    let _markerposition = _.cloneDeep(markerposition);
    _markerposition = { lat: map.latLng.lat(), lng: map.latLng.lng() };
    setMarkerposition(_markerposition);
    //  setCenter(_markerposition);
    getReverseGeocodingData(map.latLng.lat(), map.latLng.lng());
  }, []);

  function getReverseGeocodingData(lat, lng) {
    var latlng = new window.google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: latlng }, (results, status) => {
      if (status !== window.google.maps.GeocoderStatus.OK) {
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status == window.google.maps.GeocoderStatus.OK) {
        // console.log(results);
        var address = results[0].formatted_address;
        // console.log('vicky', address);
        address += "\n(" + lat + "," + lng + ")";
        setLatLng911({ lat: lat, lng: lng });
        setAddress(address);
        _setAddress(address);
      }
    });
  }
  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);
  // // console.log("map:: ", map);
  setTimeout(() => {
    zset(17);
  }, 1000);
  return isLoaded ? (
    <>
      <div className="customTxtDiv">
        <StandaloneSearchBox
          onPlacesChanged={() => onPlacesChanged()}
          onLoad={(ref) => setSearchBox(ref)}
          bounds={bounds}
          controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
        >
          <input type="text" placeholder="Search" id="gMapTextBox" />
        </StandaloneSearchBox>
      </div>
      <GoogleMap
        // mapContainerStyle={containerStyle}
        center={getcenter}
        zoom={zget}
        onBoundsChanged={() => onBoundsChanged()}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={onClick}
        id="gMapCheck"
      >
        <div>
          <Marker position={markerposition}>
            <InfoWindow options={{ maxWidth: 250 }}>
              <span
                style={{
                  maxHeight: `100px`,
                  maxHeight: `60px`,
                  fontSize: `13px`,
                  overflow: `auto`,
                }}
              >
                {_address}
              </span>
            </InfoWindow>
          </Marker>
        </div>
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

export default GMap;
