import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import _ from "lodash";

function DropdownForEmployeeSubQuestion({
    s_obj,
    questions_obj,
    onChangeEmployee,
    sectionId,
    getEmployeeDropdownval,
    SelectedOptionofDropdown,
    getApiResponseEmployee
}) {

    const [filterVal, setFilterVal] = useState("");
    const [newDropdownValue, setnewDropdownValue] = useState("");

    useEffect(() => {
        if (_.isEmpty(getEmployeeDropdownval)) {
            getApiResponseEmployee();
        }
    },[])

    useEffect(() => {
        questions_obj.subQuestion.filter(i => i.userFilterSuggestion === "supplier" && setFilterVal(i.answer));
    }, [SelectedOptionofDropdown])


    const handleDropdownChange = (val) => {
        setnewDropdownValue(val);
        let impacted_section_questions;
        if (val.company === "Other") {
            impacted_section_questions = s_obj.impacted_section_questions.filter(i => i.action === 1);
        } else {
            impacted_section_questions = s_obj.impacted_section_questions.filter(i => i.action === 0);
        }
        onChangeEmployee({
            sectionId: sectionId,
            firstName: val.firstName,
            lastName: val.lastName,
            questionId: questions_obj.questionId,
            subQuestionId: s_obj.questionId,
            email: val.value === "" ? val.company : val.value,
            impacted_section_questions: impacted_section_questions,
        });
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: 32,
            fontSize: 14,
        }),
    };

    return (
        <>
            <Form.Label>
                <span className="text-danger bold-font">
                    {s_obj.state === "Required" ? "*" : ""}
                </span>
                {s_obj.question}
            </Form.Label>
            <Select
                options={getEmployeeDropdownval.filter(i => i.company === filterVal || i.company === "Other")} // filterVal
                value={s_obj.answer === "" ? newDropdownValue : { value: s_obj.answer, label: s_obj.answer }}
                // value={SelectedOptionofDropdown}
                onChange={handleDropdownChange}
                styles={customStyles}
                placeholder="--Select --"
                isSearchable
                isDisabled = {filterVal !== "" ? false : true}
            />
            <br />
        </>
    );
}

export default DropdownForEmployeeSubQuestion;
