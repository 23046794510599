import { URL } from "../utils/Constant";
import { headers, TOKEN } from "../utils/Variable";
import _ from "lodash";
export const getProfile = async (getEmail, setLoading, setMsg, setData) => {
  setLoading(true);
  let e_data = {};
  headers.set("Authorization", "Bearer " + TOKEN());
  const resp = await fetch(URL.Profile, { method: "get", headers: headers });
  if (resp.ok) {
    const data = await resp.json();
    e_data = {
      emailId: getEmail,
      firstName: data.data.firstName,
      lastName: data.data.lastName,
      profile_img_url: data.data.profilePicture,
      policy_link: data.data.hyperlink,
      authorized_groups: data.data.hasOwnProperty("authorized_groups")
        ? data.data.authorized_groups
        : [],
      editableTime: data.data.editableTime,
    };
    var ciphertext = JSON.stringify(e_data).toString();
    sessionStorage.setItem("E_DATA", ciphertext);

    if (data.status_code === 200) {
      setData(data.data);
    } else {
      setMsg(data.message);
    }
    setLoading(false);
  }
};

export const getCertificateOptionList = async (setData) => {
  headers.set("Authorization", "Bearer " + TOKEN());
  const resp = await fetch(URL.CertifiateOptionList, {
    method: "get",
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();

    if (data.status_code === 200) {
      let _certificateList = data.data.map((obj) => {
        obj["_certificateID"] = Number(obj["certificateID"]);
        return obj;
      });
      _certificateList = _.orderBy(
        _certificateList,
        ["_certificateID"],
        ["asc"]
      );
      setData(_certificateList);
    }
  }
};

export const saveProfile = async (setLoading, setMsg, dataJSON) => {
  setLoading(true);
  // console.log(dataJSON);
  let jsonData = JSON.stringify(dataJSON);
  headers.set("Authorization", "Bearer " + TOKEN());
  const resp = await fetch(URL.EditProfile, {
    method: "put",
    body: jsonData,
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();

    if (data.status_code === 200) {
      let e_data = {
        // "emailId": dataJSON.email,
        firstName: dataJSON.firstName,
        lastName: dataJSON.lastName,
        profile_img_url: dataJSON.profilePicture,
      };
      // console.log(e_data, data1);
      var ciphertext = JSON.stringify(e_data).toString();
      // console.log("run1")
      sessionStorage.setItem("E_DATA", ciphertext);
      // console.log(data)
      // setLoading(false);
      window.location.pathname = "/profile";
    } else {
      setMsg(data.message);
    }
    setLoading(false);
  }
};

export const uploadFrontBackImgApi = async (
  setFrontBackImgData,
  getFrontBackImg,
  setLoading,
  setMsg1,
  setCheckImgValid
) => {
  setLoading(true);
  setMsg1("");
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
  };

  var formData = new FormData();
  formData.append("front", getFrontBackImg["front"]);
  formData.append("back", getFrontBackImg["back"]);
  formData.append("upload_type", "Certificate");
  // formData.append('employeeId', EMP_ID.replace(/['"]+/g, ''));
  const resp = await fetch(URL.UploadMultiImage, {
    method: "POST",
    body: formData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      setFrontBackImgData(data.data);
      if (_.isEmpty(data.data)) {
        setCheckImgValid(true);
        setMsg1("Both front & back image should be different.");
      } else {
        setCheckImgValid(false);
        setMsg1("");
      }
    } else if (data.status_code === 201) {
      setMsg1(data.message);
    }
    setLoading(false);
  }
};

export const uploadImgPicApi = async (base64, setLoading, setMsg, fileType) => {
  setLoading(true);
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  const resp = await fetch(URL.ProfilePicUpdate, {
    method: "PUT",
    body: JSON.stringify({
      // emailId: DECRYPTED_DATA.emailId,
      image_name: "profilePicture." + fileType.replace("image/", ""),
      pic_dataurl: base64,
    }),
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      setMsg(data.message);
      window.location.pathname = "/profile";
    }
    setLoading(false);
  }
};

export const addCertificateApi = async (jsonData, setLoading, setMsg) => {
  setLoading(true);
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  const resp = await fetch(URL.AddCertifiate, {
    method: "PUT",
    body: jsonData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      window.location.pathname = "/profile";
    }
    setMsg(data.message);
    setLoading(false);
  }
};
export const deleteCertificateApi = async (
  certificateSeqId,
  setLoading,
  setMsg
) => {
  setLoading(true);
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  const resp = await fetch(URL.DeleteCertificate, {
    method: "PUT",
    body: JSON.stringify({
      certificateSeqId: certificateSeqId,
      // employeeId: EMP_ID.replace(/['"]+/g, '')
    }),
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      window.location.pathname = "/profile";
    }
    setMsg(data.message);
    setLoading(false);
  }
};
export const updateCertificateApi = async (jsonData, setLoading, setMsg) => {
  setLoading(true);
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  const resp = await fetch(URL.UpdateCertifiate, {
    method: "PUT",
    body: jsonData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      window.location.pathname = "/profile";
    }
    setMsg(data.message);
    setLoading(false);
  }
};
