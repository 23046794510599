import { Container } from "react-bootstrap";
import "./footer.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DECRYPTED_DATA } from "../../utils/Variable";
export default function Footer() {
  const [getYear, setYear] = useState();

  useEffect(() => {
    const d = new Date();
    let year = d.getFullYear();
    setYear(year);
  }, []);

  return (
    <div
      className="footer navbar-expand navbar-dark d-flex justify-content-between"
      style={{ zIndex: "3000" }}
    >
      <Container className="nav-content">
        <Link className="footerLink" to={DECRYPTED_DATA.policy_link !== undefined ? DECRYPTED_DATA.policy_link : "https://safeworkblobstore.blob.core.windows.net/safeworkdev/Icons/PrivacyNoticeExternal.pdf?sp=r&st=2024-01-03T15:03:20Z&se=2030-01-01T23:03:20Z&spr=https&sv=2022-11-02&sr=b&sig=X9WE0cThVzR8pm5CIWzIN4NYhPZzWd6O6jfMW%2FLoBEg%3D"} target="_blank">Privacy</Link>| &copy; Safe Worker AI 2020-{getYear}
      </Container>
    </div>
  );
}
