import { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import GMap from "../../components/GMap/GMap";
import GMapDirection from "../GMap/GMapDirection";
import { removeTags } from "../../utils/Helper";
import _ from "lodash";
import { get911AddressInfoApi } from "../../api/CreateiJHA.api";
export default function ModalMapCustom({
  showMdl,
  setShowMdl,
  dataMdl,
  setAddress,
  getAddress,
  setDirections,
  directions,
  form_template,
  setForm_template,
}) {
  const [directionsHTML, setDirectionsHTML] = useState("");
  const [getLoading, setLoading] = useState(false);
  const [getLatLng911, setLatLng911] = useState({
    lat: "",
    lng: "",
  });
  const onAdd = () => {
    if (!dataMdl.direction) {
      if (dataMdl.sectionId) {
        dataMdl.changeFormTemplateData([
          dataMdl.sectionId,
          getAddress,
          dataMdl._key,
        ]);
      } else {
        dataMdl.changeFormTemplateData([4, getAddress, dataMdl._key]);

        if (
          dataMdl.heading !== "Nearest Police Station" &&
          dataMdl.heading !== "Nearest Fire Department"
        ) {
          get911AddressInfoApi(
            setForm_template,
            form_template,
            getLatLng911,
            setLoading
          );
        }
      }
    } else {
      // dataMdl.changeFormTemplateData([4, [directionsHTML, directions.routes[0].legs[0].distance.text, directions.routes[0].legs[0].duration.text], dataMdl._key]);
      // above code is working but we are not add in textarea wth directions
      // setTimeout(() => dataMdl.changeFormTemplateData([17, [directions.routes[0].legs[0].distance.text, directions.routes[0].legs[0].duration.text], dataMdl._key])
      // , 5000);
    }
    setShowMdl(false);
    // setAddress("");
  };
  useEffect(() => {
    if (!_.isEmpty(directions)) {
      let _directionsHTML = directions.routes[0].legs[0].steps.map((obj) => {
        return removeTags(obj.instructions);
      });

      setDirectionsHTML(_.join(_directionsHTML, "\n"));
    }
  }, [directions, setDirections]);
  return (
    <>
      <Modal
        className="gmapModal"
        show={showMdl}
        onHide={() => setShowMdl(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{dataMdl.heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>
                {!dataMdl.direction ? (
                  <GMap
                    center={{
                      lat: dataMdl.getLatitude,
                      lng: dataMdl.getLongitude,
                    }}
                    setAddress={setAddress}
                    getAddress={getAddress}
                    setLatLng911={setLatLng911}
                  />
                ) : (
                  <GMapDirection
                    defaultLocation={{
                      lat: dataMdl.getLatitude,
                      lng: dataMdl.getLongitude,
                    }}
                    orig={{ lat: dataMdl.origin[0], lng: dataMdl.origin[1] }}
                    dest={{
                      lat: dataMdl.destination[0],
                      lng: dataMdl.destination[1],
                    }}
                    setDirection={setDirections}
                    // directions={dataMdl.directions}
                  />
                )}
              </Col>
            </Row>
            <Form>
              {" "}
              {!dataMdl.direction ? (
                <Form.Group
                  controlId="firstName"
                  className="mb-3"
                  style={{ marginBottom: `0 !important` }}
                >
                  <Form.Label>Address</Form.Label>

                  <Form.Control
                    className="rounded-0 gmapTextArea"
                    as="textarea"
                    rows={2}
                    placeholder="Address"
                    value={getAddress}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Form.Group>
              ) : (
                <Form.Group controlId="firstName" className="mb-3">
                  <Form.Label>Direction</Form.Label>
                  <Form.Control
                    className="rounded-0"
                    as="textarea"
                    rows={5}
                    placeholder="Direction"
                    value={removeTags(directionsHTML)}
                    // onChange={(e) => setDirectionsHTML(e.target.value)}
                  />
                </Form.Group>
              )}
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {dataMdl.showClose ? (
            <Button variant="secondary" onClick={() => setShowMdl(false)}>
              {" "}
              Close{" "}
            </Button>
          ) : null}
          {!dataMdl.direction ? (
            <Button variant="primary" onClick={onAdd}>
              {dataMdl.saveBtnName}
            </Button>
          ) : (
            <Button variant="danger" onClick={() => setShowMdl(false)}>
              {" "}
              Close{" "}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
