import { URL } from "../utils/Constant";
import { headers, TOKEN } from "../utils/Variable";
const getRequestBody = (params) => {
  let formData = new FormData();
  for (let i in params) {
    formData.append(i, params[i]);
  }
  return formData;
};

const getUrlByQueryParams = (url, params) => {
  let queryString = "";
  Object.entries(params).forEach(([key, value], index) => {
    const formattedValue = value.replace("&", "%26");
    queryString += `${index === 0 ? "?" : "&"}${key}=${formattedValue}`;
  });
  return url + queryString;
};

export const getSupportDropdownValues = async () => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };

  const resp = await fetch(URL.GetSupportDropdown, {
    method: "GET",
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();

    return data.data;
  }
};

export const generateDetails = async (
  param,
  setLoading,
  setIsSuccess,
  setsubmittedData
) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
  };

  var formData = new FormData();

  for (let i in param) {
    if (i === "userdevice") {
      formData.append(i, JSON.stringify(param[i]));
    } else if (i === "imagelist") {
      param["imagelist"].map((value) => {
        if (value !== "") {
          formData.append("imagelist", value, value.name);
        }
      });
    } else {
      formData.append(i, param[i]);
    }
  }

  const resp = await fetch(URL.Support_Form.GENERATE_DETAILS, {
    method: "POST",
    body: formData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      setIsSuccess({
        status_code: data.status_code,
        successMsg:
          "Your support request has been received. Our team will get back to you soon.",
      });
      setsubmittedData(false);
      setLoading(true);

      return data;
    } else {
      setIsSuccess({ status_code: data.status_code, successMsg: data.message });
      setsubmittedData(false);
      setLoading(true);
    }
  }
};

export const uploadMultiImage = async (sendDataImg) => {
  let headersList = {
    Authorization: "Bearer " + TOKEN(),
  };

  var formData = new FormData();
  sendDataImg["files"].map((value) => {
    if (value !== "") {
      formData.append("files", value, value.name);
      // formData.append('files', value);

      formData.append("section_names", sendDataImg["section_names"]);
      return formData;
    }
  });

  // formData.append('emailId', sendDataImg['emailId']);
  formData.append("upload_type", sendDataImg["upload_type"]);
  formData.append("reportId", sendDataImg["reportId"]);

  const resp = await fetch(URL.UploadMultiImage, {
    method: "POST",
    body: formData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      return data.data;
    }
  }
};
