import { Row, Col, Accordion, Badge } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import trashcan from "../../assets/Icons/trashcan.svg";
import { EMP_ID, DECRYPTED_DATA } from "../../utils/Variable";
import _ from "lodash";
import close from "../../assets/Icons/close.svg";
import check from "../../assets/Icons/check.svg";
export default function SummarySOB({
  form_template,
  setProceed,
  setProceed1,
  getCrewLeadData,
  setCrewMenProceed,
  getCrewLead,
  setCrewLead,
  setCrewfill,
  setCrewfillComplete,
  setIsCompleted,
  setvalidationofsubmit,
  getIsCompleted,
  setForm_template,
  setCrewLeadData,
  setSummarybutton,
}) {
  const [getCount, setCount] = useState([]);
  const [getCount1, setCount1] = useState([]);
  const [getCrewMemberCls, setCrewMemberCls] = useState([]);
  const crewleadId = EMP_ID().replace(/['"]+/g, "");
  const crewMemberId = getCrewLeadData.crewmemberdata.map(
    (obj) => obj.employeeId
  );

  const indexsWithCrewMember = form_template
    .map((item, index) => (item.hasOwnProperty("section") ? index : -1))
    .filter((index) => index !== -1);

  useEffect(() => {
    let _getCount = [];
    let _getCount1 = [];
    _getCount["crewMembers"] = [];
    _getCount[crewleadId] = form_template.map((obj) => {
      let _fillcount = 0;
      let _totalcount = 0;
      (obj.section && obj.section[getCrewLead]
        ? obj.section[getCrewLead]
        : obj
      ).questions.map((s_obj, k) => {
        if (s_obj.visibility === true) {
          if (s_obj.questionType === "Radio+Signature") {
            !!s_obj.signature && _fillcount++;
          } else if (s_obj.questionType === "Table+Dropdown+Multi-select") {
            let _c = 0;
            s_obj.table_options.map((qobj, i) => {
              qobj.common && _fillcount++ && _totalcount++;
            });
            _c > 0 && _fillcount++;
          } else if (s_obj.questionType === "Sub-question") {
            let _c = 0;
            s_obj.subQuestion.map((sqobj) => {
              sqobj.answer !== "" && _c++;
            });
            _fillcount++;
          }
          else if (s_obj.questionType === "Radio+Image") {
            s_obj.answer !== "" && s_obj.picture !== "" && _fillcount++;
          }
          else if (s_obj.questionType === "Text" || s_obj.questionType === "Dropdown+Text" || s_obj.questionType === "Dropdown" || s_obj.questionType === "Date") {
            s_obj.answer !== "" && _fillcount++;
          }
          else if (s_obj.questionType === "Image") {
            s_obj.picture !== "" || !_.isEmpty(s_obj.multiple_picture) && _fillcount++;
          }
          else if (s_obj.questionType === "Multiple-questions") {
            obj.questions.map((qobj, i) => {
              qobj.answer !== "" && _fillcount++;
              _fillcount++;
            });
          } else if (s_obj.questionType === "Terms&condition") {
            !!s_obj.checkbox_model[0].value && _fillcount++;
          } else if (s_obj.questionType === "Radio+Sub-question") {
            s_obj.answer !== "" && _fillcount++;
          } else if (s_obj.questionType === "Radio") {
            s_obj.answer !== "" && _fillcount++;
          } else if (s_obj.questionType === "Description+Rearrange") {
            obj.questions.map((qobj, i) => {
              qobj?.options?.map((mqobj) => {
                _fillcount++;
                _totalcount = qobj.options.length;
              });
            });
          } else if (s_obj.questionType === "Description+Dropdown+Rearrange") {
            obj.questions.map((qobj, i) => {
              qobj?.options?.map((mqobj) => {
                _fillcount++;
                _totalcount = qobj.options.length;
              });
            });
          } else {
            !!s_obj.answer && _fillcount++;
          }
          _totalcount++;
        }
      });
      return {
        sectionId: obj.sectionId,
        visibility: obj.visibility,
        sectionName: obj.sectionName,
        fillcount: _fillcount,
        totalcount: _totalcount
      };
    });
    crewMemberId.map((objCwID, j) => {
      let FilteredValues = form_template.filter((item) =>
        item.hasOwnProperty("section")
      );
      _getCount1[j] = FilteredValues.map(obj => {
        let _fillcount = 0;
        let _totalcount = 0;
        let _requiredcount = 0;
        const pageIndex = form_template.findIndex(i => i.sectionId === obj.sectionId);
        obj.section[j + 1].questions.map(s_obj => {
          if (s_obj.state === "Required") {
            _requiredcount++;
          }
          if (s_obj.visibility === true) {
            if (s_obj.questionType === "Radio+Signature") {
              !!s_obj.signature && _fillcount++;
            } else if (s_obj.questionType === "Table+Dropdown+Multi-select") {
              let _c = 0;
              s_obj.table_options.map((qobj, i) => {
                qobj.common && _fillcount++ && _totalcount++;
              });
              _c > 0 && _fillcount++;
            }
            else if (s_obj.questionType === "Sub-question") {
              let _c = 0;

              obj.subQuestion.map((sqobj) => {
                sqobj.answer !== "" && _c++;
              });
              _fillcount++;
            } else if (s_obj.questionType === "Text" || s_obj.questionType === "Dropdown+Text" || s_obj.questionType === "Dropdown" || s_obj.questionType === "Date") {
              s_obj.answer !== "" && _fillcount++;
            } else if (s_obj.questionType === "Image") {
              s_obj.picture !== "" || !_.isEmpty(s_obj.multiple_picture) && _fillcount++;
            } else if (s_obj.questionType === "Radio+Image") {
              s_obj.answer !== "" && s_obj.picture !== "" && _fillcount++;
            } else if (s_obj.questionType === "Multiple-questions") {
              s_obj.questions.map((qobj, i) => {
                qobj.answer !== "" && _fillcount++;
                _fillcount++;
              });
            } else if (s_obj.questionType === "Terms&condition") {
              !!s_obj.checkbox_model[0].value && _fillcount++;
            } else if (s_obj.questionType === "Radio+Sub-question") {
              s_obj.answer !== "" && _fillcount++;
            } else if (s_obj.questionType === "Radio") {
              s_obj.answer !== "" && _fillcount++;
            } else if (s_obj.questionType === "Description+Rearrange") {
              obj.questions.map((qobj, i) => {
                qobj?.options?.map((mqobj) => {
                  _fillcount++;
                  _totalcount = qobj.options.length;
                });
              });
            } else if (s_obj.questionType === "Description+Dropdown+Rearrange") {
              s_obj.questions.map((qobj, i) => {
                qobj?.options?.map((mqobj) => {
                  _fillcount++;
                  _totalcount = qobj.options.length;
                });
              });
            } else {
              !!s_obj.answer && _fillcount++;
            }
            _totalcount++;
          }
        })
        return {
          sectionId: obj.sectionId,
          visibility: obj.visibility,
          sectionName: obj.sectionName,
          fillcount: _fillcount,
          totalcount: _totalcount,
          requiredcount: _requiredcount,
          _step: pageIndex
        };

      }).filter((obj) => obj !== undefined);
    });

    setCount1(_getCount1);
    setCount(_getCount);
  }, [getCrewLeadData]);

  const crewChangeHandler = (_key, _step = indexsWithCrewMember[0]) => {
    setSummarybutton(true);
    let _getCrewLead = _.cloneDeep(getCrewLead);
    _getCrewLead = _key + 1;
    setProceed(_step);
    setProceed1(0);
    setCrewLead(_getCrewLead);
    setCrewMenProceed(true);
    setCrewfill(true);
  };

  const crewLeadChangeHandler = (_key) => {
    setSummarybutton(true);
    let _getCrewLead = _.cloneDeep(getCrewLead);
    _getCrewLead = 0;
    setCrewLead(_getCrewLead);
    setProceed(_key);
    setProceed1(0);
    setCrewMenProceed(false);
    setCrewfill(false);
  };
  const crewMemberIsFilled = (_key) => {
    let result = getCount1[_key]
      .map((obj, i) => {
        return obj.fillcount >= obj.requiredcount && true;
      })
      .filter((obj) => obj !== undefined)
      .every((obj) => obj === true);
    let _getCrewMemberCls = _.cloneDeep(getCrewMemberCls);
    if (!_.isEmpty(getCount1[_key])) {
      if (result) {
        _getCrewMemberCls[_key] = {
          badge: false,
          comp: (
            <img
              src={String(check)}
              className="ms-5 filter-green border border-success rounded-circle  "
              style={{ width: "22px", padding: "2px" }}
              alt="check-success"
            />
          ),
        };
      } else {
        _getCrewMemberCls[_key] = {
          badge: true,
          comp: (
            <span>
              <img
                src={String(close)}
                className="ms-5 filter-red border border-danger rounded-circle  "
                style={{ width: "22px", padding: "2px" }}
                alt="check-success"
              />
              <Badge className="ms-2" bg="danger">
                Not Yet Filled In
              </Badge>
            </span>
          ),
        };
      }
    }
    setCrewMemberCls(_getCrewMemberCls);
    return !result;
  };

  const deleteCrewMember = (_key) => {
    let _getIsCompleted = _.cloneDeep(getIsCompleted);
    _getIsCompleted = _getIsCompleted.filter((i, k) => k !== _key);
    setIsCompleted(_getIsCompleted);
    let _form_template = _.cloneDeep(form_template);
    _form_template.filter((obj, k) => {
      if (!_.isEmpty(obj.section)) {
        obj.section = obj.section.filter((s_obj, i) => i !== _key && s_obj);
      }
      return obj;
    });
    setForm_template(_form_template);
    let _getCrewLeadData = _.cloneDeep(getCrewLeadData);
    _getCrewLeadData.crewmemberdata = _getCrewLeadData.crewmemberdata.filter(
      (obj, k) => k !== _key - 1 && obj
    );
    setCrewLeadData(_getCrewLeadData);
    let _getCrewLead = _.cloneDeep(getCrewLead);
    let crewMemLeg = _getCrewLeadData.crewmemberdata.length;
    if (_getCrewLead > crewMemLeg) {
      _getCrewLead = crewMemLeg;
    }
    setCrewLead(_getCrewLead);
  };

  return (
    <>
      <Accordion defaultActiveKey={[0]} alwaysOpen className="mb-3">
        <Accordion.Item eventKey={0}>
          <Accordion.Header>
            <div className="rounded border p-2 bd-highlight">
              <img
                src={DECRYPTED_DATA().profile_img_url}
                style={{ width: "60px" }}
                alt="profilePic"
              />
            </div>
            <div className="p-2 bd-highlight" style={{ color: "#0c63e4" }}>
              {DECRYPTED_DATA().firstName + " " + DECRYPTED_DATA().lastName}
            </div>
            <img
              src={String(check)}
              className="ms-5 filter-green border border-success rounded-circle  "
              style={{ width: "22px", padding: "2px" }}
              alt="check-success"
            />
          </Accordion.Header>
          <Accordion.Body>
            <Row xs={12}>
              <Col md={4}>
                <ul className="list-group mt-3 mb-3">
                  {!_.isEmpty(getCount[crewleadId]) &&
                    getCount[crewleadId].map((obj, i) => {
                      if (obj.visibility) {
                        return (
                          <li
                            key={obj.sectionId}
                            className="list-group-item d-flex flex-row bd-highlight align-items-center"
                          >
                            <span
                              className={
                                obj.fillcount !== obj.totalcount
                                  ? "w-50 text-warning"
                                  : "w-50 text-success"
                              }
                              style={{ cursor: "pointer" }}
                              onClick={() => crewLeadChangeHandler(i)}
                            >
                              <b>{obj.sectionName}</b>
                            </span>
                            <span
                              className={
                                obj.fillcount !== obj.totalcount
                                  ? "ms-3 w-25 text-warning"
                                  : "ms-3 w-25 text-success"
                              }
                            >
                              ({obj.fillcount}/{obj.totalcount})
                            </span>
                            <img
                              src={String(check)}
                              className={
                                obj.fillcount !== obj.totalcount
                                  ? "ms-5 filter-warn border border-success rounded-circle"
                                  : "ms-5 filter-green border border-success rounded-circle"
                              }
                              style={{ width: "22px", padding: "2px" }}
                              alt="check-success"
                            />
                          </li>
                        );
                      }
                    })}
                </ul>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        {getCrewLeadData.crewmemberdata.map((objCwID, j) => (
          <Accordion.Item eventKey={j + 1} key={j} className="crewMemAccord">
            <Accordion.Header
              style={{ zIndex: "0" }}
              onClick={() => { crewMemberIsFilled(j) && crewChangeHandler(j); setCrewfillComplete(false); }}
            >
              {objCwID.firstName + " " + objCwID.lastName}
              {!_.isEmpty(getCount1[j]) &&
                getCount1[j].every((obj) => obj.fillcount === obj.totalcount)
                ? setvalidationofsubmit(false)
                : setvalidationofsubmit(true)}

              {!_.isEmpty(getCount1[j]) &&
                getCount1[j].every((obj) => obj.fillcount >= obj.requiredcount) ? (
                <img
                  src={String(check)}
                  className="ms-5 filter-green border border-success rounded-circle  "
                  style={{ width: "22px", padding: "2px" }}
                  alt="check-success"
                />
              ) : (
                <span>
                  <img
                    src={String(close)}
                    className="ms-5 filter-red border border-danger rounded-circle  "
                    style={{ width: "22px", padding: "2px" }}
                    alt="check-success"
                  />

                  <Badge className="ms-2" bg="danger">
                    Not Yet Filled In
                  </Badge>
                </span>
              )}
            </Accordion.Header>
            <div className="d-flex deleteCrewDiv">
              <img
                className="icon-red-color pointer"
                src={String(trashcan)}
                onClick={() => deleteCrewMember(j + 1)}
                alt="delete-icon"
              />
            </div>
            <Accordion.Body>
              <Row xs={12}>
                <Col md={4}>
                  <ul className="list-group mt-3 mb-3">
                    {!_.isEmpty(getCount1[j]) &&
                      getCount1[j].map((obj, i) => {
                        return (
                          <li
                            key={obj.sectionId}
                            className="list-group-item d-flex flex-row bd-highlight align-items-center"
                          >
                            <span
                              className={
                                obj.fillcount !== obj.totalcount
                                  ? "w-50 text-warning"
                                  : "w-50 text-success"
                              }
                              style={{ cursor: "pointer" }}
                              onClick={() => { crewChangeHandler(j, obj._step); setCrewfillComplete(true); }}
                            >
                              <b>{obj.sectionName}</b>
                            </span>
                            <span
                              className={
                                obj.fillcount !== obj.totalcount
                                  ? "ms-3 w-25 text-warning"
                                  : "ms-3 w-25 text-success"
                              }
                            >
                              ({obj.fillcount}/{obj.totalcount})
                            </span>
                            <img
                              src={String(check)}

                              className={
                                obj.fillcount !== obj.totalcount
                                  ? "ms-5 filter-warn border border-success rounded-circle"
                                  : "ms-5 filter-green border border-success rounded-circle"
                              }
                              style={{ width: "22px", padding: "2px" }}
                              alt="check-success"
                            />
                          </li>
                        );
                      })}
                  </ul>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
}
