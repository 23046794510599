import { URL } from "../utils/Constant";
import { headers, TOKEN } from "../utils/Variable";
import _ from "lodash";
//get wheather according to f/c f=imperial,c=metric
export const getWeatherApi = async (
  setForm_template,
  form_template,
  sendData,
  setWeatherHours
) => {
  // const pageIndexofEnvironmental = form_template.findIndex((item) => item.sectionName === "Environmental & Health Hazards - Weather");
  sendData.loadingTrue();
  let _form_template = _.cloneDeep(form_template);
  // if (_form_template[pageIndexofEnvironmental] !== undefined) {
    headers.set("Authorization", "Bearer " + TOKEN());
    //?latitude=28.6139391&longitude=77.2090212&unit=imperial
    const resp = await fetch(
      // URL.GetWeather + `?latitude=${sendData['latitude']}&longitude=${sendData['longitude']}&unit=${sendData['unit']}&response_type=4&duration=12`,
      URL.GetWeather +
      `?latitude=${sendData["latitude"]}&longitude=${sendData["longitude"]}&unit=${sendData["unit"]}`,
      { method: "get", headers: headers }
    );
    if (resp.ok) {
      const data = await resp.json();
      if (data.status_code === 200) {
        // _form_template[pageIndexofEnvironmental].questions[0].weather_imperial =
        //   data.data;
          _form_template.map(i => {
            i?.questions?.map(j => {
              if (j.questionType === "Radio+Weather") {
                j.weather_imperial = data.data;
              }
            });
          });
        // console.log('getweather:', data.data);
        setForm_template(_form_template);
        const resp1 = await fetch(
          URL.GetWeather +
          `?latitude=${sendData["latitude"]}&longitude=${sendData["longitude"]}&unit=${sendData["unit"]}&response_type=4&duration=12`,
          { method: "get", headers: headers }
        );
        if (resp1.ok) {
          const data1 = await resp1.json();
          data1.status_code === 200 && setWeatherHours(data1.data);

          // _form_template[
          //   pageIndexofEnvironmental
          // ].questions[0].weather_forecasts = data1.data;
          _form_template?.map(i => {
            i?.questions?.map(j => {
              if (j.questionType === "Radio+Weather") {
                j.weather_forecasts = data1.data;
              }
            });
          });

          setForm_template(_form_template);
          sendData.loadingFalse();
        }
      }
    // }
  }
};

export const getInspectionWeatherApi = async (
  setForm_template,
  form_template,
  sendData,
  setWeatherHours
) => {
  sendData.loadingTrue();
  let _form_template = _.cloneDeep(form_template);
  headers.set("Authorization", "Bearer " + TOKEN());
  const resp = await fetch(
    URL.GetWeather +
    `?latitude=${sendData["latitude"]}&longitude=${sendData["longitude"]}&unit=${sendData["unit"]}`,
    { method: "get", headers: headers }
  );
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      _form_template?.map(i => {
        i?.questions?.map(j => {
          if (j.questionType === "Radio+Weather") {
            j.weather_imperial = data.data;
          }
        })
      })
      setForm_template(_form_template);
      const resp1 = await fetch(
        URL.GetWeather +
        `?latitude=${sendData["latitude"]}&longitude=${sendData["longitude"]}&unit=${sendData["unit"]}&response_type=4&duration=12`,
        { method: "get", headers: headers }
      );
      if (resp1.ok) {
        const data1 = await resp1.json();
        if (data1.status_code === 200) {
          setWeatherHours(data1.data);
          _form_template?.map(i => {
            i?.questions?.map(j => {
              if (j.questionType === "Radio+Weather") {
                j.weather_forecasts = data1.data;
              }
            });
          });
          setForm_template(_form_template);
        }
        sendData.loadingFalse();
      }
    }
  }
};