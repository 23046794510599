import { Modal, Button } from "react-bootstrap";
import { deleteCertificateApi } from "../../api/Profile.api";
import { Row, Col } from "react-bootstrap"
import Loading from "../Loading/Loading";
import { useState } from "react";
export default function ModalDelete({ showMdl, setShowMdl, dataMdl }) {
    const [getLoading, setLoading] = useState(false);
    const [getMsg, setMsg] = useState('');
    const deleteHandler = () => {
        deleteCertificateApi(dataMdl.certificateSeqId, setLoading, setMsg)
        // setShowMdl(false)
    }
    // console.log(dataMdl, 'delete modal', getMsg)
    return (
        <>
            <Modal show={showMdl} onHide={() => setShowMdl(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{dataMdl.heading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {dataMdl.body}
                    {getMsg !== '' &&
                        <div className="mb-3">
                            {getMsg}
                        </div>
                    }

                    {getLoading && <Row className="d-flex justify-content-center">
                        <Col>
                            <Loading width={"32"} />
                        </Col>
                    </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {dataMdl.showClose ? <Button variant="secondary" disabled={getLoading} onClick={() => setShowMdl(false)}> Close </Button> : null}
                    <Button variant="primary" disabled={getLoading} onClick={deleteHandler}>
                        {dataMdl.saveBtnName}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

