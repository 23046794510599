import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function PrivacyPolicyModal({
  show,
  onHide,
  onPrivacyCheck,
  policyLink,
  isCheckboxChecked,
  setIsCheckboxChecked,
  isEndUserChecked,
  setEndUserChecked,
  enduserStatementURL
}) {
  return (
    <Modal
      size="sm"
      backdrop="static"
      show={show}
      onHide={onHide}
      centered
      dialogClassName="custom-modal"
    >
      <Modal.Header>
        <div className="d-flex justify-content-center w-100">
          <Modal.Title>PRIVACY POLICY</Modal.Title>
        </div>
      </Modal.Header>

      <Modal.Body>
        Please read this{" "}
        <a href={policyLink} target="_blank">
          Privacy Policy
        </a>{" "}
        carefully. If you do not agree with the terms of Privacy Policy, please
        do not access the application.
      </Modal.Body>
      <Modal.Body>
        <div>
          <label>
            <input
              type="checkbox"
              checked={isCheckboxChecked}
              onChange={(e) => setIsCheckboxChecked(e.target.checked)}
            />
            &nbsp;
            <span style={{ fontWeight: "bold" }}>
              I acknowledge that I have read the privacy policy and I accept it.
            </span>
          </label>
        </div>
        <br />
        <div>
          <label>
            <input
              type="checkbox"
              checked={isEndUserChecked}
              onChange={(e) => setEndUserChecked(e.target.checked)}
            />

            &nbsp;
            <span style={{ fontWeight: "bold" }}>
              I accept the <Link className="footerLink" target="_blank" to={enduserStatementURL !== undefined ? enduserStatementURL : "https://steswprodeastus-chanbah3hbhkfdc5.z01.azurefd.net/public/ESW%20End%20User%20Statement.pdf"}>End-User statement</Link>.
            </span>
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center w-100">
          <Button
            variant="primary rounded"
            disabled={!isCheckboxChecked || !isEndUserChecked}
            onClick={() => onPrivacyCheck()}
          >
            Submit
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default PrivacyPolicyModal;
