// import { client_ID } from "./Constant";
export const TOKEN = () => {
  const _keys = sessionStorage.getItem("TOKEN")
  if (_keys !== null && _keys !== undefined) {
    return _keys;
  } else {
    return '';
  }
}

export let NEW_USER = () => {
  return sessionStorage.getItem("NEW_USER") !== null &&
    typeof sessionStorage.getItem("NEW_USER") !== "undefined"
    ? sessionStorage.getItem("NEW_USER")
    : "";
}

export let ciphertext = () => {
  return sessionStorage.getItem("E_DATA") !== null &&
    typeof sessionStorage.getItem("E_DATA") !== "undefined"
    ? sessionStorage.getItem("E_DATA")
    : "";
}
export let EMP_ID = () => {
  return sessionStorage.getItem("EMP_ID") !== null && typeof sessionStorage.getItem("EMP_ID") !== "undefined"
    ? sessionStorage.getItem("EMP_ID")
    : "";

}

export let UserGroupId = () => {
  return sessionStorage.getItem("UserGroupId") !== null && typeof sessionStorage.getItem("UserGroupId") !== "undefined"
    ? sessionStorage.getItem("UserGroupId").replace(/"/g, "") : "";
}
//  console.log("TTTTT", typeof(UserGroupId),UserGroupId)

export let DECRYPTED_DATA = () => {
  if (ciphertext() !== "") {
    let bytes = ciphertext();
    return JSON.parse(bytes);
  } else {
    return "";
  }
}

export let EMAIL_ID = () => {
  return sessionStorage.getItem("EMP_MailID") !== null &&
    typeof sessionStorage.getItem("EMP_MailID") !== "undefined"
    ? sessionStorage.getItem("EMP_MailID") : "";
}

export let IMG_TOKEN = () => {
  return sessionStorage.getItem("IMG_TOKEN") !== null && typeof sessionStorage.getItem("IMG_TOKEN") !== "undefined"
   ? sessionStorage.getItem("IMG_TOKEN").replace(/"/g, "")
    : "";

}

export let CREW_DETAILS = '';

export let headers = new Headers();
headers.set("Accept", "*/*");
headers.set("Content-Type", "application/json");

export let headersList = new Headers();
headersList.set("Authorization", "Bearer " + TOKEN());

export let head = new Headers();
head.set("Content-Type", "application/x-www-form-urlencoded");