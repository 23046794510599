import { URL } from "../utils/Constant";
import { headers, TOKEN } from "../utils/Variable";
import _ from "lodash";
//get wheather according to f/c f=imperial,c=metric
export const getWeatherApi = async (lat, lng, unit = "imperial") => {
  headers.set("Authorization", "Bearer " + TOKEN());
  //?latitude=28.6139391&longitude=77.2090212&unit=imperial
  const resp = await fetch(
    URL.GetWeather + `?latitude=${lat}&longitude=${lng}&unit=${unit}`,
    { method: "get", headers: headers }
  );
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      return data;
    }
  }
  return {};
};
