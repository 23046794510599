import { URL } from "../utils/Constant";
import { headers, TOKEN } from "../utils/Variable";
import _ from "lodash";
// import { client_ID } from "../utils/Constant";
import { useMsal } from "@azure/msal-react";

export const getLogin = async (
  setUserDetails,
  setLoading,
  // setSessionExp,
  setuser_details_error
) => {
  setLoading(true);
  // const _keys = sessionStorage.getItem("msal.token.keys." + client_ID);
  // const accessTokenKey = JSON.parse(_keys).accessToken[0];
  // const getaccessToken = sessionStorage.getItem(accessTokenKey);
  // const accessToken = JSON.parse(getaccessToken).secret;

  // sessionStorage.setItem("TOKEN", accessToken);
  let e_data = {};
  const resp1 = await fetch(URL.Profile, {
    method: "GET",
    headers: { Authorization: "Bearer " + TOKEN() },
  });
  if (resp1.ok) {
    const data1 = await resp1.json();
    if (data1.status_code === 200) {
      sessionStorage.setItem("Policy_Status", data1.data.policyStatus);
      sessionStorage.setItem("enduserStatement", data1.data.enduserStatement);
      sessionStorage.setItem("NEW_USER", data1.data.new_user);
      sessionStorage.setItem("EMP_ID", data1.data.employeeId);

      // sessionStorage.setItem("SessionExp", false);
      // setSessionExp(false);
      e_data = {
        emailId: data1.data.email,
        firstName: data1.data.firstName,
        lastName: data1.data.lastName,
        profile_img_url: data1.data.profilePicture,
        policy_link: data1.data.hyperlink,
        enduserStatementURL: data1.data.end_user_statement,
        authorized_groups: data1.data.hasOwnProperty("authorized_groups")
          ? data1.data.authorized_groups
          : [],
        editableTime: data1.data.editableTime,
        startDate: data1.data.filter_date_details.start_date,
        company:data1.data.company
      };
      sessionStorage.setItem("UserGroupId", data1.data.usergroupId);

      if (!_.isEmpty(data1.data.profilePicture)) {
        sessionStorage.setItem(
          "IMG_TOKEN",
          JSON.stringify("?" + data1.data.profilePicture.split("?")[1])
        );
      }
      sessionStorage.setItem("E_DATA", JSON.stringify(e_data));
      setUserDetails(true);
      // setTokenExp(JSON.parse(getaccessToken).expiresOn);
      setLoading(false);
    } else {
      setuser_details_error(data1.message);
    }
  } else{
    const data1 = await resp1.json();
    setuser_details_error(data1.message);
  }
};

// export const updateRefreshCall = async (setTokenExp) => {
//   setTimeout(() => {
//     const _keys = sessionStorage.getItem("msal.token.keys." + client_ID);
//     const accessTokenKey = JSON.parse(_keys).accessToken[0];
//     const getaccessToken = sessionStorage.getItem(accessTokenKey);
//     setTokenExp(JSON.parse(getaccessToken).expiresOn);
//   }, 360000);
// };

export const useLogout = () => {
  const { instance } = useMsal();
  const logout = () => {
    sessionStorage.clear();
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
    caches.keys().then(function (cacheNames) {
      return Promise.all(
        cacheNames.map(function (cacheName) {
          return caches.delete(cacheName);
        })
      );
    });
    instance.logoutRedirect();
    // window.location.pathname = "/login";
  };
  return [logout];
};

// register
export const getRegister = async (
  dataJSON,
  setLoading,
  setMsgcls,
  setMsg,
  setRegisterCheck
) => {
  setLoading(true);
  setMsgcls("");
  setMsg("");
  //const navigate = useNavigate();
  let jsonData = JSON.stringify(dataJSON);
  // console.log(jsonData)

  const resp = await fetch(URL.Register, {
    method: "POST",
    body: jsonData,
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.message === "Signum ID is already registered") {
      setMsgcls([data.message, "danger"]);
      setMsg("");
    } else {
      setMsg(data.message);
      setMsgcls("");
      setTimeout(() => {
        window.location.pathname = "/login";
      }, 15000);
    }
    setLoading(false);
    setRegisterCheck(true);
  }
};

// export const getRegister = async (dataJSON, setLoading, setMsg) => {
//     setLoading(true);
//     setMsg("");
//     //const navigate = useNavigate();
//     let jsonData = JSON.stringify(dataJSON);
//     // console.log(jsonData)

//     const resp = await fetch(URL.Register, { method: 'POST', body: jsonData, headers: headers });
//     if (resp.ok) {
//         const data = await resp.json();
//         setMsg(data.message)
//         setLoading(false);
//     }
// }

export const sendOTPEmail = async (
  param,
  setIsEmailSent,
  setLoading,
  setMsgcls
) => {
  setLoading(true);
  setMsgcls("");

  const resp = await fetch(
    URL.SentOTP + "?emailid=" + param["emailid"] + "&name=" + param["name"],
    { method: "GET", headers: headers }
  );
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      setMsgcls(["OTP code is sent to your email id successfully", "success"]);
      setIsEmailSent(true);
    } else if (data.status_code === 201) {
      if ("Email is already registered" === data.message) {
        setMsgcls([data.message, "danger"]);
      } else {
        setMsgcls([data.message, "danger"]);
        setIsEmailSent(false);
      }
    }
    setLoading(false);
  } else {
    setMsgcls([
      "Sending OTP email is failed. Please check you email",
      "danger",
    ]);
    setIsEmailSent(true);
    setLoading(false);
  }
};

export const checkOTP = async (
  param,
  setIsEmailVerified,
  setLoading,
  setMsgcls
) => {
  setLoading(true);
  setMsgcls("");

  const resp = await fetch(
    URL.VerifyOTP + "?emailid=" + param["emailid"] + "&otp=" + param["otp"],
    { method: "GET", headers: headers }
  );
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      setIsEmailVerified(true);
      setMsgcls([data.message, "success"]);
    } else if (data.status_code === 201) {
      setMsgcls([data.message, "danger"]);
      setIsEmailVerified(false);
    }
    setLoading(false);
  } else {
    setIsEmailVerified(true);
    setLoading(false);
  }
};
export const getAllForms = async (
  setAllFormsData,
  setLoading,
  setMsgcls,
  setStatusData
) => {
  setLoading(true);
  setMsgcls("");
  headers.set("Authorization", "Bearer " + TOKEN());
  const resp = await fetch(URL.GetAllForms + "?source=webapp", {
    method: "GET",
    headers: headers,
  });
  let data = {};
  if (resp.ok) {
    data = await resp.json();
    if (data.status_code === 200) {
      setAllFormsData(data.data);
      setStatusData(data.report_status_data);
    } else if (data.status_code === 201) {
      setMsgcls(data.message);
      setAllFormsData(data.data);
      setStatusData(data.report_status_data);
    }
    setLoading(false);
  } else {
    setLoading(false);
  }
  return data.data;
};

// GET Valid Domain
export const checkValidDomain = async (setLoading, setValidDomain) => {
  setLoading(true);
  const resp = await fetch(URL.ValidDomains + "?employeeId=test", {
    method: "GET",
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();
    setValidDomain(data.data);
    setLoading(false);
  }
};