import { Row, Col, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import moment from 'moment';
import Signature from "../../components/Signature/Signature";
import {
  getDataInFormat,
  removeTags,
  getUrlWithQueryString,
} from "../../utils/Helper";
import { useState, useEffect, useCallback, useMemo } from "react";
import { uploadMultiImage } from "../../api/CreateiJHA.api";
import { DECRYPTED_DATA, EMP_ID, IMG_TOKEN } from "../../utils/Variable";
import plus from "../../assets/Icons/plus.svg";
import { v4 as uuidv4 } from "uuid";
import position from "../../assets/Icons/position.svg";
import ModalCertificateSiteObsrForm from "../../components/Modal/ModalCertificateSiteObsrForm";
import CertificateListSiteObsrForm from "../../components/CertificateList/CertificateListSiteObsrForm";
import _ from "lodash";

export default function SectionComp({
  questions_obj,
  sectionId,
  onChangeFormTemplate,
  removeImgData,
  setLoading,
  sectionName,
  modalMapHandler,
  setAddress,
  getAddress,
  changeFormTemplateData,
  getCertificateOptionListD,
  setCertifyData,
  getCertifyData,
  onChangeCertificate,
  setCertifySection,
}) {
  // '19/08/2022'
  const [getDataMdl1, setDataMdl1] = useState({
    heading: "",
    body: "",
    showClose: false,
    saveBtnName: "",
  });
  const [showMdl1, setShowMdl1] = useState(false);
  const [getSignBase64Uri, setSignBase64Uri] = useState("");
  const [getUploadedImgUrls, setUploadedImgUrls] = useState([]);
  const [getError, setError] = useState("");
  const [getUploadedImgUrls1, setUploadedImgUrls1] = useState([]);
  const [getSetP, setSetP] = useState(false);
  const [getImgUrls, setImgUrls] = useState({});
  const getSignUrl = useCallback(() => {
    const sendDataImg = {
      files: [getSignBase64Uri],
      emailId: DECRYPTED_DATA().emailId,
      reportId: uuidv4(),
      upload_type: "JHA",
      section_names: sectionName,
      employeeId: EMP_ID,
      setLoading: setLoading,
      setSetP: setSetP,
    };
    //[e.target.files[0], 0, 2, 'ai']
    uploadMultiImage(setUploadedImgUrls, sendDataImg, setImgUrls);
  }, [getSignBase64Uri]);
  const getUploadImageUrl = (fileObj) => {
    const sendDataImg = {
      files: [fileObj],
      emailId: DECRYPTED_DATA().emailId,
      reportId: uuidv4(),
      upload_type: "JHA",
      section_names: sectionName,
      employeeId: EMP_ID(),
      setLoading: setLoading,
      setSetP: setSetP,
    };
    //[e.target.files[0], 0, 2, 'ai']
    uploadMultiImage(setUploadedImgUrls1, sendDataImg, setImgUrls);
  };

  useEffect(() => {
    getSignBase64Uri !== "" && getSignUrl();
  }, [getSignUrl]);
  useEffect(() => {
    getUploadedImgUrls.length &&
      onChangeFormTemplate({
        sectionId: sectionId,
        signature: getUploadedImgUrls[0],
        questionId: questions_obj.questionId,
      });
  }, [getUploadedImgUrls]);
  useEffect(() => {
    getUploadedImgUrls1.length &&
      onChangeFormTemplate({
        sectionId: sectionId,
        multiple_picture: getUploadedImgUrls1[0],
        questionId: questions_obj.questionId,
      });
  }, [getUploadedImgUrls1]);
  const getDateObject = (strDate) => {
    let dateArr = strDate.split("/");
    return new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);
  };
  const MyContainer = ({ className, children }) => {
    return (
      <div>
        <div className={className}>
          <div style={{ position: "relative" }}>{children}</div>
        </div>
      </div>
    );
  };

  const modalAddCertificate = (event) => {
    //  let _getData1 = _.cloneDeep(getData1);
    let existingCertificateModel = [];
    // _getData1.certificateModel.map(obj => {
    //     if (obj.certificateName !== 'Other' && typeof obj.certificateId !== 'undefined')
    //         existingCertificateModel.push(obj.certificateName)
    //     else if (typeof obj.certificateId === 'undefined') {
    //         obj.certificateMultipleName.map(obj => obj !== 'Other' && existingCertificateModel.push(obj))
    //     }
    // });

    const Options = getCertificateOptionListD
      .map((obj, i) => {
        if (!existingCertificateModel.includes(obj.certificateName)) {
          return {
            value: obj.certificateName,
            label: obj.certificateName,
            color: "#00B8D9",
            _key: obj.certificateID,
            // isFixed: obj.is_selected,
            // _is_selected: obj.is_selected,
          };
        }
      })
      .filter((obj) => obj !== undefined);
    let dataMdl = {
      heading: "Add Certificate",
      body: "",
      options: Options,
      showClose: false,
      saveBtnName: "Add",
    };
    setDataMdl1(dataMdl);
    setShowMdl1(true);
    setCertifySection(event);
  };

  const modalUpdateCertificate = (_certificateSeqId) => {
    const selectedCertificate =
      typeof getCertifyData.certificateModel !== "undefined" &&
      getCertifyData.certificateModel.filter((obj, k) => {
        if (_certificateSeqId === obj.certificateSeqId) {
          return obj;
        }
      });
    let existingCertificateModel = [];
    getCertifyData.certificateModel.map((obj) => {
      if (
        obj.certificateName !== "Other" &&
        typeof obj.certificateId !== "undefined" &&
        _certificateSeqId !== obj.certificateSeqId
      )
        existingCertificateModel.push(obj.certificateName);
      else if (
        typeof obj.certificateId === "undefined" &&
        _certificateSeqId !== obj.certificateSeqId
      ) {
        obj.certificateMultipleName.map(
          (obj) => obj !== "Other" && existingCertificateModel.push(obj)
        );
      }
    });

    const Options = getCertificateOptionListD
      .map((obj, i) => {
        if (!existingCertificateModel.includes(obj.certificateName)) {
          return {
            value: obj.certificateName,
            label: obj.certificateName,
            color: "#00B8D9",
            _key: obj.certificateID,
            // isFixed: obj.is_selected,
            // _is_selected: obj.is_selected,
          };
        }
      })
      .filter((obj) => obj !== undefined);
    let dataMdl = {
      heading: "Update Certificate",
      body: "",
      options: Options,
      selectedCertificate: selectedCertificate[0],
      showClose: false,
      saveBtnName: "Update",
    };
    setDataMdl1(dataMdl);
    setShowMdl1(true);
  };

  const return_control = useMemo(() => {
    switch (questions_obj.questionType) {
      case "Image+Certificate":
        return (
          <>
            <div className="flex-shrink-1 bd-highlight certifyDiv">
              <a
                className="certifyLink"
                onClick={() => {
                  modalAddCertificate({
                    sectionId: sectionId,
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                }}
              >
                <img
                  alt=""
                  src={String(plus)}
                  width="16"
                  height="16"
                  className="certifyImg rounded-circle"
                  style={{ border: "1px solid #000", cursor: "pointer" }}
                />
              </a>
            </div>
          </>
        );

      case "Date":
        return (
          <DatePicker
            className="rounded-0 form-control"
            placeholderText=""
            required={questions_obj.state === "Required" ? true : false}
            selected={getDateObject(questions_obj.answer)}
            onChange={(date) =>
              onChangeFormTemplate({
                sectionId: sectionId,
                answer: getDataInFormat(date),
                questionId: questions_obj.questionId,
              })
            }
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            todayButton="Today"
            calendarContainer={MyContainer}
          />
        );

      case "Description":
        return (
          <>
            <Form.Control
              className="rounded-0"
              as="textarea"
              rows={5}
              value={questions_obj.answer}
              required={questions_obj.state === "Required" ? true : false}
              // defaultValue={form_template[1].questions[1].answer}
              // {...register('loc911Add', { required: true })}

              onChange={(e) => {
                e.target.value === "" && questions_obj.state === "Required"
                  ? setError("This field is required")
                  : setError("");

                onChangeFormTemplate({
                  sectionId: sectionId,
                  answer: e.target.value,
                  questionId: questions_obj.questionId,
                });
              }}
            />
            <Form.Label className="mt-2 text-danger">{getError}</Form.Label>
          </>
        );
      case "Text":
        return (
          <>
            <Form.Control
              className="rounded-0"
              type="text"
              value={questions_obj.answer}
              required={questions_obj.state === "Required" ? true : false}
              onChange={(e) => {
                e.target.value === "" && questions_obj.state === "Required"
                  ? setError("This field is required")
                  : setError("");
                onChangeFormTemplate({
                  sectionId: sectionId,
                  answer: e.target.value,
                  questionId: questions_obj.questionId,
                  required: questions_obj.state,
                });
                // checkedRequired({
                //     sectionId: sectionId,
                //     answer: e.target.value,
                //     required: questions_obj.state
                // })
              }}
            />
            <Form.Label className="mt-2 text-danger">{getError}</Form.Label>
          </>
        );
      // image-upload+radio+description
      case "Radio":
        return (
          <div
            key={`inline-radio-${questions_obj.questionId}`}
            className="mb-3"
          >
            {questions_obj.radio_options.values.map((obj_v, i) => {
              // console.log(questions_obj.questionId, ' :', questions_obj.answer === obj_v, questions_obj.answer, obj_v, 'radio')
              return (
                <Form.Check
                  inline
                  label={obj_v}
                  name={`group-${questions_obj.questionId}`}
                  type="radio"
                  className="mb-3"
                  id={`inline-radio-${i}-${questions_obj.questionId}`}
                  defaultChecked={questions_obj.answer === obj_v}
                  required={questions_obj.state === "Required" ? true : false}
                  onClick={(e) => {
                    onChangeFormTemplate({
                      sectionId: sectionId,
                      answer: obj_v,
                      questionId: questions_obj.questionId,
                      required: questions_obj.state,
                    });
                  }}
                />
              );
            })}
            {questions_obj.imageUpload && (
              <Form.Control
                className="rounded-0 custom-file-input mb-3"
                type="file"
                onChange={(e) => {
                  getUploadImageUrl(e.target.files[0]);
                  e.target.value = null;
                }}
              />
            )}
            {questions_obj.multiple_picture.length !== 0 && (
              <div className="mb-3">
                {"Uploaded Image Count:" +
                  questions_obj.multiple_picture.length}
              </div>
            )}
            {questions_obj.multiple_picture.length !== 0 && (
              <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                {questions_obj.multiple_picture.map((obj, i) => (
                  <Col className="item-w m-1  imgDiv">
                    <div
                      className="removeImg"
                      onClick={() =>
                        removeImgData({
                          sectionId: sectionId,
                          questionId: questions_obj.questionId,
                          idx: i,
                        })
                      }
                    >
                      x
                    </div>
                    <img
                      src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                      alt={i}
                      className="shadow-sm bg-light rounded"
                    />
                  </Col>
                ))}
              </Row>
            )}
            {questions_obj.description_model[0].visible && (
              <Form.Control
                className="rounded-0 mb-3"
                as="textarea"
                rows={5}
                value={questions_obj.description_model[0].text}
                // defaultValue={form_template[1].questions[1].answer}
                // {...register('loc911Add', { required: true })}
                onChange={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    desc_text: e.target.value,
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                }}
              />
            )}
          </div>
        );
      case "Signature":
        return (
          <div className="col-3">
            <Signature
              setSignBase64Uri={setSignBase64Uri}
              imgName="signature.png"
              resetSign={(e) => {
                onChangeFormTemplate({
                  sectionId: sectionId,
                  signature: "",
                  questionId: questions_obj.questionId,
                  required: questions_obj.state,
                });
              }}
            />
            {questions_obj.signature !== "" && (
              <div className="p-1 m-1">
                <img
                  src={questions_obj.signature + IMG_TOKEN()}
                  alt={`Signature-${questions_obj.questionId}`}
                  className="shadow-sm bg-light rounded img-size_preview"
                />
              </div>
            )}
          </div>
        );
      case "Dropdown":
        return (
          <>
            <Form.Select
              aria-label={`select-${questions_obj.questionId}`}
              className="rounded-0"
              defaultValue={questions_obj.answer}
              required={questions_obj.state === "Required" ? true : false}
              onChange={(e) => {
                onChangeFormTemplate({
                  sectionId: sectionId,
                  answer: e.target.value,
                  questionId: questions_obj.questionId,
                  required: questions_obj.state,
                });
                e.target.value === "" && questions_obj.state === "Required"
                  ? setError("This field is required")
                  : setError("");
              }}
            >
              <option value="">Select</option>
              {questions_obj.options.map((obj) => (
                <option value={obj}>{obj}</option>
              ))}
            </Form.Select>
            <Form.Label className="mt-2 text-danger">{getError}</Form.Label>
          </>
        );
      case "Description+Location":
        return (
          <div className="position-relative ">
            <div className="position-absolute top-0 end-0 sig-controls">
              <Button
                variant="light"
                size="sm"
                onClick={
                  // heading, getAddress, onChangeFormTemplate, _key, lat, lng
                  () =>
                    modalMapHandler(
                      questions_obj.question,
                      getAddress,
                      changeFormTemplateData,
                      sectionId,
                      questions_obj.questionId,
                      questions_obj.lat,
                      questions_obj.long
                    )
                }
              >
                <img
                  src={String(position)}
                  alt="reset sign"
                  style={{ width: "18px" }}
                />
              </Button>
            </div>
            <Form.Control
              className="rounded-0"
              as="textarea"
              rows={5}
              value={removeTags(questions_obj.answer)}
              required={questions_obj.state === "Required" ? true : false}
              // defaultValue={form_template[1].questions[1].answer}
              // {...register('loc911Add', { required: true })}
              onChange={(e) => {
                onChangeFormTemplate({
                  sectionId: sectionId,
                  answer: e.target.value,
                  questionId: questions_obj.questionId,
                  required: questions_obj.state,
                });
                e.target.value === "" && questions_obj.state === "Required"
                  ? setError("This field is required")
                  : setError("");
              }}
            />
            <Form.Label className="mt-2 text-danger">{getError}</Form.Label>
          </div>
        );
      default:
        return (
          <>
            <Form.Control
              className="rounded-0"
              type="text"
              value={questions_obj.answer}
              // {...register('piNumber', { required: true })}
              required={questions_obj.state === "Required" ? true : false}
              onChange={(e) => {
                onChangeFormTemplate({
                  sectionId: sectionId,
                  answer: e.target.value,
                  questionId: questions_obj.questionId,
                  required: questions_obj.state,
                });
                e.target.value === "" && questions_obj.state === "Required"
                  ? setError("This field is required")
                  : setError("");
              }}
            />
            <Form.Label className="mt-2 text-danger">{getError}</Form.Label>
          </>
        );
        {
          /* <Form.Label className="mt-2 text-danger">{errors.piNumber && errors.piNumber.message}</Form.Label> */
        }
    }
  }, [questions_obj]);

  return (
    <>
      <Form.Group
        controlId={`form-${questions_obj.questionId}`}
        className="mb-3"
      >
        <Form.Label>
          <span className="text-danger bold-font">
            {questions_obj.state === "Required" ? "*" : ""}
          </span>
          {questions_obj.question}
        </Form.Label>
        {return_control}
      </Form.Group>
      {questions_obj.questionType === "Image+Certificate" &&
        typeof getCertifyData.certificateModel !== "undefined" && (
          <Form.Group className="CertificateSec">
            {typeof getCertifyData.certificateModel !== "undefined" &&
              getCertifyData.certificateModel.map((obj, k) => {
                return (
                  <CertificateListSiteObsrForm
                    key={k}
                    certificateDate={obj.certificateDate}
                    certificateName={obj.certificateName}
                    certificatePicture={obj.certificatePicList[0]}
                    certificateMultipleName={obj.certificateMultipleName}
                    singleOrMulti={
                      obj.certificateName === undefined ? "multi" : "single"
                    }
                    certificateSeqId={k}
                    modalUpdateCertificate={modalUpdateCertificate}
                    setCertifyData={setCertifyData}
                    getCertifyData={getCertifyData}
                    onChangeCertificate={onChangeCertificate}
                    // aiInsights={obj.ai_insights}
                    // getUserSection={getUserSection}
                    // getDataMdl1={getDataMdl1}
                  />
                );
              })}
          </Form.Group>
        )}
      {showMdl1 && (
        <ModalCertificateSiteObsrForm
          showMdl={showMdl1}
          setShowMdl={setShowMdl1}
          dataMdl={getDataMdl1}
          setCertifyData={setCertifyData}
          getCertifyData={getCertifyData}
          onChangeCertificate={onChangeCertificate}
        />
      )}
    </>
  );
}
