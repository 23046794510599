import Datepicker from "../Datepicker/Datepicker";
// selectedDate, setDate
import { Stack, Button, Form, Col, Row } from "react-bootstrap";
import MultiSelectDropDown from "../CustomMultiSelectDropDown/MultiSelectDropDown";
import MultiselectDropDownForStatus from "../CustomMultiSelectDropDown/MultiSelectDropDownForStatus";
import { useEffect } from "react";
import _ from "lodash";
import "./CustomDatePickerForm.css";
import { UserGroupId } from "../../utils/Variable";
export default function CustomDatePickerForm({
  getStatusData,
  getAllFormsData,
  datepickerData,
  submitData,
  formName,
}) {
  const status_option = [
    { id: 1, value: "Submitted" },
    { id: 2, value: "Approved" },
    { id: 3, value: "Rejected" },
    { id: 4, value: "AI Approved" },
    { id: 5, value: "In Progress" },
    { id: "*", value: "All" },
  ];
  function userStats() {
    return (
      <Stack direction="horizontal" className="mb-5">
        <div className="me-3">
          Start date:
          <Datepicker
            selectedDate={datepickerData.startDate}
            setDate={(date) => datepickerData.setStartDate(date)}
          />
        </div>
        <div className="me-3">
          End date:
          <Datepicker
            selectedDate={datepickerData.endDate}
            setDate={(date) => datepickerData.setEndDate(date)}
          />
        </div>
        {/* <div className="me-3"><br /><Button onClick={submitData} variant="light" size="sm">Submit</Button></div> */}
      </Stack>
    );
  }

  useEffect(() => {
    // console.log('asdas', datepickerData)
    if (
      !_.isNull(datepickerData.startDate) &&
      !_.isNull(datepickerData.endDate)
    ) {
      submitData();
    }
  }, []);
  function reportStats() {
    if (getAllFormsData.filter((i) => i.formId).length > 1) {
      return (
        <>
          <Row className="mb-5 reportDate">
            <Col lg={3} md={6} className="custom-css">
              Start date:
              <br />
              <Datepicker
                selectedDate={datepickerData.startDate}
                setDate={(date) => datepickerData.setStartDate(date)}
                setmaxDate={datepickerData.maxDate}
              />
              {_.isNull(datepickerData.startDate) && (
                <span className="text-danger mt-1">Start date is Invalid</span>
              )}
            </Col>
            <Col lg={3} md={6} className="custom-css">
              End date:
              <br />
              <Datepicker
                selectedDate={datepickerData.endDate}
                setDate={(date) => datepickerData.setEndDate(date)}
                setmaxDate={datepickerData.maxDate}
              />
              {_.isNull(datepickerData.endDate) && (
                <span className="text-danger  mt-1">End date is Invalid</span>
              )}
            </Col>
            <Col xs={12} lg={3} md={6}>
              Report Type:
              <MultiSelectDropDown
                getAllFormsData={getAllFormsData}
                setFormId={datepickerData.setFormId}
              />
            </Col>

            {formName === "reports" && !_.isEmpty(getStatusData) && (
              <Col xs={12} lg={3} md={6}>
                Status:
                <MultiselectDropDownForStatus
                  getStatusData={getStatusData}
                  setStatusOption={datepickerData.setStatusOption}
                  getInProgressSet={datepickerData.getInProgressSet}
                  setInProgressSet={datepickerData.setInProgressSet}
                />
              </Col>
            )}
          </Row>

          {/* <div><br /><Button variant="light" size="sm" onClick={submitData}>Submit</Button></div> */}
        </>
      );
    } else {
      return (
        <>
          <Row className="mb-5 reportDate">
            <Col xs={12} md={6} lg={3}>
              Start date:
              <Datepicker
                selectedDate={datepickerData.startDate}
                setDate={(date) => datepickerData.setStartDate(date)}
              />
              {_.isNull(datepickerData.startDate) && (
                <span className="text-danger mt-1">Start date is Invalid</span>
              )}
            </Col>
            <Col xs={12} md={6} lg={3}>
              End date:
              <Datepicker
                selectedDate={datepickerData.endDate}
                setDate={(date) => datepickerData.setEndDate(date)}
              />
              {_.isNull(datepickerData.endDate) && (
                <span className="text-danger  mt-1">End date is Invalid</span>
              )}
            </Col>

            {formName === "reports" && !_.isEmpty(getStatusData) && (
              <Col xs={12} md={6} lg={3}>
                Status:
                <MultiselectDropDownForStatus
                  getStatusData={getStatusData}
                  setStatusOption={datepickerData.setStatusOption}
                  getInProgressSet={datepickerData.getInProgressSet}
                  setInProgressSet={datepickerData.setInProgressSet}
                />
              </Col>
            )}
          </Row>
        </>
      );
    }
  }

  switch (formName) {
    case "user-stats":
      return userStats();
    case "report-stats":
      return reportStats();
    case "reports":
      return reportStats();
    case "ai-insights":
      return userStats();
  }
}
