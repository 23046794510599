import editImg from "../../assets/Icons/edit.svg";
import deleteImg from "../../assets/Icons/trashcan.svg";
import ic_ai from "../../assets/Icons/ic_ai.png";
import { useState, useEffect } from "react";
import ModalDelCertificateSiteObsrForm from "../../components/Modal/ModalDelCertificateSiteObsrForm";
import ModalCertificateSiteObsrPreview from "../Modal/ModalCertificateSiteObsrPreview";
import { Col, Row, Button, ButtonGroup } from "react-bootstrap";
import { EMP_ID, IMG_TOKEN } from "../../utils/Variable";
import { getDataInFormat } from "../../utils/Helper";
import _ from "lodash";
import moment from "moment";
export default function CertificateList({
  certificateDate,
  certificateName,
  certificatePicture,
  certificateMultipleName,
  singleOrMulti,
  certificateSeqId,
  setCertifyData,
  getCertifyData,
  onChangeCertificate,
}) {
  // console.log(certificatePicture, certificateMultipleName, singleOrMulti, '::clist', aiInsights)
  const [showMdl2, setShowMdl2] = useState(false);
  const [getDataMdl2, setDataMdl2] = useState({
    heading: "",
    body: "Do you want to delete?",
    showClose: true,
    saveBtnName: "",
  });
  const [showMdl3, setShowMdl3] = useState(false);
  const [getDataMdl3, setDataMdl3] = useState({
    heading: "",
    body: "",
    showClose: false,
    saveBtnName: "",
  });
  const [getClassWarning, setClassWarning] = useState("");
  const [getInnerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", resize);
  }, []);
  const resize = () => {
    setInnerWidth(window.innerWidth);
  };
  let picUrl = [];
  if (!_.isEmpty(certificatePicture.front)) {
    picUrl[0] = certificatePicture.front;
    picUrl[1] = certificatePicture.back;
  } else {
    picUrl = certificatePicture.map((obj) => obj);
  }
  const modalChangeDelete = (_certificateSeqId) => {
    let dataMdl = {
      heading: "Delete Certificate",
      body: "Do you want to delete this certificate?",
      showClose: true,
      saveBtnName: "Delete",
      certificateSeqId: _certificateSeqId,
    };
    setDataMdl2(dataMdl);
    setShowMdl2(true);
  };
  const modalPreview = (_certificateSeqId) => {
    let dataMdl = {
      showPreviewCarousal: true,
      heading: "Preview Certificate",
      body: "",
      showClose: false,
      saveBtnName: "",
      certificateSeqId: _certificateSeqId,
      picUrl: picUrl,
    };
    setDataMdl3(dataMdl);
    setShowMdl3(true);
  };
  const modalPreviewAI = (_certificateSeqId, aiInsights) => {
    let dataMdl = {
      showPreviewCarousal: false,
      aiInsights: aiInsights,
      heading: "AI Insights",
      body: "",
      showClose: true,
      saveBtnName: "",
      certificateSeqId: _certificateSeqId,
      picUrl: picUrl,
    };
    setDataMdl3(dataMdl);
    setShowMdl3(true);
  };
  useEffect(() => {
    checkExp();
  }, []);
  const checkExp = () => {
    var today = new Date();
    var nextweek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );
    let splitDate = certificateDate.split("/");
    const certDate = new Date(
      new Date(moment(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`))
    );

    var classWarning = "";
    if (certDate.getTime() <= nextweek.getTime()) {
      classWarning = "text-danger";
    } else {
      classWarning = "";
    }
    setClassWarning(classWarning);
  };

  window.mobileCheck = function () {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  return (
    <Row
      xs={12}
      className="d-flex justify-content-between certificate-div align-items-center mb-2"
    >
      <ModalDelCertificateSiteObsrForm
        showMdl={showMdl2}
        setShowMdl={setShowMdl2}
        dataMdl={getDataMdl2}
        setCertifyData={setCertifyData}
        getCertifyData={getCertifyData}
        onChangeCertificate={onChangeCertificate}
      />
      <ModalCertificateSiteObsrPreview
        showMdl={showMdl3}
        setShowMdl={setShowMdl3}
        dataMdl={getDataMdl3}
      />
      <Col xs={2} className="me-2">
        <div onClick={modalPreview} style={{ cursor: "pointer" }}>
          <img alt="" src={picUrl[0] + IMG_TOKEN()} width="64" height="64" />
        </div>
      </Col>
      <Col xs={4}>
        <div>
          {singleOrMulti === "multi"
            ? certificateMultipleName.map((obj) => (
                <div className="mb-1">{obj}</div>
              ))
            : certificateName}
        </div>
      </Col>
      <Col xs={3} className="d-flex justify-content-left align-items-center">
        <div className={getClassWarning}>{certificateDate}</div>
      </Col>
      <Col xs={2}>
        <div className="d-flex justify-content-center align-items-center">
          {/* <img alt=""
                        src={String(editImg)}
                        width="16"
                        height="16"
                        style={{ cursor: "pointer" }}
                        onClick={() => modalUpdateCertificate(certificateSeqId)} />
                    <img className="ms-4" alt=""
                        src={String(deleteImg)}
                        width="16"
                        height="16"
                        style={{ cursor: "pointer" }}
                        onClick={() => modalChangeDelete(certificateSeqId)} /> */}

          <ButtonGroup
            className="mb-2 "
            size="lg"
            vertical={getInnerWidth <= 450 ? true : false}
          >
            {/* <Button variant='secondary' className={`rounded me-3 ${getInnerWidth <= 450 ? "mb-3" : "me-3"}`}
                            onClick={() => modalPreviewAI(certificateSeqId, aiInsights)}>
                            <img alt=""
                                src={String(ic_ai)}
                                className="icon-white-color"
                                width="20"
                                height="20"
                            /></Button> */}
            {/* <Button className={`rounded me-3 ${getInnerWidth <= 450 ? "mb-3" : "me-3"}`}
                            onClick={() => modalUpdateCertificate(certificateSeqId)}>
                            <img alt=""
                                src={String(editImg)}
                                className="icon-white-color"
                                width="16"
                                height="16"
                            /></Button>*/}
            <Button
              className={`rounded me-3 ${
                getInnerWidth <= 450 ? "mb-3" : "me-3"
              }`}
              variant="danger"
              onClick={() => modalChangeDelete(certificateSeqId)}
            >
              <img
                className="icon-white-color"
                alt=""
                src={String(deleteImg)}
                width="16"
                height="16"
              />
            </Button>
          </ButtonGroup>
        </div>
        {/* <div style={{ marginLeft: "10px" }}>
                </div> */}
      </Col>
      {/* <Col style={{ display: "inherit" }}>
            </Col> */}
    </Row>
  );
}
