import React, { useState, useEffect, useRef } from "react";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { getLogin, useLogout } from "../api/Login.api";
import { useMsal } from "@azure/msal-react";
// import Routing from "../Routing";
import Loading from "../components/Loading/Loading";
import { loginRequest } from "../authConfig";
import moment from "moment";
import _ from "lodash";
import SidePanel from "../components/SidePanel/SidePanel";
import { getLastLoginDetails } from "../api/LastLoginDetails.api";
import LastLoginDetailsModal from "../components/LastLoginDetailsModal/LastLoginDetailsModal";
// import ForgetPasswordClick from "./Main/ForgetPasswordClick"
import UserDetailError from "./Main/UserDetailError";
import SessionExpired from "./Main/SessionExpired";

function Layout() {
  const [refreshToggle, setRefreshToogle] = useState(true);
  // const [getForgetPassClick, setForgetPassClick] = useState(false);
  // const hasApiBeenCalledRef = useRef(false);
  // const [getTokenCall, setTokenCall] = useState(false);
  const [showsidepanel, setshowsidepanel] = useState(false);
  const { instance, accounts } = useMsal();
  const [getTokenExp, setTokenExp] = useState();
  const [getLoading, setLoading] = useState(false);
  const [user_details_error, setuser_details_error] = useState("");
  // const [getSessionExp, setSessionExp] = useState(sessionStorage.getItem("SessionExp"));
  const [getToken, setToken] = useState(sessionStorage.getItem("TOKEN"));
  const [ShowLastLoginDialogBox, setShowLastLoginDialogBox] = useState(true);
  const [lastLoginData, setLastLoginData] = useState([]);
  const [isLastLoginDetailsCalled, setIsLastLoginDetailsCalled] =
    useState(false);
  const [getUserDetails, setUserDetails] = useState(false);
  const [logout] = useLogout();

  // let activeAccount;
  // if (instance) {
  //   activeAccount = instance.getActiveAccount();
  // }

  useEffect(() => {
    if (refreshToggle) {
      (async () => {
        const accessTokenRequest = {
          ...loginRequest,
          account: accounts[0],
        };
        await instance
          .acquireTokenSilent(accessTokenRequest)
          .then(({ accessToken, expiresOn }) => {
            if (accessToken && expiresOn) {
              const refreshTime = moment
                .duration(moment(expiresOn).diff(moment().local()))
                .asMilliseconds();
              setTokenExp(expiresOn);
              sessionStorage.setItem("TOKEN", accessToken);
              setToken(accessToken);
              // setTokenCall(true);
              setTimeout(() => {
                setRefreshToogle(true);
              }, refreshTime);
            }
          })
          .catch((error) => {
            console.log("error", error);
            // setForgetPassClick(true);
          });
      })();
      setRefreshToogle(false);
    }
  }, [instance, accounts, refreshToggle]);


  useEffect(() => {
    if (getToken) {
      getLogin(
        setUserDetails,
        setLoading,
        // setSessionExp,
        setuser_details_error
      );
    }
  }, [getToken]);

  // useEffect(() => {
  //   if (!_.isEmpty(getTokenExp)) {
  //     const currDate = new Date();
  //     const tokenExpDate = new Date(getTokenExp * 1000);
  //     const diff = tokenExpDate - currDate;
  //     setInterval(async () => {
  //       instance.ssoSilent(silentRequest).catch((error) => console.log(error));
  //       await updateRefreshCall(setTokenExp);
  //     }, diff - 120000);
  //   }
  // }, [getTokenExp]);

  // useEffect(() => {
  //   let idleTime = new Date(new Date().getTime() + 30 * 60000);
  //   const resetIdleTime = () => {
  //     idleTime = new Date(new Date().getTime() + 30 * 60000);
  //   };
  //   document.addEventListener("mousemove", resetIdleTime, false);
  //   document.addEventListener("keypress", resetIdleTime, false);

  //   const checkIfIdle = () => {
  //     let curr_idleTime = new Date();
  //     if (curr_idleTime >= idleTime) {
  //       console.log("Session Expired");
  //       sessionStorage.setItem("SessionExp", true);
  //       setSessionExp(true);
  //       clearInterval(idleInterval);
  //     }
  //   };
  //   let idleInterval = setInterval(checkIfIdle, 1000);
  // }, []);

  /*This useEffect is created to call last logged in details graph api*/
  useEffect(() => {
    if (
      !_.isEmpty(getTokenExp) &&
      JSON.parse(sessionStorage.getItem("isLastLoginDetailsCalled")) ===
      false &&
      JSON.parse(sessionStorage.getItem("Policy_Status")) === true &&
      isLastLoginDetailsCalled === false
    ) {
      getLastLoginDetails(
        setLastLoginData,
        setShowLastLoginDialogBox,
        setIsLastLoginDetailsCalled
      );
    }
  }, [isLastLoginDetailsCalled, getTokenExp]);
  // console.log('getTokenCall', getToken)
  return (
    <>
      {!_.isEmpty(user_details_error) && (
        <UserDetailError user_details_error={user_details_error} setuser_details_error={setuser_details_error} />
      )}
      {getUserDetails ?
        <>
          {/* {JSON.parse(getSessionExp) && (
            <SessionExpired setSessionExp={setSessionExp} />
          )} */}
          {lastLoginData.message === "Success" &&
            JSON.parse(sessionStorage.getItem("isLastLoginDetailsCalled")) ===
            false &&
            JSON.parse(sessionStorage.getItem("Policy_Status")) === true && (
              <LastLoginDetailsModal
                lastLoginData={lastLoginData.data.lastSignInDateTime}
                show={ShowLastLoginDialogBox}
                onHide={() => {
                  setShowLastLoginDialogBox(false);
                  sessionStorage.setItem("isLastLoginDetailsCalled", true);
                }}
              ></LastLoginDetailsModal>
            )}

          <div className={showsidepanel ? "overlay" : ""}>
            <Header
              logout={logout}
              setshowsidepanel={setshowsidepanel}
              showsidepanel={showsidepanel}
            />
            <SidePanel
              showsidepanel={showsidepanel}
              setshowsidepanel={setshowsidepanel}
            />
            {/* <Routing /> */}
            <Footer />
            {getLoading && <Loading width="32" height="32" />}
          </div>
        </>
        :
        <Loading width="32" height="32" />
      }
      {/* {getForgetPassClick &&
        <ForgetPasswordClick />
      } */}
    </>
  );
}

export default Layout;