import { URL } from "../utils/Constant";

import { TOKEN } from "../utils/Variable";

export const GetUserGroup = async (param, setLoading, setErrorMsg) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  setLoading(true);
  await fetch(URL.SwitchUserGroup, {
    method: "POST",
    body: JSON.stringify(param),
    headers: headersList,
  })
    .then(async (data) => {
      return await data.json();
    })
    .then((data) => {
      setErrorMsg("Internal Server Error");
      setLoading(false);
      if (data.status_code === 200) {
        setErrorMsg("");
        // sessionStorage.clear();
        // window.location.pathname = "/login";
      }
    })
    .catch((error) => {
      setErrorMsg("Internal Server Error");
      setLoading(false);
    });
};
