import { useCallback } from "react";
import { useState, useMemo, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import SectionComp from "./SectionComp";
import FormProgressBarSiteOber from "../../components/FormProgressBar/FormProgressBarSiteOber";
import _ from "lodash";
import "./SelectedSectionForm.css";
import { ijhaFormSubmit } from "../../api/CreateiJHA.api";
import { getWeatherApi } from "../../api/PPESubmission.api";
import Loading from "../../components/Loading/Loading";
import { EMP_ID } from "../../utils/Variable";
import { v4 as uuidv4 } from "uuid";
import ic_complete_ppe from "../../assets/Icons/ic_complete_ppe.png";
import { getCurrentDataTime, getTotalTime } from "../../utils/Helper";
import SummarySOB from "./SummarySOB";
import ModalMapCustom from "../../components/Modal/ModalMapCustom";
export default function SelectedSectionForm({
  getReportfilled,
  selectedSectionData,
  form_template_p,
  reportRequired,
  getLoc,
  getCertificateOptionListD,
}) {
  const [form_template, set_form_template] = useState(form_template_p);
  const [getSORForm, setSORForm] = useState({});
  const [getProceed, setProceed] = useState(0);
  const [getRequired, setRequired] = useState(false);
  const [getLoading, setLoading] = useState(false);
  const [getLoading1, setLoading1] = useState(false);
  const [getStepSection, setStepSection] = useState(0);
  const [getSubmitMsg, setSubmitMsg] = useState("");
  const [getReportRequired, setReportRequired] = useState(reportRequired);
  const [showScroll, setShowScroll] = useState(false);
  const [getCrewMemberProceed, setCrewMemberProceed] = useState({
    crew: 0,
    steps: 0,
  });
  const [getProceed1, setProceed1] = useState(0);
  const [getErrMsg, setErrMsg] = useState("");
  const [getAddress, setAddress] = useState("");
  const [getBackRequired, setBackRequired] = useState(false);
  const [showMdl, setShowMdl] = useState(false);
  const [getIsCompleted, setIsCompleted] = useState([]);
  const [getDataMdl, setDataMdl] = useState({
    heading: "",
    body: "",
    showClose: false,
    saveBtnName: "",
  });
  const [directions, setDirections] = useState({});
  const [getCertifyData, setCertifyData] = useState({});
  const [getCertifySection, setCertifySection] = useState({});

  /**modal start */
  const changeFormTemplateData = (arr) => {
    onChangeFormTemplate({
      sectionId: arr[0],
      answer: arr[1],
      questionId: arr[2],
      required: arr[3],
    });
  };

  const modalMapHandler = (
    heading,
    getAddress,
    changeFormTemplateData,
    sectionId,
    _key,
    lat,
    lng
  ) => {
    let dataMdl = {
      heading: heading,
      sectionId: sectionId,
      setAddress: setAddress,
      getLatitude: Number(lat),
      getLongitude: Number(lng),
      direction: false,
      body: "",
      changeFormTemplateData: changeFormTemplateData,
      _key: _key,
      showClose: false,
      saveBtnName: "Add",
    };

    setDataMdl(dataMdl);
    setShowMdl(true);
  };
  // modal end
  //get selected section into JSON
  const getWeatherData = async () => {
    let { lat, lng } = getLoc;
    //  console.log(lat, lng)
    const weatherData = await getWeatherApi(lat, lng);
    /// console.log(weatherData, '--->_weatherDetails');
    let _weatherDetails = [];
    if (weatherData.status_code === 200) {
      _weatherDetails = weatherData.data;
      const unitFC = "°F";
      _weatherDetails["results"][0]["cloudCover"] =
        _weatherDetails["results"][0]["cloudCover"] + "%";
      _weatherDetails["results"][0]["dewPoint"]["value"] =
        _weatherDetails["results"][0]["dewPoint"]["value"] + " " + unitFC;
      _weatherDetails["results"][0]["pressure"]["value"] =
        _weatherDetails["results"][0]["pressure"]["value"] +
        " " +
        _weatherDetails["results"][0]["pressure"]["unit"];
      _weatherDetails["results"][0]["realFeelTemperature"]["value"] =
        _weatherDetails["results"][0]["realFeelTemperature"]["value"] +
        " " +
        unitFC;
      _weatherDetails["results"][0]["relativeHumidity"] =
        _weatherDetails["results"][0]["relativeHumidity"] + "%";
      _weatherDetails["results"][0]["temperature"]["value"] =
        _weatherDetails["results"][0]["temperature"]["value"] + " " + unitFC;
      _weatherDetails["results"][0]["visibility"]["value"] =
        _weatherDetails["results"][0]["visibility"]["value"] +
        " " +
        _weatherDetails["results"][0]["visibility"]["unit"];
      _weatherDetails["results"][0]["wind"]["speed"]["value"] =
        _weatherDetails["results"][0]["wind"]["speed"]["value"] +
        " " +
        _weatherDetails["results"][0]["wind"]["speed"]["unit"];
      _weatherDetails["results"][0]["windGust"]["speed"]["value"] =
        _weatherDetails["results"][0]["windGust"]["speed"]["value"] +
        " " +
        _weatherDetails["results"][0]["windGust"]["speed"]["unit"];
      return _weatherDetails;
    }
    return [];
  };

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  window.addEventListener("scroll", checkScrollTop);

  useEffect(() => {
    let _SORForm = _.cloneDeep(getSORForm);
    _SORForm["start_time_datetime"] = getCurrentDataTime();

    _SORForm["state"] = "";
    _SORForm["state_id"] = "";
    _SORForm["complete_status"] = "1";
    _SORForm["usergroupId"] = null;
    _SORForm["formId"] = "11";
    _SORForm["app_type"] = "Tillman";
    _SORForm["latitude"] = getLoc.lat;
    _SORForm["longitude"] = getLoc.lng;
    _SORForm["device_details"] = {
      app_version: null,
      device_token: null,
      build_number: null,
      os_version: null,
    };
    _SORForm["form_name"] = "SOR Form";
    setSORForm(_SORForm);
    scrollUp();
  }, [getProceed, getProceed1]);

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const _selectedSections = useMemo(() => {
    const _selectedSectionId = selectedSectionData
      .filter((obj) => obj.checked_val && obj.sectionId)
      .map((obj) => obj.sectionId);
    // const _selectedSection = form_template.filter(obj => {
    //     return _selectedSectionId.includes(obj.sectionId)

    // });
    return _selectedSectionId;
  }, [selectedSectionData]);
  const proceedNxtSec = () => {
    _selectedSections.length > getProceed && setProceed(getProceed + 1);
    setRequired(false);
    if (!getReportRequired) {
      setStepSection(parseInt(getStepSection) + 1);
    }
    setBackRequired(true);
  };
  const onBackSec = () => {
    getProceed > 0 && setProceed(getProceed - 1);
    getProceed !== 0
      ? setStepSection(parseInt(getStepSection) - 1)
      : setStepSection(parseInt(getStepSection));
    getProceed !== 0 ? setRequired(true) : setRequired(false);
    getProceed !== 1 ? setBackRequired(true) : setBackRequired(false);
  };
  // console.log(_selectedSections, "_selectedSections");
  // console.log(form_template, "form_template");
  const _selectedSection_render = form_template.filter((obj, i) => {
    return (
      _selectedSections.includes(obj.sectionId) &&
      obj.sectionId === _selectedSections[getProceed]
    );
  });
  const onChangeFormTemplate = (changeValArr) => {
    let _form_template = _.cloneDeep(form_template);
    _form_template.map((obj) => {
      if (obj.sectionId === changeValArr.sectionId) {
        setStepSection(changeValArr.sectionId);
        return obj.questions.map((s_obj) => {
          if (s_obj.questionId === changeValArr.questionId) {
            if (changeValArr.answer !== undefined)
              s_obj.answer = changeValArr.answer;
            else if (changeValArr.signature !== undefined)
              s_obj.signature = changeValArr.signature;
            else if (changeValArr.desc_text !== undefined)
              s_obj.description_model[0].text = changeValArr.desc_text;
            else if (changeValArr.multiple_picture !== undefined)
              s_obj.multiple_picture.push(changeValArr.multiple_picture);
          }
          return s_obj;
        });
      }
      return obj;
    });
    set_form_template(_form_template);
  };

  const removeImgData = (e) => {
    let _form_template = _.cloneDeep(form_template);
    _form_template.map((obj) => {
      if (obj.sectionId === e.sectionId) {
        return obj.questions.map((s_obj) => {
          if (s_obj.questionId === e.questionId) {
            if (s_obj.multiple_picture !== undefined)
              s_obj.multiple_picture = s_obj.multiple_picture.filter(
                (k, i) => i !== e.idx && k
              );
          }
          return s_obj;
        });
      }
      return obj;
    });
    set_form_template(_form_template);
  };

  const onSORFormSubmit = async () => {
    setLoading1(true);
    let _SORForm = _.cloneDeep(getSORForm);
    _SORForm["form_template"] = form_template;
    _SORForm["reportId"] = uuidv4();
    _SORForm["teamLeadId"] = EMP_ID().replaceAll('"', "");
    _SORForm["crew_members"] = [EMP_ID().replaceAll('"', "")];
    _SORForm["submitted_datetime"] = getCurrentDataTime();
    _SORForm["weather"] = await getWeatherData();
    _SORForm["total_time_taken"] = getTotalTime(
      _SORForm["start_time_datetime"],
      _SORForm["submitted_datetime"]
    );
    _SORForm["resubmit"] = "0";

    // testing code(dummy submission)
    setTimeout(() => {
      setLoading(false);
      setSubmitMsg("Form submitted for approval.");
    }, 5000);

    // submit api is working

    ijhaFormSubmit(_SORForm, setLoading, setSubmitMsg);
  };

  // console.log(getCertifyData)

  const onChangeCertificate = (event) => {
    let _form_template = _.cloneDeep(form_template);
    _form_template.map((obj, k) => {
      if (obj.sectionId === getCertifySection.sectionId) {
        setStepSection(k);
        return obj.questions.map((s_obj) => {
          if (s_obj.questionId === getCertifySection.questionId) {
            if (!_.isEmpty(event.certificateModel)) {
              let multiImgNew = [];
              const multiImgOld = event.certificateModel.map((i) =>
                i.certificatePicList.map((j, idx) => j)
              );
              for (let i = 0; i < multiImgOld.length; i++) {
                multiImgOld[i][0].back = multiImgOld[i][0].back.split("?")[0];
                multiImgOld[i][0].front = multiImgOld[i][0].front.split("?")[0];
                multiImgNew.push(multiImgOld[i][0]);
              }
              s_obj.answer = event.certificateModel.map((i) =>
                i.certificateName !== undefined
                  ? [i.certificateName]
                  : i.certificateMultipleName
              );
              s_obj.multiple_picture = multiImgNew;
              s_obj.notes = event.certificateModel.map(
                (r) => r.certificateDate
              );
            } else {
              s_obj.answer = "";
              s_obj.multiple_picture = [];
              s_obj.notes = "";
            }
          }
          return s_obj;
        });
      }
      return obj;
    });
    set_form_template(_form_template);
  };

  // useEffect(() => {
  //    // debugger;
  //     let _form_template = _.cloneDeep(form_template);
  //     let rawData = [];
  //     _form_template.map(obj => {
  //         if (obj.sectionName === "Certificate Details") {
  //             return obj.questions.map((s_obj, k) => {
  //                 if (s_obj.answer !== '') {
  //                 //    debugger;
  //                     if(s_obj.answer[k].length > 1){
  //                       //  debugger;
  //                          rawData.push( {
  //                             "certificateDate": s_obj.notes.toString().replace("[", "").replace("]", ""),
  //                             "certificateMultipleId": '',
  //                             "certificateMultipleName": s_obj.answer,
  //                             "certificatePicList": s_obj.multiple_picture
  //                         })
  //                     }else{
  //                         rawData.push({
  //                             "certificateDate": s_obj.notes.toString().replace("[", "").replace("]", ""),
  //                             "certificateId": '',
  //                             "certificateName": s_obj.answer.toString().replace("[", "").replace("]", ""),
  //                             "certificatePicList": s_obj.multiple_picture
  //                         })
  //                     }
  //                 }
  //             })
  //         }
  //     })

  //     let finalData = {
  //         "employeeId": EMP_ID.replace(/['"]+/g, ''),
  //         "certificateModel": rawData
  //     }
  //  debugger;

  // }, [])

  // console.log(_selectedSection_render, '_selectedSection_render')
  // console.log("form_template - all ", form_template)
  // console.log("***********************CHeck********", getSORForm.reportId)

  return (
    <Row>
      <Row>
        <Col>
          <FormProgressBarSiteOber
            getReportfilled={getReportfilled}
            setProceed={setProceed}
            getProgressCount={getProceed}
            stepSection={getProceed}
            setRequired={setRequired}
            _selectedSectionJson={form_template.filter((obj, i) =>
              _selectedSections.includes(obj.sectionId)
            )}
            getsummary={_selectedSections.length === getProceed}
          />
        </Col>
        {showMdl && (
          <ModalMapCustom
            showMdl={showMdl}
            setShowMdl={setShowMdl}
            dataMdl={getDataMdl}
            getAddress={getAddress}
            setAddress={setAddress}
            setDirections={setDirections}
            directions={directions}
            form_template={form_template}
            setForm_template={set_form_template}
          />
        )}
      </Row>
      <Row className="shadow-lg p-3 mb-5 mt-3 rounded ">
        {_selectedSection_render.map((obj, i) => {
          return (
            <Row key={i}>
              <Row className="mb-3">
                <Col lg="12">
                  <div className="form-subheading-1">{obj.sectionName}</div>
                </Col>
              </Row>
              <Row>
                {obj.questions.map((s_obj, k) => {
                  return (
                    <Row xs={12} key={k}>
                      <Col className="sect">
                        <SectionComp
                          questions_obj={s_obj}
                          sectionId={obj.sectionId}
                          onChangeFormTemplate={onChangeFormTemplate}
                          removeImgData={removeImgData}
                          setLoading={setLoading}
                          sectionName={obj.sectionName}
                          modalMapHandler={modalMapHandler}
                          setAddress={setAddress}
                          getAddress={getAddress}
                          changeFormTemplateData={changeFormTemplateData}
                          getCertificateOptionListD={getCertificateOptionListD}
                          setCertifyData={setCertifyData}
                          getCertifyData={getCertifyData}
                          setCertifySection={setCertifySection}
                          onChangeCertificate={onChangeCertificate}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </Row>
            </Row>
          );
        })}
        <Row>
          {_.isEmpty(getSORForm.reportId) &&
            getIsCompleted.every((obj) => obj === true) &&
            !getLoading1 &&
            _selectedSections.length === getProceed &&
            !getSubmitMsg && (
              <Row lg="12">
                <Col>
                  <div className="form-subheading-1">Summary</div>
                  <br />
                  <SummarySOB
                    form_template={form_template.filter((obj, i) =>
                      _selectedSections.includes(obj.sectionId)
                    )}
                    setCrewMemberProceed={setCrewMemberProceed}
                    getCrewMemberProceed={getCrewMemberProceed}
                    setProceed1={setProceed1}
                    setProceed={setProceed}
                  />
                </Col>
              </Row>
            )}
          {getSubmitMsg === "" && getLoading1 && (
            <div>
              <Loading width="32" height="32" />
              <h5 className="mt-3 d-flex justify-content-center text-center">
                Please wait while we process your Site Observation form
                submission.
              </h5>
              <h5 className="mt-1 p-2 d-flex justify-content-center text-center">
                Do not refresh the page or navigate away while this is
                happening.
              </h5>
            </div>
          )}

          {getSubmitMsg !== "" && (
            <div>
              <Row xs={12}>
                <Col className="d-flex flex-row bd-highlight align-items-center mb-3 position-relative">
                  <img
                    src={String(ic_complete_ppe)}
                    className="p-2 m-2"
                    alt="check-success"
                    style={{ width: "74px" }}
                  />
                  <h5 className="p-2 bd-highlight">
                    {getSubmitMsg !== "" && getSubmitMsg}
                    {" Your Site Observation form will be available in "}

                    {getSubmitMsg !== "" && (
                      <a href="/reports" style={{ fontSize: "1.25rem" }}>
                        Reports tab
                      </a>
                    )}
                  </h5>
                </Col>
              </Row>
              <Row xs={12}>
                <Col className="d-flex justify-content-center mt-3 mb-3">
                  Click to go&nbsp;<a href="/">Home Page</a>
                </Col>
              </Row>
            </div>
          )}
          <Col lg="6">
            {getSubmitMsg === "" && (
              <>
                <Button
                  onClick={onBackSec}
                  disabled={getProceed === 0 ? true : false}
                >
                  Back
                </Button>
                {_selectedSections.length > getProceed ? (
                  <Button
                    className="ms-3"
                    variant="success"
                    disabled={!getRequired && !getReportRequired ? true : false}
                    onClick={proceedNxtSec}
                  >
                    Proceed
                  </Button>
                ) : (
                  <div className="d-inline-block ms-3">
                    {_.isEmpty(getSORForm.reportId) && (
                      <Button
                        variant={"success"}
                        onClick={onSORFormSubmit}
                        disabled={
                          getLoading ||
                          !getIsCompleted.every((obj) => obj === true)
                        }
                      >
                        {" "}
                        Submit
                      </Button>
                    )}
                    {!_.isEmpty(getSORForm.reportId) &&
                    !getIsCompleted.every((obj) => obj === true) ? (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Please make sure all sections are reviewed for all
                            members in the iJHA
                          </Tooltip>
                        }
                      >
                        <span className="d-inline-block">
                          <Button
                            variant={"success"}
                            onClick={onSORFormSubmit}
                            disabled={
                              getLoading ||
                              !getIsCompleted.every((obj) => obj === true)
                            }
                          >
                            {" "}
                            Submit
                          </Button>
                        </span>
                      </OverlayTrigger>
                    ) : (
                      ""
                    )}
                    {!_.isEmpty(getSORForm.reportId) &&
                    getIsCompleted.every((obj) => obj === true) ? (
                      <Button
                        variant={"success"}
                        onClick={onSORFormSubmit}
                        disabled={
                          getLoading ||
                          !getIsCompleted.every((obj) => obj === true)
                        }
                      >
                        {" "}
                        Submit
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </>
            )}
          </Col>
          <Col lg="1"></Col>
        </Row>
        {_selectedSections.length > getProceed && (
          <Row>
            <Col> {getLoading && <Loading width="32" height="32" />}</Col>
          </Row>
        )}
      </Row>
    </Row>
  );
}
