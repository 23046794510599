import { URL } from "../utils/Constant";

import { TOKEN } from "../utils/Variable";
import _ from "lodash";

export const getReportsChildElements = async (
  dataJSON,
  setLoading,
  setMsg,
  ParentReportIdvalue,
  ReportId
) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };

  // dataJSON['crewleadId'] = EMP_ID.replace(/['"]+/g, '');
  setMsg("");
  //const navigate = useNavigate();

  const newParam = {
    ...dataJSON,
    parent_report_Id: ParentReportIdvalue,
    report_Id: ReportId,
    childdata: "Y",
  };
  let jsonData = JSON.stringify(newParam);
  // let jsonData = JSON.stringify(dataJSON);
  // console.log("WQUEWQEYWQYEYQWYeq", dataJSON)
  // console.log(jsonData)

  const resp = await fetch(URL.GetCrewReports, {
    method: "POST",
    body: jsonData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    return data.data;
  }
};
