import React from "react";
import { Modal } from "react-bootstrap";

function LastLoginDetailsModal({ lastLoginData, show, onHide }) {
  return (
    <Modal
      size="sm"
      backdrop="static"
      show={show}
      onHide={onHide}
      centered
      dialogClassName="custom-modal"
    >
      <Modal.Header closeButton>
        <div className="d-flex justify-content-center w-100">
          <Modal.Title>Last Login Details</Modal.Title>
        </div>
      </Modal.Header>

      <Modal.Body>
        <p>
          The last time you logged in was on {lastLoginData.split("T")[0]} at{" "}
          {lastLoginData.split("T")[1]}
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default LastLoginDetailsModal;
