import { Routes, Route, Navigate } from "react-router-dom";
import { TOKEN, NEW_USER, DECRYPTED_DATA } from "./utils/Variable";
import Login from "./layouts/Main/Login";
import ChangePassword from "./layouts/Main/ChangePassword";
import CreateiJHA from "./views/CreateiJHA/CreateiJHA";
import Profile from "./views/Profile/Profile";
import PPE_Submission_Form from "./views/CreateiJHA/PPE_Submission_Form";
import IjhaForm from "./views/Ijha_Dynamic/IjhaForm";
import SiteInspectionForm from "./views/Site_Inspection_form/SiteInspectionForm";
import Home from "./views/Home/Home";
import Register from "./layouts/Main/Register";
import Reports from "./views/Reports/Reports";
import ReportsDownload from "./views/Reports/ReportsDownload";
import SiteObservationForm from "./views/SiteObservationForm/SiteObservationForm";
import SupportForm from "./views/Reports/SupportForm/Support_Form";
import ResetPassword from "./layouts/Main/ResetPassword";
// import VideoCallingFeature from "./components/VideoCalling/VideoCallingFeature";
export default function Routing() {
  let hasAcceptedPolicy = sessionStorage.getItem("Policy_Status");
  let hasAcceptedEndUserStatement = sessionStorage.getItem("enduserStatement");

  // let LOGGEDIN = TOKEN() !== "" && sessionStorage.getItem("NEW_USER") === "0" ? true : false;
  // console.log(LOGGEDIN)
  // console.log(DECRYPTED_DATA)
  // var element = document.getElementById("myDiv");
  // const roomName = 'my-super-secret-meeting-123e4567-e89b-12d3-a456-426655440000'
  // const userFullName = 'Joseph Strawberry'
  // element.classList.remove("watermark-logo");
  // const loginRoutes = (
  //   <>
  //     <Route exact path="/" element={<Navigate to="/login" />} />
  //     <Route exact path="/login" element={<Login />} />
  //     <Route exact path="*" element={<Navigate to="/login" />} />
  //   </>
  // );


  const authenticatedRoutes = (
    <>
      <Route exact path="*" element={<Navigate to="/home" />} />
      <Route exact path="/" element={<Navigate to="/home" />} />
      <Route exact path="/home" element={<Home />} />
      {/* <Route exact path="/register" element={<Register />} /> */}
      <Route exact path="/create-iJHA" element={<CreateiJHA />} />
      <Route exact path="/ppe-submission" element={<PPE_Submission_Form />} />
      <Route exact path="/support-form" element={<SupportForm />} />
      <Route exact path="/profile" element={<Profile />} />
      <Route exact path="/reports" element={<Reports />} />
      <Route exact path="/reports-download" element={<ReportsDownload />} />
      <Route exact path="/site-observation" element={<SiteObservationForm />} />
      <Route exact path="/create-JSA" element={<IjhaForm />} />
      <Route exact path="/create-SiteInspection" element={<SiteInspectionForm />} />
      <Route exact path="/create-LibyaiJHA" element={<IjhaForm />} />
      <Route exact path="/create-default-iJHA" element={<IjhaForm />} />
      <Route exact path="/create-DynamicIJHA" element={<IjhaForm />} />
    </>
  )

  const policyRoutes = (
    <>
      <Route exact path="/" element={<Navigate to="/home" />} />
      <Route exact path="*" element={<Navigate to="/home" />} />
    </>
  );

  return (
    <Routes>
      {/* {TOKEN() === '' && loginRoutes} */}
      {TOKEN() !== '' && (!hasAcceptedPolicy || !hasAcceptedEndUserStatement) && policyRoutes}
      {TOKEN() !== '' && (hasAcceptedPolicy || hasAcceptedEndUserStatement) && authenticatedRoutes}
    </Routes>
  );
}
