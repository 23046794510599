import Select from "react-select";
import _ from "lodash";
export default function MultiSelectforCrockets({
  options,
  getOption,
  setOption,
  setHCMOptions1,
  setSelectedOptionsMulti12,
  setSelectedOptionsMulti1,
  form_template,
  setForm_template,
  getProceed,
}) {
  let _form_template = _.cloneDeep(form_template);

  function setmData(e) {
    setHCMOptions1([]);
    setSelectedOptionsMulti12([[], [], [], [], [], [], [], [], [], []]);
    setSelectedOptionsMulti1([[], [], [], [], [], [], [], [], [], []]);
    setOption([e]);
    const QuestionID = _form_template[getProceed].questions.findIndex(
      (item) =>
        item.questionType === "Table+Dropdown+Multi-select" &&
        item.hasOwnProperty("dependant_question")
    );
    let _getHCMOptions = [e].map((obj) => obj.value);
    if ([e].length !== 0) {
      for (
        let j = 0;
        j <
        _form_template[getProceed].questions[QuestionID].table_options.length;
        j++
      ) {
        if (
          _getHCMOptions.includes(
            _form_template[getProceed].questions[QuestionID].table_options[j]
              .question
          )
        ) {
          _form_template[getProceed].questions[QuestionID].table_options[
            j
          ].common = true;
        } else {
          _form_template[getProceed].questions[QuestionID].table_options[
            j
          ].common = false;
          _form_template[getProceed].questions[QuestionID].table_options[
            j
          ].mitigation_selected_options = [];
          _form_template[getProceed].questions[QuestionID].table_options[
            j
          ].mitigation_pre_selected_options = [];
          _form_template[getProceed].questions[QuestionID].table_options[
            j
          ].selected_options = [];
          _form_template[getProceed].questions[QuestionID].table_options[
            j
          ].pre_selected_options = [];
        }
      }
    } else {
      for (
        let j = 0;
        j <
        _form_template[getProceed].questions[QuestionID].table_options.length;
        j++
      ) {
        _form_template[getProceed].questions[QuestionID].table_options[
          j
        ].common = false;
        _form_template[getProceed].questions[QuestionID].table_options[
          j
        ].mitigation_selected_options = [];
        _form_template[getProceed].questions[QuestionID].table_options[
          j
        ].mitigation_pre_selected_options = [];
        _form_template[getProceed].questions[QuestionID].table_options[
          j
        ].selected_options = [];
        _form_template[getProceed].questions[QuestionID].table_options[
          j
        ].pre_selected_options = [];
      }
    }
    setForm_template(_form_template);
  }

  return (
    <Select
      name="colors"
      value={getOption}
      options={options}
      onChange={(e) => setmData(e)}
      className="basic-multi-select mb-3"
      classNamePrefix="select"
    />
  );
}
