import { Modal, Button } from "react-bootstrap";
import _ from "lodash";
export default function ModalDelCertificateSiteObsrForm({
  showMdl,
  setShowMdl,
  dataMdl,
  setStatusCode,
  setCertifyData,
  getCertifyData,
  onChangeCertificate,
}) {
  const deleteHandler = () => {
    let _getCertifyData = _.cloneDeep(getCertifyData);
    let deletCert = _getCertifyData.certificateModel.filter(
      (obj, k) => k !== dataMdl.certificateSeqId
    );
    let data = {
      certificateModel: deletCert,
    };
    setCertifyData(data);
    setShowMdl(false);
    onChangeCertificate(data);
  };

  return (
    <>
      <Modal show={showMdl} onHide={() => setShowMdl(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{dataMdl.heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{dataMdl.body}</Modal.Body>
        <Modal.Footer>
          {dataMdl.showClose ? (
            <Button variant="secondary" onClick={() => setShowMdl(false)}>
              {" "}
              Close{" "}
            </Button>
          ) : null}
          <Button variant="primary" onClick={deleteHandler}>
            {dataMdl.saveBtnName}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
