import { URL } from "../utils/Constant";
import { TOKEN } from "../utils/Variable";

export const PrivacyCheck = async (setShowDialogBox) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };

  const resp = await fetch(URL.PostPrivacyCheck, {
    method: "POST",
    headers: headersList
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      setShowDialogBox(false);
      sessionStorage.setItem("Policy_Status", true);
      sessionStorage.setItem("enduserStatement", true);
    } else {
      setShowDialogBox(true);
    }
  }
};
