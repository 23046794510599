import Select from "react-select";
export default function MultiSelect({ options, getOption, setOption }) {
  // console.log('getoptions::', getOption);
  return (
    <Select
      // defaultValue={[]}
      isMulti
      name="colors"
      value={getOption}
      options={options}
      onChange={(e) => {
        setOption(e);
      }}
      className="basic-multi-select mb-3"
      classNamePrefix="select"
    />
  );
}
