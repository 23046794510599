import React, { useEffect, useState } from "react";
import { Container, Form, Button, Col, Row, Alert } from "react-bootstrap";
import "./Profile.scss";
import plus from "../../assets/Icons/plus.svg";
import ic_camera from "../../assets/Icons/ic_camera.png";
import avatar_hardhat from "../../assets/Icons/avatar_hardhat.png";
import ModalCustom from "../../components/Modal/ModalCustom";
import ModalImgChange from "../../components/Modal/ModalImgChange";
import ModalCertificate from "../../components/Modal/ModalCertificate";
import profile from "../../assets/Icons/profile.svg";
import {
  getProfile,
  getCertificateOptionList,
  saveProfile,
} from "../../api/Profile.api";
// import Datepicker from "../../components/Datepicker/Datepicker";
import Loading from "../../components/Loading/Loading";
import CertificateList from "../../components/CertificateList/CertificateList";
import { DECRYPTED_DATA } from "../../utils/Variable";
// import MultiSelect4 from "../../components/MultiSelect4/MultiSelect4";
export default function Profile() {
  const [getEmail, setEmail] = useState(DECRYPTED_DATA().emailId);
  const [getData, setData] = useState([]);
  const [getMsg, setMsg] = useState("");
  const [getLoading, setLoading] = useState(false);
  const [getCertificateOptionListD, setCertificateOptionListD] = useState([]);
  const [showMdl, setShowMdl] = useState(false);
  const [getExpiryDate, setExpiryDate] = useState();
  const [getDataMdl, setDataMdl] = useState({
    heading: "",
    body: "",
    showClose: false,
    saveBtnName: "",
  });
  const [showMdl1, setShowMdl1] = useState(false);
  const [getDataMdl1, setDataMdl1] = useState({
    heading: "Change Profile Image",
    body: "",
    showClose: false,
    saveBtnName: "Update",
  });
  // const [getSelectedOptions, setSelectedOptions] = useState([]);
  let dataJSON = {
    countryCode: getData.countryCode,
    phoneNumber: getData.phoneNumber,
    firstName: getData.firstName,
    lastName: getData.lastName,
    company: getData.company,
    assignment: getData.assignment,
    riggerClass: getData.riggerClass,
    EFSMarket: getData.EFSMarket,
    userName: getData.userName,
    emergencyContact: getData.emergencyContact,
    profilePicture: getData.profilePicture,
  };
  const changeData = (_data, key) => {
    let data = { ...getData };
    data[key] = _data;
    setData(data);
    console.log(data, getData);
  };
  useEffect(() => {
    getCertificateOptionList(setCertificateOptionListD);
    getProfile(getEmail, setLoading, setMsg, setData);

    // console.log(getData, getCertificateOptionListD);
  }, []);
  // console.log(getData);
  const modalEdit = () => {
    let dataMdl = {
      heading: "Personel Details",
      body: "",
      showClose: false,
      saveBtnName: "Update",
    };

    dataMdl.body = (
      <div>
        <Form>
          <Form.Group controlId="firstName" className="mb-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control type="text" className="modal-form-1" />
          </Form.Group>
          <Form.Group controlId="lastName" className="mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control type="text" className="modal-form-1" />
          </Form.Group>
          <Form.Group controlId="contact" className="mb-3">
            <Form.Label>Contact</Form.Label>
            <Form.Control type="text" className="modal-form-1" />
          </Form.Group>
          <Form.Group controlId="company" className="mb-3">
            <Form.Label>Ericsson</Form.Label>
            <Form.Control type="text" className="modal-form-1" />
          </Form.Group>
        </Form>
      </div>
    );
    setDataMdl(dataMdl);
    setShowMdl(true);
  };

  const modalAddCertificate = () => {
    let existingCertificateModel = [];
    getData.certificateModel.map((obj) => {
      if (
        obj.certificateName !== "Other" &&
        typeof obj.certificateId !== "undefined"
      )
        existingCertificateModel.push(obj.certificateName);
      else if (typeof obj.certificateId === "undefined") {
        obj.certificateMultipleName.map(
          (obj) => obj !== "Other" && existingCertificateModel.push(obj)
        );
      }
    });

    const Options = getCertificateOptionListD
      .map((obj, i) => {
        if (!existingCertificateModel.includes(obj.certificateName)) {
          return {
            value: obj.certificateName,
            label: obj.certificateName,
            color: "#00B8D9",
            _key: obj.certificateID,
            // isFixed: obj.is_selected,
            // _is_selected: obj.is_selected,
          };
        }
      })
      .filter((obj) => obj !== undefined);
    let dataMdl = {
      heading: "Add Certificate",
      body: "",
      options: Options,
      showClose: false,
      saveBtnName: "Add",
    };
    // dataMdl.body = (
    //     <div>
    //         <Form>

    //             <Form.Group controlId="formFile" className="mb-3">
    //                 <Form.Label>Certificate picture</Form.Label>
    //                 <Form.Control type="file" className="modal-form-1" />
    //             </Form.Group>
    //             <Form.Group className="mb-3">
    //                 <Form.Label >Select Certificate</Form.Label>
    //                 {/* <Form.Select id="select" className="modal-form-1">
    //                     <option>select</option>
    //                     {
    //                         getCertificateOptionListD.map((obj) => {
    //                             return <option value={obj.certificateID} key={obj.certificateID}>{obj.certificateName}</option>
    //                         })
    //                     }
    //                 </Form.Select> */}
    //                 <MultiSelect4
    //                     options={dataMdl.options}
    //                     getOption={dataMdl.getOption}
    //                     setOption={dataMdl.setOption}></MultiSelect4>
    //             </Form.Group>
    //             <Form.Group className="mb-3">
    //                 <Form.Label >Expiry Date</Form.Label>
    //                 <Datepicker selectedDate={dataMdl.getExpiryDate} setDate={dataMdl.setExpiryDate} placeholderText="Expiry Date" />
    //             </Form.Group>
    //         </Form>
    //     </div>
    // )
    setDataMdl(dataMdl);
    setShowMdl(true);
  };
  const modalChangeImage = () => {
    setShowMdl1(true);
  };
  const modalUpdateCertificate = (_certificateSeqId) => {
    const selectedCertificate =
      typeof getData.certificateModel !== "undefined" &&
      getData.certificateModel.filter((obj, k) => {
        if (_certificateSeqId === obj.certificateSeqId) {
          return obj;
        }
      });
    let existingCertificateModel = [];
    getData.certificateModel.map((obj) => {
      if (
        obj.certificateName !== "Other" &&
        typeof obj.certificateId !== "undefined" &&
        _certificateSeqId !== obj.certificateSeqId
      )
        existingCertificateModel.push(obj.certificateName);
      else if (
        typeof obj.certificateId === "undefined" &&
        _certificateSeqId !== obj.certificateSeqId
      ) {
        obj.certificateMultipleName.map(
          (obj) => obj !== "Other" && existingCertificateModel.push(obj)
        );
      }
    });

    const Options = getCertificateOptionListD
      .map((obj, i) => {
        if (!existingCertificateModel.includes(obj.certificateName)) {
          return {
            value: obj.certificateName,
            label: obj.certificateName,
            color: "#00B8D9",
            _key: obj.certificateID,
            // isFixed: obj.is_selected,
            // _is_selected: obj.is_selected,
          };
        }
      })
      .filter((obj) => obj !== undefined);
    let dataMdl = {
      heading: "Update Certificate",
      body: "",
      options: Options,
      selectedCertificate: selectedCertificate[0],
      showClose: false,
      saveBtnName: "Update",
    };
    setDataMdl(dataMdl);
    setShowMdl(true);
  };
  return (
    <Container className="margin-container">
      <h4>Profile</h4>
      {/* <div>{getMsg}</div> */}
      {/* <ModalCustom showMdl={showMdl} setShowMdl={setShowMdl} dataMdl={getDataMdl} /> */}
      <ModalCertificate
        showMdl={showMdl}
        setShowMdl={setShowMdl}
        dataMdl={getDataMdl}
      />
      <ModalImgChange
        showMdl={showMdl1}
        setShowMdl={setShowMdl1}
        dataMdl={getDataMdl1}
      />
      {!getLoading ? (
        <div>
          {getMsg !== "" && (
            <Row className="d-flex justify-content-center">
              <Col xs={12} lg={6} md={6}>
                <Alert variant="danger" onClose={() => setMsg("")} dismissible>
                  {getMsg}
                </Alert>
              </Col>
            </Row>
          )}
          {/* <Row>
                        <Col className="d-flex justify-content-center">
                            <img className="card-custom shadow-lg p-3 mb-5  rounded" style={{ height: "150px", width: "150px" }} src={getData.profilePicture} alt="profile-pic" />
                        </Col>
                    </Row> */}
          <Row>
            <Col className="d-flex justify-content-center">
              <div className="d-flex justify-content-center container1">
                <img
                  src={
                    getData.profilePicture !== "" ||
                    getData.profilePicture !== null
                      ? getData.profilePicture
                      : String(profile)
                  }
                  alt="profile-pic"
                  className=" image1"
                />
                <img
                  src={String(ic_camera)}
                  alt="camera"
                  className="overlay1"
                  onClick={modalChangeImage}
                />
              </div>
            </Col>
          </Row>

          <Form>
            <div className="d-flex justify-content-between  mb-5"></div>

            <Row xs={12}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicFname">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={getData.firstName}
                    onChange={(e) => changeData(e.target.value, "firstName")}
                    placeholder="Enter First Name"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicLname">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={getData.lastName}
                    onChange={(e) => changeData(e.target.value, "lastName")}
                    placeholder="Enter Last Name"
                  />
                  {/* <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text> */}
                </Form.Group>
              </Col>
            </Row>

            <Row xs={12}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    type="email"
                    value={getData.email}
                    onChange={(e) => changeData(e.target.value, "emailId")}
                    disabled={true}
                    placeholder="Enter Email ID"
                  />
                  {/* <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text> */}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicContact">
                  <Form.Label>Contact</Form.Label>
                  <Form.Control
                    type="text"
                    value={getData.phoneNumber}
                    onChange={(e) => changeData(e.target.value, "phoneNumber")}
                    placeholder="Enter Contact"
                  />
                  {/* <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text> */}
                </Form.Group>
              </Col>
            </Row>
            <Row xs={12}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicCompany">
                  <Form.Label>Emergency Contact</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={getData.emergencyContact}
                    placeholder="Enter Emergency Contact"
                    onChange={(e) =>
                      changeData(e.target.value, "emergencyContact")
                    }
                    rows={4}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row xs={12}>
              {Object.keys(getData).length !== 0 ? (
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicCompany">
                    <Form.Label>Company</Form.Label>

                    <Form.Control
                      type="text"
                      value={getData.company}
                      onChange={(e) => changeData(e.target.value, "company")}
                      placeholder="Enter Company"
                      disabled={
                        getData.email.split("@")[1].split(".")[0] === "ericsson"
                          ? true
                          : false
                      }
                    />

                    {/* <Form.Text className="text-muted">

                        We'll never share your email with anyone else.

                    </Form.Text> */}
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row xs={12}>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicCertificates"
                  >
                    <Form.Label>Certificates</Form.Label>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <div className="d-flex bd-highlight justify-content-between p-2 mb-3">
                  <div className="p-2 w-50 bd-highlight font-weight-normal">
                    Add Certificate
                  </div>
                  <div className="p-2 flex-shrink-1 bd-highlight">
                    <a onClick={modalAddCertificate}>
                      <img
                        alt=""
                        src={String(plus)}
                        width="16"
                        height="16"
                        className="rounded-circle"
                        style={{ border: "1px solid #000", cursor: "pointer" }}
                      />
                    </a>
                  </div>
                </div>
              </Row>
              {typeof getData.certificateModel !== "undefined" &&
                getData.certificateModel.map((obj, k) => {
                  return (
                    <CertificateList
                      key={k}
                      certificateDate={obj.certificateDate}
                      certificateName={obj.certificateName}
                      certificatePicture={obj.certificatePicSasUrl}
                      certificateMultipleName={obj.certificateMultipleName}
                      singleOrMulti={
                        obj.certificateName === undefined ? "multi" : "single"
                      }
                      certificateSeqId={obj.certificateSeqId}
                      modalUpdateCertificate={modalUpdateCertificate}
                      aiInsights={obj.ai_insights}
                    />
                  );
                })}
            </Row>
            <Row xs={12}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicCertificateUR">
                  {/* <Form.Label>Certificate under review</Form.Label> */}
                  {/* <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text> */}
                </Form.Group>
              </Col>
            </Row>
            <Button
              variant="primary"
              onClick={() => saveProfile(setLoading, setMsg, dataJSON)}
            >
              Save
            </Button>
          </Form>
        </div>
      ) : (
        <Loading width={"32"} />
      )}
    </Container>
  );
}
