import { URL } from "../utils/Constant";
import { TOKEN } from "../utils/Variable";
export const getLastLoginDetails = async (
  setLastLoginData,
  setShowLastLoginDialogBox,
  setIsLastLoginDetailsCalled
) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  const resp = await fetch(URL.LastLoginDetails, {
    method: "GET",
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      setLastLoginData(data);
      setShowLastLoginDialogBox(true);
      setIsLastLoginDetailsCalled(true);
    }
  } else {
    setShowLastLoginDialogBox(false);
  }
};
