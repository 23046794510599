import MultiSelect4 from "../../components/MultiSelect4/MultiSelect4";
import Datepicker from "../../components/Datepicker/Datepicker";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import {
  uploadFrontBackImgApi,
  addCertificateApi,
  updateCertificateApi,
} from "../../api/SiteObservationCertificate.api";
import { useState, useEffect } from "react";
import _ from "lodash";
import Loading from "../Loading/Loading";
import { useForm } from "react-hook-form";
import { EMP_ID, IMG_TOKEN } from "../../utils/Variable";
import moment from "moment";
import ModalOtherCertificate from "./ModalOtherCetificate";
import { getDataInFormat, dataURLtoFile } from "../../utils/Helper";
export default function ModalCertificate({
  showMdl,
  setShowMdl,
  dataMdl,
  setCertifyData,
  getCertifyData,
  onChangeCertificate,
}) {
  const [getCheckImgValid, setCheckImgValid] = useState(false);
  const [getSelectedOptions, setSelectedOptions] = useState([]);
  const [getExpiryDate, setExpiryDate] = useState("");
  const [getLoading, setLoading] = useState(false);
  const [getPreCertificate, setPreCertificate] = useState({});
  const [getMsg, setMsg] = useState("");
  const [getMsg1, setMsg1] = useState("");
  const [getWhichAction, setWhichAction] = useState("add");
  const [getFrontBackImg, setFrontBackImg] = useState({
    front: "",
    back: "",
  });
  const [getFrontBackImgData, setFrontBackImgData] = useState({
    front: "",
    back: "",
  });
  const [showMdl1, setShowMdl1] = useState(false);
  const [getDataMdl1, setDataMdl1] = useState({
    heading: "Add Other Options",
    body: "",
    showClose: false,
    saveBtnName: "Add",
  });
  const [getActive, setActive] = useState({
    cert_img: true,
    exp_date: true,
    cert_opt: true,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm();

  // console.log('rn, showMdl, dataMdl.selectedCertificate,getSelectedOptions', showMdl, dataMdl.selectedCertificate, getSelectedOptions)
  // console.log(IMG_TOKEN(), 'IMG_TOKEN(),getactive getExpiryDate', getFrontBackImgData, getExpiryDate);
  useEffect(() => {
    if (!showMdl) {
      setFrontBackImg({
        front: "",
        back: "",
      });
      setFrontBackImgData({
        front: "",
        back: "",
      });
      setMsg1("");
    }
  }, [showMdl]);
  useEffect(() => {
    let _getActive = _.cloneDeep(getActive);
    if (!_.isEmpty(dataMdl.selectedCertificate)) {
      let splitDate = dataMdl.selectedCertificate.certificateDate.split("/");
      setExpiryDate(
        new Date(moment(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`))
      );
      _getActive.exp_date = false;
      if (!_.isEmpty(dataMdl.selectedCertificate.certificateMultipleName)) {
        // console.log('rn0', dataMdl.selectedCertificate.certificateMultipleName)
        let _selectedOptions = dataMdl.options.filter((obj) => {
          if (
            dataMdl.selectedCertificate.certificateMultipleName.includes(
              obj.value
            )
          )
            return obj;
        });
        dataMdl.selectedCertificate.certificateMultipleName.map((obj) => {
          if (obj.includes("Other -")) {
            let _val = obj.split("-")[1].trim();
            _selectedOptions[_selectedOptions.length] = {
              value: _val,
              label: _val,
              color: "#00B8D9",
              _key: "100",
            };
          }
        });
        _getActive.cert_opt = false;
        setSelectedOptions(_selectedOptions);
        // console.log('rn, _selectedOptions 0:', _selectedOptions)
      } else if (!_.isEmpty(dataMdl.selectedCertificate.certificateName)) {
        // console.log('rn1')
        let _selectedOptions = dataMdl.options.filter((obj) => {
          // console.log('rn', dataMdl.selectedCertificate.certificateName, obj._key, dataMdl.selectedCertificate.certificateId, dataMdl.options, dataMdl.selectedCertificate.certificateId.includes(obj._key))
          if (dataMdl.selectedCertificate.certificateName === obj.value)
            return obj;
        });
        if (dataMdl.selectedCertificate.certificateName.includes("Other -")) {
          let _val = dataMdl.selectedCertificate.certificateName
            .split("-")[1]
            .trim();
          _selectedOptions[_selectedOptions.length] = {
            value: _val,
            label: _val,
            color: "#00B8D9",
            _key: "100",
          };
        }
        _getActive.cert_opt = false;
        setSelectedOptions(_selectedOptions);
        // console.log('rn, _selectedOptions 1:', _selectedOptions)
      }

      if (!_.isEmpty(dataMdl.selectedCertificate.certificatePicList)) {
        setFrontBackImgData(dataMdl.selectedCertificate.certificatePicList[0]);
        _getActive.cert_img = false;
      } else if (!_.isEmpty(dataMdl.selectedCertificate.certificatePicture)) {
        setFrontBackImgData({
          front: dataMdl.selectedCertificate.certificatePicture[0],
          back: dataMdl.selectedCertificate.certificatePicture[0],
        });
        _getActive.cert_img = false;
      } else {
        _getActive.cert_img = true;
      }
      setActive(_getActive);
      // console.log('fileobj', dataURLtoFile(dataMdl.selectedCertificate.certificatePicList[0].front, '1.png'))
      setWhichAction("update");
    } else {
      setSelectedOptions([]);
      setExpiryDate(null);
      setWhichAction("add");
      setFrontBackImgData({
        front: "",
        back: "",
      });
      setFrontBackImg({
        front: "",
        back: "",
      });
      setActive({ cert_img: true, exp_date: true, cert_opt: true });
    }
  }, [dataMdl]);
  useEffect(() => {
    // console.log(getFrontBackImg, 'getFrontBackImg');
    if (getFrontBackImg.front !== "" || getFrontBackImg.back !== "") {
      uploadFrontBackImgApi(
        setFrontBackImgData,
        getFrontBackImg,
        setLoading,
        setMsg1,
        setCheckImgValid
      );
    } else if (
      getWhichAction === "update" &&
      (getFrontBackImg.front === "" || getFrontBackImg.back === "")
    ) {
      setFrontBackImgData({
        front: "",
        back: "",
      });
    }
  }, [getFrontBackImg]);
  useEffect(() => {
    let _getActive = _.cloneDeep(getActive);
    if (getExpiryDate !== "" && getExpiryDate !== null) {
      _getActive.exp_date = false;
    } else {
      _getActive.exp_date = true;
    }
    setActive(_getActive);
    // console.log(getExpiryDate, 'getactive')
  }, [getExpiryDate]);
  useEffect(() => {
    let _getSelectedOptions = getSelectedOptions.map((obj) => obj.value);
    // console.log('getSelectedOptions::other', _getSelectedOptions, _getSelectedOptions.includes('Others'));
    if (_getSelectedOptions.includes("Other")) {
      setShowMdl1(true);
    }
    let _getActive = _.cloneDeep(getActive);
    if (_getSelectedOptions.length) {
      _getActive.cert_opt = false;
    } else {
      _getActive.cert_opt = true;
    }
    setActive(_getActive);
  }, [getSelectedOptions]);
  useEffect(() => {
    // console.log(getFrontBackImgData, 'getFrontBackImgData')
    let _getActive = _.cloneDeep(getActive);
    if (getFrontBackImgData.front !== "") {
      _getActive.cert_img = false;
    } else {
      _getActive.cert_img = true;
    }
    setActive(_getActive);
  }, [getFrontBackImgData]);

  const onImgChange = (_image, _type) => {
    // e.target.files[0]
    let _getFrontBackImg = _.cloneDeep(getFrontBackImg);
    _getFrontBackImg[_type] = _image;
    setFrontBackImg(_getFrontBackImg);
  };
  const onAddCertificate = () => {
    let jsonData = {};
    if (getSelectedOptions.length > 1) {
      let rawData = {
        certificateModel: [
          {
            certificateDate: getDataInFormat(getExpiryDate),
            certificateMultipleId: getSelectedOptions.map((obj) => obj._key),
            certificateMultipleName: getSelectedOptions.map((obj) => obj.value),
            certificatePicList: [getFrontBackImgData],
          },
        ],
      };
      if (getWhichAction === "update")
        rawData["certificateModel"][0]["certificateSeqId"] =
          dataMdl.selectedCertificate.certificateSeqId;

      jsonData = JSON.stringify(rawData);
      // console.log('>1::', jsonData)
    } else if (getSelectedOptions.length === 1) {
      let rawData = {
        certificateModel: [
          {
            certificateDate: getDataInFormat(getExpiryDate),
            certificateId: getSelectedOptions.map((obj) => obj._key)[0],
            certificateName: getSelectedOptions.map((obj) => obj.value)[0],
            certificatePicList: [getFrontBackImgData],
          },
        ],
      };
      if (getWhichAction === "update")
        rawData["certificateModel"][0]["certificateSeqId"] =
          dataMdl.selectedCertificate.certificateSeqId;
      jsonData = JSON.stringify(rawData);

      // console.log('==1::', jsonData)
    }
    if (getWhichAction === "update" && !_.isEmpty(jsonData)) {
      // updateCertificateApi(jsonData, setLoading, setMsg, setShowMdl);
      let dataJSON = JSON.parse(jsonData);
      setCertifyData(dataJSON);
    } else if (getWhichAction === "add" && !_.isEmpty(jsonData)) {
      let dataJSON = JSON.parse(jsonData);
      if (!_.isEmpty(getCertifyData)) {
        let data = getCertifyData;
        data.certificateModel.push(dataJSON.certificateModel[0]);
        setCertifyData(data);
        onChangeCertificate(data);
        setShowMdl(false);
      } else {
        setCertifyData(dataJSON);
        setShowMdl(false);
        onChangeCertificate(dataJSON);
      }
    }
  };
  // console.log(getActive, 'getactive')
  return (
    <Modal show={showMdl} onHide={() => setShowMdl(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{dataMdl.heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form>
            <ModalOtherCertificate
              showMdl={showMdl1}
              setShowMdl={setShowMdl1}
              dataMdl={getDataMdl1}
              setSelectedOptions={setSelectedOptions}
              getSelectedOptions={getSelectedOptions}
            />
            <Form.Group controlId="formFile" className="mb-3">
              <Row>
                <Form.Label>Certificate picture</Form.Label>
                <Col>
                  <Form.Label>Front</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => {
                      let fileType = e.target.files[0]["type"];
                      let validImageTypes = [
                        "image/gif",
                        "image/jpeg",
                        "image/png",
                      ];
                      if (!validImageTypes.includes(fileType)) {
                        setError("frontImg", {
                          message: "Only jpeg/png/gif image are allowed.",
                        });
                        onImgChange("", "front");
                      } else {
                        setError("frontImg", {
                          message: "",
                        });
                        onImgChange(e.target.files[0], "front");
                      }
                    }}
                    // className="custom-file-input"
                  />
                  {!_.isEmpty(getFrontBackImgData.front) && (
                    <div className="mt-2 d-flex justify-content-center shadow-sm p-3 bg-white rounded">
                      <img
                        className=""
                        src={getFrontBackImgData.front + IMG_TOKEN()}
                        style={{ width: "128px", height: "100px" }}
                        alt="front-image"
                      />
                    </div>
                  )}
                  <Form.Label className="mt-2 text-danger">
                    {errors.frontImg && errors.frontImg.message}
                  </Form.Label>
                  <Form.Label className="mt-2">
                    <b>
                      {getFrontBackImgData.front !== "" &&
                        "Front Image Uploaded"}
                    </b>
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Label>Back</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => {
                      let fileType = e.target.files[0]["type"];
                      let validImageTypes = [
                        "image/gif",
                        "image/jpeg",
                        "image/png",
                      ];
                      if (!validImageTypes.includes(fileType)) {
                        setError("backImg", {
                          message: "Only jpeg/png/gif image are allowed.",
                        });
                        onImgChange("", "back");
                      } else {
                        setError("backImg", {
                          message: "",
                        });
                        onImgChange(e.target.files[0], "back");
                      }
                    }}
                    // className="custom-file-input"
                  />
                  {!_.isEmpty(getFrontBackImgData.back) && (
                    <div className="mt-2 d-flex justify-content-center shadow-sm p-3 bg-white rounded">
                      <img
                        className=""
                        src={getFrontBackImgData.back + IMG_TOKEN()}
                        style={{ width: "128px", height: "100px" }}
                        alt="back-image"
                      />
                    </div>
                  )}
                  <Form.Label className="mt-2 text-danger">
                    {errors.backImg && errors.backImg.message}
                  </Form.Label>
                  <Form.Label className="mt-2">
                    <b>
                      {getFrontBackImgData.back !== "" && "Back Image Uploaded"}
                    </b>
                  </Form.Label>
                </Col>
              </Row>
              <Row className="text-danger">
                <Col>{getMsg1 !== "" && getMsg1}</Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Select Certificate</Form.Label>
              <MultiSelect4
                options={dataMdl.options}
                getOption={getSelectedOptions}
                setOption={setSelectedOptions}
              ></MultiSelect4>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Expiry Date</Form.Label>
              <Datepicker
                selectedDate={getExpiryDate}
                setDate={setExpiryDate}
                placeholderText="Expiry Date"
              />
            </Form.Group>
            {getMsg !== "" && (
              <Form.Group className="mb-3">
                <Form.Label>{getMsg}</Form.Label>
              </Form.Group>
            )}
          </Form>
          {getLoading && (
            <Row className="d-flex justify-content-center">
              <Col>
                <Loading width={"32"} />
              </Col>
            </Row>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!getCheckImgValid ? (
          <Button
            variant="primary"
            onClick={() => {
              onAddCertificate();
            }}
            disabled={
              getLoading ||
              getActive.cert_img ||
              getActive.exp_date ||
              getActive.cert_opt
            }
          >
            {dataMdl.saveBtnName}
          </Button>
        ) : (
          <Button variant="primary" disabled>
            {dataMdl.saveBtnName}
          </Button>
        )}

        {dataMdl.showClose ? (
          <Button variant="danger" onClick={() => setShowMdl(false)}>
            {" "}
            Close{" "}
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
}
