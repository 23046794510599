import { useState } from "react";
import _, { map } from "lodash";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
export default function ModalOtherCertificate({
  showMdl,
  setShowMdl,
  dataMdl,
  setSelectedOptions,
  getSelectedOptions,
}) {
  const [getOtherName, setOtherName] = useState("");
  const onAddHandler = () => {
    let _getSelectedOptions = _.cloneDeep(getSelectedOptions);
    _getSelectedOptions.push({
      color: "#00B8D9",
      isFixed: false,
      label: getOtherName,
      value: getOtherName,
      _key: "100",
    });

    setSelectedOptions(_getSelectedOptions);

    setShowMdl(false);
  };
  return (
    <Modal show={showMdl} onHide={() => setShowMdl(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{dataMdl.heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form>
            <Form.Group controlId="formFile" className="mb-3">
              <Row>
                <Col>
                  <Form.Label>Enter Other Option</Form.Label>
                  <Form.Control
                    className="rounded-0 mt-3"
                    type="text"
                    // {...register('siteLanLord3', { required: true })}
                    onChange={(e) => {
                      // e.target.value === ''
                      //     ? setError('siteLanLord3', { message: "This field is required" })
                      //     : setError('siteLanLord3', { message: "" });
                      setOtherName(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onAddHandler}>
          {dataMdl.saveBtnName}
        </Button>
        {/* {dataMdl.showClose ? <Button variant="danger" onClick={() => setShowMdl(false)}> Close </Button> : null} */}
      </Modal.Footer>
    </Modal>
  );
}
