import Select from "react-select";
import _ from "lodash";
import { Form } from "react-bootstrap";
export default function MultiSelect1({
  options,
  getOption,
  setOption,
  _s_key,
  getCommentMulti1,
  setCommentMulti1,
}) {
  // console.log('getoptions11111111111111111111::', getOption);
  function setmData(e) {
    let _getOption = _.cloneDeep(getOption);
    _getOption[_s_key] = e;
    setOption(_getOption);
  }
  function setCommentData(e) {
    let _getCommentMulti1 = _.cloneDeep(getCommentMulti1);
    _getCommentMulti1[_s_key] = e.target.value;
    setCommentMulti1(_getCommentMulti1);
  }
  return (
    <>
      <Select
        // defaultValue={[]}
        isMulti
        name="colors"
        value={getOption[_s_key]}
        options={options}
        onChange={(e) => setmData(e)}
        className="basic-multi-select mb-3"
        classNamePrefix="select"
      />
      <Form.Control
        className="rounded-0"
        as="textarea"
        rows={2}
        value={getCommentMulti1[_s_key]}
        placeholder="Comment"
        onChange={(e) => setCommentData(e)}
      />
    </>
  );
}
