import Select from "react-select";
import _ from "lodash";

export default function MultiSelect1forCrockets({
  options,
  getOption,
  setOption,
  setHCMOptions1,
  getHCMOptions1,
  _s_key,
}) {
  function setmData(e, index) {
    let _getOption = _.cloneDeep(getOption);
    _getOption[_s_key] = e;
    setOption(_getOption);
    setHCMOptions1(e);
  }

  return (
    <>
      <Select
        // defaultValue={[]}
        isMulti
        name="colors"
        value={getOption[_s_key]}
        options={options}
        onChange={(e, index) => setmData(e, index)}
        className="basic-multi-select mb-3"
        classNamePrefix="select"
      />
    </>
  );
}
