import loading from "../../assets/Icons/loading.svg";

export default function Loading({ width, height }) {
  return (
    <div className="loading-container d-flex justify-content-around align-items-center">
      <img
        alt=""
        src={String(loading)}
        width={width}
        height={height}
        className="loading-logo  "
      />
    </div>
  );
}
