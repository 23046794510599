import { Row, Col, Accordion, Badge } from "react-bootstrap";
import { useState, useEffect } from "react";
import { EMP_ID, DECRYPTED_DATA } from "../../utils/Variable";
import _ from "lodash";
import close from "../../assets/Icons/close.svg";
import check from "../../assets/Icons/check.svg";
export default function SummarySOB({
  form_template,
  setCrewMemberProceed,
  getCrewMemberProceed,
  setProceed,
  setProceed1,
}) {
  const [getCount, setCount] = useState([]);
  const crewleadId = EMP_ID().replace(/['"]+/g, "");
  useEffect(() => {
    let _getCount = [];
    _getCount[crewleadId] = form_template.map((obj) => {
      let _fillcount = 0;
      let _totalcount = 0;
      obj.questions.map((qobj) => {
        switch (qobj.questionType) {
          case "Signature":
            qobj.signature !== "" && _fillcount++;
            _totalcount++;
            break;
          case "Date":
          case "Image+Certificate":
          case "Dropdown":
          case "Text":
          case "Description":
            // case "":
            qobj.answer !== "" && _fillcount++;
            _totalcount++;
            break;
          case "Radio":
            qobj.answer !== "" && _fillcount++;
            _totalcount++;
            break;
        }
        // if (qobj.questionType !== "Signature") {
        //     qobj.answer !== '' && _fillcount++;
        // } else if (qobj.questionType === "Signature") {
        //     qobj.signature !== '' && _fillcount++;
        // }
      });
      return {
        sectionId: obj.sectionId,
        sectionName: obj.sectionName,
        fillcount: _fillcount,
        totalcount: _totalcount,
      };
    });
    setCount(_getCount);
  }, []);
  const crewLeadChangeHandler = (_key) => {
    let _getCrewMemberProceed = _.cloneDeep(getCrewMemberProceed);
    _getCrewMemberProceed.crew = 0;
    setCrewMemberProceed(_getCrewMemberProceed);
    setProceed(_key);
    setProceed1(0);
    // setPFASOptions([])
  };
  return (
    <Accordion defaultActiveKey={[0]} alwaysOpen className="mb-3">
      <Accordion.Item eventKey={0}>
        <Accordion.Header>
          <div className="rounded border p-2 bd-highlight">
            <img
              src={DECRYPTED_DATA().profile_img_url}
              style={{ width: "60px" }}
              alt="profilePic"
            />
          </div>
          <div className="p-2 bd-highlight">
            {DECRYPTED_DATA().firstName + " " + DECRYPTED_DATA().lastName}
          </div>
          <img
            src={String(check)}
            className="ms-5 filter-green border border-success rounded-circle  "
            style={{ width: "22px", padding: "2px" }}
            alt="check-success"
          />
        </Accordion.Header>
        <Accordion.Body>
          <Row xs={12}>
            <Col md={4}>
              <ul className="list-group mt-3 mb-3">
                {!_.isEmpty(getCount[crewleadId]) &&
                  getCount[crewleadId].map((obj, i) => {
                    return (
                      <li
                        key={obj.sectionId}
                        className="list-group-item d-flex flex-row bd-highlight align-items-center"
                      >
                        <span
                          className={
                            obj.fillcount !== obj.totalcount
                              ? "w-50 text-warning"
                              : "w-50 text-success"
                          }
                          style={{ cursor: "pointer" }}
                          onClick={() => crewLeadChangeHandler(i)}
                        >
                          <b>{obj.sectionName}</b>
                        </span>
                        <span
                          className={
                            obj.fillcount !== obj.totalcount
                              ? "ms-3 w-25 text-warning"
                              : "ms-3 w-25 text-success"
                          }
                        >
                          ({obj.fillcount}/{obj.totalcount})
                        </span>
                        {obj.fillcount === obj.totalcount ? (
                          <img
                            src={String(check)}
                            className="ms-5 filter-green border border-success rounded-circle  "
                            style={{ width: "22px", padding: "2px" }}
                            alt="check-success"
                          />
                        ) : (
                          <img
                            src={String(close)}
                            className="ms-5 filter-red border border-danger rounded-circle  "
                            style={{ width: "22px", padding: "2px" }}
                            alt="check-failure"
                          />
                        )}
                      </li>
                    );
                  })}
              </ul>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
