import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ReactSignatureCanvas from "react-signature-canvas";
import "./Signature.scss";
import restore from "../../assets/Icons/restore.svg";
export default function Signature({ setSignBase64Uri, imgName, resetSign }) {
  // const [getSignPad, setSignPad] = useState(null);
  var getSignPad = null;
  const [getClearbtn, setClearbtn] = useState(true);

  const handleOnEnd = () => {
    setClearbtn(getSignPad.isEmpty());
    setSignBase64Uri(
      getSignPad.isEmpty() ? "" : dataURLtoFile(getSignPad.toDataURL(), imgName)
    );
    var png = getSignPad.toDataURL().split(",")[1];
    // console.log(new File(new Blob([window.atob(png)], { type: 'image/png', encoding: 'utf-8' }), "test1212"));
    // console.log(dataURLtoFile(getSignPad.toDataURL(), "signature"))
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  const clear = () => {
    resetSign();
    getSignPad.clear();
    handleOnEnd();
  };
  return (
    <>
      <div className="position-relative ">
        {/* <Button >clear</Button> */}
        <div className="position-absolute top-0 end-0 sig-controls">
          <Button
            variant="light"
            size="sm"
            onClick={clear}
            disabled={getClearbtn}
          >
            <img
              src={String(restore)}
              alt="reset sign"
              style={{ width: "18px" }}
            />
          </Button>
        </div>

        <ReactSignatureCanvas
          penColor="black"
          canvasProps={{ className: "sig-canvas" }}
          onEnd={handleOnEnd}
          ref={(ref) => {
            getSignPad = ref;
          }}
        ></ReactSignatureCanvas>
      </div>
    </>
  );
}
