import React, { useState, useCallback, memo, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import _ from "lodash";
import { googleMapsKey } from "../../utils/Constant";
const containerStyle = {
  width: "100%",
  height: "200px",
};

// const center = {
//     lat: -3.745,
//     lng: -38.523
// };
// const center = { lat: 19.0759837, lng: 72.8776559 };

function GMapStatic({ center, getAddress, getZoom }) {
  const [_address, _setAddress] = useState(getAddress);
  const [markerposition, setMarkerposition] = useState(center);
  const [map, setMap] = useState(null);
  const [zget, zset] = useState(0);

  var { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsKey,
    libraries: ["places"],
  });

  const onLoad = useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      map.setZoom(3);
      setMap(map);
      // let _markerposition = _.cloneDeep(markerposition);
      // _markerposition = { lat: center.lat, lng: center.lng }
      // setMarkerposition(_markerposition)
      getReverseGeocodingData(center.lat, center.lng);
    },
    [center]
  );

  const onClick = useCallback(function callback(map) {
    let _markerposition = _.cloneDeep(markerposition);
    _markerposition = { lat: map.latLng.lat(), lng: map.latLng.lng() };
    setMarkerposition(_markerposition);

    getReverseGeocodingData(map.latLng.lat(), map.latLng.lng());
  }, []);

  function getReverseGeocodingData(lat, lng) {
    var latlng = new window.google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: latlng }, (results, status) => {
      if (status !== window.google.maps.GeocoderStatus.OK) {
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status == window.google.maps.GeocoderStatus.OK) {
        // console.log(results);
        var address = results[0].formatted_address;
        // console.log('vicky', address);
        address += " (" + lat + "," + lng + ")";
        // setAddress(address);
        _setAddress(address);
      }
    });
  }
  // const options = {
  //     zoomControlOptions: {
  //         position: window.google.maps.ControlPosition.RIGHT_CENTER // 'right-center' ,
  //         // ...otherOptions
  //     }
  // }
  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);
  // console.log("map:: ", map);
  setTimeout(() => {
    zset(15);
  }, 1000);
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zget}
      // options={options}
      // defaultZoom={1}
      onLoad={onLoad}
      onUnmount={onUnmount}
      // onClick={onClick}
      // onDragEnd={boundsCallBack}
    >
      <Marker position={{ lat: center.lat, lng: center.lng }}>
        <InfoWindow options={{ maxWidth: 500 }}>
          <span>{getAddress}</span>
        </InfoWindow>
      </Marker>

      {/* Child components, such as markers, info windows, etc. */}
      {/* {data.map((place, index) => (
                <p key={index} onMouseOver={() => onMouseOverEvent(place)}>
                    {place.name}
                </p>
            ))} */}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default memo(GMapStatic);
