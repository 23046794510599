import { URL } from "../utils/Constant";
import { headers, TOKEN } from "../utils/Variable";
import _ from "lodash";
//get wheather according to f/c f=imperial,c=metric
export const getWeather = async (
  setForm_template,
  form_template,
  sendData,
  setWeatherHours,
  getWeatherHours
) => {
  sendData.loadingTrue();
  let _form_template = _.cloneDeep(form_template);
  if (_form_template[7] !== undefined) {
    headers.set("Authorization", "Bearer " + TOKEN());
    //?latitude=28.6139391&longitude=77.2090212&unit=imperial
    const resp = await fetch(
      // URL.GetWeather + `?latitude=${sendData['latitude']}&longitude=${sendData['longitude']}&unit=${sendData['unit']}&response_type=4&duration=12`,
      URL.GetWeather +
      `?latitude=${sendData["latitude"]}&longitude=${sendData["longitude"]}&unit=${sendData["unit"]}`,
      { method: "get", headers: headers }
    );
    if (resp.ok) {
      const data = await resp.json();
      if (data.status_code === 200) {
        _form_template[7].questions[0].weather_imperial = data.data;
        // console.log('getweather:', data.data);
        setForm_template(_form_template);
        const resp1 = await fetch(
          URL.GetWeather +
          `?latitude=${sendData["latitude"]}&longitude=${sendData["longitude"]}&unit=${sendData["unit"]}&response_type=4&duration=12`,
          { method: "get", headers: headers }
        );
        if (resp1.ok) {
          const data1 = await resp1.json();
          data1.status_code === 200 && setWeatherHours(data1.data);
          // console.log("SAIIIIIIIIIIIIIIIIIIII", getWeatherHours.forecasts[0].temperature)
        }
      }
      sendData.loadingFalse();
    }
  }
};

export const getWeatherJSA = async (
  setForm_template,
  form_template,
  sendData,
  setWeatherHours
) => {
  sendData.loadingTrue();
  let _form_template = _.cloneDeep(form_template);
  if (_form_template[7] !== undefined) {
    headers.set("Authorization", "Bearer " + TOKEN());
    //?latitude=28.6139391&longitude=77.2090212&unit=imperial
    const resp = await fetch(
      // URL.GetWeather + `?latitude=${sendData['latitude']}&longitude=${sendData['longitude']}&unit=${sendData['unit']}&response_type=4&duration=12`,
      URL.GetWeather +
      `?latitude=${sendData["latitude"]}&longitude=${sendData["longitude"]}&unit=${sendData["unit"]}`,
      { method: "get", headers: headers }
    );
    if (resp.ok) {
      const data = await resp.json();
      if (data.status_code === 200) {
        _form_template[7].questions[0].weather_imperial = data.data;
        // console.log('getweather:', data.data);
        setForm_template(_form_template);
        const resp1 = await fetch(
          URL.GetWeather +
          `?latitude=${sendData["latitude"]}&longitude=${sendData["longitude"]}&unit=${sendData["unit"]}&response_type=4&duration=12`,
          { method: "get", headers: headers }
        );
        if (resp1.ok) {
          const data1 = await resp1.json();
          data1.status_code === 200 && setWeatherHours(data1.data);
        }
      }
      sendData.loadingFalse();
    }
  }
};

// uploading mulptiple image
export const uploadMultiImage = async (
  setUploadedImgUrls,
  sendDataImg,
  setImgUrls
) => {
  sendDataImg.setLoading(true);
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
  };

  if (sendDataImg["files"][0] !== undefined) {
    var formData = new FormData();
    sendDataImg["files"].map((value) => {
      // console.log(value)
      if (value !== "" && value !== undefined) {
        formData.append("files", value, value.name);
        // formData.append('files', value);

        formData.append("section_names", sendDataImg["section_names"]);
        return formData;
      }
    });

    // formData.append('emailId', sendDataImg['emailId']);
    formData.append("upload_type", sendDataImg["upload_type"]);
    formData.append("reportId", sendDataImg["reportId"]);
    // console.log(Object.fromEntries(formData.entries()))
    const resp = await fetch(URL.UploadMultiImage, {
      method: "POST",
      body: formData,
      headers: headersList,
    });
    if (resp.ok) {
      const data = await resp.json();
      if (data.status_code === 200) {
        // console.log(data)
        setUploadedImgUrls(data.data);
        setImgUrls({});
      }
      sendDataImg.setSetP(true);
    }
  }
  sendDataImg.setLoading(false);
};

//detect ai
export const detectAI = async (
  setAIData,
  pic_url,
  category,
  getImgAiDetectKeys,
  setImgAiDetectKeys,
  changeFormTemplateData,
  setLoading,
  _key_val
) => {
  // console.log(pic_url, category);
  setLoading(true);
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  let jsonData = JSON.stringify({
    pic_url: pic_url,
    category: category,
  });

  const resp = await fetch(URL.DetectAI, {
    method: "POST",
    body: jsonData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      // console.log(data)
      //13 - Personal Protective Equipment
      //25- Hazard Controls

      changeFormTemplateData([
        _key_val,
        [
          pic_url,
          data.data.status,
          data.data.confidence,
          data.data.threshold,
          data.data.other_confidence,
          data.data.ai_approved,
        ],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        getImgAiDetectKeys.index,
        category,
      ]);
      setAIData(data.data);
      // setImgAiDetectKeys({});

      // console.log("hSSSHSHHSS", data.data.image_metadata)
    }
    setLoading(false);
  }
};

export const ijhaFormSubmit = async (
  getIjhaform,
  setLoading,
  setIjhaSubmit
) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  setLoading(true);

  let jsonData = JSON.stringify(getIjhaform);
  const resp = await fetch(URL.SubmitIJHA, {
    method: "POST",
    body: jsonData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      // console.log(data)
      setIjhaSubmit("Form submitted for approval.");
    } else {
      setIjhaSubmit(data.message);
    }
    setLoading(false);
  }
};

export const ijhaInCompleteFormSubmit = async (getIjhaform, setLoading) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  setLoading(true);
  let jsonData = JSON.stringify(getIjhaform);
  const resp = await fetch(URL.SubmitIJHA, {
    method: "POST",
    body: jsonData,
    headers: headersList,
  });
  const data = await resp.json();
  // setTimeout(()=> {setLoading(false);},1000)
  setLoading(false);
};

export const getFormIjhaApi = async (formId, getLoc, setLoading, setErrMsg) => {
  headers.set("Authorization", "Bearer " + TOKEN());
  setLoading(true);
  // console.log(getLoc, EMP_ID)
  const resp = await fetch(
    URL.GetFrom +
    `?latitude=${getLoc.lat}&longitude=${getLoc.lng}&formId=${formId}&Source=webapp`,
    // URL.GetFrom + `?employeeId=${EMP_ID}&latitude=${getLoc.lat}&longitude=${getLoc.lng}&formId=10&Source=webapp`,
    { method: "get", headers: headers }
  );
  let data = {};
  if (resp.ok) {
    data = await resp.json();
    if (data.status_code === 200) {
      // console.log(data)
      // setIjhaform(data.data);
    } else {
      setErrMsg(data.message);
    }
    setLoading(false);
  }
  return data;
};

export const getLevelDataApi = async (
  setLevelData,
  setCheckUSA,
  getLatitude,
  getLongitude,
  setTraumaCenterName,
  setLoading,
  setErrMsg
) => {
  headers.set("Authorization", "Bearer " + TOKEN());
  setLoading(true);
  const resp = await fetch(URL.TraumaCentreLevels, {
    method: "get",
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      setLevelData(data.data);
      await getTraumaCenterNameApi(
        setTraumaCenterName,
        setCheckUSA,
        getLatitude,
        getLongitude,
        data.data.default_levels,
        setLoading,
        setErrMsg
      );
    }
    setErrMsg(data.message);
    setLoading(false);
  }
};
export const getTraumaCenterNameApi = async (
  setTraumaCenterName,
  setCheckUSA,
  getLatitude,
  getLongitude,
  getLevels,
  setLoading,
  setErrMsg
) => {
  headers.set("Authorization", "Bearer " + TOKEN());
  setLoading(true);
  const resp = await fetch(
    URL.TraumaCentreNames +
    `?latitude=${getLatitude}&longitude=${getLongitude}&tlevel=${getLevels}`,
    { method: "get", headers: headers }
  );
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      if (data.data.length !== 0) {
        let _getTraumaCenterName = data.data.map((obj) => {
          obj["_Distance"] = Number(_.split(obj["Distance"], " ")[0]);
          obj["_Duration"] = Number(_.split(obj["Duration"], " ")[0]);
          return obj;
        });
        _getTraumaCenterName = _.orderBy(
          _getTraumaCenterName,
          ["_Duration"],
          ["asc"]
        );

        setTraumaCenterName(_getTraumaCenterName);
      } else {
        const resp = await fetch(
          URL.TraumaCentreCurrentLocation +
          `?latitude=${getLatitude}&longitude=${getLongitude}&type=hospital&radius=20000`,
          { method: "GET", headers: headers }
        );
        setCheckUSA(true);
        if (resp.ok) {
          const data = await resp.json();
          if (data.status_code === 200) {
            let _getTraumaCenterName = [];
            let _getTraumaCenterDetail = data.data.results.map((obj, k) => {
              // obj["_Distance"] = Number(_.split(obj["Distance"], ' ')[0])
              // obj["_Duration"] = Number(_.split(obj["Duration"], ' ')[0])
              // return obj;
              _getTraumaCenterName.push({
                ADDRESS: obj.vicinity,
                CITY: "",
                COUNTRY: "",
                Distance: "",
                Duration: "",
                LATITUDE: obj.geometry.location.lat,
                LONGITUDE: obj.geometry.location.lng,
                NAME: obj.name,
                STATE: "",
                TELEPHONE: "",
                TRAUMA: k + 1,
                ZIP: "",
              });
            });
            // _getTraumaCenterName = _.orderBy(_getTraumaCenterName, ['_Duration'], ['asc']);

            setTraumaCenterName(_getTraumaCenterName);
          }
        }
      }
    }
    setErrMsg(data.message);
    setLoading(false);
  }
};

export const getAddCrewMemberApi = async (
  setCrewLeadData,
  setLoading,
  setErrMsg
) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    // "Content-Type": "application/json"
  };
  setLoading(true);
  // JSON.stringify({ "crewleadId": EMP_ID.replace(/['"]+/g, '') })
  const resp = await fetch(URL.GetAddCrewMember, {
    method: "GET",
    headers: headersList,
  });
  let data = {};
  if (resp.ok) {
    data = await resp.json();
    if (data.status_code === 200) {
      // var ciphertext = JSON.stringify(data.data).toString();
      // data.data.company =  DECRYPTED_DATA().company;
      setCrewLeadData(data.data);
    } else {
      setErrMsg({ cls: "danger", msg: data.message });
    }
    setLoading(false);
  }
  return data.data;
};

export const addCrewMemberApi = async (
  getCrewFormData,
  setLoading,
  setErrMsg
) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  setLoading(true);
  let jsonData = JSON.stringify({
    crewmemberdata: getCrewFormData,
  });
  const resp = await fetch(URL.GetAddCrewMember, {
    method: "POST",
    body: jsonData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      // var ciphertext = JSON.stringify(getCrewFormData).toString();

      // console.log(data)
      setErrMsg({ cls: "success", msg: data.message });
    } else {
      setErrMsg({ cls: "danger", msg: data.message });
    }
    setLoading(false);
  }
};
export const getHospitalDirectionFromToApi = async (
  setForm_template,
  form_template,
  param,
  getFullAdd,
  setLoading
) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    // "Content-Type": "application/json"
  };
  setLoading(true);

  const resp = await fetch(
    URL.GetHospitalDirectionFromTo +
    `?latitude=${param.lat}&longitude=${param.lng}&hosp_lat=${param.hosp_lat}&hosp_long=${param.hosp_lng}`,
    { method: "GET", headers: headersList }
  );
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      let _form_template = _.cloneDeep(form_template);

      _form_template[5].questions[1].answer = getFullAdd;
      _form_template[5].questions[1].lat = Number(
        getFullAdd.split("(")[1].split(",")[0]
      );
      _form_template[5].questions[1].long = Number(
        getFullAdd.split("(")[1].split(",")[1].split(")")[0]
      );

      _form_template[5].questions[3].answer = data.data[0].answer;
      _form_template[5].questions[3].distance = data.data[0].distance;
      _form_template[5].questions[3].duration = data.data[0].duration;

      _form_template[5].questions[4].answer = data.data[1].answer;
      _form_template[5].questions[4].distance = data.data[1].distance;
      _form_template[5].questions[4].duration = data.data[1].duration;
      // console.log(_form_template, "hosp1")

      setForm_template(_form_template);
    }
    setLoading(false);
  }
};

export const getHospitalDirectionFromToApiJSA = async (
  setForm_template,
  form_template,
  param,
  getFullAdd,
  setLoading
) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    // "Content-Type": "application/json"
  };
  setLoading(true);

  const resp = await fetch(
    URL.GetHospitalDirectionFromTo +
    `?latitude=${param.lat}&longitude=${param.lng}&hosp_lat=${param.hosp_lat}&hosp_long=${param.hosp_lng}`,
    { method: "GET", headers: headersList }
  );
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      let _form_template = _.cloneDeep(form_template);

      _form_template[6].questions[1].answer = getFullAdd;
      _form_template[6].questions[1].lat = Number(
        getFullAdd.split("(")[1].split(",")[0]
      );
      _form_template[6].questions[1].long = Number(
        getFullAdd.split("(")[1].split(",")[1].split(")")[0]
      );

      _form_template[6].questions[3].answer = data.data[0].answer;
      _form_template[6].questions[3].distance = data.data[0].distance;
      _form_template[6].questions[3].duration = data.data[0].duration;

      _form_template[6].questions[4].answer = data.data[1].answer;
      _form_template[6].questions[4].distance = data.data[1].distance;
      _form_template[6].questions[4].duration = data.data[1].duration;
      // console.log(_form_template, "hosp1")

      setForm_template(_form_template);
    }
    setLoading(false);
  }
};

export const get911AddressInfoApi = async (
  setForm_template,
  form_template,
  param,
  setLoading
) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    // "Content-Type": "application/json"
  };
  setLoading(true);
  const resp = await fetch(
    URL.Get911AddressInfo +
    `?latitude=${Number(param.lat)}&longitude=${Number(param.lng)}`,
    { method: "GET", headers: headersList }
  );
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      let _form_template = _.cloneDeep(form_template);
      //evacuation point
      _form_template[5].questions[2].answer = data.data[0].answer;
      _form_template[5].questions[2].lat =
        data.data[0].lat !== "" ? data.data[0].lat : Number(param.lat);
      _form_template[5].questions[2].long =
        data.data[0].long !== "" ? data.data[0].long : Number(param.lng);

      //911
      _form_template[2].questions[1].answer = data.data[0].answer;
      _form_template[2].questions[1].lat = data.data[0].lat;
      _form_template[2].questions[1].long = data.data[0].long;

      _form_template[5].questions[1].answer = data.data[1].answer;
      _form_template[5].questions[1].lat =
        data.data[1].lat !== "" ? data.data[1].lat : Number(param.lat);
      _form_template[5].questions[1].long =
        data.data[1].long !== "" ? data.data[1].long : Number(param.lng);

      _form_template[5].questions[3].answer = data.data[2].answer;
      _form_template[5].questions[3].distance = data.data[2].distance;
      _form_template[5].questions[3].duration = data.data[2].duration;

      _form_template[5].questions[4].answer = data.data[3].answer;
      _form_template[5].questions[4].distance = data.data[3].distance;
      _form_template[5].questions[4].duration = data.data[3].duration;

      _form_template[5].questions[5].answer = data.data[4].answer;
      _form_template[5].questions[5].lat =
        data.data[4].lat !== "" ? data.data[4].lat : Number(param.lat);
      _form_template[5].questions[5].long =
        data.data[4].long !== "" ? data.data[4].long : Number(param.lng);
      _form_template[5].questions[5].phone_number = data.data[4].phone_number;

      _form_template[5].questions[6].answer = data.data[5].answer;
      _form_template[5].questions[6].lat =
        data.data[5].lat !== "" ? data.data[5].lat : Number(param.lat);
      _form_template[5].questions[6].long =
        data.data[5].long !== "" ? data.data[5].long : Number(param.lng);
      // console.log(_form_template, "form_template", data.data, "911change")

      setForm_template(_form_template);
    }
    setLoading(false);
  }
};

export const get911AddressInfoApiJSA = async (
  setForm_template,
  form_template,
  param,
  setLoading
) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    // "Content-Type": "application/json"
  };
  setLoading(true);
  const resp = await fetch(
    URL.Get911AddressInfo +
    `?latitude=${Number(param.lat)}&longitude=${Number(param.lng)}`,
    { method: "GET", headers: headersList }
  );
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      let _form_template = _.cloneDeep(form_template);
      //evacuation point
      _form_template[6].questions[2].answer = data.data[0].answer;
      _form_template[6].questions[2].lat = data.data[0].lat;
      _form_template[6].questions[2].long = data.data[0].long;

      //911
      _form_template[2].questions[1].answer = data.data[0].answer;
      _form_template[2].questions[1].lat = data.data[0].lat;
      _form_template[2].questions[1].long = data.data[0].long;

      _form_template[6].questions[1].answer = data.data[1].answer;
      _form_template[6].questions[1].lat = data.data[1].lat;
      _form_template[6].questions[1].long = data.data[1].long;

      _form_template[6].questions[3].answer = data.data[2].answer;
      _form_template[6].questions[3].distance = data.data[2].distance;
      _form_template[6].questions[3].duration = data.data[2].duration;

      _form_template[6].questions[4].answer = data.data[3].answer;
      _form_template[6].questions[4].distance = data.data[3].distance;
      _form_template[6].questions[4].duration = data.data[3].duration;

      _form_template[6].questions[5].answer = data.data[4].answer;
      _form_template[6].questions[5].lat = data.data[4].lat;
      _form_template[6].questions[5].long = data.data[4].long;

      _form_template[6].questions[6].answer = data.data[5].answer;
      _form_template[6].questions[6].lat = data.data[5].lat;
      _form_template[6].questions[6].long = data.data[5].long;
      // console.log(_form_template, "form_template", data.data, "911change")

      setForm_template(_form_template);
    }
    setLoading(false);
  }
};

// export const getFormSiteInspectionApi = async (setIjhaform, getLoc, setLoading, setErrMsg) => {
//     headers.set("Authorization", "Bearer " + TOKEN());
//     setLoading(true);
//     // console.log(getLoc, EMP_ID)
//     const resp = await fetch(
//         URL.GetFrom + `?employeeId=${EMP_ID.replace(/['"]+/g, '')}&latitude=${getLoc.lat}&longitude=${getLoc.lng}&formId=10&Source=webapp`,
//         // URL.GetFrom + `?employeeId=${EMP_ID}&latitude=${getLoc.lat}&longitude=${getLoc.lng}&formId=10&Source=webapp`,
//         { method: 'get', headers: headers }
//     );
//     if (resp.ok) {
//         const data = await resp.json();
//         if (data.status_code === 200) {
//             // console.log(data)
//             setIjhaform(data.data);
//         }
//         setErrMsg(data.message);
//         setLoading(false);
//     }
// }

//detect ai-all
export const detectAIALL = async (pic_url, setLoading) => {
  // console.log(pic_url);
  setLoading(true);
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  let jsonData = JSON.stringify({
    pic_url: pic_url,
  });

  const resp = await fetch(URL.DetectAIALL, {
    method: "POST",
    body: jsonData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      // console.log(data)
      setLoading(false);
      return data;
      //13 - Personal Protective Equipment
      //25- Hazard Controls
      // changeFormTemplateData([_key_val, [pic_url, data.data.status, data.data.confidence, data.data.threshold], getImgAiDetectKeys.section, getImgAiDetectKeys.questions]);
      // setAIData(data.data);
      // setImgAiDetectKeys({});
    }
    setLoading(false);
  }
};
