import { Modal, Button, Carousel, Row, Col } from "react-bootstrap";
import { IMG_TOKEN } from "../../utils/Variable";
import _ from "lodash";
export default function ModalCertificateSiteObsrPreview({
  showMdl,
  setShowMdl,
  dataMdl,
}) {
  // console.log(dataMdl, 'ModalPreview', dataMdl.aiInsights)
  let aiInsights_JSX = [];
  if (!_.isEmpty(dataMdl.aiInsights)) {
    const object = dataMdl.aiInsights;
    for (const property in object) {
      aiInsights_JSX.push(
        <Row className="mb-3">
          <Col>
            <b>{property} </b>
          </Col>
          <Col>{object[property]}</Col>
        </Row>
      );
    }
  } else {
    aiInsights_JSX.push(
      <h5 className="d-flex justify-content-center align-items-center mb-2 text-warning">
        No AI Insights Data Available!!
      </h5>
    );
  }

  return (
    <>
      <Modal show={showMdl} onHide={() => setShowMdl(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{dataMdl.heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dataMdl.showPreviewCarousal ? (
            <Carousel variant="dark" interval={8000} pause="hover">
              {dataMdl.picUrl !== undefined &&
                dataMdl.picUrl.map((obj) => {
                  return (
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={obj + IMG_TOKEN()}
                        alt="First slide"
                      />
                      {/* <Carousel.Caption>
                                            <h3>First slide label</h3>
                                        </Carousel.Caption> */}
                    </Carousel.Item>
                  );
                })}
            </Carousel>
          ) : (
            <div className="mb-2 shadow-none p-1 bg-light rounded">
              {aiInsights_JSX.map((obj) => obj)}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {dataMdl.showClose ? (
            <Button variant="secondary" onClick={() => setShowMdl(false)}>
              {" "}
              Close{" "}
            </Button>
          ) : null}
          {/* <Button variant="primary" disabled={getLoading} onClick={deleteHandler}>
                        {dataMdl.saveBtnName}
                    </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
