export function removeTags(str) {
  if (str === null || str === "") return "";
  else {
    str = str.toString();
    str = str.replace(/<br\s*[\/]?>/gi, "\n");
  }
  let res = str.replace(/(<([^>]+)>)/gi, "").replace(/\&nbsp;/g, "");
  if (res.slice(-1) === "\n") {
    res = res.slice(0, -1);
  }
  return res;
}
const date = new Date();
const month = date.getMonth() + 1;
const day = date.getDate();
const year = date.getFullYear();
export const formattedDate = `${day.toString().padStart(2, "0")}/${month
  .toString()
  .padStart(2, "0")}/${year}`;

export function getCurrentDataTime() {
  var today = new Date();
  var day = today.getDate() + "";
  var month = today.getMonth() + 1 + "";
  var year = today.getFullYear() + "";
  var hour = today.getHours() + "";
  var minutes = today.getMinutes() + "";
  var seconds = today.getSeconds() + "";

  day = checkZero(day);
  month = checkZero(month);
  year = checkZero(year);
  hour = checkZero(hour);
  minutes = checkZero(minutes);
  seconds = checkZero(seconds);

  // console.log(day + "/" + month + "/" + year + " " + hour + ":" + minutes + ":" + seconds);
  return `${year}/${month}/${day} ${hour}:${minutes}:${seconds}`;
}

export function getDataInFormat(today, time = false) {
  var day = today.getDate() + "";
  var month = today.getMonth() + 1 + "";
  var year = today.getFullYear() + "";
  var hour = today.getHours() + "";
  var minutes = today.getMinutes() + "";
  var seconds = today.getSeconds() + "";

  day = checkZero(day);
  month = checkZero(month);
  year = checkZero(year);
  hour = checkZero(hour);
  minutes = checkZero(minutes);
  seconds = checkZero(seconds);

  if (time) {
    return `${day}/${month}/${year} ${hour}:${minutes}:${seconds}`;
  }
  // return `${year}/${month}/${day}`;
  return `${day}/${month}/${year}`;
}
export function getTotalTime(start_datatime, submitted_datetime) {
  // var start_datatime = new Date('2022/08/01 09:20:30');
  // var submitted_datetime = new Date('2022/08/02 09:27:33');

  var delta =
    Math.abs(new Date(submitted_datetime) - new Date(start_datatime)) / 1000;

  // calculate (and subtract) whole days
  var days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  var seconds = delta % 60; // in theory the modulus is not required

  return minutes + ":" + seconds + " mins";
}
function checkZero(data) {
  if (data.length === 1) {
    data = "0" + data;
  }
  return data;
}

export function getFileTypeImgDoc(_value) {
  if (typeof _value !== "undefined") {
    if (_value.match(/\.(jpg|png|gif|jpeg|JPG|PNG|GIF|JPEG)\b/)) {
      //Image
      return "Image";
    } else if (_value.match(/\.(PDF|pdf)\b/)) {
      //Document
      return "Document";
    } else {
      //null
      return "";
    }
  }
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function formatAMPM(date1, type = "h") {
  var date = new Date(date1);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  if (type === "m") {
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  }
  return hours + " " + ampm;
}

export function emailValidation(e) {
  var regex_email =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var email = e.target.value;
  if (!regex_email.test(String(email).toLowerCase())) {
    return true;
  } else {
    return false;
  }
}
export function emailValidation1(e) {
  var regex_email =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var email = e;
  if (!regex_email.test(String(email).toLowerCase())) {
    return true;
  } else {
    return false;
  }
}

// Without it, your current regex only matches that you have 6 to 16 valid characters, it doesn't validate that it has at least a number, and at least a special character. That's what the lookahead above is for.
// (?=.*[0-9]) - Assert a string has at least one number;
// (?=.*[!@#$%^&*]) - Assert a string has at least one special character.
export function passwordValidation(e) {
  var regex_pass =
    /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  var pass = e.target.value;
  if (!regex_pass.test(String(pass))) {
    return true;
  } else {
    return false;
  }
}

export function signamID(e) {
  var regex_pass = /^[a-zA-Z]*$/;
  var pass = e.target.value;
  if (!regex_pass.test(String(pass))) {
    return true;
  } else {
    return false;
  }
}

export function Names(e) {
  var regex_pass = /^[A-Za-z]+$/;
  var pass = e.target.value;
  if (!regex_pass.test(String(pass))) {
    return true;
  } else {
    return false;
  }
}

export function getUrlWithQueryString(url) {
  return url.split("?")[0];
}

export function getPPEImageUrl(arr) {
  const urls = arr
    .filter((obj, i) => {
      if (i >= 2 && i <= 6) {
        return obj;
      }
    })
    .map((obj) => obj.picture);

  return urls.length ? urls[0] : "";
}

export function convertTemperature(value, unit, isFahrenheit) {
  if (unit === "F" && !isFahrenheit) {
    return Math.round(((value - 32) * 5) / 9);
  } else if (unit === "C" && isFahrenheit) {
    return Math.round((value * 9) / 5 + 32);
  } else {
    return Math.round(value);
  }
}

export function convertMilesPerHour(value, unit, isMilesPerHour) {
  if (unit === "mi/h" && !isMilesPerHour) {
    return Math.round(value * 1.60934);
  } else if (unit === "km/h" && isMilesPerHour) {
    return Math.round(value / 1.60934);
  } else {
    return Math.round(value);
  }
}

export function getTemperatureColorFilter(
  min,
  max,
  currentTemperature,
  setwarningmessagerequired,
  warningmessagerequired
) {
  const temperatureRange =
    currentTemperature >= min && currentTemperature <= max;
  if (temperatureRange) {
    return "grayscale(100%)";
  } else {
    setwarningmessagerequired((prevState) => ({
      ...prevState,
      Temperature: "Temperature",
      toastErrMsg: true,
    }));
    return "invert(48%) sepia(800%) saturate(400%) hue-rotate(330deg) brightness(80%) contrast(180%)";
  }
}

export function getWindGustColorFilter(
  min,
  max,
  currentWindGust,
  setwarningmessagerequired,
  warningmessagerequired
) {
  const WindGustRange = currentWindGust <= max;
  if (WindGustRange) {
    return "grayscale(100%)";
  } else {
    setwarningmessagerequired((prevState) => ({
      ...prevState,
      WindGust: "Wind Gust",
      toastErrMsg: true,
    }));
    return "invert(29%) sepia(21%) saturate(4708%) hue-rotate(325deg) brightness(104%) contrast(102%)";
  }
}
export function DateTimeConversion(date) {
  const originalDate = new Date(date);
  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
  const day = originalDate.getDate().toString().padStart(2, "0");
  const hours = originalDate.getHours().toString().padStart(2, "0");
  const minutes = originalDate.getMinutes().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:00`;

  return formattedDate;
}
