import { Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import trashcan from "../../assets/Icons/trashcan.svg";
import { Names, emailValidation } from "../../utils/Helper";

import { useForm } from "react-hook-form";
import Select from "react-select";
import { useState, useEffect } from "react";
import _ from "lodash";
// import { UserGroupId } from "../../utils/Variable";
import { isEmpty } from "lodash";
export default function CrewMember({
  setCrewMemberType,
  setStatus,
  getCrewLeadData,
  getFormData,
  emailId,
  firstName,
  lastName,
  phoneNumber,
  membertype,
  company,
  crewMemberChangeHandler,
  _key,
  deleteCrewMember,
  getKey,
  membertype_list,
  setCrewFormData,
  EditFlow,
  setCrewLeadData,
  getCompanyList,
  getCrewMembersList
}) {
  const [leadMemberType, setleadMemberType] = useState("");
  const [showSuggestion, setShowSuggestion] = useState(false);
  const { formState: { errors }, setError } = useForm();
  // const [dropdownoptions, setdropdownoptions] = useState([]);
  const [selectedCrewMember, setselectedCrewMember] = useState(null);
  const [showdropdownreq, setshowdropdownreq] = useState(getFormData.crew_member_creation[0].dropdown);
  const [showfreetextreq, setshowfreetextreq] = useState(getFormData.crew_member_creation[0].free_text);
  const [getCompanyName, setCompanyName] = useState("");
  const [getotherCompanyName, setOtherCompanyName] = useState("");

  useEffect(() => {
    if (leadMemberType === "Climber") {
      setShowSuggestion(true);
    }
  }, [leadMemberType]);



  const handleStatusChange = (type) => {
    setCrewFormData((prevdata) => {
      return prevdata.map((crewmember, i) => {
        if (i === _key) {
          return {
            emailId: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            membertype: "",
            company: getCrewLeadData.company
          };
        }
        return crewmember;
      });
    });
    setCrewMemberType(true);
    setselectedCrewMember(null);
    if (type === "dropdown") {
      setshowdropdownreq(!true);
      setshowfreetextreq(true);
      setStatus((prevstate) => {
        return {
          ...prevstate,
          [`${_key}`]: { dropdown: false, freetext: true },
        };
      });
      setCrewFormData((prevdata) => {
        return prevdata.map((crewmember, i) => {
          if (i === _key) {
            return {
              emailId: "",
              firstName: "",
              lastName: "",
              phoneNumber: "",
              membertype: "",
              company: getCrewLeadData.company
            };
          }
          return crewmember;
        });
      });
      setCrewLeadData((prevData) => ({
        ...prevData,
        crewmemberdata: [],
        membertype_list: ["Climber", "Non-Climber"],
      }));
    } else {
      setCompanyName(getCrewLeadData.company);
      setshowfreetextreq(!true);
      setshowdropdownreq(true);
      setStatus((prevstate) => {
        return {
          ...prevstate,
          [`${_key}`]: { freetext: false, dropdown: true },
        };
      });
      setCrewFormData((prevdata) => {
        return prevdata.map((crewmember, i) => {
          if (i === _key) {
            return {
              emailId: "",
              firstName: "",
              lastName: "",
              phoneNumber: "",
              membertype: "",
              company: getCrewLeadData.company
            };
          }
          return crewmember;
        });
      });
      setCrewLeadData((prevData) => ({
        ...prevData,
        crewmemberdata: [],
        membertype_list: ["Climber", "Non-Climber"],
      }));
    }
  };

  useEffect(() => {
    if (showdropdownreq && showfreetextreq && EditFlow !== null) {
      if (isEmpty(EditFlow)) {
        setStatus((prevstate) => {
          return {
            ...prevstate,
            [`${_key}`]: { freetext: false, dropdown: true },
          };
        });
        setshowfreetextreq(false);
      } else {
        if (!(String(_key) in EditFlow)) {
          EditFlow[_key] = {
            dropdown: true,
            freetext: false,
          };
        }
        if (EditFlow && EditFlow[_key].dropdown) {
          setshowdropdownreq(true);
          setshowfreetextreq(false);
          setStatus((prevstate) => {
            return {
              ...prevstate,
              [`${_key}`]: { freetext: false, dropdown: true },
            };
          });
        } else {
          setshowdropdownreq(false);
          setshowfreetextreq(true);
          setStatus((prevstate) => {
            return {
              ...prevstate,
              [`${_key}`]: { freetext: true, dropdown: false },
            };
          });
        }
      }
    } else if (showdropdownreq && showfreetextreq) {
      setshowdropdownreq(true);
      setshowfreetextreq(false);
      setStatus((prevstate) => {
        return {
          ...prevstate,
          [`${_key}`]: { freetext: false, dropdown: true },
        };
      });
    }
  }, []);



  useEffect(() => {
    if (!_.isEmpty(company)) {
      if (company.includes("Other")) {
        setCompanyName(company.split('-')[0]);
        setOtherCompanyName(company.split('-')[1]);
        setshowfreetextreq(true);
        setshowdropdownreq(false);
      } else {
        setCompanyName(company);
      }
      crewMemberChangeHandler(_key, "company", company);
    } else {
      setCompanyName(getCrewLeadData.company);
      crewMemberChangeHandler(_key, "company", getCrewLeadData.company);
    }
  }, [getCrewLeadData.company]);

  const companyHandler = (val) => {
    setCompanyName(val);
    if (val === "Other") {
      setshowfreetextreq(true);
      setshowdropdownreq(false);
      setOtherCompanyName("");
      crewMemberChangeHandler(_key, "company", val + "-");
    } else {
      crewMemberChangeHandler(_key, "company", val);
      const checkEmp = getCrewMembersList.filter(i => i.company === val);
      if (_.isEmpty(checkEmp)) {
        setshowfreetextreq(true);
        setshowdropdownreq(false);
      } else {
        setshowfreetextreq(false);
        setshowdropdownreq(true);
      }
    }
  }

  const otherCompanyHandler = (val) => {
    setOtherCompanyName(val);
    crewMemberChangeHandler(_key, "company", "Other-" + val);
  }

  return (
    <>
      <Row
        xs={12}
        className="mb-5 p-3 d-flex justify-content-left shadow rounded text-decoration-none crewMemControls"
      >
        <Col className="customCol" xs={12} sm={6} md={4} xl={3}>
          <Form.Group controlId="form-2-1" className="mb-3">
            <Form.Label>
              <span className="text-danger bold-font">*</span>Company
            </Form.Label>
            <Form.Select
              className="rounded-0 hide-blinking-cursor company"
              value={getCompanyName}
              onChange={(e) => {
                companyHandler(e.target.value);

                if (e.target.value === "") {
                  setError("company" + _key, {
                    message: "This field is required",
                  });
                } else {
                  setError("company" + _key, { message: "" });
                }
              }
              }
            >
              <option value="" style={{ display: 'none' }}>{getCompanyName}</option>
              {getCompanyList.map((i, k) => (
                <option key={k} value={i}>
                  {i}
                </option>
              )
              )}
            </Form.Select>
            <Form.Label className="mt-2 text-danger">
              {errors["company" + _key] &&
                errors["company" + _key].message}
            </Form.Label>
          </Form.Group>
        </Col>
        {showdropdownreq &&
          (
            <Col className="customCol" xs={12} sm={6} md={4} xl={3}>
              <Form.Label>
                <span className="text-danger bold-font">*</span>Crew Member
              </Form.Label>
              <Select
                options={getCrewMembersList.filter(i => !_.isEmpty(getCrewLeadData.crewmemberdata) ? i.company === getCrewLeadData.crewmemberdata[_key].company || i.company === "Other" : i.company === getCrewLeadData.company || i.company === "Other")}
                value={
                  getCrewMembersList.find((option) => option.value === emailId && option.value !== "") ||
                  selectedCrewMember
                }
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setselectedCrewMember(selectedOption);
                    const formattedValue = selectedOption.value;
                    if (selectedOption.company === "Other") {
                      const [firstName, lastName] = ["", ""];
                      setshowfreetextreq(true);
                      crewMemberChangeHandler(
                        _key,
                        "emailId",
                        formattedValue,
                        firstName,
                        lastName,
                        getCompanyName
                      );
                    } else {
                      const [firstName, lastName] = selectedOption.label.split(" ");
                      crewMemberChangeHandler(
                        _key,
                        "emailId",
                        formattedValue,
                        firstName,
                        lastName,
                        getCompanyName
                      );
                      setshowfreetextreq(false);
                    }
                  }
                }}
                placeholder="Select Crew Member"
              />
            </Col>
          )}

        {showfreetextreq && (
          <>
            {getCompanyName === "Other" &&
              <Col className="customCol" xs={12} sm={6} md={4} xl={3}>
                <Form.Group controlId="form-2-1" className="mb-3">
                  <Form.Label>
                    <span className="text-danger bold-font">*</span>Other Company Name
                  </Form.Label>
                  <Form.Control
                    className="rounded-0 "
                    type="text"
                    value={getotherCompanyName || ""}
                    onChange={(e) => {
                      otherCompanyHandler(e.target.value)
                      if (e.target.value === "") {
                        setError("companyOther" + _key, {
                          message: "This field is required",
                        });
                      } else {
                        setError("companyOther" + _key, { message: "" });
                      }
                    }}
                  />
                  <Form.Label className="mt-2 text-danger">
                    {errors["companyOther" + _key] &&
                      errors["companyOther" + _key].message}
                  </Form.Label>
                </Form.Group>
              </Col>
            }
            <Col className="customCol" xs={12} sm={6} md={4} xl={3}>
              <Form.Group controlId="form-2-1" className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font">*</span>Email ID
                </Form.Label>
                <Form.Control
                  className="rounded-0 "
                  type="email"
                  value={emailId || ""}
                  onChange={(e) => {
                    crewMemberChangeHandler(_key, "emailId", e.target.value);
                    //let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,3})+$/
                    if (e.target.value === "") {
                      setError("emailId" + _key, {
                        message: "This field is required",
                      });
                    } else if (emailValidation(e)) {
                      setError("emailId" + _key, {
                        message: "Please enter a valid Email ID",
                      });
                    } else {
                      setError("emailId" + _key, { message: "" });
                    }
                  }}
                />
                <Form.Label className="mt-2 text-danger">
                  {errors["emailId" + _key] && errors["emailId" + _key].message}
                </Form.Label>
              </Form.Group>
            </Col>

            <Col className="customCol" xs={12} sm={6} md={4} xl={3}>
              <Form.Group controlId="form-2-1" className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font">*</span>First Name
                </Form.Label>
                <Form.Control
                  className="rounded-0 "
                  type="text"
                  value={firstName || ""}
                  onChange={(e) => {
                    crewMemberChangeHandler(_key, "firstName", e.target.value);
                    if (e.target.value === "") {
                      setError("firstName" + _key, {
                        message: "This field is required",
                      });
                    } else if (Names(e)) {
                      setError("firstName" + _key, {
                        message: "Please enter valid First Name",
                      });
                    } else {
                      setError("firstName" + _key, { message: "" });
                    }
                  }}
                />
                <Form.Label className="mt-2 text-danger">
                  {errors["firstName" + _key] &&
                    errors["firstName" + _key].message}
                </Form.Label>
              </Form.Group>
            </Col>

            <Col className="customCol" xs={12} sm={6} md={4} xl={3}>
              <Form.Group controlId="form-2-1" className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font">*</span>Last Name
                </Form.Label>
                <Form.Control
                  className="rounded-0 "
                  type="text"
                  value={lastName || ""}
                  onChange={(e) => {
                    crewMemberChangeHandler(_key, "lastName", e.target.value);
                    if (e.target.value === "") {
                      setError("lastName" + _key, {
                        message: "This field is required",
                      });
                    } else if (Names(e)) {
                      setError("lastName" + _key, {
                        message: "Please enter valid Last Name",
                      });
                    } else {
                      setError("lastName" + _key, { message: "" });
                    }
                  }}
                />
                <Form.Label className="mt-2 text-danger">
                  {errors["lastName" + _key] &&
                    errors["lastName" + _key].message}
                </Form.Label>
              </Form.Group>
            </Col>

            <Col className="customCol" xs={12} sm={6} md={4} xl={3}>
              <Form.Group controlId="form-2-1" className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font"></span>Phone No.
                </Form.Label>
                <Form.Control
                  className="rounded-0 "
                  type="text"
                  value={phoneNumber || ""}
                  pattern="[0-9]*"
                  onChange={(e) => {
                    crewMemberChangeHandler(
                      _key,
                      "phoneNumber",
                      e.target.validity.valid ? e.target.value : phoneNumber
                    );
                    let regex =
                      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                    if (regex.test(e.target.value)) {
                      setError("phone" + _key, {
                        message: "",
                      });
                    } else {
                      setError("phone" + _key, {
                        message: "Please enter a valid phone number",
                      });
                    }
                  }}
                />
                <Form.Label className="mt-2 text-danger">
                  {errors["phone" + _key] && errors["phone" + _key].message}
                </Form.Label>
              </Form.Group>
            </Col>
          </>
        )}


        {getFormData.climber_nonclimber_required !== "N" && (
          <Col className="customCol" xs={12} sm={6} md={4} xl={3}>
            <Form.Group controlId="form-2-1" className="mb-3">
              <Form.Label>
                <span className="text-danger bold-font">*</span>Member type
              </Form.Label>
              {showSuggestion && leadMemberType === "Climber" && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      All climbers must have PPEs
                    </Tooltip>
                  }
                >
                  <span className="icon-info">i</span>
                </OverlayTrigger>
              )}
              <Form.Select
                className="rounded-0 hide-blinking-cursor"
                value={membertype}
                onChange={(e) => {
                  crewMemberChangeHandler(_key, "membertype", e.target.value);
                  setleadMemberType(e.target.value);
                  if (e.target.value === "") {
                    setError("membertype" + _key, {
                      message: "This field is required",
                    });
                  } else {
                    setError("membertype" + _key, { message: "" });
                  }
                }}
              >
                <option value="">Select</option>
                {membertype_list.map((i, k) => (
                  <option key={k} value={i}>
                    {i}
                  </option>
                ))}
              </Form.Select>
              <Form.Label className="mt-2 text-danger">
                {errors["membertype" + _key] &&
                  errors["membertype" + _key].message}
              </Form.Label>
            </Form.Group>
          </Col>
        )}
        {getKey !== 1 && getKey !== 0 ? (
          <div className="d-flex justify-content-end">
            <img
              className="icon-red-color pointer pt-1"
              src={String(trashcan)}
              onClick={() => deleteCrewMember(_key)}
              alt="delete icon"
            />
          </div>
        ) : (
          ""
        )}
        {showfreetextreq ? (
          <p>
            <span
              className="texthref"
              onClick={(e) => handleStatusChange("freetext")}
            >
              Select from existing members list
            </span>
          </p>
        ) : showdropdownreq ? (
          <p>
            Not in the list?{" "}
            <span
              className="texthref"
              onClick={(e) => handleStatusChange("dropdown")}
            >
              Add New Member
            </span>
          </p>
        ) : null}
      </Row>
    </>
  );
}
