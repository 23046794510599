import { useState } from "react";
import _, { map } from "lodash";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
export default function ModalOther({
  showMdl,
  setShowMdl,
  dataMdl,
  getProceed,
  setForm_template,
  form_template,
  getPFASOptions,
  setPFASOptions,
  getCrewMemberProceed,
}) {
  const [getOtherName, setOtherName] = useState("");
  const onAddHandler = () => {
    let _form_template = _.cloneDeep(form_template);

    switch (getProceed) {
      case 5: //sectionId: "7"
      case 7: //sectionId: "10"
        _form_template[getProceed].questions[0].answer = getOtherName;
        _form_template[getProceed].questions[0].options.push(getOtherName);
        break;
      case 11:
        let _getPFASOptions = _.cloneDeep(getPFASOptions);

        let z = _form_template[11].section[
          getCrewMemberProceed.crew
        ].questions[0].multiselect_options.push({
          is_selected: true,
          value: getOtherName,
        });
        _getPFASOptions.push({
          color: "#00B8D9",
          isFixed: false,
          label: getOtherName,
          value: getOtherName,
          _is_selected: true,
          _key: 0,
          _s_key: z,
        });
        setPFASOptions(_getPFASOptions);
        _form_template[11].section[
          getCrewMemberProceed.crew
        ].questions[0].multiselect_options.map((obj) => {
          if (obj.value === "Other") {
            obj.is_selected = false;
          }
        });
        break;
    }
    setForm_template(_form_template);

    setShowMdl(false);
  };
  return (
    <Modal show={showMdl} onHide={() => setShowMdl(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{dataMdl.heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form>
            <Form.Group controlId="formFile" className="mb-3">
              <Row>
                <Col>
                  <Form.Label>Enter Other Option</Form.Label>
                  <Form.Control
                    className="rounded-0 mt-3"
                    type="text"
                    // {...register('siteLanLord3', { required: true })}
                    onChange={(e) => {
                      // e.target.value === ''
                      //     ? setError('siteLanLord3', { message: "This field is required" })
                      //     : setError('siteLanLord3', { message: "" });
                      setOtherName("Other-" + e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onAddHandler}>
          {dataMdl.saveBtnName}
        </Button>
        {/* {dataMdl.showClose ? <Button variant="danger" onClick={() => setShowMdl(false)}> Close </Button> : null} */}
      </Modal.Footer>
    </Modal>
  );
}
