import { Row, Col, Form, Button } from "react-bootstrap";
export default function SelectionSection({
  getSelectedSection,
  onChangeSectionSelection,
  onChangeVisibilityCheck,
  onClickSelectAllSection,
  _disable_sel_proceed,
  onClickSelectionProceed,
  getLoading,
}) {
  return (
    <Row className="shadow-lg p-3 mb-5 mt-3 rounded ">
      <Row className="mb-3 mt-1">
        <h4>Sections</h4>
      </Row>
      <Row>
        {getSelectedSection.map((obj) => {
          return (
            <Col lg={3} className="p-2" key={obj.sectionId}>
              <Form.Group controlId={obj.sectionId} className="mb-3">
                {/* <Form.Label><span className="text-danger bold-font">*</span>{obj.sectionName}</Form.Label> */}
                <Form.Check
                  inline
                  name="group1"
                  checked={obj.checked_val}
                  label={obj.sectionName}
                  onChange={(e) => {
                    onChangeSectionSelection([obj.sectionId, e.target.checked]);
                    onChangeVisibilityCheck([obj.sectionId, e.target.checked]);
                  }}
                  type="checkbox"
                  id={`inline-checkbox-${obj.sectionId}`}
                />
              </Form.Group>
            </Col>
          );
        })}
      </Row>
      <Row lg="12">
        <Col className="d-flex justify-content-start">
          <Button
            variant="primary"
            onClick={() => onClickSelectAllSection(_disable_sel_proceed)}
          >
            {_disable_sel_proceed ? "Select All" : "UnSelect All"}
          </Button>
        </Col>
        <Col className="d-flex justify-content-end">
          {!getLoading ? (
            <Button
              variant="success"
              disabled={_disable_sel_proceed && true}
              onClick={onClickSelectionProceed}
            >
              Proceed
            </Button>
          ) : (
            <Button
              variant="success"
              disabled={true}
              onClick={onClickSelectionProceed}
            >
              Proceed
            </Button>
          )}
        </Col>
      </Row>
    </Row>
  );
}
