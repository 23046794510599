import { URL } from "../utils/Constant";
import { headers, headersList, TOKEN } from "../utils/Variable";
import moment from "moment-timezone";
const getRequestBody = (params) => {
  let formData = new FormData();
  for (let i in params) {
    formData.append(i, params[i]);
  }
  return formData;
};

export const checkImageData = async (payload, setImgMetaData) => {
  let formData = payload;
  // let _lastModified = new Date(formData.doc_file.lastModified).toUTCString()
  // let _gmtDATE = _lastModified.split(', ')[1].split(' GMT')[0];
  // let _gmtDATEFotmat = moment(_gmtDATE).format('YYYY-MM-DD HH:mm:ss');
  // formData['modified_date'] = _gmtDATEFotmat;

  let _timeZone = moment.tz.guess(true);
  formData["timezone_info"] = _timeZone;

  const resp = await fetch(URL.Image_Reuploads.GETMETADATA, {
    method: "POST",
    body: getRequestBody(formData),
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    let Timeconversion = parseInt(data.response.time_diff);
    if (Timeconversion >= 720) {
      setImgMetaData(
        "The image is older than 12 hours. Please upload the image captured in last 1 hour only."
      );
    } else if (data.response.time_diff === "Time not get extracted") {
      setImgMetaData(
        "Unable to identify date/time the photograph was taken on. If this photograph was not taken within the last 1 hour you must replace it with a recent photograph."
      );
    } else {
      setImgMetaData("Time Verified");
    }
    // console.log(data);
    return data.response;
  }
};
