import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Col, Row, OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import pdf_img from "../../assets/Icons/pdf_icon.png";
import edit from "../../assets/Icons/edit.svg";
import chevrondown from "../../assets/Icons/chevrondown.svg";
import chevronright from "../../assets/Icons/chevronright.svg";
import {
  getViewReportJSONApi,
  deleteInProcessReport,
  getInProcessReportJSONApi,
} from "../../api/Reports.api";
import { getEditCrewMemberApi } from "../../api/CreateiJHA.api";
import { useNavigate } from "react-router-dom";
import { domain_url, redirect_URI } from "../../utils/Constant";
import { TOKEN } from "../../utils/Variable";
import { useEffect, useState } from "react";
import trashcan from "../../assets/Icons/trashcan.svg";
import copy from "../../assets/Icons/copy.svg";
import Loading from "../../components/Loading/Loading";
import { DECRYPTED_DATA } from "../../utils/Variable";
import _, { reject } from "lodash";
export default function ReportsTbl({
  NewChildComponent,
  totaltime_js,
  getStatusOption,
  getAllFormsData,
}) {
  const [getLoading, setLoading] = useState(false);
  const [getErrMsg, setErrMsg] = useState("");
  const [expandedRows, setExpandedRows] = useState([]);
  const [ProductsUpdated, setProductsUpdated] = useState([]);
  const [getReportId, setReportId] = useState("");
  const [getLoadingofData, setLoadingofData] = useState(false);

  const [getEditFormIjha, setEditFormIjha] = useState({});

  const [getTableData, setTableData] = useState(totaltime_js);
  const [getCrewLeadData, setCrewLeadData] = useState({
    crewmemberdata: [],
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    company: ""
  });

  const callingfunction = (e, isExpand) => {
    isExpand = false;
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (!_.isEmpty(getEditFormIjha) && !_.isEmpty(getCrewLeadData)) {
      let _getEditFormIjha = _.cloneDeep(getEditFormIjha);
      if (_getEditFormIjha.complete_status === "Submitted") {
        _getEditFormIjha.form_template.filter((obj) => {
          if ("section" in obj) {
            obj.section.filter((sobj) => {
              sobj.end_time = "";
              sobj.start_time = "";
              sobj.time_taken = 0;
            });
            return obj;
          } else {
            obj.end_time = "";
            obj.start_time = "";
            obj.time_taken = 0;
          }
          return obj;
        });
      } else {
        if (_getEditFormIjha.complete_status === "InProgress") {
          _getEditFormIjha.form_template.filter((obj, k) => {
            if (k === _getEditFormIjha.inProgressSection) {
              if ("section" in obj) {
                obj.section.filter((sobj) => {
                  if (sobj.end_time === "" && sobj.start_time !== "" && sobj.time_taken === 0) {
                    sobj.end_time = "";
                    sobj.start_time = "";
                    sobj.time_taken = 0;
                  }
                });
                return obj;
              } else {
                if (obj.end_time === "" && obj.start_time !== "" && obj.time_taken === 0) {
                  obj.end_time = "";
                  obj.start_time = "";
                  obj.time_taken = 0;
                }
              }
              return obj;
            }
          });
        }
      }

      const _getFormData = getAllFormsData.filter(
        (i) => i.formId === getEditFormIjha.formId
      );
      const _getFormData1 = getAllFormsData.filter(
        (i) => i.formId === getEditFormIjha.formId
      );
      if (getEditFormIjha.crew_member_required === "Y") {
        navigate("/create-DynamicIJHA", {
          state: {
            ijhaform: _getEditFormIjha,
            reportIJHA: true,
            getCrewLeadData: getCrewLeadData,
            crewmemberdata: getCrewLeadData.crewmemberdata,
            getFormData: _getFormData,
          },
        });
      } else {
        navigate("/create-SiteInspection", {
          state: {
            SiteInspectionForm: _getEditFormIjha,
            getCrewLeadData: getCrewLeadData,
            setRequired: true,
            getFormData1: _getFormData1,
          },
        });
      }
    }
  }, [getEditFormIjha, getCrewLeadData]);

  const deleteReport = async (reportID) => {
    await deleteInProcessReport(reportID, setLoading, setErrMsg);
    setTimeout(() => {
      setErrMsg("");
    }, 1000);
    let _getTableData = _.cloneDeep(getTableData);
    const _ind = Object.values(_getTableData["ReportID"])
      .filter((i, k) => i)
      .indexOf(reportID);

    let _CrewLead = Object.values(_getTableData["CrewLead"]).filter(
      (i, k) => k !== _ind && i
    );
    let _CrewSize = Object.values(_getTableData["CrewSize"]).filter(
      (i, k) => k !== _ind && i
    );
    let _ReportID = Object.values(_getTableData["ReportID"]).filter(
      (i, k) => k !== _ind && i
    );
    let _ReportType = Object.values(_getTableData["ReportType"]).filter(
      (i, k) => k !== _ind && i
    );
    let _Status = Object.values(_getTableData["Status"]).filter(
      (i, k) => k !== _ind && i
    );
    let _UniqueID = Object.values(_getTableData["UniqueID"]).filter(
      (i, k) => k !== _ind && i
    );
    let _submitted_datetime = Object.values(
      _getTableData["submitted_datetime"]
    ).filter((i, k) => k !== _ind && i);

    let CrewLead = Object.assign({}, _CrewLead);
    let CrewSize = Object.assign({}, _CrewSize);
    let ReportID = Object.assign({}, _ReportID);
    let ReportType = Object.assign({}, _ReportType);
    let Status = Object.assign({}, _Status);
    let UniqueID = Object.assign({}, _UniqueID);
    let submitted_datetime = Object.assign({}, _submitted_datetime);

    _getTableData["CrewLead"] = CrewLead;
    _getTableData["CrewSize"] = CrewSize;
    _getTableData["ReportID"] = ReportID;
    _getTableData["ReportType"] = ReportType;
    _getTableData["Status"] = Status;
    _getTableData["UniqueID"] = UniqueID;
    _getTableData["submitted_datetime"] = submitted_datetime;

    setTableData(_getTableData);
  };
  let products1 = [];
  let products = [];
  for (let tblData in getTableData["ReportID"]) {
    let _DownLoad = (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id={`tooltip-bottom`}>Click To Open/Download</Tooltip>
        }
      >
        <a
          target="_blank"
          rel="noreferrer"
          className="id-custom-body-1 btn btn-light btn-sm"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
          href={
            redirect_URI +
            "/public/reports/" +
            getTableData["ReportID"][tblData]
          }
        >
          <img src={String(pdf_img)} alt="pdf icon" style={{ width: "20px" }} />
        </a>
      </OverlayTrigger>
    );

    let reportId =
      getTableData["Status"][tblData] === "In Progress" ? "" : _DownLoad;

    const currentDate = new Date();
    const DateEditable =
      DECRYPTED_DATA().editableTime !== undefined || ""
        ? parseInt(DECRYPTED_DATA().editableTime)
        : 3;
    const submittedDatetime = getTableData["submitted_datetime"][tblData];
    const submittedDate = new Date(submittedDatetime);
    const currentDateOnly = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const SubmittedDateOnly = new Date(
      submittedDate.getFullYear(),
      submittedDate.getMonth(),
      submittedDate.getDate()
    );
    const daysDifference =
      (currentDateOnly - SubmittedDateOnly) / (1000 * 3600 * 24);
    let reportEdit =
      getTableData["Status"][tblData] === "In Progress" ? (
        <>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id={`tooltip-bottom`}>Click To Delete Report</Tooltip>
            }
          >
            <span
              className="id-custom-body-1 btn btn-light btn-sm"
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
            >
              <img
                className="icon-red-color pointer"
                src={String(trashcan)}
                onClick={() => deleteReport(getTableData["ReportID"][tblData])}
                alt="delete icon"
                style={{ width: `20px` }}
              />
            </span>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id={`tooltip-bottom`}>Click To Edit Report</Tooltip>
            }
          >
            <button
              className="id-custom-body-1 btn btn-warning btn-sm"
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              onClick={() =>
                getInProcessReportJSONApi(
                  getTableData["ReportID"][tblData],
                  setLoading,
                  setEditFormIjha,
                  setCrewLeadData,
                  "submittedReport"
                )
              }
            >
              <img
                src={String(edit)}
                alt="edit icon"
                style={{ width: "20px" }}
              />
            </button>
          </OverlayTrigger>
        </>
      ) : daysDifference <= DateEditable - 1 ? (
        <>
          {
            <>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={`tooltip-bottom`}>Click To Clone Report</Tooltip>
                }
              >
                <button
                  className="id-custom-body-1 btn btn-sm"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  onClick={() =>
                    getViewReportJSONApi(
                      getTableData["ReportID"][tblData],
                      setLoading,
                      setEditFormIjha,
                      setCrewLeadData,
                      "NewReport"
                    )
                  }
                >
                  <img
                    src={String(copy)}
                    alt="edit icon"
                    style={{ width: "20px", color: "#0dcaf0" }}
                  />
                </button>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={`tooltip-bottom`}>Click To Edit Report</Tooltip>
                }
              >
                <button
                  className="id-custom-body-1 btn btn-warning btn-sm"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  onClick={() =>
                    getViewReportJSONApi(
                      getTableData["ReportID"][tblData],
                      setLoading,
                      setEditFormIjha,
                      setCrewLeadData,
                      "submittedReport"
                    )
                  }
                >
                  <img
                    src={String(edit)}
                    alt="edit icon"
                    style={{ width: "20px" }}
                  />
                </button>
              </OverlayTrigger>
            </>
          }
        </>
      ) : (
        <>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id={`tooltip-bottom`}>Click To Clone Report</Tooltip>
            }
          >
            <button
              className="id-custom-body-1 btn btn-sm"
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              onClick={() =>
                getViewReportJSONApi(
                  getTableData["ReportID"][tblData],
                  setLoading,
                  setEditFormIjha,
                  setCrewLeadData,
                  "NewReport"
                )
              }
            >
              <img
                src={String(copy)}
                alt="edit icon"
                style={{ width: "20px", color: "#0dcaf0" }}
              />
            </button>
          </OverlayTrigger>
          <span className="naTag">N/A</span>
        </>
      );

    products.push({
      "Unique ID": getTableData["UniqueID"][tblData],
      "Crew Lead": getTableData["CrewLead"][tblData],
      "Crew Size": getTableData["CrewSize"][tblData],
      "Report Type": getTableData["ReportType"][tblData],
      "ESW Unique ID": getTableData["EswUniqueId"][tblData],
      "Submitted Datetime": getTableData["submitted_datetime"][tblData],
      key: getTableData["ReportID"][tblData],
      ParentReportId: getTableData.hasOwnProperty(["Parent_ReportID"])
        ? getTableData["Parent_ReportID"][tblData]
        : null,
      CountofParentReport: getTableData.hasOwnProperty(["Count"])
        ? getTableData["Count"][tblData]
        : null,
      ReportID: reportId,
      status: getTableData["Status"][tblData],
      "Report Edit": reportEdit,
    });
  }
  let _reportEditCol = [];

  _reportEditCol = [
    {
      dataField: "Report Edit",
      text: "Report Edit",
      sort: false,
      classes: "id-custom-body-1 text-right reportEdit",
      headerClasses: "text-right reportEdit",
    },
  ];

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "ESW Unique ID",
      text: "Report ID",
      sort: true,
      classes: "id-custom-body-1",
    },
    {
      dataField: "Report Type",
      text: "Report Type",
      sort: true,
      classes: "id-custom-body-1",
      headerClasses: "id-custom-header-crew-size",
    },
    {
      dataField: "Unique ID",
      text: "Project ID",
      sort: true,
      classes: "id-custom-body-1",
    },
    {
      dataField: "Crew Size",
      text: "Crew Size",
      sort: true,
      classes: "id-custom-body-1",
      headerClasses: "id-custom-header-crew-size",
    },
    {
      dataField: "Submitted Datetime",
      text: "Submitted Datetime",
      sort: true,
      classes: "id-custom-body-1",
      headerClasses: "id-custom-header-5-date-time",
    },
    {
      dataField: "ReportID",
      text: "Download / Open",
      sort: false,
      classes: "id-custom-body-1 text-center",
      headerClasses: "id-custom-header-1 text-center",
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      classes: "id-custom-body-1 text-center",
      headerClasses: "id-custom-header-1 text-center",
    },
    ..._reportEditCol,
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} entries
    </span>
  );

  const options = {
    paginationSize: 2,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    alwaysShowAllBtns: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "All", value: products.length },
    ],
  };

  const optionsforExpand = {
    paginationSize: 2,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    alwaysShowAllBtns: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "All", value: products.length },
    ],
  };

  const defaultSorted = [
    {
      dataField: "Submitted Datetime",
      order: "desc",
    },
  ];
  const sortedSbmitted = [
    {
      dataField: "Submitted Datetime",
      order: "asc",
    },
  ];
  const sortedAll = [
    {
      dataField: "status",
      order: "desc",
    },
  ];
  let defaultSort = "";
  switch (getStatusOption) {
    case 1:
    case "1":
      defaultSort = sortedSbmitted;
      break;

    default:
      defaultSort = defaultSorted;
  }

  const nestedColumns = [
    {
      dataField: "ESW Unique ID",
      text: "Report ID",
      sort: true,
      classes: "id-custom-body-1",
    },
    {
      dataField: "Unique ID",
      text: "Project ID",
      sort: true,
      classes: "id-custom-body-1",
    },
    {
      dataField: "Crew Size",
      text: "Crew Size",
      sort: true,
      classes: "id-custom-body-1",
      headerClasses: "id-custom-header-crew-size",
    },
    {
      dataField: "Submitted Datetime",
      text: "Submitted Datetime",
      sort: true,
      classes: "id-custom-body-1",
      headerClasses: "id-custom-header-5-date-time",
    },
    {
      dataField: "ReportID",
      text: "Download / Open",
      sort: false,
      classes: "id-custom-body-1 text-center",
      headerClasses: "id-custom-header-1 text-center",
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
      classes: "id-custom-body-1 text-center",
      headerClasses: "id-custom-header-1 text-center",
    },
    // ..._reportEditCol
  ];

  const ResponseData = async (ParentReportIdvalue, ReportId) => {
    const response = await NewChildComponent(ParentReportIdvalue, ReportId);
    const Objectresponse = !_.isEmpty(response) && JSON.parse(response);
    return Objectresponse;
  };

  const UpdateDataforTable = (row, isExpand, index) => {
    return new Promise((resolve, reject) => {
      ResponseData(row.ParentReportId, row.key)
        .then((response) => {
          let updatedProducts = [];
          const rowIndex = index;
          for (let tblData in response["ReportID"]) {
            const rowIdx = `${rowIndex}-${tblData}`;
            let _DownLoad = (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id={`tooltip-bottom`}>
                    Click To Open/Download
                  </Tooltip>
                }
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="id-custom-body-1 btn btn-light btn-sm"
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  href={
                    redirect_URI +
                    "/public/reports/" +
                    response["ReportID"][tblData]
                  }
                >
                  <img
                    src={String(pdf_img)}
                    alt="pdf icon"
                    style={{ width: "20px" }}
                  />
                </a>
              </OverlayTrigger>
            );

            let reportId =
              response["Status"][tblData] === "In Progress" ? "" : _DownLoad;

            let pastDate = new Date();
            pastDate.setDate(pastDate.getDate() - 3);

            let reportEdit =
              response["Status"][tblData] === "In Progress" ? (
                <>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        Click To Delete Report
                      </Tooltip>
                    }
                  >
                    <span
                      className="id-custom-body-1 btn btn-light btn-sm"
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                    >
                      <img
                        className="icon-red-color pointer"
                        src={String(trashcan)}
                        onClick={() =>
                          deleteReport(response["ReportID"][tblData])
                        }
                        alt="delete icon"
                        style={{ width: `20px` }}
                      />
                    </span>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        Click To Edit Report
                      </Tooltip>
                    }
                  >
                    <button
                      className="id-custom-body-1 btn btn-warning btn-sm"
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                      onClick={() =>
                        getInProcessReportJSONApi(
                          response["ReportID"][tblData],
                          setLoading,
                          setEditFormIjha,
                          setCrewLeadData,
                          "submittedReport"
                        )
                      }
                    >
                      <img
                        src={String(edit)}
                        alt="edit icon"
                        style={{ width: "20px" }}
                      />
                    </button>
                  </OverlayTrigger>
                </>
              ) : Date.parse(pastDate.toLocaleString()) <=
                Date.parse(response["submitted_datetime"][tblData]) ? (
                <>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        Click To Clone Report
                      </Tooltip>
                    }
                  >
                    <button
                      className="id-custom-body-1 btn btn-sm"
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                      onClick={() =>
                        getViewReportJSONApi(
                          response["ReportID"][tblData],
                          setLoading,
                          setEditFormIjha,
                          setCrewLeadData,
                          "NewReport"
                        )
                      }
                    >
                      <img
                        src={String(copy)}
                        alt="edit icon"
                        style={{ width: "20px", color: "#0dcaf0" }}
                      />
                    </button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        Click To Edit Report
                      </Tooltip>
                    }
                  >
                    <button
                      className="id-custom-body-1 btn btn-warning btn-sm"
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                      onClick={() =>
                        getViewReportJSONApi(
                          response["ReportID"][tblData],
                          setLoading,
                          setEditFormIjha,
                          setCrewLeadData,
                          "submittedReport"
                        )
                      }
                    >
                      <img
                        src={String(edit)}
                        alt="edit icon"
                        style={{ width: "20px" }}
                      />
                    </button>
                  </OverlayTrigger>
                </>
              ) : (
                <span className="naTag">N/A</span>
              );

            updatedProducts.push({
              "Unique ID": response["UniqueID"][tblData],
              "Crew Lead": response["CrewLead"][tblData],
              "Crew Size": response["CrewSize"][tblData],
              "ESW Unique ID": response["EswUniqueId"][tblData],
              "Report Type": response["ReportType"][tblData],
              "Submitted Datetime": response["submitted_datetime"][tblData],
              key: response["ReportID"][tblData],
              ReportID: reportId,
              status: response["Status"][tblData],
              "Report Edit": reportEdit,
            });
          }

          resolve(updatedProducts);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleExpandRow = async (row, isExpand, rowIndex, props) => {
    if (isExpand && !row.fetched) {
      try {
        const productsUpdation = await UpdateDataforTable(
          row,
          isExpand,
          rowIndex
        );
        row.productsUpdated = productsUpdation;
        setExpandedRows((prevState) => {
          const updatedRows = [...prevState];
          const existingRow = updatedRows.find((r) => r.key === row.key);
          if (existingRow) {
            existingRow.productsUpdated = productsUpdation;
          } else {
            updatedRows.push(row);
          }
          return updatedRows;
        });
      } catch (error) {
        console.error("Error Updating");
      }
    } else if (!isExpand) {
      setExpandedRows((prevState) =>
        prevState.filter((r) => r.key !== row.key)
      );
    }
  };
  return (
    <>
      {getLoading ? (
        <div className="m-5 p-5">
          <Loading />
        </div>
      ) : (
        <ToolkitProvider
          bootstrap4
          keyField="name"
          data={products}
          columns={columns}
          search
        >
          {(props) => (
            <>
              {getErrMsg !== "" && (
                <Row className="d-flex justify-content-center">
                  <Col xs={12} lg={6} md={6}>
                    <Alert
                      variant="success"
                      onClose={() => setErrMsg("")}
                      dismissible
                    >
                      {getErrMsg}
                    </Alert>
                  </Col>
                </Row>
              )}

              <Row>
                <Col md={12} className="d-flex justify-content-end">
                  <span className="search-label-report me-3">Search: </span>
                  <SearchBar
                    className="search-bar-report rounded-0"
                    {...props.searchProps}
                    srText=""
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <BootstrapTable
                    {...props.baseProps}
                    filter={filterFactory()}
                    defaultSorted={defaultSorted}
                    className="table-css"
                    keyField="key"
                    pagination={paginationFactory(options)}
                    noDataIndication="No Data Found!!!"
                    bordered={false}
                    bodyClasses="table-body-1"
                    hover={true}
                    classes="table-css"
                    headerClasses="header-class-report-tbl"
                    expandRow={{
                      renderer: (row) => {
                        const expandedRow = expandedRows.find(
                          (r) => r.key === row.key
                        );

                        if (
                          expandedRow &&
                          expandedRow.productsUpdated &&
                          expandedRow.productsUpdated.length > 0
                        ) {
                          return (
                            <BootstrapTable
                              keyField="nestedKey"
                              loading={Loading}
                              data={
                                expandedRow.productsUpdated !== ""
                                  ? expandedRow.productsUpdated
                                  : []
                              }
                              columns={nestedColumns}
                              bordered={true}
                            />
                          );
                        } else {
                          return (
                            <BootstrapTable
                              noDataIndication="No Data Found!!!"
                              keyField="nestedKey"
                              loading={Loading}
                              data={[]}
                              columns={nestedColumns}
                              bordered={true}
                            />
                          );
                        }
                      },
                      onExpand: (row, isExpand, index, e) => {
                        if (row.CountofParentReport < 2) {
                          edit.preventDefault();
                        } else {
                          handleExpandRow(row, isExpand, index);
                        }
                      },
                      expanded: expandedRows.map((row) => row.key),
                      showExpandColumn: true,

                      nonExpandable: expandedRows
                        .filter((row) => row.CountofParentReport < 2)
                        .map((row) => row.key),
                      expandByColumnOnly: true,
                      expandColumnRenderer: ({
                        expanded,
                        rowKey,
                        expandable,
                      }) => {
                        const row = props.baseProps.data.find(
                          (item) => item.key === rowKey
                        );
                        if (row && row.CountofParentReport < 2) {
                          expandable = true;
                          expanded = true;
                          return null;
                        }
                        return (
                          <span
                            style={{ cursor: "pointer" }}
                            title={expanded ? "Collapse All" : "Expand All"}
                          >
                            {expanded ? (
                              <img
                                src={String(chevrondown)}
                                alt="edit icon"
                                style={{ width: "15px" }}
                              />
                            ) : (
                              <img
                                src={String(chevronright)}
                                alt="edit icon"
                                style={{ width: "15px" }}
                              />
                            )}
                          </span>
                        );
                      },

                      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                        return null;
                      },
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </ToolkitProvider>
      )}
    </>
  );
}
