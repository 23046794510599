import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import FormProgressBar from "../../components/FormProgressBar/FormProgressBar";
import { useEffect } from "react";
import _ from "lodash";
import Loading from "../../components/Loading/Loading";
import SelectionSection from "./SelectionSection";
import SelectedSectionForm from "./SelectedSectionForm";
import arrow_up from "../../assets/Icons/arrow_up.png";
import { getCertificateOptionList } from "../../api/SiteObservationCertificate.api";
export default function SiteObservationForm({}) {
  const _loc = useLocation();

  const [getLoc, setLoc] = useState({ lat: "", lng: "" });
  const [getErrMsg, setErrMsg] = useState("");
  const [getReportRequired, setReportRequired] = useState(
    _loc.state.setRequired
  );
  const [getReportfilled, setReportfilled] = useState(_loc.state.setRequired);
  const [getSiteObservationformS, setSiteObservationformS] = useState(
    _loc.state.siteObservationform
  );
  const [form_template, set_form_template] = useState(
    _loc.state.siteObservationform.form_template
  );

  const [getSelectedSection, setSelectedSection] = useState([]);
  const [getVisiblityComp, setVisiblityComp] = useState({
    selectionSection: true,
    selectedSectionForm: false,
  });

  const [getProceed, setProceed] = useState(0);
  const [getProceed1, setProceed1] = useState(0);
  const [getLoading, setLoading] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  const [getCertificateOptionListD, setCertificateOptionListD] = useState([]);

  const defaultSelectedSection = () => {
    let _getSelectedSection = form_template.map((obj) => {
      return {
        sectionId: obj.sectionId,
        sectionName: obj.sectionName,
        checked_val: obj.visibility,
      };
    });
    setSelectedSection(_getSelectedSection);
  };

  useEffect(() => {
    fetchCertificateOptionList();
  }, []);

  async function fetchCertificateOptionList() {
    await getCertificateOptionList(setCertificateOptionListD, setLoading);
  }

  //  section selection
  const onChangeSectionSelection = (updated_val) => {
    let _getSelectedSection = _.cloneDeep(getSelectedSection);
    _getSelectedSection = _getSelectedSection.map((obj) => {
      if (obj.sectionId === updated_val[0]) obj.checked_val = updated_val[1];
      return obj;
    });
    setSelectedSection(_getSelectedSection);
  };

  const onChangeVisibilityCheck = (updated_val) => {
    let visibilityCheck = form_template.map((obj1) => {
      if (obj1.sectionId === updated_val[0]) obj1.visibility = updated_val[1];
      return obj1;
    });
    set_form_template(visibilityCheck);
  };
  const onClickSelectAllSection = (_disable_sel_proceed) => {
    let _getSelectedSection = _.cloneDeep(getSelectedSection);
    // console.log(_getSelectedSection)
    // _getSelectedSection.sectionId
    _getSelectedSection = _getSelectedSection.map((obj) => {
      obj.checked_val = _disable_sel_proceed;
      return obj;
    });
    setSelectedSection(_getSelectedSection);
    setReportRequired(false);

    let visibilityCheck = form_template.map((obj1) => {
      obj1.visibility = _disable_sel_proceed;
      return obj1;
    });
    set_form_template(visibilityCheck);
  };
  const onClickSelectionProceed = () => {
    if (getLoc.lat === "" && getLoc.lng === "") {
      setErrMsg("Please turn on your location");
    } else {
      setErrMsg("");
      let _getVisiblityComp = _.cloneDeep(getVisiblityComp);
      _getVisiblityComp.selectedSectionForm = true;
      _getVisiblityComp.selectionSection = false;
      setVisiblityComp(_getVisiblityComp);
      setReportRequired(false);
    }
  };
  const closeAlert = (e) => {
    setErrMsg("");
    window.location.pathname = "/site-observation";
  };

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  window.addEventListener("scroll", checkScrollTop);

  useEffect(() => {
    !_.isEmpty(getSiteObservationformS) && defaultSelectedSection();
    scrollUp();
    var _latlng;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        _latlng = _.cloneDeep(getLoc);
        _latlng.lat = position.coords.latitude;
        _latlng.lng = position.coords.longitude;
        console.log(position);
        setLoc(_latlng);
      });
    } else {
      setErrMsg("Geolocation is not supported by this browser.");
    }
  }, [getProceed, getProceed1]);
  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollDown = () => {
    let _totalHeight = document.body.offsetHeight;
    window.scrollTo({ top: _totalHeight, behavior: "smooth" });
  };

  const _disable_sel_proceed = getSelectedSection.every(
    (obj) => obj.checked_val === false
  );

  return (
    <Container className="margin-container">
      <h4 className="pt-2">Site Observation Form</h4>
      <Row>
        {/* <Col>
                <FormProgressBar getProgressCount={getProceed1 === 0 ? getProceed + 1 : 15} />
            </Col> */}
      </Row>
      {getErrMsg !== "" && (
        <Row className="d-flex justify-content-center">
          <Col xs={12} lg={6} md={6}>
            <Alert variant="danger" onClose={(e) => closeAlert(e)} dismissible>
              {getErrMsg}
            </Alert>
          </Col>
        </Row>
      )}
      {getVisiblityComp.selectionSection && (
        <SelectionSection
          getSelectedSection={getSelectedSection}
          onChangeSectionSelection={onChangeSectionSelection}
          onClickSelectAllSection={onClickSelectAllSection}
          onChangeVisibilityCheck={onChangeVisibilityCheck}
          _disable_sel_proceed={_disable_sel_proceed}
          onClickSelectionProceed={onClickSelectionProceed}
          getLoading={getLoading}
        />
      )}
      {getVisiblityComp.selectedSectionForm && (
        <SelectedSectionForm
          getReportfilled={getReportfilled}
          selectedSectionData={getSelectedSection}
          form_template_p={form_template}
          reportRequired={getReportRequired}
          getLoc={getLoc}
          getCertificateOptionListD={getCertificateOptionListD}
        />
      )}
      {getLoading && <Loading width="32" height="32" />}
      <Button
        onClick={scrollDown}
        className="btn btn-sm btn-dark mb-5 rounded-0 position-fixed topToBottom end-0 translate-middle "
        value="bottom"
        id="back-to-down"
      >
        <img
          src={String(arrow_up)}
          alt="scroll bottom"
          style={{ width: "18px" }}
          className="icon-white-color"
        />
      </Button>
      <Button
        onClick={scrollUp}
        className="btn btn-sm btn-dark mb-5 rounded-0 position-fixed bottom-0 end-0 translate-middle "
        value="top"
        id="back-to-up"
      >
        <img
          src={String(arrow_up)}
          alt="scroll top"
          style={{ width: "18px" }}
          className="icon-white-color"
        />
      </Button>
    </Container>
  );
}
