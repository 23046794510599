import React from "react";
import { useLogout } from "../../api/Login.api";
import { Row, Col} from "react-bootstrap";

function UserDetailError({user_details_error,setuser_details_error}) {
    const [logout] = useLogout();
    return (
      <Row className="sessionPopup">
      <Col sm={11} lg={5} md={10} className="content">
          <div className="body">
            <div>{user_details_error}</div>
          </div>
          <div className="bottom">
            <button
              id="btnSubmit"
              className="btn warning"
              onClick={() => {
                setuser_details_error("");
                logout();
              }}
            >
              Ok
            </button>
          </div>
        </Col>
      </Row>
    );
}

export default UserDetailError;
