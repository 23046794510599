import React, { useEffect } from "react";
import './App.scss';
import Layout from './layouts/Layout';
import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider } from "@azure/msal-react";
import Routing from "./Routing";
// import { EventType } from "@azure/msal-browser";
// import { b2cPolicies } from "./authConfig";
import Login from "./layouts/Main/Login";

function App({ instance }) {
  // const [getForgotPasswordClick, SetForgotPasswordClick] = useState(false);
  // useEffect(() => {
  //   instance.addEventCallback((message) => {
  //     if (message.eventType === EventType.LOGIN_FAILURE && message.error.errorMessage.includes("AADB2C90118")) {
  //       // SetForgotPasswordClick(true);
  //       instance.loginRedirect(b2cPolicies.authorities.changePassword);
  //     }
  //   });
  // }, []);

  return (
    <MsalProvider instance={instance}>
      <AuthenticatedTemplate>
        <Layout />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
        <Routing />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
}

export default App;