import { Form, Button } from "react-bootstrap";
import failed from "../../assets/Icons/failed.svg";
import trashcan from "../../assets/Icons/trashcan.svg";
import _ from "lodash";
export default function DailyTask({
  form_template,
  setForm_template,
  setError,
  errors,
}) {
  function click(index, e) {
    let _form_template = _.cloneDeep(form_template);
    _form_template[3].questions[1].multipleQuestion.splice(index, 1);
    setForm_template(_form_template);
  }
  function onChangeHandler(_key, index, e) {
    let _form_template = _.cloneDeep(form_template);
    _form_template[3].questions[1].multipleQuestion[index].questions[
      _key
    ].description = e.target.value;
    setForm_template(_form_template);
  }
  function onClearHandler(_key, index) {
    let _form_template = _.cloneDeep(form_template);
    _form_template[3].questions[1].multipleQuestion[index].questions[
      _key
    ].description = "";
    setForm_template(_form_template);
  }
  let _dailyTask = form_template[3].questions[1].multipleQuestion.map(
    (row, index) => {
      return (
        <Form.Group
          key={index}
          controlId="form-3-2-container"
          className="mb-3 shadow p-3 mb-5 rounded"
        >
          {index !== 0 ? (
            <div className="d-flex justify-content-end">
              <Button
                variant="light"
                size="sm"
                onClick={(e) => click(index, e)}
              >
                <img
                  className="icon-red-color"
                  src={String(trashcan)}
                  style={{ width: "22px" }}
                />
              </Button>
            </div>
          ) : null}
          <Form.Group controlId="form-3-2-1" className="mb-3">
            <Form.Label>
              {
                form_template[3].questions[1].multipleQuestion[index]
                  .questions[0].question
              }
            </Form.Label>
            <div className="position-relative ">
              <div className="position-absolute top-0 end-0 sig-controls">
                <Button
                  variant="light"
                  size="sm"
                  onClick={(e) => onClearHandler(0, index)}
                >
                  <img
                    src={String(failed)}
                    alt="clear"
                    style={{ width: "18px" }}
                  />
                </Button>
              </div>

              <Form.Control
                className="rounded-0"
                as="textarea"
                rows={5}
                value={
                  form_template[3].questions[1].multipleQuestion[index]
                    .questions[0].description
                }
                onChange={(e) => {
                  // let kn = 'dailytask' + index
                  e.target.value === ""
                    ? setError("dailytask" + index, {
                        message: "This field is required",
                      })
                    : setError("dailytask" + index, { message: "" });
                  onChangeHandler(0, index, e);
                }}
              />
            </div>
            <Form.Label className="mt-2 text-danger">
              {errors["dailytask" + index] &&
                errors["dailytask" + index].message}
            </Form.Label>
            {/* <Form.Label className="mt-2 text-danger"> {form_template[3].questions[1].multipleQuestion[index].questions[0].description === '' && "This field is required"}</Form.Label> */}
          </Form.Group>

          <Form.Group controlId="form-3-2-2" className="mb-3">
            <Form.Label>
              {
                form_template[3].questions[1].multipleQuestion[index]
                  .questions[1].question
              }
            </Form.Label>
            <div className="position-relative ">
              <div className="position-absolute top-0 end-0 sig-controls">
                <Button
                  variant="light"
                  size="sm"
                  onClick={(e) => onClearHandler(1, index)}
                >
                  <img
                    src={String(failed)}
                    alt="clear"
                    style={{ width: "18px" }}
                  />
                </Button>
              </div>

              <Form.Control
                className="rounded-0"
                as="textarea"
                rows={5}
                value={
                  form_template[3].questions[1].multipleQuestion[index]
                    .questions[1].description
                }
                onChange={(e) => {
                  e.target.value === ""
                    ? setError("dthazard" + index, {
                        message: "This field is required",
                      })
                    : setError("dthazard" + index, { message: "" });
                  onChangeHandler(1, index, e);
                }}
              />
            </div>
            <Form.Label className="mt-2 text-danger">
              {errors["dthazard" + index] && errors["dthazard" + index].message}
            </Form.Label>
          </Form.Group>

          <Form.Group controlId="form-3-2-3" className="mb-3">
            <Form.Label>
              {
                form_template[3].questions[1].multipleQuestion[index]
                  .questions[2].question
              }
            </Form.Label>
            <div className="position-relative ">
              <div className="position-absolute top-0 end-0 sig-controls">
                <Button
                  variant="light"
                  size="sm"
                  onClick={(e) => onClearHandler(2, index)}
                >
                  <img
                    src={String(failed)}
                    alt="clear"
                    style={{ width: "18px" }}
                  />
                </Button>
              </div>

              <Form.Control
                className="rounded-0"
                as="textarea"
                rows={5}
                value={
                  form_template[3].questions[1].multipleQuestion[index]
                    .questions[2].description
                }
                onChange={(e) => {
                  e.target.value === ""
                    ? setError("dtMitigations" + index, {
                        message: "This field is required",
                      })
                    : setError("dtMitigations" + index, { message: "" });
                  onChangeHandler(2, index, e);
                }}
              />
            </div>
            <Form.Label className="mt-2 text-danger">
              {errors["dtMitigations" + index] &&
                errors["dtMitigations" + index].message}
            </Form.Label>
          </Form.Group>
        </Form.Group>
      );
    }
  );
  return <>{_dailyTask} </>;
}
