import { URL } from "../utils/Constant";
import { headers, TOKEN } from "../utils/Variable";
import _, { cloneDeep } from "lodash";
//get wheather according to f/c f=imperial,c=metric

// uploading mulptiple image
export const uploadMultiImage = async (
  setUploadedImgUrls,
  sendDataImg,
  setImgUrls
) => {
  // console.log("'setLoading': setLoading", sendDataImg);
  sendDataImg.setLoading(true);
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
  };

  var formData = new FormData();
  sendDataImg["files"].map((value) => {
    // console.log(value)
    if (value !== "") {
      formData.append("files", value, value.name);
      // formData.append('files', value);

      formData.append("section_names", sendDataImg["section_names"]);
      return formData;
    }
  });

  // formData.append('emailId', sendDataImg['emailId']);
  formData.append("upload_type", sendDataImg["upload_type"]);
  formData.append("reportId", sendDataImg["reportId"]);
  // console.log(Object.fromEntries(formData.entries()))
  const resp = await fetch(URL.UploadMultiImage, {
    method: "POST",
    body: formData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      // console.log(data)
      setUploadedImgUrls(data.data);
      setImgUrls({});
    }
    sendDataImg.setLoading(false);
    sendDataImg.setSetP(true);
  }
};

//detect ai
export const detectAI = async (
  setAIData,
  pic_url,
  category,
  getImgAiDetectKeys,
  setImgAiDetectKeys,
  changeFormTemplateData,
  setLoading,
  _key_val
) => {
  // console.log(pic_url, category);
  setLoading(true);
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  let jsonData = JSON.stringify({
    pic_url: pic_url,
    category: category,
  });

  const resp = await fetch(URL.DetectAI, {
    method: "POST",
    body: jsonData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      // console.log(data)
      //13 - Personal Protective Equipment
      //25- Hazard Controls
      changeFormTemplateData([
        _key_val,
        [pic_url, data.data.status, data.data.confidence, data.data.threshold],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
      ]);
      setAIData(data.data);
      // setImgAiDetectKeys({});

      // console.log("hSSSHSHHSS", data.data.image_metadata)
    }
    setLoading(false);
  }
};

export const ijhaFormSubmit = async (
  getIjhaform,
  setLoading,
  setIjhaSubmit
) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  setLoading(true);

  let jsonData = JSON.stringify(getIjhaform);
  const resp = await fetch(URL.SubmitIJHA, {
    method: "POST",
    body: jsonData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      let Requires_Review = true;
      getIjhaform.form_template.filter(i => {
        if (i.sectionName === "Type of Work") {
          i.questions.map(j => {
            if (j.question === "Will any part of the work be done at height?" && j.answer === "Yes" && j.visibility) {
              Requires_Review = false;
            }
          });
        }
      });
      if (!Requires_Review) {
        setIjhaSubmit("Form submitted for approval.");
      } else {
        setIjhaSubmit("Form submitted successfully.");
      }

    } else {
      setIjhaSubmit(data.message);
    }
    setLoading(false);
  }
};

export const ijhaInCompleteFormSubmit = async (getIjhaform, setLoading, setSubmitStatusErr) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  setLoading(true);
  let jsonData = JSON.stringify(getIjhaform);
  const resp = await fetch(URL.SubmitIJHA, {
    method: "POST",
    body: jsonData,
    headers: headersList,
  });
  const data = await resp.json();
  // setTimeout(()=> {setLoading(false);},1000)
  if (data.status_code !== 200) {
    setSubmitStatusErr(data.message);
  }
  setLoading(false);
};

export const getFormIjhaApi = async (formId, getLoc, setLoading, setErrMsg) => {
  headers.set("Authorization", "Bearer " + TOKEN());
  setLoading(true);
  // console.log(getLoc, EMP_ID)
  const resp = await fetch(
    URL.GetFrom +
    `?latitude=${getLoc.lat}&longitude=${getLoc.lng}&formId=${formId}&Source=webapp`,
    // URL.GetFrom + `?employeeId=${EMP_ID}&latitude=${getLoc.lat}&longitude=${getLoc.lng}&formId=10&Source=webapp`,
    { method: "get", headers: headers }
  );
  let data = {};
  if (resp.ok) {
    data = await resp.json();
    if (data.status_code === 200) {
      // console.log(data)
      // setIjhaform(data.data);
    } else {
      setErrMsg(data.message);
    }
    setLoading(false);
  }
  return data;
};

export const getLevelDataApi = async (
  setLevelData,
  setCheckUSA,
  getLatitude,
  getLongitude,
  setTraumaCenterName,
  setLoading,
  setErrMsg
) => {
  headers.set("Authorization", "Bearer " + TOKEN());
  setLoading(true);
  const resp = await fetch(URL.TraumaCentreLevels, {
    method: "get",
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      setLevelData(data.data);
      await getTraumaCenterNameApi(
        setTraumaCenterName,
        setCheckUSA,
        getLatitude,
        getLongitude,
        data.data.default_levels,
        setLoading,
        setErrMsg
      );
    }
    setErrMsg(data.message);
    setLoading(false);
  }
};
export const getTraumaCenterNameApi = async (
  setTraumaCenterName,
  setCheckUSA,
  getLatitude,
  getLongitude,
  getLevels,
  setLoading,
  setErrMsg
) => {
  headers.set("Authorization", "Bearer " + TOKEN());
  setLoading(true);
  const resp = await fetch(
    URL.TraumaCentreNames +
    `?latitude=${getLatitude}&longitude=${getLongitude}&tlevel=${getLevels}`,
    { method: "get", headers: headers }
  );
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      if (data.data.length !== 0) {
        let _getTraumaCenterName = data.data.map((obj) => {
          obj["_Distance"] = Number(_.split(obj["Distance"], " ")[0]);
          obj["_Duration"] = Number(_.split(obj["Duration"], " ")[0]);
          return obj;
        });
        _getTraumaCenterName = _.orderBy(
          _getTraumaCenterName,
          ["_Duration"],
          ["asc"]
        );

        setTraumaCenterName(_getTraumaCenterName);
      } else {
        const resp = await fetch(
          URL.TraumaCentreCurrentLocation +
          `?latitude=${getLatitude}&longitude=${getLongitude}&type=hospital&radius=20000`,
          { method: "GET", headers: headers }
        );
        setCheckUSA(true);
        if (resp.ok) {
          const data = await resp.json();
          if (data.status_code === 200) {
            let _getTraumaCenterName = [];
            let _getTraumaCenterDetail = data.data.results.map((obj, k) => {
              // obj["_Distance"] = Number(_.split(obj["Distance"], ' ')[0])
              // obj["_Duration"] = Number(_.split(obj["Duration"], ' ')[0])
              // return obj;
              _getTraumaCenterName.push({
                ADDRESS: obj.vicinity,
                CITY: "",
                COUNTRY: "",
                Distance: "",
                Duration: "",
                LATITUDE: obj.geometry.location.lat,
                LONGITUDE: obj.geometry.location.lng,
                NAME: obj.name,
                STATE: "",
                TELEPHONE: "",
                TRAUMA: k + 1,
                ZIP: "",
              });
            });
            // _getTraumaCenterName = _.orderBy(_getTraumaCenterName, ['_Duration'], ['asc']);

            setTraumaCenterName(_getTraumaCenterName);
          }
        }
      }
    }
    setErrMsg(data.message);
    setLoading(false);
  }
};

export const getAddCrewMemberApi = async (
  setCrewLeadData,
  setLoading,
  setErrMsg
) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    // "Content-Type": "application/json"
  };
  setLoading(true);
  // JSON.stringify({ "crewleadId": EMP_ID.replace(/['"]+/g, '') })
  const resp = await fetch(URL.GetAddCrewMember, {
    method: "GET",
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      var ciphertext = JSON.stringify(data.data).toString();
      setCrewLeadData(data.data);
    } else {
      setErrMsg({ cls: "danger", msg: data.message });
    }
    setLoading(false);
  }
};

export const addCrewMemberApi = async (
  getCrewFormData,
  setLoading,
  setErrMsg
) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  setLoading(true);
  let jsonData = JSON.stringify({
    // "crewleadId": EMP_ID.replace(/['"]+/g, ''),
    crewmemberdata: getCrewFormData,
  });
  const resp = await fetch(URL.GetAddCrewMember, {
    method: "POST",
    body: jsonData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      var ciphertext = JSON.stringify(getCrewFormData).toString();
      // console.log(data)
      setErrMsg({ cls: "success", msg: data.message });
    } else {
      setErrMsg({ cls: "danger", msg: data.message });
    }
    setLoading(false);
  }
};

export const getHospitalDirectionFromToApi = async (
  setForm_template,
  form_template,
  param,
  getFullAdd,
  setLoading
) => {
  // const pageIndexofEmergency = form_template.findIndex(item => (item.sectionName === 'Emergency Action Plan - EAP'))

  // const IndexofDescriptionHospital = form_template[pageIndexofEmergency].questions.findIndex(question => (question.questionType === 'Description+Hospital'))
  // const IndexofDescriptionToDirection = form_template[pageIndexofEmergency].questions.findIndex(question => (question.questionType === 'Description+ToDirection'))
  // const IndexofDescriptionFromDirection = form_template[pageIndexofEmergency].questions.findIndex(question => (question.questionType === 'Description+FromDirection'))

  // { console.log("SAISIAIQOOOOOOOO", IndexofDescriptionHospital, IndexofDescriptionToDirection, IndexofDescriptionFromDirection) }
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    // "Content-Type": "application/json"
  };
  setLoading(true);

  const resp = await fetch(
    URL.GetHospitalDirectionFromTo +
    `?latitude=${param.lat}&longitude=${param.lng}&hosp_lat=${param.hosp_lat}&hosp_long=${param.hosp_lng}`,
    { method: "GET", headers: headersList }
  );
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      const _form_template = cloneDeep(form_template);
      const questionTypesToFill = [
        "Description+ToDirection",
        "Description+FromDirection",
      ];
      form_template.forEach((userForm, userFormIndex) => {
        if (userForm.questions) {
          userForm.questions.forEach((question, questionIndex) => {
            if (
              question.questionType === "Description+Hospital" &&
              getFullAdd
            ) {
              _form_template[userFormIndex].questions[questionIndex].answer =
                getFullAdd;
              const coordinates = getFullAdd.match(
                /\((-?\d+\.\d+),(-?\d+\.\d+)\)/
              );
              if (coordinates && coordinates.length === 3) {
                const lat = Number(coordinates[1]);
                const long = Number(coordinates[2]);
                _form_template[userFormIndex].questions[questionIndex].lat =
                  JSON.stringify(lat);
                _form_template[userFormIndex].questions[questionIndex].long =
                  JSON.stringify(long);
              }
            } else if (questionTypesToFill.includes(question.questionType)) {
              const dataInResponse = data.data.find(
                (answer) => answer.questionType === question.questionType
              );
              if (!dataInResponse) {
                return;
              }
              Object.entries(dataInResponse).forEach(([key, value]) => {
                let refinedValue = value;
                if (key === "lat" && getFullAdd) {
                  refinedValue = Number(getFullAdd.split("(")[1].split(",")[0]);
                } else if (key === "long" && getFullAdd) {
                  refinedValue = Number(
                    getFullAdd.split("(")[1].split(",")[1].split(")")[0]
                  );
                }
                _form_template[userFormIndex].questions[questionIndex][key] =
                  refinedValue;
                _form_template[userFormIndex].questions[questionIndex].lat =
                  JSON.stringify(param.hosp_lat);
                _form_template[userFormIndex].questions[questionIndex].long =
                  JSON.stringify(param.hosp_lng);
              });
            }
          });
        } else {
          userForm.section.forEach((section, sectionIndex) => {
            section.questions.forEach((question, questionIndex) => {
              if (
                question.questionType === "Description+Hospital" &&
                getFullAdd
              ) {
                _form_template[userFormIndex].section[sectionIndex].questions[
                  questionIndex
                ].answer = getFullAdd;
                const coordinates = getFullAdd.match(
                  /\((-?\d+\.\d+),(-?\d+\.\d+)\)/
                );
                if (coordinates && coordinates.length === 3) {
                  const lat = Number(coordinates[1]);
                  const long = Number(coordinates[2]);
                  _form_template[userFormIndex].section[sectionIndex].questions[
                    questionIndex
                  ].lat = JSON.stringify(lat);
                  _form_template[userFormIndex].section[sectionIndex].questions[
                    questionIndex
                  ].long = JSON.stringify(long);
                }
              } else if (questionTypesToFill.includes(question.questionType)) {
                const dataInResponse = data.data.find(
                  (answer) => answer.questionType === question.questionType
                );
                if (!dataInResponse) {
                  return;
                }
                Object.entries(dataInResponse).forEach(([key, value]) => {
                  let refinedValue = value;
                  if (key === "lat" && getFullAdd) {
                    refinedValue = Number(
                      getFullAdd.split("(")[1].split(",")[0]
                    );
                  } else if (key === "long" && getFullAdd) {
                    refinedValue = Number(
                      getFullAdd.split("(")[1].split(",")[1].split(")")[0]
                    );
                  }
                  _form_template[userFormIndex].section[sectionIndex].questions[
                    questionIndex
                  ][key] = refinedValue;
                  _form_template[userFormIndex].questions[questionIndex].lat =
                    JSON.stringify(param.hosp_lat);
                  _form_template[userFormIndex].questions[questionIndex].long =
                    JSON.stringify(param.hosp_lng);
                });
              }
            });
          });
        }
      });

      setForm_template(_form_template);

      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionHospital].answer = getFullAdd;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionHospital].lat = Number(getFullAdd.split('(')[1].split(',')[0]);
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionHospital].long = Number(getFullAdd.split('(')[1].split(',')[1].split(')')[0]);

      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionToDirection].answer = data.data[0].answer;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionToDirection].distance = data.data[0].distance;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionToDirection].duration = data.data[0].duration;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionToDirection].lat = Number(getFullAdd.split('(')[1].split(',')[0]);
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionToDirection].long = Number(getFullAdd.split('(')[1].split(',')[1].split(')')[0]);

      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionFromDirection].answer = data.data[1].answer;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionFromDirection].distance = data.data[1].distance;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionFromDirection].duration = data.data[1].duration;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionFromDirection].lat = Number(getFullAdd.split('(')[1].split(',')[0]);
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionFromDirection].long = Number(getFullAdd.split('(')[1].split(',')[1].split(')')[0]);

      // { console.log("SAISIAIQOOOOOOOO", _form_template[pageIndexofEmergency]) }

      // setForm_template(_form_template);
    }
    setLoading(false);
  }
};

export const get911AddressInfoApi = async (
  setForm_template,
  form_template,
  param,
  setLoading
) => {
  // const pageIndexofEmergency = form_template.findIndex(item => (item.sectionName === 'Emergency Action Plan - EAP'))
  // const pageIndexofGeneral = form_template.findIndex(item => (item.sectionName === 'General Site Information'))
  // const IndexofDescriptionLocation = form_template[pageIndexofEmergency].questions.findIndex(question => (question.questionType === 'Description+Location'))
  // const IndexofDescriptionHospital = form_template[pageIndexofEmergency].questions.findIndex(question => (question.questionType === 'Description+Hospital'))
  // const IndexofDescriptionToDirection = form_template[pageIndexofEmergency].questions.findIndex(question => (question.questionType === 'Description+ToDirection'))
  // const IndexofDescriptionFromDirection = form_template[pageIndexofEmergency].questions.findIndex(question => (question.questionType === 'Description+FromDirection'))
  // const IndexofDescriptionFirestation = form_template[pageIndexofEmergency].questions.findIndex(question => (question.questionType === 'Description+Firestation'))
  // const IndexofDescriptionPolicestation = form_template[pageIndexofEmergency].questions.findIndex(question => (question.questionType === 'Description+Policestation'))
  // console.log("QIWWWWWWWWWWWWW1", IndexofDescriptionLocation, IndexofDescriptionFirestation, IndexofDescriptionPolicestation, IndexofDescriptionHospital, IndexofDescriptionToDirection, IndexofDescriptionFromDirection)

  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    // "Content-Type": "application/json"
  };

  setLoading(true);
  const resp = await fetch(
    URL.Get911AddressInfo +
    `?latitude=${Number(param.lat)}&longitude=${Number(param.lng)}`,
    { method: "GET", headers: headersList }
  );
  if (resp.ok) {
    const data = await resp.json();

    if (data.status_code === 200) {
      const _form_template = cloneDeep(form_template);
      const questionTypesToFill = [
        "Description+Location",
        "Description+Hospital",
        "Description+ToDirection",
        "Description+FromDirection",
        "Description+Firestation",
        "Description+Policestation",
      ];
      form_template.forEach((userForm, userFormIndex) => {
        if (userForm.questions) {
          userForm.questions.forEach((question, questionIndex) => {
            if (questionTypesToFill.includes(question.questionType)) {
              const dataInResponse = data.data.find(
                (answer) => answer.questionType === question.questionType
              );
              if (!dataInResponse) return;
              Object.entries(dataInResponse).forEach(([key, value]) => {
                _form_template[userFormIndex].questions[questionIndex][key] =
                  (key === "lat" ||
                    key === "long" ||
                    (key === "long" && param.hasOwnProperty("lng"))) &&
                    !value
                    ? JSON.stringify(
                      Number(param[key === "long" ? "lng" : key])
                    )
                    : value;
              });
            }
          });
        } else {
          userForm.section.forEach((section, sectionIndex) => {
            section.questions.forEach((question, questionIndex) => {
              if (questionTypesToFill.includes(question.questionType)) {
                const dataInResponse = data.data.find(
                  (answer) => answer.questionType === question.questionType
                );
                if (!dataInResponse) return;
                Object.entries(dataInResponse).forEach(([key, value]) => {
                  _form_template[userFormIndex].section[sectionIndex].questions[
                    questionIndex
                  ][key] =
                    (key === "lat" || key === "long") && !value
                      ? Number(param[key])
                      : value;
                });
              }
            });
          });
        }
      });

      setForm_template(_form_template);

      // let _form_template = _.cloneDeep(form_template);

      // //evacuation point
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionLocation].answer = data.data[0].answer;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionLocation].lat = data.data[0].lat !== '' ? data.data[0].lat : Number(param.lat);
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionLocation].long = data.data[0].long !== '' ? data.data[0].long : Number(param.lng);
      // //911

      // _form_template[pageIndexofGeneral].questions[5].answer = data.data[0].answer;
      // _form_template[pageIndexofGeneral].questions[5].lat = data.data[0].lat;
      // _form_template[pageIndexofGeneral].questions[5].long = data.data[0].long;

      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionHospital].answer = data.data[1].answer;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionHospital].lat = data.data[1].lat !== '' ? data.data[1].lat : Number(param.lat);
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionHospital].long = data.data[1].long !== '' ? data.data[1].long : Number(param.lng);

      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionToDirection].answer = data.data[2].answer;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionToDirection].distance = data.data[2].distance;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionToDirection].duration = data.data[2].duration;

      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionFromDirection].answer = data.data[3].answer;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionFromDirection].distance = data.data[3].distance;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionFromDirection].duration = data.data[3].duration;

      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionFirestation].answer = data.data[4].answer;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionFirestation].lat = data.data[4].lat !== '' ? data.data[4].lat : Number(param.lat);
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionFirestation].long = data.data[4].long !== '' ? data.data[4].long : Number(param.lng);
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionFirestation].distance = data.data[4].distance;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionFirestation].duration = data.data[4].duration;

      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionPolicestation].answer = data.data[5].answer;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionPolicestation].lat = data.data[5].lat !== '' ? data.data[5].lat : Number(param.lat);
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionPolicestation].long = data.data[5].long !== '' ? data.data[5].long : Number(param.lng);
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionPolicestation].distance = data.data[5].distance;
      // _form_template[pageIndexofEmergency].questions[IndexofDescriptionPolicestation].duration = data.data[5].duration;

      setForm_template(_form_template);
      return _form_template;
    }

    setLoading(false);
  }
};

//detect ai-all
export const detectAIALL = async (pic_url, setLoading) => {
  // console.log(pic_url);
  setLoading(true);
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  let jsonData = JSON.stringify({
    pic_url: pic_url,
  });

  const resp = await fetch(URL.DetectAIALL, {
    method: "POST",
    body: jsonData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      // console.log(data)
      setLoading(false);
      return data;
    }
    setLoading(false);
  }
};

//delete image from blob storage on cancelling the image
export const deleteImageFromBlob = async (report_Id) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  let jsonData = JSON.stringify({
    blob_url: report_Id,
  });

  const resp = await fetch(URL.DeleteImageFromBlob, {
    method: "DELETE",
    body: jsonData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      console.log("Image deletion on blog storage is ", data.message);
      // setLoading(false);
      return;
    } else if (data.status_code === 401) {
      console.log(data.message);
    } else {
      console.log(data.message);
    }
    //setLoading(false);
  }
};
