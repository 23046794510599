import { Form } from "react-bootstrap";

export default function Radio_Image_Document({
  questions_obj,
  onChangeFormTemplate,
  sectionId,
  getUploadSingleImage,
}) {
  return (
    questions_obj.visibility && (
      <div key={`inline-radio-${questions_obj.questionId}`} className="mb-3">
        <Form.Label>
          <span className="text-danger bold-font">
            {questions_obj.state === "Required" ? "*" : ""}
          </span>
          {questions_obj.question}
        </Form.Label>
        <br />

        {questions_obj.hasOwnProperty("radio_options") &&
          questions_obj.radio_options.values.map((obj_v, i) => {
            // console.log(questions_obj.questionId, ' :', questions_obj.answer === obj_v, questions_obj.answer, obj_v, 'radio')
            return (
              <Form.Check
                inline
                label={obj_v}
                name={`group-${questions_obj.questionId}`}
                type="radio"
                className="mb-3"
                id={`inline-radio-${i}-${questions_obj.questionId}`}
                defaultChecked={questions_obj.answer === obj_v}
                required={questions_obj.state === "Required" ? true : false}
                onClick={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: obj_v,
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                }}
              />
            );
          })}

        {questions_obj.imageUpload && (
          <Form.Control
            className="rounded-0 custom-attachment-input mb-3"
            type="file"
            onChange={(e) => {
              getUploadSingleImage([
                e.target.files[0],
                sectionId,
                questions_obj.questionId,
              ]);
            }}
          />
        )}
        {questions_obj.picture.length !== 0 && (
          <Form.Label className="mt-2 mb-3">
            <b>{questions_obj.picture !== "" && "1 File Uploaded"}</b>
          </Form.Label>
        )}

        {questions_obj.hasOwnProperty("description_model")
          ? questions_obj.description_model[0].visible && (
              <Form.Control
                className="rounded-0 mb-3"
                as="textarea"
                rows={5}
                value={questions_obj.description_model[0].text}
                onChange={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    desc_text: e.target.value,
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                }}
              />
            )
          : null}
      </div>
    )
  );
}
