import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import _ from "lodash";

export default function MultiselectDropDown({ getAllFormsData, setFormId }) {
  const [btnClick, setBtnCLick] = useState(false);
  const [selected, setSelected] = useState([]);
  const [getOption, setOption] = useState([]);
  // const [selected, setSelected] = useState([
  //     { label: "Ericsson Web iJHA", value: "10" },
  //     { label: "PPE Submission", value: "14" },

  // ]);

  // console.log("EQWUEYQWYEQWETQ1111", getAllFormsData)
  if (_.isEmpty(selected)) {
    let _setSelected = [];
    getAllFormsData.map((i) =>
      _setSelected.push({ label: i.form_name, value: i.formId })
    );
    setSelected(_setSelected);
    setOption(_setSelected);
  }

  const options = getOption;
  // const options = [
  //     { label: "Ericsson Web iJHA", value: "10" },
  //     { label: "PPE Submission", value: "14" },

  // ];

  const overrideStrings = {
    allItemsAreSelected: "All",
    noOptions: "No Option is Selected",
  };

  const onChange = (values) => {
    setSelected(values);
    setBtnCLick(true);
    const filterData = values.map((i) => i.value);
    setFormId(filterData);
  };

  window.addEventListener("click", function (e) {
    if (document.querySelectorAll(".dropdown-container").length !== 0) {
      if (!document.querySelector(".dropdown-container").contains(e.target)) {
        setBtnCLick(false);
      }
    }
  });

  return (
    <div className="multiSelectCheckBox">
      <MultiSelect
        className="multiSelectDropDown"
        options={options}
        value={selected}
        onChange={(values) => onChange(values)}
        labelledBy="Select Options"
        disableSearch={true}
        hasSelectAll={true}
        overrideStrings={overrideStrings}
      />
    </div>
  );
}
