import { useState } from "react";
import { Container, Row, Col, Alert, Form, Button } from "react-bootstrap";
import CrewMemberPPE from "../../components/CrewMember/CrewMemberPPE";
import Loading from "../../components/Loading/Loading";
import _ from "lodash";
import { useEffect } from "react";
import {
  getAddCrewMemberApi,
  addCrewMemberApi,
} from "../../api/CreateiJHA.api";
const AddCrewMemberPPE = ({ getIJHForm, getCrewData }) => {
  const [getKey, setKey] = useState(0);
  const [getLoading, setLoading] = useState(false);
  const [getErrMsg, setErrMsg] = useState({ msg: "", cls: "" });
  const [getCrewLeadData, setCrewLeadData] = useState({
    crewmemberdata: [],
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
  });
  const [getCrewFormData, setCrewFormData] = useState([
    {
      emailId: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
    },
  ]);
  const addCrewMember = () => {
    let _getCrewFormData = _.cloneDeep(getCrewFormData);
    _getCrewFormData[_getCrewFormData.length] = {
      emailId: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
    };
    setCrewFormData(_getCrewFormData);
    setKey(_getCrewFormData.length);
  };
  const deleteCrewMember = (_key) => {
    let _getCrewFormData = getCrewFormData.filter((obj, i) => i !== _key);
    setCrewFormData(_getCrewFormData);
    setKey(_getCrewFormData.length);
  };
  const crewMemberChangeHandler = (_key, _s_key, _value) => {
    let _getCrewFormData = _.cloneDeep(getCrewFormData);
    _getCrewFormData[_key][_s_key] = _value;
    setCrewFormData(_getCrewFormData);
    setKey(_getCrewFormData.length);
  };
  useEffect(() => {
    getAddCrewMemberApi(setCrewLeadData, setLoading, setErrMsg);
    if (getCrewData !== undefined) {
      getCrewData.crewmemberdata.forEach((object) => {
        if (!("phoneNumber" in object) && !("emailId" in object)) {
          object.phoneNumber = "";
        }
      });
      if (_.isEmpty(getCrewLeadData.crewmemberdata)) {
        let _getCrewLeadData = _.cloneDeep(getCrewData);
        setKey(_getCrewLeadData.crewmemberdata.length);
        setCrewLeadData(_getCrewLeadData.getCrewLeadData);
        setCrewFormData(_getCrewLeadData.crewmemberdata);
      }
    }
  }, []);
  useEffect(() => {
    getErrMsg.cls === "success" &&
      getAddCrewMemberApi(setCrewLeadData, setLoading, setErrMsg);
    if (getCrewData !== undefined) {
      getErrMsg.cls === "success" && getIJHForm(getCrewFormData);
    }
  }, [getErrMsg]);
  // shadow-lg p-3 mb-5 rounded
  const saveCrewMember = () => {
    addCrewMemberApi(getCrewFormData, setLoading, setErrMsg);
  };
  const proceedToIjha = async () => {
    if (getCrewData !== undefined) {
      let _getCrewLeadData = _.cloneDeep(getCrewFormData);
      let counter = false;
      let _getBlankData = _getCrewLeadData;
      _getBlankData = _getBlankData.filter((_blank) => {
        if (!("employeeId" in _blank)) {
          return (counter = true);
        }
      });

      if (counter) {
        _getCrewLeadData = _getCrewLeadData.filter(
          (obj) => !("employeeId" in obj)
        );
        await addCrewMemberApi(
          getCrewFormData[0].emailId !== "" ? _getCrewLeadData : "",
          setLoading,
          setErrMsg
        );
      } else {
        let res = await getIJHForm(getCrewFormData);
      }
    } else {
      setLoading(true);
      await addCrewMemberApi(
        getCrewFormData[0].emailId !== "" ? getCrewFormData : "",
        setLoading,
        setErrMsg
      );
      let _getCrewLeadData = _.cloneDeep(getCrewLeadData);
      _getCrewLeadData.crewmemberdata = getCrewFormData;
      const _getAddCrewMemberApi = await getAddCrewMemberApi(
        setCrewLeadData,
        setLoading,
        setErrMsg
      );
      const _crewEMPID = _getAddCrewMemberApi.crewmemberdata.map(
        (i) => i.employeeId
      );
      _getCrewLeadData.crewmemberdata.map(
        (i, k) => (i.employeeId = _crewEMPID[k])
      );
      setCrewLeadData(_getCrewLeadData);
      let res = await getIJHForm(_getCrewLeadData);
    }
  };
  return (
    <Container className="margin-container  ">
      <Row>
        <Col>
          <h4>Crew Details</h4>
        </Col>
      </Row>
      <Row xs={12} className="mb-5">
        <Form>
          <Row
            xs={12}
            className="m-1 p-3 d-flex justify-content-left shadow-lg rounded text-decoration-none"
          >
            <h5 className="mb-4">Crew Lead Details</h5>
            <Col lg={3} md={3} xs={12}>
              <Form.Group controlId="form-2-1" className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font">*</span>First Name
                </Form.Label>
                <Form.Control
                  className="rounded-0 hide-blinking-cursor"
                  type="text"
                  value={getCrewLeadData.firstName}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={3} md={3}>
              <Form.Group controlId="form-2-1" className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font">*</span>Last Name
                </Form.Label>
                <Form.Control
                  className="rounded-0 hide-blinking-cursor"
                  type="text"
                  value={getCrewLeadData.lastName}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={3} md={3}>
              <Form.Group controlId="form-2-1" className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font">*</span>Email ID
                </Form.Label>
                <Form.Control
                  className="rounded-0 hide-blinking-cursor"
                  type="text"
                  value={getCrewLeadData.email}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={3} md={3}>
              <Form.Group controlId="form-2-1" className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font"></span>Phone No.
                </Form.Label>
                <Form.Control
                  className="rounded-0 hide-blinking-cursor"
                  type="text"
                  value={getCrewLeadData.phone}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Row>

      <Row xs={12} className="shadow-lg m-1 p-3 mb-5 rounded ">
        <h5 className="mb-4">Add Crew Member</h5>

        <Form>
          {getCrewFormData.map((getCrewData, i) => (
            <CrewMemberPPE
              key={i}
              emailId={getCrewData.emailId}
              firstName={getCrewData.firstName}
              lastName={getCrewData.lastName}
              phoneNumber={getCrewData.phoneNumber}
              crewMemberChangeHandler={crewMemberChangeHandler}
              _key={i}
              deleteCrewMember={deleteCrewMember}
              getKey={getKey}
            />
          ))}
          <Row>
            <Col className="d-flex justify-content-center mb-3">
              <Button size={"sm"} disabled={getLoading} onClick={addCrewMember}>
                + Add More
              </Button>
            </Col>
          </Row>

          <Row>
            <Col className="d-flex justify-content-end">
              <Button
                className="ms-5"
                variant="success"
                disabled={getLoading}
                onClick={proceedToIjha}
              >
                Proceed
              </Button>

              {/* } */}
            </Col>
          </Row>
        </Form>
        {getLoading && (
          <Col
            xs={12}
            className="m-5 p-5 d-flex justify-content-center"
            style={{ cursor: "pointer" }}
          >
            <Loading width="32" height="32" />
          </Col>
        )}
      </Row>
    </Container>
  );
};
export default AddCrewMemberPPE;
