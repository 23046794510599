import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function Datepicker({
  selectedDate,
  setDate,
  placeholderText,
  setmaxDate,
}) {
  return (
    <DatePicker
      className="modal-form-1"
      placeholderText={placeholderText}
      selected={selectedDate}
      onChange={(date) => setDate(date)}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      todayButton="Today"
      isClearable
      maxDate={setmaxDate}
    />
  );
}
