import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Accordion,
  Button,
  Nav,
  Modal,
  NavDropdown,
} from "react-bootstrap";
import { NavLink, Link, useNavigate } from "react-router-dom";
import PrivacyPolicyModal from "../../components/PrivacyPolicy/PrivacyPolicy";
import { useState, useEffect } from "react";
import { getAllForms } from "../../api/Login.api";
import { getReports } from "../../api/Reports.api";
import { getFormIjhaApi, getAddCrewMemberApi } from "../../api/CreateiJHA.api";
import ManualGMap from "../../components/GMap/ManualGMap";
import Loading from "../../components/Loading/Loading";

import AddCrewMember from "../CrewMember/AddCrewMember";
import AddCrewMemberPPE from "../CrewMember/AddCrewMemberPPE";
import AddCrewMemberSite from "../CrewMember/AddCrewMemberSite";
import moment from "moment-timezone";
import { DECRYPTED_DATA, UserGroupId } from "../../utils/Variable";
import _ from "lodash";
import AddCrewMemberDynamic from "../CrewMember/AddCrewMemberDynamic";
import IjhaForm from "../Ijha_Dynamic/IjhaForm";
import { PrivacyCheck } from "../../api/PrivacyStatus.api";
export default function Home() {
  const [ShowDialogBox, setShowDialogBox] = useState(true);

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isEndUserChecked, setEndUserChecked] = useState(false);
  const handlePrivacyCheck = () => {
    PrivacyCheck(setShowDialogBox);
  };

  const navigate = useNavigate();
  const [getLoc, setLoc] = useState({ lat: "", lng: "" });
  const [getLoading, setLoading] = useState(false);
  const [getErrMsg, setErrMsg] = useState("");
  const [getErr, setErr] = useState("");
  const [showGMap, setShowGMap] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [getIsAddCrew, setIsAddCrew] = useState(false);
  const [getAllFormsData, setAllFormsData] = useState([]);
  const [getReportsData, setReportsData] = useState({});
  const [startDate, setStartDate] = useState(
    new Date(moment().tz("America/Chicago"))
  );
  const [endDate, setEndDate] = useState(
    new Date(moment().tz("America/Chicago"))
  );
  const [getRptAlert, setRptAlert] = useState(false);
  const [getInProgressCount, setInprogressCount] = useState();

  // Dynamic Form Selection code
  const [getForm, setForm] = useState({});
  const [getDynamicFormID, setDynamicFormID] = useState();
  const [getFormToNext, setFormToNext] = useState(false);
  const [getUserGroup, setUserGroup] = useState(parseInt(UserGroupId));
  const [getFormData, setFormData] = useState([]);
  const [getStatusData, setStatusData] = useState([]);
  const [getFormData1, setFormData1] = useState([]);
  const [getPolicyStatus, setPolicyStatus] = useState(
    JSON.parse(sessionStorage.getItem("Policy_Status"))
  );
  const [getEndUserStatement, setEndUserStatement] = useState(
    JSON.parse(sessionStorage.getItem("enduserStatement"))
  );

  useEffect(() => {
    const fetchData = async () => {
      if (
        getPolicyStatus !== null &&
        getPolicyStatus !== "" &&
        getPolicyStatus !== undefined &&
        getEndUserStatement !== null &&
        getEndUserStatement !== "" &&
        getEndUserStatement !== undefined
      ) {
        try {
          const formsData = await getAllForms(
            setAllFormsData,
            setLoading,
            setErrMsg,
            setStatusData
          );

          var _latlng;
          if (!_.isEmpty(formsData)) {
            const isLocationRequired = formsData.some(
              (entry) => entry.location_required
            );
            if (isLocationRequired && navigator.geolocation) {
              navigator.geolocation.getCurrentPosition((position) => {
                _latlng = _.cloneDeep(getLoc);
                _latlng.lat = position.coords.latitude;
                _latlng.lng = position.coords.longitude;
                setLoc(_latlng);
              });
            }
          }
        } catch (error) { }
      }
    };

    setIsCheckboxChecked(JSON.parse(sessionStorage.getItem("Policy_Status")));
    setEndUserChecked(JSON.parse(sessionStorage.getItem("enduserStatement")));

    fetchData();
  }, [getPolicyStatus, getEndUserStatement]); // Only trigger the effect when getPolicyStatus change

  useEffect(() => {
    let param = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).add(1, "day").format("YYYY-MM-DD"),
      status: ["5"],
      formId: getAllFormsData.map((i) => i.formId),
    };
    getReports(param, setLoading, setErr, setReportsData);
  }, [getAllFormsData, getStatusData]);

  useEffect(() => {
    if (!_.isEmpty(getReportsData)) {
      let reportData = JSON.parse(getReportsData);
      Object.values(reportData.Status).includes("In Progress") &&
        setRptAlert(true);
      setInprogressCount(Object.values(reportData.Status).length);
    }
  }, [getReportsData]);

  useEffect(() => {
    const _getFormData = getAllFormsData.filter(
      (i) => parseInt(i.formId) === getDynamicFormID
    );
    setFormData(_getFormData);
  }, [getDynamicFormID]);

  const handleCloseModal = () => {
    setShowGMap(false);
  };

  const handleLocationSelection = (latlng) => {
    setSelectedLocation(latlng);
    setLoc(latlng);
    sessionStorage.setItem("selectedLocation", JSON.stringify(latlng));
  };

  useEffect(() => {
    const savedLocation = sessionStorage.getItem("selectedLocation");
    if (savedLocation) {
      const latlng = JSON.parse(savedLocation);
      handleLocationSelection(latlng);
    }
  }, []);

  const getIJHForm = async (_getCrewLeadData) => {
    const _getFormData = await getFormIjhaApi(
      getDynamicFormID,
      getLoc,
      setLoading,
      setErrMsg
    );
    setForm(_getFormData.data);

    if (
      _getCrewLeadData.crewmemberdata.filter((i) => i.employeeId).length === 0
    ) {
      _getCrewLeadData.crewmemberdata = [];
    } else {
      _getCrewLeadData.crewmemberdata = _getCrewLeadData.crewmemberdata.filter(
        (i) => i.employeeId !== "" && i.employeeId !== undefined
      );
    }

    let _getForm = _.cloneDeep(_getFormData.data);
    _getForm.form_template = _getForm.form_template.map((obj) => {
      if (!_.isEmpty(obj.section)) {
        // consolelog(obj, ':section:')
        for (
          let i = 0;
          i < _getCrewLeadData.crewmemberdata.filter((i) => i.emailId).length;
          i++
        ) {
          obj.section[obj.section.length] = _.cloneDeep(obj.section[0]);
        }
      }
      return obj;
    });

    let _checkClimbMember = _getCrewLeadData.crewmemberdata.map(
      (i, k) => i.membertype
    );
    let _checkClimber = [_getCrewLeadData.membertype, ..._checkClimbMember];

    _getForm.form_template.map((obj) => {
      if (obj.sectionName === "Personal Protective Equipment") {
        obj.section.map((s_obj, k) => {
          s_obj.questions.map((ss_obj) => {
            if ("image_metadata" in ss_obj && _checkClimber[k] === "Climber") {
              ss_obj.state = "Required";
            }
          });
        });
      }
    });
  };

  const getFormTemplate = async (
    formID,
    locationconfig,
    CrewMember_required,
    defaultlocation
  ) => {
    const isCrewMemberRequired = CrewMember_required === "Y";
    const isFormIdValid = locationconfig === false;

    const _getFormData = getAllFormsData.filter(
      (i) => parseInt(i.formId) === formID
    );
    const commonState = {
      formID,
      reportIJHA: false,
      getFormData: _getFormData,
    };

    const commonNavigate = (path, additionalState) => {
      navigate(path, { state: { ...commonState, ...additionalState } });
      setIsAddCrew(isCrewMemberRequired);
      setFormToNext(true);
      setDynamicFormID(formID);
    };

    if (isCrewMemberRequired) {
      if (isFormIdValid) {
        setSelectedLocation({
          lat: defaultlocation.lat,
          lng: defaultlocation.long,
        });
        commonNavigate("/create-DynamicIJHA", {
          getLoc: { lat: defaultlocation.lat, lng: defaultlocation.long },
        });
      } else {
        if (getLoc.lat !== "" && getLoc.lng !== "") {
          commonNavigate("/create-DynamicIJHA", { getLoc });
        } else {
          setShowGMap(true);
        }
      }
    } else {
      const _getFormData1 = getAllFormsData.filter(
        (i) => parseInt(i.formId) === formID
      );
      setFormData1(_getFormData1);
      if (isFormIdValid) {
        setSelectedLocation({
          lat: defaultlocation.lat,
          lng: defaultlocation.long,
        });
        setFormToNext(true);
        const _getForm = await getFormIjhaApi(
          formID,
          { lat: defaultlocation.lat, lng: defaultlocation.long },
          setLoading,
          setErrMsg
        );
        if (_.isEmpty(_getForm.data)) {
          setErrMsg(_getForm.message);
        } else {
          setErrMsg("");
          navigate("/create-SiteInspection", {
            state: {
              SiteInspectionForm: _getForm.data,
              getLoc: { lat: defaultlocation.lat, lng: defaultlocation.long },
              setRequired: false,
              getFormData1: _getFormData1,
            },
          });
        }
      } else {
        if (getLoc.lat !== "" && getLoc.lng !== "") {
          setFormToNext(true);
          const _getForm = await getFormIjhaApi(
            formID,
            getLoc,
            setLoading,
            setErrMsg
          );
          if (_.isEmpty(_getForm.data)) {
            setErrMsg(_getForm.message);
          } else {
            setErrMsg("");
            navigate("/create-SiteInspection", {
              state: {
                SiteInspectionForm: _getForm.data,
                getLoc: getLoc,
                setRequired: false,
                getFormData1: _getFormData1,
              },
            });
          }
        } else {
          setShowGMap(true);
        }
      }
    }
  };

  return (
    <Container className="margin-container">
      {/* {getErrormessage!==''?
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                {getErrormessage}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>:null} */}

      {((JSON.parse(sessionStorage.getItem("Policy_Status")) === false) || (JSON.parse(sessionStorage.getItem("enduserStatement")) === false)) ? (
        <PrivacyPolicyModal
          isCheckboxChecked={isCheckboxChecked}
          setIsCheckboxChecked={setIsCheckboxChecked}
          isEndUserChecked={isEndUserChecked}
          setEndUserChecked={setEndUserChecked}
          show={ShowDialogBox}
          onHide={() => setShowDialogBox(false)}
          onPrivacyCheck={handlePrivacyCheck}
          policyLink={DECRYPTED_DATA().policy_link}
          enduserStatementURL={DECRYPTED_DATA().enduserStatementURL}
        />
      ) : null}
      {showGMap && (
        <ManualGMap
          center={0}
          setLatLng911={handleLocationSelection}
          showModal={showGMap}
          closeModal={handleCloseModal}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
        />
      )}
      {getRptAlert && !getIsAddCrew && (
        <div className="inProgress">
          <Alert
            variant="success"
            onClose={() => setRptAlert(false)}
            dismissible
          >
            <div
              onClick={() =>
                navigate("/reports", { state: { getStatusOption: ["5"] } })
              }
            >
              You have{" "}
              <span className="inProgressCount">{getInProgressCount}</span> In
              Progress Form.
            </div>
            <div className="shadowAnimation scale"></div>
          </Alert>
        </div>
      )}

      {getErrMsg !== "" && (
        <Row className="d-flex justify-content-center">
          <Col xs={12} lg={6} md={6}>
            <Alert variant="danger" onClose={() => setErrMsg("")} dismissible>
              {getErrMsg}
            </Alert>
          </Col>
        </Row>
      )}

      {!getIsAddCrew ? (
        <>
          {!getFormToNext && (
            <Row xs={12}>
              {getUserGroup !== 1 && !_.isEmpty(getAllFormsData) ? (
                getAllFormsData.map((i, k) => {
                  return (
                    <Col
                      key={k}
                      onClick={() => {
                        getFormTemplate(
                          parseInt(i.formId),
                          i.location_required,
                          i.crew_member_required,
                          i.default_location
                        );
                      }}
                      xs={9}
                      lg={3}
                      md={4}
                      className="m-5 pt-5 ps-4 pe-4 pb-5 d-flex justify-content-center shadow-lg rounded text-decoration-none"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="p-3">
                        <h4>{i.form_name}</h4>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <>
                  {!_.isEmpty(getAllFormsData) && (
                    <>
                      {getAllFormsData.map((i, k) => {
                        if (k !== 0) {
                          return (
                            <Col
                              key={k}
                              onClick={() => {
                                getFormTemplate(
                                  parseInt(i.formId),
                                  i.crew_member_required
                                );
                              }}
                              xs={9}
                              lg={3}
                              md={4}
                              className="m-5 pt-5 ps-4 pe-4 pb-5 d-flex justify-content-center shadow-lg rounded text-decoration-none"
                              style={{ cursor: "pointer" }}
                            >
                              <div className="p-3">
                                <h4>{i.form_name}</h4>
                              </div>
                            </Col>
                          );
                        }
                      })}
                      {/* <Col
                                                onClick={() => {
                                                    getFormTemplate(parseInt(getAllFormsData[1].formId));
                                                }
                                                }
                                                xs={9} lg={3} md={4} className="m-5 pt-5 ps-4 pe-4 pb-5 d-flex justify-content-center shadow-lg rounded text-decoration-none"
                                                style={{ cursor: "pointer" }}>
                                                <div className="p-3" >
                                                    <h4>{getAllFormsData[1].form_name}</h4>
                                                </div>
                                            </Col> */}
                      <Accordion
                        defaultActiveKey={[0]}
                        alwaysOpen
                        className="mb-3"
                      >
                        <Accordion.Item eventKey={0}>
                          <Accordion.Header className="accordHeader">
                            Optional Forms
                          </Accordion.Header>
                          <Accordion.Body>
                            <Row xs={12}>
                              <Col
                                onClick={() => {
                                  getFormTemplate(
                                    parseInt(getAllFormsData[0].formId),
                                    getAllFormsData[0].crew_member_required
                                  );
                                }}
                                xs={9}
                                lg={3}
                                md={4}
                                className="m-5 pt-5 ps-4 pe-4 pb-5 d-flex justify-content-center shadow-lg rounded text-decoration-none"
                                style={{ cursor: "pointer" }}
                              >
                                <div className="p-3">
                                  <h4>{getAllFormsData[0].form_name}</h4>
                                </div>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  )}
                </>
              )}
            </Row>
          )}
        </>
      ) : (
        <>
          {/* <AddCrewMemberPPE getIJHForm={getIJHForm} /> */}

          {getDynamicFormID !== undefined && (
            <>
              {getDynamicFormID === 14 ? (
                <AddCrewMemberPPE getIJHForm={getIJHForm} />
              ) : (
                <AddCrewMember getIJHForm={getIJHForm} />
              )}
            </>
          )}
        </>
      )}

      {!getLoading && !getIsAddCrew && (
        <Button className="btnSupport btn-sm btn-dark">
          <NavDropdown.Item as={NavLink} to="/support-form">
            Contact Support
          </NavDropdown.Item>
        </Button>
      )}

      {getLoading && (
        <Col
          xs={12}
          className="m-5  p-5 d-flex justify-content-center "
          style={{ cursor: "pointer" }}
        >
          <Loading width="32" height="32" />
        </Col>
      )}
    </Container>
  );
}
