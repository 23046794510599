import { Row, Col, Accordion, Badge } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import trashcan from "../../assets/Icons/trashcan.svg";
import { EMP_ID } from "../../utils/Variable";
import { getAddCrewMemberApi } from "../../api/CreateiJHA.api";
import _ from "lodash";
import close from "../../assets/Icons/close.svg";
export default function SummaryforPpeSubmission({
  form_template,
  setForm_template,
  setCrewLeadData,
  setDelCrew,
  setIsCompleted,
  getIsCompleted,
  profile_img_url,
  getUserName,
  check,
  setProceed1,
  setProceed,
  setCrewMemberProceed,
  getCrewMemberProceed,
  getCrewLeadData,
  setPFASOptions,
  setpbtn,
  getFormID,
  setCrewfill,
}) {
  const [getCount, setCount] = useState([]);
  const [getCount1, setCount1] = useState([]);
  const crewleadId = EMP_ID().replace(/['"]+/g, "");
  let crewMemberId = getCrewLeadData.crewmemberdata.map(
    (obj) => obj.employeeId
  );
  const [getCrewMemberCls, setCrewMemberCls] = useState([]);

  useEffect(() => {
    let _getCount = [];
    let _getCount1 = [];
    _getCount["crewMembers"] = [];
    _getCount[crewleadId] = form_template.map((obj) => {
      let _fillcount = 0;
      let _totalcount = 0;
      switch (obj.sectionId) {
        case "1":
          if (
            form_template[0].section[0].questions[0].questionType ===
            "Signature"
          ) {
            obj.section[0].questions[0].signature !== "" && _fillcount++;
            obj.section[0].questions[0].picture !== "" && _fillcount++;
            _totalcount = 2;
          } else if (
            form_template[0].section[0].questions[0].questionType ===
            "Terms&condition"
          ) {
            form_template[0].section[0].questions[0].checkbox_model[0].value &&
              _fillcount++;
            _totalcount = 1;
          }
          break;

        case "2":
          obj.questions.map((qobj, i) => {
            if (i === 0) {
              qobj.answer !== "" && _fillcount++;
              // qobj.answer !== '' && _fillcount++;
              // _totalcount++;
            } else {
              qobj.answer !== "" && _fillcount++;
            }
            _totalcount++;
          });
          break;
        case "3":
          obj.questions.map((qobj, i) => {
            if (i === 1) {
              let _c = 0;
              qobj.multipleQuestion[0].questions.map((mqobj) => {
                mqobj.description !== "" && _c++;
              });
              if (_c === 3) _fillcount++;
            } else {
              qobj.answer !== "" && _fillcount++;
            }
            _totalcount++;
          });
          break;
        case "4":
          obj.questions.map((qobj, i) => {
            if (i < 4) {
              qobj.answer !== "" && _fillcount++;
              _totalcount++;
            }
          });
          break;
        case "5":
          obj.questions.map((qobj, i) => {
            if (i === 7 || (i === 8) | (i === 9)) {
              let _c = 0;
              qobj.subQuestion.map((sqobj) => {
                sqobj.answer !== "" && _c++;
              });
              _c === 2 && _fillcount++;
            } else {
              qobj.answer !== "" && _fillcount++;
            }
            _totalcount++;
          });
          break;
        case "7":
          obj.questions.map((qobj, i) => {
            if (i === 1 || (i === 2) | (i === 3)) {
              let _c = 0;
              qobj.subQuestion.map((sqobj) => {
                sqobj.answer !== "" && _c++;
              });
              _c === 2 && _fillcount++;
            } else {
              qobj.answer !== "" && _fillcount++;
            }
            _totalcount++;
          });
          break;
        case "8":
          obj.questions.map((qobj, i) => {
            qobj.answer !== "" && _fillcount++;
            _totalcount++;
          });
          break;
        case "10":
          obj.questions.map((qobj, i) => {
            qobj.answer !== "" && _fillcount++;
            _totalcount++;
          });
          break;
        case "11":
          let _c = 0;
          obj.questions[0].table_options.map((qobj, i) => {
            qobj.common && _c++;
          });
          _totalcount++;
          _c > 0 && _fillcount++;
          break;
        case "12":
          obj.questions.map((qobj, i) => {
            if (i === 1) {
              qobj.picture !== "" && _fillcount++;
            } else {
              qobj.answer !== "" && _fillcount++;
            }
            _totalcount++;
          });
          break;

        case "6":
          obj.section[0].questions.map((qobj, i) => {
            if (i === 0) {
              qobj.picture !== "" && _fillcount++;
            } else {
              qobj.answer !== "" && _fillcount++;
            }
            _totalcount++;
          });
          break;
        case "13":
          let c = 0;
          obj.section[0].questions[0].multiselect_options.map((mqobj) => {
            mqobj.is_selected && c++;
          });
          c > 0 && _fillcount++;
          _totalcount++;
          break;
        case "14":
          obj.section[0].questions.map((qobj, i) => {
            qobj.answer !== "" && _fillcount++;
            _totalcount++;
          });
          break;
        default:
      }
      return {
        sectionId: obj.sectionId,
        sectionName: obj.sectionName,
        fillcount: _fillcount,
        totalcount: _totalcount,
      };
    });
    crewMemberId.map((objCwID, j) => {
      _getCount1[j] = form_template
        .map((obj) => {
          let _fillcount = 0;
          let _totalcount = 0;
          switch (obj.sectionId) {
            case "1":
              if (
                form_template[0].section[j + 1].questions[0].questionType ===
                "Signature"
              ) {
                obj.section[j + 1].questions[0].signature !== "" &&
                  _fillcount++;
                obj.section[j + 1].questions[0].picture !== "" && _fillcount++;
                _totalcount = 2;
              } else if (
                form_template[0].section[j + 1].questions[0].questionType ===
                "Terms&condition"
              ) {
                form_template[0].section[j + 1].questions[0].checkbox_model[0]
                  .value && _fillcount++;
                _totalcount = 1;
              }
              return {
                sectionId: obj.sectionId,
                sectionName: obj.sectionName,
                fillcount: _fillcount,
                totalcount: _totalcount,
                _step: 0,
              };
            // break;

            case "6":
              obj.section[j + 1].questions.map((qobj, i) => {
                if (i === 0) {
                  qobj.picture !== "" && _fillcount++;
                } else {
                  qobj.answer !== "" && _fillcount++;
                }
                _totalcount++;
              });
              return {
                sectionId: obj.sectionId,
                sectionName: obj.sectionName,
                fillcount: _fillcount,
                totalcount: _totalcount,
                _step: 10,
              };
            // break;
            case "13":
              let c = 0;
              obj.section[j + 1].questions[0].multiselect_options.map(
                (mqobj) => {
                  mqobj.is_selected && c++;
                }
              );
              c > 0 && _fillcount++;
              _totalcount++;
              return {
                sectionId: obj.sectionId,
                sectionName: obj.sectionName,
                fillcount: _fillcount,
                totalcount: _totalcount,
                _step: 11,
              };
            // break;
            case "14":
              obj.section[j + 1].questions.map((qobj, i) => {
                if (
                  i === 2 &&
                  qobj.answer !== "" &&
                  qobj.signature &&
                  qobj.picture
                ) {
                  _fillcount++;
                } else if (i < 2 && qobj.answer !== "") {
                  _fillcount++;
                }
                _totalcount++;
              });
              return {
                sectionId: obj.sectionId,
                sectionName: obj.sectionName,
                fillcount: _fillcount,
                totalcount: _totalcount,
                _step: 12,
              };
            // break;
            default:
          }
        })
        .filter((obj) => obj !== undefined);
    });
    setCount1(_getCount1);
    setCount(_getCount);
  }, [getCrewLeadData]);
  // console.log(getCrewLeadData, CREW_DETAILS, "CREW_DETAILS:::empid", EMP_ID.replace(/['"]+/g, ''))
  //console.log(form_template, "CREW_DETAILS", getCount, getCount1);
  const crewChangeHandler = (_key, _step = 0) => {
    let _getCrewMemberProceed = _.cloneDeep(getCrewMemberProceed);
    _getCrewMemberProceed.crew = _key + 1;

    if (getFormID === 10) {
      _getCrewMemberProceed.steps = _step;
      setProceed(_step);
    } else {
      _getCrewMemberProceed.steps = 1;
      setProceed(1);
    }
    setProceed1(0);
    setCrewMemberProceed(_getCrewMemberProceed);
    setCrewfill(true);
    // setPFASOptions([])
  };
  const crewLeadChangeHandler = (_key) => {
    let _getCrewMemberProceed = _.cloneDeep(getCrewMemberProceed);
    _getCrewMemberProceed.crew = 0;
    setCrewMemberProceed(_getCrewMemberProceed);
    setProceed(_key);
    setProceed1(0);
    setCrewfill(false);
    // setPFASOptions([])
  };
  const crewMemberIsFilled = (_key) => {
    let result = getCount1[_key]
      .map((obj, i) => {
        if (i == 0 || i == 1) {
          return (
            obj.fillcount == obj.totalcount && getIsCompleted[_key + 1] && true
          );
        }
      })
      .filter((obj) => obj !== undefined)
      .every((obj) => obj === true);
    //  console.log(result, 'filled')
    let _getCrewMemberCls = _.cloneDeep(getCrewMemberCls);

    if (!_.isEmpty(getCount1[_key])) {
      if (!result) {
        _getCrewMemberCls[_key] = {
          badge: false,
          comp: (
            <img
              src={String(check)}
              className="ms-5 filter-green border border-success rounded-circle  "
              style={{ width: "22px", padding: "2px" }}
              alt="check-success"
            />
          ),
        };
      } else {
        _getCrewMemberCls[_key] = {
          badge: true,
          comp: (
            <span>
              <img
                src={String(close)}
                className="ms-5 filter-red border border-danger rounded-circle  "
                style={{ width: "22px", padding: "2px" }}
                alt="check-success"
              />
              <Badge className="ms-2" bg="danger">
                Not Yet Filled In
              </Badge>
            </span>
          ),
        };
      }
    }
    setCrewMemberCls(_getCrewMemberCls);
    return !result;
  };

  const deleteCrewMember = (_key) => {
    setDelCrew(true);
    let _getIsCompleted = _.cloneDeep(getIsCompleted);
    _getIsCompleted = _getIsCompleted.filter((i, k) => k !== _key);
    setIsCompleted(_getIsCompleted);
    ///////
    let _form_template = _.cloneDeep(form_template);
    _form_template.filter((obj, k) => {
      if (!_.isEmpty(obj.section)) {
        obj.section = obj.section.filter((s_obj, i) => i !== _key && s_obj);
      }
      return obj;
    });
    setForm_template(_form_template);
    //////
    let _getCrewLeadData = _.cloneDeep(getCrewLeadData);
    _getCrewLeadData.crewmemberdata = _getCrewLeadData.crewmemberdata.filter(
      (obj, k) => k !== _key - 1 && obj
    );
    //  crewMemberId = _getCrewLeadData.crewmemberdata.map(obj => obj.employeeId);
    setCrewLeadData(_getCrewLeadData);

    //////
    let _getCrewMemberProceed = _.cloneDeep(getCrewMemberProceed);
    let crewMemLeg = _getCrewLeadData.crewmemberdata.length;
    if (_getCrewMemberProceed.crew > crewMemLeg) {
      _getCrewMemberProceed.crew = crewMemLeg;
    }
    setCrewMemberProceed(_getCrewMemberProceed);
  };

  // console.log('badge::', getCrewMemberCls)
  return (
    <>
      <Accordion defaultActiveKey={[0]} alwaysOpen className="mb-3">
        <Accordion.Item eventKey={0}>
          <Accordion.Header>
            <div className="rounded border p-2 bd-highlight">
              <img
                src={profile_img_url}
                style={{ width: "60px" }}
                alt="profilePic"
              />
            </div>
            <div className="p-2 bd-highlight">{getUserName}</div>
            <img
              src={String(check)}
              className="ms-5 filter-green border border-success rounded-circle  "
              style={{ width: "22px", padding: "2px" }}
              alt="check-success"
            />
          </Accordion.Header>
          <Accordion.Body>
            <Row xs={12}>
              <Col md={4}>
                <ul className="list-group mt-3 mb-3">
                  {!_.isEmpty(getCount[crewleadId]) &&
                    getCount[crewleadId].map((obj, i) => {
                      return (
                        <li
                          key={obj.sectionId}
                          className="list-group-item d-flex flex-row bd-highlight align-items-center"
                        >
                          <span
                            className={
                              obj.fillcount !== obj.totalcount
                                ? "w-50 text-warning"
                                : "w-50 text-success"
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() => crewLeadChangeHandler(i)}
                          >
                            <b>{obj.sectionName}</b>
                          </span>
                          <span
                            className={
                              obj.fillcount !== obj.totalcount
                                ? "ms-3 w-25 text-warning"
                                : "ms-3 w-25 text-success"
                            }
                          >
                            ({obj.fillcount}/{obj.totalcount})
                          </span>
                          <img
                            src={String(check)}
                            className="ms-5 filter-green border border-success rounded-circle  "
                            style={{ width: "22px", padding: "2px" }}
                            alt="check-success"
                          />
                        </li>
                      );
                    })}
                </ul>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        {/* {
                    // getCount['crewMembers'] !== undefined &&
                    getCrewLeadData.crewmemberdata.map((objCwID, j) => { */}

        {getCrewLeadData.crewmemberdata.map((objCwID, j) => (
          <Accordion.Item eventKey={j + 1} key={j} className="crewMemAccord">
            <Accordion.Header
              style={{ zIndex: "0" }}
              onClick={() => crewMemberIsFilled(j) && crewChangeHandler(j)}
            >
              {objCwID.firstName + " " + objCwID.lastName}

              {!_.isEmpty(getCount1[j]) &&
              getIsCompleted[j + 1] &&
              getCount1[j][0].fillcount === getCount1[j][0].totalcount ? (
                <img
                  src={String(check)}
                  className="ms-5 filter-green border border-success rounded-circle  "
                  style={{ width: "22px", padding: "2px" }}
                  alt="check-success"
                />
              ) : (
                <span>
                  <img
                    src={String(close)}
                    className="ms-5 filter-red border border-danger rounded-circle  "
                    style={{ width: "22px", padding: "2px" }}
                    alt="check-success"
                  />
                  <Badge className="ms-2" bg="danger">
                    Not Yet Filled In
                  </Badge>
                </span>
              )}
            </Accordion.Header>
            <div className="d-flex deleteCrewDiv">
              <img
                className="icon-red-color pointer"
                src={String(trashcan)}
                onClick={() => deleteCrewMember(j + 1)}
                alt="delete-icon"
              />
            </div>
            <Accordion.Body>
              <Row xs={12}>
                <Col md={4}>
                  <ul className="list-group mt-3 mb-3">
                    {!_.isEmpty(getCount1[j]) &&
                      getCount1[j].map((obj, i) => {
                        return (
                          <li
                            key={obj.sectionId}
                            className="list-group-item d-flex flex-row bd-highlight align-items-center"
                          >
                            <span
                              className={
                                obj.fillcount !== obj.totalcount
                                  ? "w-50 text-warning"
                                  : "w-50 text-success"
                              }
                              style={{ cursor: "pointer" }}
                              onClick={() => crewChangeHandler(j, obj._step)}
                            >
                              <b>{obj.sectionName}</b>
                            </span>
                            <span
                              className={
                                obj.fillcount !== obj.totalcount
                                  ? "ms-3 w-25 text-warning"
                                  : "ms-3 w-25 text-success"
                              }
                            >
                              ({obj.fillcount}/{obj.totalcount})
                            </span>
                            <img
                              src={String(check)}
                              className="ms-5 filter-green border border-success rounded-circle  "
                              style={{ width: "22px", padding: "2px" }}
                              alt="check-success"
                            />
                          </li>
                        );
                      })}
                  </ul>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      {/* <Row xs={12}>
                <Col md={6} className="d-flex flex-row bd-highlight align-items-center mb-3 position-relative" >
                    <div className="rounded border p-2 bd-highlight" >
                        <img src={profile_img_url} style={{ width: "60px" }} alt="profilePic" />
                    </div>
                    <div className="p-2 bd-highlight">{getUserName}</div>

                </Col>
            </Row> */}
      {/* <Row xs={12}>
                <Col md={4}>
                    <ul className="list-group mt-3 mb-3">
                        {!_.isEmpty(getCount) &&
                            getCount.map((obj, i) => {
                                return (
                                    <li key={obj.sectionId} className="list-group-item d-flex flex-row bd-highlight align-items-center">
                                        <span className={obj.fillcount !== obj.totalcount ? "w-50 text-warning" : "w-50 text-success"} style={{ cursor: "pointer" }} onClick={() => {
                                            setProceed(i)
                                            setProceed1(0)
                                        }}><b>{obj.sectionName}</b></span>
                                        <span className={obj.fillcount !== obj.totalcount ? "ms-3 w-25 text-warning" : "ms-3 w-25 text-success"} >({obj.fillcount}/{obj.totalcount})</span>
                                        <img src={String(check)} className="ms-5 filter-green border border-success rounded-circle  " style={{ width: "22px", padding: "2px" }} alt="check-success" />
                                    </li>
                                )
                            })

                        }
                    </ul>


                </Col>
            </Row> */}
    </>
  );
}
