import { TOKEN } from "../utils/Variable";
import { URL } from "../utils/Constant";

export const GetJWTToken = async (param) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  let jsonData = JSON.stringify(param);

  const resp = await fetch(URL.GetJWTToken, {
    method: "POST",
    body: jsonData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      let JitsiToken = JSON.stringify(data.data.jitsi_token);
      sessionStorage.setItem("JitsiToken", JitsiToken);
    }
  }
};
