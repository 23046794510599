import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./FormProgressBar.scss";
import _ from "lodash";

export default function FormProgressBar({
  getReportfilled,
  setProceed,
  getProgressCount,
  _selectedSectionJson,
  setRequired,
  stepSection,
  getsummary,
}) {
  const [getProgress, setProgress] = useState([]);

  useEffect(() => {
    checkRequired();
  }, [_selectedSectionJson]);

  useEffect(() => {
    let _emptyArr = _.cloneDeep(getProgress);
    _emptyArr.push(getProgressCount);
    _emptyArr = [...new Set(_emptyArr)];
    setProgress(_emptyArr);
  }, [getProgressCount]);

  const checkRequired = async () => {
    let _form_template = _.cloneDeep(_selectedSectionJson);
    let flg = true;
    _form_template.map((obj, k) => {
      if (stepSection === getProgressCount) {
        if (k === getProgressCount) {
          obj.questions.map((s_obj) => {
            if (flg) {
              if (s_obj.answer === "" && s_obj.state !== "") {
                setRequired(false);
                flg = false;
                if (s_obj.signature !== "") {
                  setRequired(true);
                }
              } else {
                setRequired(true);
              }
            }
          });
        }
      } else {
        if (obj.sectionId === stepSection.toString()) {
          obj.questions.map((s_obj) => {
            if (flg) {
              if (s_obj.answer === "" && s_obj.state !== "") {
                setRequired(false);
                flg = false;
                if (s_obj.signature !== "") {
                  setRequired(true);
                }
              } else {
                setRequired(true);
              }
            }
          });
        }
      }
    });
  };

  const selectGo = (i, e) => {
    if (!e.currentTarget.classList.contains("visibleSummary")) {
      if (getReportfilled) {
        setProceed(i);
      } else {
        let _finsalArr = [...new Set(getProgress)];
        if (_finsalArr.includes(i)) {
          setProceed(i);
        }
      }
    }
  };

  return (
    <>
      <ul className="step-wizard-list">
        {_selectedSectionJson.map((obj, i) => {
          let sectionName = obj.sectionName;
          if (obj.sectionId === "15") sectionName = 'Capstan / "Cathead"';
          return (
            <li
              key={i}
              onClick={(e) => selectGo(i, e)}
              className={`step-wizard-item ${
                getProgressCount === i ? "current-item " : ""
              }${
                getProgress.includes(i) || getReportfilled ? "selected " : ""
              }${getsummary ? "visibleSummary" : ""}`}
            >
              <span className="progress-count ">{i + 1}</span>
              <span className="progress-label">{sectionName}</span>
            </li>
          );
        })}
      </ul>
    </>
  );
}
