import { URL } from "../utils/Constant";
import { headers, TOKEN, EMP_ID } from "../utils/Variable";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

export const getCertificateOptionList = async (
  setCertificateOptionListD,
  setLoading
) => {
  setLoading(true);
  headers.set("Authorization", "Bearer " + TOKEN());
  const resp = await fetch(URL.CertifiateOptionList, {
    method: "get",
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      let _certificateList = data.data.map((obj) => {
        obj["_certificateID"] = Number(obj["certificateID"]);
        return obj;
      });
      _certificateList = _.orderBy(
        _certificateList,
        ["_certificateID"],
        ["asc"]
      );
      setCertificateOptionListD(_certificateList);
      setLoading(false);
    }
  }
};

export const uploadFrontBackImgApi = async (
  setFrontBackImgData,
  getFrontBackImg,
  setLoading,
  setMsg1,
  setCheckImgValid
) => {
  console.log("'setLoading': setLoading", getFrontBackImg);
  setLoading(true);
  setMsg1("");
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
  };

  var formData = new FormData();
  formData.append("front", getFrontBackImg["front"]);
  formData.append("back", getFrontBackImg["back"]);
  formData.append("upload_type", "FORM_CERTIFICATE");
  formData.append("reportId", uuidv4());
  // formData.append('employeeId', EMP_ID.replace(/['"]+/g, ''));
  const resp = await fetch(URL.UploadMultiImage, {
    method: "POST",
    body: formData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      setFrontBackImgData(data.data);
      if (_.isEmpty(data.data)) {
        setCheckImgValid(true);
        setMsg1("Both front & back image should be different.");
      } else {
        setCheckImgValid(false);
        setMsg1("");
      }
    } else if (data.status_code === 201) {
      setMsg1(data.message);
    }
    setLoading(false);
  }
};

// export const addCertificateApi = async (jsonData, setLoading, setMsg, getUserSection, setStatusCode, setShowMdl) => {
//     console.log("jsonData:::", jsonData);
//     setLoading(true);
//     let headersList = {
//         "Accept": "*/*",
//         "Authorization": "Bearer " + TOKEN(),
//         "Content-Type": "application/json"
//     }
//     const resp = await fetch(URL.AddCertifiate,
//         {
//             method: 'PUT',
//             body: jsonData,
//             headers: headersList
//         });
//     if (resp.ok) {
//         const data = await resp.json();
//         if (data.status_code === 200) {
//             console.log(data)
//             if (getUserSection === undefined && getUserSection !== true) {
//                 window.location.pathname = '/profile';
//             } else {
//                 setStatusCode(data.status_code)
//             }
//         }
//         setMsg(data.message);
//         setLoading(false);
//         setShowMdl(false);
//     }
// }

// export const deleteCertificateApi = async (certificateSeqId, setLoading, setMsg, getUserSection, setStatusCode, setShowMdl) => {
//     console.log("certificateSeqId delete:::", certificateSeqId);

//     setLoading(true);
//     let headersList = {
//         "Accept": "*/*",
//         "Authorization": "Bearer " + TOKEN(),
//         "Content-Type": "application/json"
//     }
//     const resp = await fetch(URL.DeleteCertificate,
//         {
//             method: 'PUT',
//             body: JSON.stringify({
//                 certificateSeqId: certificateSeqId,
//                 employeeId: EMP_ID.replace(/['"]+/g, '')
//             }),
//             headers: headersList
//         });
//     if (resp.ok) {
//         const data = await resp.json();
//         if (data.status_code === 200) {
//             if (getUserSection === undefined && getUserSection !== true) {
//                 window.location.pathname = '/profile';
//             } else {
//                 setStatusCode(data.status_code)
//             }
//         }
//         setMsg(data.message);
//         setLoading(false);
//         setShowMdl(false);
//     }
// }

// export const updateCertificateApi = async (jsonData, setLoading, setMsg, getUserSection, setStatusCode, setShowMdl) => {
//     console.log("jsonData:::", jsonData);
//     setLoading(true);
//     let headersList = {
//         "Accept": "*/*",
//         "Authorization": "Bearer " + TOKEN(),
//         "Content-Type": "application/json"
//     }
//     const resp = await fetch(URL.UpdateCertifiate,
//         {
//             method: 'PUT',
//             body: jsonData,
//             headers: headersList
//         });
//     if (resp.ok) {
//         const data = await resp.json();
//         if (data.status_code === 200) {
//             if (getUserSection === undefined && getUserSection !== true) {
//                 window.location.pathname = '/profile';
//             }else {
//                 setStatusCode(data.status_code);
//             }
//         }
//         setMsg(data.message);
//         setLoading(false);
//         setShowMdl(false);
//     }
// }
