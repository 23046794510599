import { useEffect } from "react";
import Select from "react-select";
import _ from "lodash";
export default function MultiSelect3({ options, getOption, setOption }) {
  console.log("getLevelData:M:", getOption, options);
  useEffect(() => {
    console.log(options["LevelOptions"], "LevelOptions");
  }, [options["LevelOptions"]]);
  return (
    <Select
      // defaultValue={options["defaultOptions"]}
      isMulti
      name="colors"
      value={_.isEmpty(getOption) ? options["defaultOptions"] : getOption}
      options={options["LevelOptions"]}
      onChange={(e) => setOption(e)}
      className="basic-multi-select mb-3"
      classNamePrefix="select"
    />
  );
}
