import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import debounce from "lodash/debounce";

function ToastMessage(warningmessagerequired) {
  const [toastDisplayed, setToastDisplayed] = useState(false);

  const displayToast = debounce(() => {
    if (
      warningmessagerequired &&
      warningmessagerequired.value &&
      !toastDisplayed
    ) {
      const { Temperature, WindGust } = warningmessagerequired.value;
      setToastDisplayed(true);

      if (Temperature && WindGust) {
        toast.error(
          `Current ${Temperature} value is outside the recommended range set by your account administrator`,
          {
            autoClose: 5000,
            hideProgressBar: false,
            newestOnTop: false,
            rtl: false,
            theme: "light",
            onClose: () => setToastDisplayed(false),
          }
        );
        toast.error(
          `Current ${WindGust} value is outside the recommended range set by your account administrator`,
          {
            autoClose: 5000,
            hideProgressBar: false,
            newestOnTop: false,
            rtl: false,
            theme: "light",
            onClose: () => setToastDisplayed(false),
          }
        );
      } else if (Temperature) {
        toast.error(
          `Current ${Temperature} value is outside the recommended range set by your account administrator`,
          {
            autoClose: 5000,
            hideProgressBar: false,
            newestOnTop: false,
            rtl: false,
            theme: "light",
            onClose: () => setToastDisplayed(false),
          }
        );
      } else if (WindGust) {
        toast.error(
          `Current ${WindGust} value is outside the recommended range set by your account administrator`,
          {
            autoClose: 5000,
            hideProgressBar: false,
            newestOnTop: false,
            rtl: false,
            theme: "light",
            onClose: () => setToastDisplayed(false),
          }
        );
      }
    }
  }, 500);

  useEffect(() => {
    displayToast();
  }, []);

  return (
    <div className="toast_message">
      <ToastContainer />
    </div>
  );
}

export default ToastMessage;
