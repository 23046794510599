import React, { useState, memo, useEffect } from "react";

import {
  GoogleMap,
  DirectionsRenderer,
  useJsApiLoader,
} from "@react-google-maps/api";
import { googleMapsKey } from "../../utils/Constant";
// const defaultLocation = { lat: 40.756795, lng: -73.954298 };
// let origin = { lat: 40.756795, lng: -73.954298 };
// let origin = { lat: 28.581079714427815, lng: 77.15323348001913 }
// let destination = { lat: 41.756795, lng: -78.954298 };
// let destination = { lat: 28.607310039891996, lng: 77.19404081193474 }
let directionsService;
const containerStyle = {
  width: "100%",
  height: "300px",
};

function GMapDirection({ defaultLocation, orig, dest, setDirection }) {
  const [origin, setOrigin] = useState(orig);
  const [destination, setDestination] = useState(dest);
  const [zget, zset] = useState(0);

  const [directions, setDirections] = useState(null);
  const [bounds, setBounds] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsKey,
    libraries: ["places"],
  });
  const onMapLoad = (map) => {
    directionsService = new window.google.maps.DirectionsService();
    //load default origin and destination
    // map.fitBounds(directionsService);
    changeDirection(origin, destination);
  };
  //function that is calling the directions service
  const changeDirection = (origin, destination) => {
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          //changing the state of directions to the result of direction service

          setDirection(result);
          setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };
  // console.log(directions);
  setTimeout(() => {
    zset(15);
  }, 1000);
  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={defaultLocation}
        // zoom={zget}
        onLoad={(map) => onMapLoad(map)}
      >
        {directions !== null && <DirectionsRenderer directions={directions} />}
      </GoogleMap>
    </div>
  ) : null;
}

export default memo(GMapDirection);
