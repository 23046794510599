import { TOKEN } from "../utils/Variable";
import { URL } from "../utils/Constant";

export const GetHazardTopic = async () => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };

  const resp = await fetch(URL.GetSafetyHazardTopic, {
    method: "GET",
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    return data.data;
  }
};
