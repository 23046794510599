import { useEffect } from "react";
import Select from "react-select";
import _ from "lodash";
export default function MultiSelect4({ options, getOption, setOption }) {
  useEffect(() => {}, [options]);
  useEffect(() => {
    let _getOption = _.cloneDeep(getOption);
    let _valoption = _getOption.map((obj) => obj.value);
    if (_valoption.includes("Other")) {
      _getOption = _getOption.filter((obj) => {
        return obj.value !== "Other";
      });
      setOption(_getOption);
    }
  }, [getOption]);

  // function onChangeHandler(e) {
  //     let _getOption = _.cloneDeep(getOption);
  //     console.log('asdasd: 2', e)
  //     _getOption = e;

  //     _getOption = _getOption.filter((obj) => {
  //         if (obj.value !== 'Other')
  //             return obj;
  //     });
  //     console.log('asdasd: 2', _getOption);
  //     setOption(_getOption)
  // }
  return (
    <Select
      // defaultValue={options["defaultOptions"]}
      isMulti
      name="colors"
      value={getOption}
      options={options}
      onChange={(e) => setOption(e)}
      // onChange={(e) => onChangeHandler(e)}
      className="basic-multi-select mb-3"
      classNamePrefix="select"
    />
  );
}
