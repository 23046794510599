import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

export default function MultiselectDropDownForStatus({
  getStatusData,
  setStatusOption,
  getInProgressSet,
  setInProgressSet,
}) {
  const [btnClick, setBtnCLick] = useState(false);
  const [getInprogressCheck, setInprogressCheck] = useState(getInProgressSet);
  const [selected, setSelected] = useState(
    getStatusData
      ? Object.keys(getStatusData).map((key) => ({
          label: getStatusData[key],
          value: key,
        }))
      : []
  );

  const inprogressselected = [{ label: "In Progress", value: "5" }];

  const options = getStatusData
    ? Object.keys(getStatusData).map((key) => ({
        label: getStatusData[key],
        value: key,
      }))
    : [];

  const overrideStrings = {
    allItemsAreSelected: "All",
    noOptions: "No Option is Selected",
  };

  const onChange = (values) => {
    setInprogressCheck(null);
    setInProgressSet(null);
    setSelected(values);
    setBtnCLick(true);
    const filterData = values.map((i) => i.value);
    setStatusOption(filterData);
  };

  window.addEventListener("click", function (e) {
    if (document.querySelectorAll(".dropdown-container1").length !== 0) {
      if (!document.querySelector(".dropdown-container1").contains(e.target)) {
        setBtnCLick(false);
      }
    }
  });

  return (
    <div className="multiSelectCheckBox">
      <MultiSelect
        className="multiSelectDropDown"
        options={options}
        value={getInprogressCheck === null ? selected : inprogressselected}
        onChange={(values) => onChange(values)}
        labelledBy="Select Options"
        disableSearch={true}
        hasSelectAll={true}
        overrideStrings={overrideStrings}
      />
    </div>
  );
}
