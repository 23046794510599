import React, { useEffect, useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Button,
  Modal,
  ListGroup,
} from "react-bootstrap";
import logo from "../../assets/Icons/sw_logo.svg";
// import helpdesk from "../../assets/Icons/helpdesk.svg";
import profile from "../../assets/Icons/profile.svg";
// import dashboard from "../../assets/Icons/dashboard.svg";
import switching from "../../assets/Icons/switching.svg";
import person from "../../assets/Icons/profile.svg";
// import success from '../../assets/Icons/success.svg'
import reports from "../../assets/Icons/reports.svg";
import app_launcher from "../../assets/Icons/app_launcher.svg";
import message_double from "../../assets/Icons/message_double.svg";
import logoutimg from "../../assets/Icons/logoutimg.svg";
import success_tick from "../../assets/Icons/success_tick.png";
import reset_password from "../../assets/Icons/reset-password.svg";
// import { domain_url } from "../../utils/Constant";
import { Link, NavLink } from "react-router-dom";
import "./header.scss";
import Routing from "../../Routing";
import { useNavigate } from "react-router-dom";
import { DECRYPTED_DATA, UserGroupId, TOKEN } from "../../utils/Variable";
import { GetUserGroup } from "../../api/Multitenancy.api";
// import Loading from "../../components/Loading/Loading";
import { useLogout } from "../../api/Login.api";
import { useMsal } from '@azure/msal-react';
import { b2cPolicies } from '../../authConfig';
import _ from "lodash";
import packageJson from "../../../package.json";

export default function Header({ setshowsidepanel, showsidepanel }) {
  // const { instance } = useMsal();
  const [logout] = useLogout();

  const [ShowDialogBox, setShowDialogBox] = useState(false);
  // const [getUserName, setUserName] = useState(
  //   DECRYPTED_DATA().firstName + " " + DECRYPTED_DATA().lastName
  // );
  const [getLoading, setLoading] = useState(false);
  const [getErrorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const [getIdpTokenAccess, setIdpTokenAccess] = useState(true);
  const { instance } = useMsal();

  useEffect(() => {
    const _token = TOKEN();
    if (!_.isEmpty(_token)) {
      const base64Url = _token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      if (sessionStorage.getItem('idp_access_token') === null) {
        setIdpTokenAccess(JSON.parse(jsonPayload).idp_access_token ? true : false);
        sessionStorage.setItem('idp_access_token', JSON.parse(jsonPayload).idp_access_token ? true : false)
      } else {
        setIdpTokenAccess(sessionStorage.getItem('idp_access_token') === "true" ? true : false)
      }
    }
  }, []);

  const handleUserGroup = async (item) => {
    let param = {
      Id: item.id,
    };
    const response = await GetUserGroup(param, setLoading, setErrorMsg);
    logout();
  };

  const handleSwitchgroup = () => {
    setShowDialogBox(true);
    setErrorMsg("");
  };

  const handleToggleSidePanel = () => {
    setshowsidepanel(!showsidepanel);
  };
  const changePassword = () => {
    instance.loginRedirect(b2cPolicies.authorities.changePassword).catch((error) => console.log(error));
  }

  return (
    <>
      <Navbar
        fixed="top"
        expand="sm"
        style={{
          height: "48px",
          backgroundColor: "#0c0c0c",
        }}
      >
        <Container fluid>
          <Navbar.Brand as={NavLink} to="/home" className="product">
            <img
              alt=""
              src={String(logo)}
              width="37"
              className="d-inline-block align-top whiteLogo"
            />{" "}
            <span className="ms-1">Safe Worker</span>
          </Navbar.Brand>

          {DECRYPTED_DATA() !== "" &&
            DECRYPTED_DATA().firstName !== undefined ? (
            <>
              <Nav className="me-auto"></Nav>

              <div className="d-flex justify-content-center profile-icon-header">
                <div className="appVersion">v{packageJson.version}</div>
                <NavDropdown.Item onClick={handleToggleSidePanel}>
                  <img
                    alt=""
                    src={String(app_launcher)}
                    width="36"
                    height="26"
                    className="d-inline-block align-top icon-white-color justify-content-start"
                    style={{
                      marginRight: "15px",
                      justifyContent: "flex-end",
                      pointerEvents: "auto",
                    }}
                  />
                </NavDropdown.Item>
                <Link to="/support-form">
                  <img
                    title="Contact Support"
                    alt=""
                    src={String(message_double)}
                    width="16"
                    height="16"
                    className="d-inline-block align-top icon-white-color"
                    style={{
                      marginRight: "20px",
                      marginTop: "2px",
                    }}
                  />
                </Link>
                <img
                  alt=""
                  src={String(person)}
                  width="16"
                  height="16"
                  className="d-inline-block align-top icon-white-color "
                  style={{ marginRight: "5px" }}
                />

                <NavDropdown
                  align="end"
                  id="nav-dropdown-dark-username"
                  title={
                    DECRYPTED_DATA().firstName + " " + DECRYPTED_DATA().lastName
                  }
                  menuVariant="dark"
                  className="nav-dropdown-dark-username"
                >
                  <NavDropdown.Item as={NavLink} to="/reports">
                    <img
                      src={String(reports)}
                      className="mb-1 me-2 icon-white-color"
                      alt="reports icon"
                      width="20"
                      height="20"
                    />
                    Reports
                  </NavDropdown.Item>

                  {DECRYPTED_DATA().hasOwnProperty("authorized_groups") &&
                    DECRYPTED_DATA().authorized_groups.length > 0 ? (
                    <NavDropdown.Item onClick={handleSwitchgroup}>
                      <img
                        src={String(switching)}
                        className="mb-1 me-2 icon-white-color"
                        alt="reports icon"
                        width="20"
                        height="20"
                      />
                      Switch Organization
                    </NavDropdown.Item>
                  ) : null}

                  <NavDropdown.Item as={NavLink} to="/profile">
                    <img
                      src={String(profile)}
                      className="mb-1 me-2 icon-white-color"
                      alt="reports icon"
                      width="20"
                      height="20"
                    />
                    Profile
                  </NavDropdown.Item>

                  {!getIdpTokenAccess && <NavDropdown.Item onClick={changePassword}>
                    <img
                      src={String(reset_password)}
                      className="mb-1 me-2 icon-white-color"
                      alt="reports icon"
                      width="20"
                      height="20"
                    />
                    Change Password
                  </NavDropdown.Item>}

                  <NavDropdown.Divider
                    style={{
                      backgroundColor: "currentColor",
                      opacity: ".25",
                    }}
                  />
                  <NavDropdown.Item onClick={logout}>
                    <img
                      src={String(logoutimg)}
                      className="mb-1 me-2 icon-white-color"
                      alt="reports icon"
                      width="20"
                      height="20"
                    />
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </>
          ) : null}

          {DECRYPTED_DATA() !== "" &&
            DECRYPTED_DATA().firstName !== undefined &&
            DECRYPTED_DATA().hasOwnProperty("authorized_groups") &&
            DECRYPTED_DATA().authorized_groups.length > 0 ? (
            <Modal
              size="sm"
              show={ShowDialogBox}
              onHide={() => setShowDialogBox(false)}
              centered
              dialogClassName="custom-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Switch Organization
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {getErrorMsg !== "" ? (
                  <div className="alert alert-danger">
                    <strong> {getErrorMsg}</strong>
                  </div>
                ) : (
                  <ListGroup variant="flush">
                    <ListGroup.Item className="d-flex justify-content-between align-items-center custom-heading">
                      <div>
                        <strong>Organization</strong>
                      </div>
                    </ListGroup.Item>
                    {DECRYPTED_DATA().authorized_groups.map((item) => (
                      <ListGroup.Item
                        key={item.id}
                        className="d-flex justify-content-between align-items-center"
                        action
                      >
                        <div>{item.GroupName}</div>
                        <div className="d-flex align-items-center">
                          {item.id === UserGroupId() ? (
                            <span>
                              <img
                                alt=""
                                src={String(success_tick)}
                                className="icon-greencolor"
                                width="15"
                                height="15"
                              />
                              Current
                            </span>
                          ) : (
                            <Button
                              variant="secondary"
                              size="md"
                              style={{
                                marginLeft: "12px",
                                borderRadius: "5px",
                                borderColor: "white",
                              }}
                              onClick={() => handleUserGroup(item)}
                            >
                              Switch
                            </Button>
                          )}
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </Modal.Body>
            </Modal>
          ) : null}
        </Container>
      </Navbar>

      <Routing />
    </>
  );
}
