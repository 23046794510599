import { Form, Button } from "react-bootstrap";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import failed from "../../assets/Icons/failed.svg";
import trashcan from "../../assets/Icons/trashcan.svg";
import _ from "lodash";
export default function DailyTaskforIJHA({
  questions_obj_key,
  setError,
  errors,
  form_template,
  setForm_template,
  getProceed,
  clearErrors,
}) {

  function click(index, e) {
    let _form_template = _.cloneDeep(form_template);
    const questionIndex = _form_template[getProceed - 1].questions.findIndex(
      (question) => question.questionType === "Description+Dropdown+Rearrange"
    );

    if (questionIndex !== -1) {
      _form_template[getProceed].questions[
        questions_obj_key
      ].multipleQuestion.splice(index, 1);
      _form_template[getProceed - 1].questions[questionIndex].options.splice(
        index,
        1
      );
      setForm_template(_form_template);
    }
  }
  function onChangeHandler(_key, index, e) {
    let _form_template = _.cloneDeep(form_template);
    _form_template[getProceed].questions[questions_obj_key].multipleQuestion[
      index
    ].questions[_key].description = e.target.value;
    setForm_template(_form_template);
  }
  function onClearHandler(_key, index) {
    let _form_template = _.cloneDeep(form_template);
    _form_template[getProceed].questions[questions_obj_key].multipleQuestion[
      index
    ].questions[_key].description = "";
    setForm_template(_form_template);
  }

  let questions =
    form_template[getProceed].questions[questions_obj_key].multipleQuestion;

  let _dailyTask = questions.map((row, index) => {
    return (
      <Form.Group
        key={index}
        controlId={`form-3-2-container-${index}`}
        className="mb-3 shadow p-3 mb-5 rounded"
      >
        {index !== 0 ? (
          <div className="d-flex justify-content-end">
            <Button variant="light" size="sm" onClick={(e) => click(index, e)}>
              <img
                className="icon-red-color"
                src={String(trashcan)}
                style={{ width: "22px" }}
              />
            </Button>
          </div>
        ) : null}
        {row.questions.map((question, questionIndex) => (
          <Form.Group
            key={questionIndex}
            controlId={`form-3-2-${questionIndex + 1}`}
            className="mb-3"
          >
            <Form.Label>{question.question}</Form.Label>
            <div className="position-relative ">
              <Form.Control
                className="rounded-0"
                as="textarea"
                rows={5}
                value={question.description}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setError(
                      `dt${
                        questionIndex === 1 ? "hazard" : "Mitigations"
                      }${index}`,
                      { message: "This field is required" }
                    );
                  } else {
                    clearErrors(
                      `dt${
                        questionIndex === 1 ? "hazard" : "Mitigations"
                      }${index}`
                    );
                  }
                  onChangeHandler(questionIndex, index, e);
                }}
                disabled={questionIndex === 0}
              />
              {questionIndex ? (
                <div className="position-absolute top-0 end-0 sig-controls">
                  <Button
                    variant="light"
                    size="sm"
                    onClick={(e) => onClearHandler(questionIndex, index)}
                  >
                    <img
                      src={String(failed)}
                      alt="clear"
                      style={{ width: "18px" }}
                    />
                  </Button>
                </div>
              ) : null}
            </div>
          </Form.Group>
        ))}
      </Form.Group>
    );
  });

  return <>{_dailyTask} </>;
}
