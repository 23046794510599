import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
export default function ReportsDownload() {
  let data = useLocation();
  const doc = new jsPDF();

  const [gets, sets] = useState(data.state.id);

  useEffect(() => {
    if (gets !== "") {
      doc.text(JSON.parse(gets), 10, 10);
      // doc.fromHTML(JSON.parse(gets), 10, 10);
      doc.save("a4.pdf");
      // doc.html(document.getElementById('pdf-view'), {
      //     callback: () => {
      //         doc.save('test.pdf');
      //     }
      // })
    }
    // window.print();
  }, [gets]);

  return <div id="pdf-view">{JSON.parse(gets)}</div>;
}
